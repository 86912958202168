import {
    NavigationContainer,
    NavigationLogoSection,
    NavigationPadding,
    NeveLogoLink,
    StyledNeveLogo,
} from './Navigation';
import strings from '../../strings/strings.json';
import styled from 'styled-components';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { sizes } from 'theme';
import LoginIcon from 'assets/icons/navigation/LoginIcon';
import { IconSize } from 'assets/icons/icon-sizes';
import React from 'react';
import { AuthContext } from 'contextProviders/AuthContext';
import { useLocation } from 'react-router-dom';

export default function NavigationPublic(): JSX.Element {
    const { login } = React.useContext(AuthContext);
    const location = useLocation();

    return (
        <>
            <NavigationContainer>
                <RowContent>
                    <NavigationLogoSectionPublic>
                        <NeveLogoLink to={'/'}>
                            <StyledNeveLogo
                                alt={strings.appHeader.neveLearningLogoAltText}
                                aria-label={strings.appHeader.neveLearningLogoAria}
                            />
                        </NeveLogoLink>
                    </NavigationLogoSectionPublic>
                    <LoginButtonContainer>
                        <PrimaryButton
                            title={strings.loginPage.submitButton}
                            aria={strings.loginPage.submitButtonAriaLabel}
                            onClick={() => login(location.pathname)}
                            icon={<LoginIcon size={IconSize.SMALL} />}
                        />
                    </LoginButtonContainer>
                </RowContent>
            </NavigationContainer>
            <NavigationPadding />
        </>
    );
}

const LoginButtonContainer = styled.div`
    margin-right: ${sizes.spacingMd};
`;

const NavigationLogoSectionPublic = styled(NavigationLogoSection)`
    border-bottom: unset;
`;

const RowContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    max-width: ${sizes.appNavWidth}px;
    height: 100%;
`;
