export const validateThreeCharacterLimit = (v: number | string | null) => {
    if (v === null || v === '') return true;
    return (Number(v) > 0 && Number(v) < 1000) || 'Choose a number from 1 to 999';
};

export const validateThreeCharacterLimitDurationRequired = (v: number | string | null) => {
    if (v === null || v === '') return 'Duration is a required field';
    if (Number(v) === 0) return 'Choose a number from 1 to 999';
    return (Number(v) > 0 && Number(v) < 1000) || 'Choose a number from 1 to 999';
};

export const validateSmallerThanValue = (
    v: number | string | null,
    otherValue: number | string | null,
    vName: string,
    otherValueName: string,
) => {
    if (v === null || v === '') return true;
    if (otherValue === null || otherValue === '') return true;

    if (Number(v) <= Number(otherValue)) return true;

    return `${vName} must be less than ${otherValueName}`;
};
