import * as React from 'react';
import styled from 'styled-components';
import { breakpoints, FONTSIZE, sizes, theme } from '../../theme';
import { BodySmall } from '../Elements';
import { StepInput, StepStatus } from './stepperTypes';
import TickIcon from '../../assets/icons/controls/TickIcon';

type StepperProps = {
    steps: StepInput[];
    currentStep: number;
};

export default function StepperNavigation({ steps, currentStep }: StepperProps): JSX.Element {
    const completedSteps = currentStep + 1;
    const totalSteps = steps.length;
    const currentStepName = steps[currentStep].name;

    return (
        <>
            <StepperContainer>
                {steps.map((step, index, allSteps) => {
                    let stepStatus = StepStatus.INCOMPLETE;

                    if (index === currentStep) stepStatus = StepStatus.CURRENT;
                    if (index < currentStep) stepStatus = StepStatus.COMPLETE;

                    if (allSteps.length === index + 1)
                        return <FinalStep step={step} stepStatus={stepStatus} key={index} />;

                    return <StandardStep step={step} stepStatus={stepStatus} key={index} />;
                })}
            </StepperContainer>
            <StepperMobileContainer>
                <StepMobileContainer $progress={(360 * Math.round((completedSteps / totalSteps) * 100)) / 100}>
                    <ProgressContainer>{completedSteps + ' of ' + steps.length}</ProgressContainer>
                </StepMobileContainer>
                <StepperTitle>{currentStepName}</StepperTitle>
            </StepperMobileContainer>
        </>
    );
}

function StandardStep(props: { step: StepInput; stepStatus: StepStatus }): JSX.Element {
    const { step, stepStatus } = props;

    return (
        <StepContainer>
            <StepIconContainer>
                <Step $status={stepStatus}>
                    {stepStatus === StepStatus.COMPLETE ? <TickIcon colour={theme.stepperBackgroundColour} /> : null}
                </Step>
                <StepConnector $status={stepStatus} />
            </StepIconContainer>
            <StepperText $bold>{step.name}</StepperText>
        </StepContainer>
    );
}

function FinalStep(props: { step: StepInput; stepStatus: StepStatus }): JSX.Element {
    const { step, stepStatus } = props;

    return (
        <FinalStepContainer>
            <StepIconContainer>
                <Step $status={stepStatus} />
            </StepIconContainer>
            <StepperText $bold>{step.name}</StepperText>
        </FinalStepContainer>
    );
}

const StepperContainer = styled.div`
    display: flex;
    padding: ${sizes.spacingMd};
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: ${theme.stepperBackgroundColour};
    @media (max-width: ${breakpoints.sm}) {
        display: none;
    }
`;

const Step = styled.div<{ $status?: StepStatus }>`
    border-radius: 50%;
    border: 5px solid
        ${(props) =>
            props.$status === StepStatus.INCOMPLETE ? theme.stepperIncompleteLineColour : theme.stepperTextColour};
    background-color: ${(props) =>
        props.$status === StepStatus.COMPLETE ? theme.stepperCompleteLineColour : theme.stepperIncompleteLineColour};
    width: 2rem;
    height: 2rem;
    margin-left: -1%;
    margin-right: -1%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
`;

const StepConnector = styled.div<{ $status?: StepStatus }>`
    flex-grow: 1;
    height: 0.5rem;
    border: 5px solid
        ${(props) =>
            props.$status === StepStatus.COMPLETE
                ? theme.stepperCompleteLineColour
                : theme.stepperIncompleteLineColour};
    display: inline;
`;

const StepContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 4rem;
    min-width: 4rem;
    flex-grow: 1;
    gap: ${sizes.spacingSm};
`;

const FinalStepContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 4rem;
    min-width: 4rem;
    gap: ${sizes.spacingSm};
`;

const StepIconContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
`;

const StepperText = styled(BodySmall)`
    color: ${theme.stepperTextColour};
`;

const StepperMobileContainer = styled.div`
    padding: 12px;
    background-color: ${theme.stepperBackgroundColour};
    display: none;
    align-items: center;
    gap: 12px;
    @media (max-width: ${breakpoints.sm}) {
        display: flex;
        padding-left: 20px;
    }
`;

const StepMobileContainer = styled.div<{ $progress: number }>`
    height: 62px;
    width: 62px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: radial-gradient(${theme.circularProgressBar.darkVersionIndicatorTrackColour} 50%, transparent 51%),
        conic-gradient(
            transparent 0deg ${({ $progress }) => $progress}deg,
            ${theme.circularProgressBar.darkVersionIndicatorTrackColour} ${({ $progress }) => $progress}deg 360deg
        ),
        conic-gradient(
            ${theme.circularProgressBar.darkVersionIndicatorColour} 0deg,
            ${theme.circularProgressBar.darkVersionIndicatorColour}
        );
`;

const ProgressContainer = styled.div`
    height: 50px;
    width: 50px;
    border-radius: 100%;
    background-color: ${theme.circularProgressBar.darkVersionBackgroundColour};
    color: ${theme.circularProgressBar.darkVersionTextColour};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 2px;
`;

const StepperTitle = styled.div`
    color: ${theme.circularProgressBar.darkVersionTextColour};
    font-size: ${FONTSIZE.BodySmall};
    font-weight: 700;
`;
