import * as React from 'react';
import strings from 'strings/strings.json';
// @ts-ignore
import { ReactComponent as CalendarIcon } from 'assets/icons/schedule-icons/schedule-white.svg';
import { useNavigate, useParams } from 'react-router-dom';
import useGetRequest from 'hooks/useGetRequest';
import { Course } from 'models/Course';
import SubpageHeader from '../../../components/sections/SubpageHeader';
import CourseOverview from 'components/courseSchedule/CourseOverview';
import SecondaryButton from 'components/buttons/SecondaryButton';
import { MainSectionWithGaps } from 'components/sections/MainSection';
import Skeleton from 'react-loading-skeleton';
import PrimaryButton from '../../../components/buttons/PrimaryButton';

export default function OACoursePage(): JSX.Element {
    const params = useParams();
    const { courseId } = params;
    const navigate = useNavigate();

    const { data: course, loading } = useGetRequest<Course>(`courses/${courseId}`, !courseId);

    const goToPreview = () => {
        navigate(`/preview/${courseId}`, {
            state: { previewContextEntryPoint: `/organisation/courses/${courseId}/view` },
        });
    };

    const backButtonDetails = React.useMemo(() => {
        return {
            title: strings.OACoursePage.backToCoursesButtonTitle,
            aria: strings.OACoursePage.backToCoursesButtonTitleAria,
            href: '/organisation/courses',
        };
    }, []);

    if (loading || !course)
        return (
            <>
                <SubpageHeader
                    title={<Skeleton height={56} count={1} />}
                    subtitle={<Skeleton height={32} count={1} />}
                    backButtonDetails={backButtonDetails}
                    shadedBackground
                    buttons={<></>}
                />
                <MainSectionWithGaps>
                    <Skeleton height={56} count={3} />
                    <Skeleton height={100} count={3} />
                </MainSectionWithGaps>
            </>
        );

    return (
        <>
            <SubpageHeader
                title={course?.title ?? null}
                subtitle={course?.description ?? null}
                backButtonDetails={backButtonDetails}
                shadedBackground
                buttons={
                    <>
                        <SecondaryButton
                            title={strings.courseEditorPage.previewCourseButtonTitle}
                            aria={strings.courseEditorPage.previewCourseButtonAria}
                            onClick={goToPreview}
                        />
                        <PrimaryButton
                            title={strings.coursePage.scheduleButtonTitle}
                            aria={strings.coursePage.scheduleButtonTitleAria}
                            onClick={() => {
                                navigate(`/schedule/${course.id}/`, {
                                    state: { returnUrl: `/organisation/courses`, backUrl: `/organisation/courses` },
                                });
                            }}
                            icon={<CalendarIcon />}
                        />
                    </>
                }
            />
            <CourseOverview course={course} />
        </>
    );
}
