import styled from 'styled-components';
import { sizes, theme } from '../../theme';
import { Headline1 } from '../Elements';

export default function AdminHeader({ title }: { title: string }): JSX.Element {
    if (!title) return <></>;

    return (
        <BannerBackground>
            <LimitedContainer>
                <Headline1>{title}</Headline1>
            </LimitedContainer>
        </BannerBackground>
    );
}

const LimitedContainer = styled.header`
    display: flex;
    margin: auto;
    max-width: ${sizes.appMaxWidthWithoutPadding}px;
    align-items: center;
    height: 100%;
    padding-left: ${sizes.edgePadding};
    padding-right: ${sizes.edgePadding};
`;

const BannerBackground = styled.div`
    background: ${theme.adminDashboardBannerBackground};
    height: 260px;
    width: 100%;
`;
