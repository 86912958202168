import * as React from 'react';
import styled from 'styled-components';
import strings from 'strings/strings.json';
import useGetRequest from 'hooks/useGetRequest';
import useWindowWidth from 'hooks/useWindowWidth';
import Skeleton from 'react-loading-skeleton';
import { breakpoints, sizes, theme } from 'theme';
import LearnerCourseCard from './components/LearnerCourseCard';
import MainSection from '../../components/sections/MainSection';
import LearnerHeader from '../../components/sections/LearnerHeader';
import Notification, { NotificationType } from '../../components/notifiers/Notification';
import { LearnerCourseOverview } from '../../models/LearnerCourseOverview';
import { Headline2, Headline3 } from 'components/Elements';
import CoursesIcon from 'assets/icons/navigation/CoursesIcon';
import { IconSize } from 'assets/icons/icon-sizes';

export default function LearnerCoursesPage(): JSX.Element {
    const {
        data: learnerCourseOverviews,
        loading,
        errors,
    } = useGetRequest<LearnerCourseOverview[]>('learner/schedules');
    const { isMobileWidth } = useWindowWidth();
    if (!learnerCourseOverviews || loading) {
        return (
            <>
                <LearnerHeader title={strings.learnerCoursesPage.title} />
                <MainSection>
                    <ListContainer>
                        <CourseList>
                            <TitleAndLogoContainer>
                                <Skeleton height={72} width={isMobileWidth ? 345 : 620} count={1} />
                            </TitleAndLogoContainer>
                            <CourseCardsContainer>
                                {isMobileWidth ? (
                                    <Skeleton height={400} width={345} count={1} borderRadius={30} />
                                ) : (
                                    <>
                                        <Skeleton height={493} width={392} count={1} borderRadius={30} />
                                        <Skeleton height={493} width={392} count={1} borderRadius={30} />
                                        <Skeleton height={493} width={392} count={1} borderRadius={30} />
                                    </>
                                )}
                            </CourseCardsContainer>
                        </CourseList>
                    </ListContainer>
                </MainSection>
            </>
        );
    }

    if (errors && errors.length > 0) {
        return (
            <>
                <LearnerHeader title={strings.learnerCoursesPage.title} />
                <MainSection>
                    <ListContainer>
                        <Notification
                            notificationType={NotificationType.DANGER}
                            title={strings.learnerCoursesPage.getUserDataError}
                        />
                    </ListContainer>
                </MainSection>
            </>
        );
    }

    const activeCourses:LearnerCourseOverview[] = []
    const oldCourses:LearnerCourseOverview[] = []
    const cancelledCourses:LearnerCourseOverview[] = []

    const endOfYesterday = new Date();
    endOfYesterday.setDate(endOfYesterday.getDate() - 1);
    endOfYesterday.setHours(23, 59, 59, 59);

    for(const course of learnerCourseOverviews) {
        if(course.cancelledDate) cancelledCourses.push(course)
        else if(new Date(course.endDate) <= endOfYesterday) {oldCourses.push(course)}
        else(activeCourses.push(course))
     } 

    return (
        <>
            <LearnerHeader title={strings.learnerCoursesPage.title} />
            <MainSection>
                {learnerCourseOverviews.length ? (
                    <ListContainer>
                        {activeCourses.length > 0 && (
                            <CourseList>
                                <TitleAndLogoContainer>
                                    <CoursesIcon size={isMobileWidth ? IconSize.XLARGE : IconSize.XXLARGE} />
                                    <Headline2Blue>{strings.learnerCoursesPage.currentCourses}</Headline2Blue>
                                </TitleAndLogoContainer>
                                <CourseCardsContainer>
                                    {activeCourses
                                        .sort(
                                            (a, b) =>
                                                new Date(b.startDate).getTime() - new Date(a.startDate).getTime(),
                                        )
                                        .map((courseOverview: LearnerCourseOverview) => {
                                            return (
                                                <LearnerCourseCard
                                                    key={courseOverview.id}
                                                    courseOverview={courseOverview}
                                                />
                                            );
                                        })}
                                </CourseCardsContainer>
                            </CourseList>
                        )}

                        {oldCourses.length > 0 && (
                            <CourseList>
                                <Headline3DarkBlue>{strings.learnerCoursesPage.oldCourses}</Headline3DarkBlue>
                                <Border />
                                <CourseCardsContainer>
                                    {oldCourses
                                        .sort(
                                            (a, b) =>
                                                new Date(b.startDate).getTime() - new Date(a.startDate).getTime(),
                                        )
                                        .map((courseOverview: LearnerCourseOverview) => {
                                            return (
                                                <LearnerCourseCard
                                                    key={courseOverview.id}
                                                    courseOverview={courseOverview}
                                                />
                                            );
                                        })}
                                </CourseCardsContainer>
                            </CourseList>
                        )}

                        {cancelledCourses.length > 0 && (
                            <CourseList>
                                <Headline3DarkBlue>{strings.learnerCoursesPage.cancelledCourses}</Headline3DarkBlue>
                                <Border />
                                <CourseCardsContainer>
                                    {cancelledCourses
                                        .sort(
                                            (a, b) =>
                                                new Date(b.startDate).getTime() - new Date(a.startDate).getTime(),
                                        )
                                        .map((courseOverview: LearnerCourseOverview) => {
                                            return (
                                                <LearnerCourseCard
                                                    key={courseOverview.id}
                                                    courseOverview={courseOverview}
                                                />
                                            );
                                        })}
                                </CourseCardsContainer>
                            </CourseList>
                        )}
                    </ListContainer>
                ) : (
                    <MainSection>
                        <ListContainer>
                            <TitleAndLogoContainer>
                                <CoursesIcon size={isMobileWidth ? IconSize.XLARGE : IconSize.XXLARGE} />
                                <Headline2Blue>{strings.learnerCoursesPage.currentCourses}</Headline2Blue>
                            </TitleAndLogoContainer>
                            <Notification
                                notificationType={NotificationType.INFO}
                                title={strings.learnerCoursesPage.noCoursesMessage.title}
                                description={strings.learnerCoursesPage.noCoursesMessage.description}
                                maxWidth={'100%'}
                            />
                        </ListContainer>
                    </MainSection>
                )}
            </MainSection>
        </>
    );
}

const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingLg};
    padding-top: ${sizes.spacingLg};
    @media (max-width: ${breakpoints.sm}) {
        gap: ${sizes.spacingMd};
    }
`;

const CourseList = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingLg};
    margin-bottom: ${sizes.spacingXl};
`;

const CourseCardsContainer = styled.div`
    display: flex;
    gap: ${sizes.spacingLg};
    flex-wrap: wrap;
    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
    }
`;

const TitleAndLogoContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${sizes.spacingMd};
`;

const Headline2Blue = styled(Headline2)`
    color: ${theme.primaryBrandColour};
`;

const Headline3DarkBlue = styled(Headline3)`
    color: ${theme.primaryButtonHoverBackgroundColour};
`;

const Border = styled.div`
    border-bottom: 1px solid ${theme.secondaryButtonDisabledBackgroundColour};
`;
