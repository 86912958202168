import { theme } from 'theme';
import { IconSize } from '../icon-sizes';

export default function PreviewIcon({ size = IconSize.SMALL, colour = theme.primaryBrandColour }): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.7896 8.09446C15.2832 5.15529 12.301 3.16667 8.88629 3.16667C5.47156 3.16667 2.4885 5.15668 0.982943 8.09474C0.919399 8.22043 0.886292 8.35931 0.886292 8.50015C0.886292 8.641 0.919399 8.77987 0.982943 8.90557C2.48933 11.8447 5.47156 13.8334 8.88629 13.8334C12.301 13.8334 15.2841 11.8434 16.7896 8.90529C16.8532 8.7796 16.8863 8.64072 16.8863 8.49988C16.8863 8.35903 16.8532 8.22016 16.7896 8.09446ZM8.88629 12.5C8.09516 12.5 7.3218 12.2654 6.66401 11.8259C6.00621 11.3864 5.49352 10.7617 5.19077 10.0308C4.88802 9.29985 4.8088 8.49558 4.96314 7.71965C5.11748 6.94373 5.49845 6.23099 6.05786 5.67158C6.61727 5.11217 7.33 4.73121 8.10593 4.57687C8.88185 4.42253 9.68612 4.50174 10.417 4.80449C11.1479 5.10724 11.7726 5.61993 12.2122 6.27773C12.6517 6.93553 12.8863 7.70889 12.8863 8.50002C12.8866 9.02538 12.7833 9.54564 12.5823 10.0311C12.3814 10.5165 12.0868 10.9575 11.7153 11.329C11.3438 11.7005 10.9027 11.9951 10.4173 12.1961C9.93191 12.397 9.41165 12.5003 8.88629 12.5ZM8.88629 5.83334C8.64827 5.83667 8.41179 5.87208 8.18323 5.93862C8.37163 6.19464 8.46204 6.5097 8.43806 6.82666C8.41408 7.14362 8.27731 7.4415 8.05254 7.66626C7.82777 7.89103 7.5299 8.0278 7.21294 8.05178C6.89598 8.07576 6.58092 7.98535 6.3249 7.79696C6.17911 8.33407 6.20543 8.90337 6.40014 9.42474C6.59486 9.94611 6.94817 10.3933 7.41035 10.7033C7.87252 11.0134 8.42029 11.1707 8.97656 11.1531C9.53283 11.1356 10.0696 10.944 10.5113 10.6054C10.9529 10.2668 11.2773 9.79819 11.4387 9.26557C11.6002 8.73295 11.5905 8.16312 11.4111 7.63628C11.2317 7.10945 10.8916 6.65213 10.4387 6.32871C9.98577 6.00528 9.44283 5.83203 8.88629 5.83334Z"
                fill={colour}
            />
        </svg>
    );
}
