import { theme } from '../../../theme';
import { IconSize } from '../icon-sizes';

export default function TimeIcon({
    colour = theme.primaryBrandColour,
    size = IconSize.MEDIUM,
}: {
    colour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.4001 20.2667L20.2667 18.4L15.3334 13.4667V7.33335H12.6667V14.5334L18.4001 20.2667ZM14.0001 27.3334C12.1556 27.3334 10.4223 26.9834 8.80008 26.2834C7.17786 25.5834 5.76675 24.6334 4.56675 23.4334C3.36675 22.2334 2.41675 20.8222 1.71675 19.2C1.01675 17.5778 0.666748 15.8445 0.666748 14C0.666748 12.1556 1.01675 10.4222 1.71675 8.80002C2.41675 7.1778 3.36675 5.76669 4.56675 4.56669C5.76675 3.36669 7.17786 2.41669 8.80008 1.71669C10.4223 1.01669 12.1556 0.666687 14.0001 0.666687C15.8445 0.666687 17.5779 1.01669 19.2001 1.71669C20.8223 2.41669 22.2334 3.36669 23.4334 4.56669C24.6334 5.76669 25.5834 7.1778 26.2834 8.80002C26.9834 10.4222 27.3334 12.1556 27.3334 14C27.3334 15.8445 26.9834 17.5778 26.2834 19.2C25.5834 20.8222 24.6334 22.2334 23.4334 23.4334C22.2334 24.6334 20.8223 25.5834 19.2001 26.2834C17.5779 26.9834 15.8445 27.3334 14.0001 27.3334ZM14.0001 24.6667C16.9556 24.6667 19.4723 23.6278 21.5501 21.55C23.6279 19.4722 24.6667 16.9556 24.6667 14C24.6667 11.0445 23.6279 8.5278 21.5501 6.45002C19.4723 4.37224 16.9556 3.33335 14.0001 3.33335C11.0445 3.33335 8.52786 4.37224 6.45008 6.45002C4.3723 8.5278 3.33341 11.0445 3.33341 14C3.33341 16.9556 4.3723 19.4722 6.45008 21.55C8.52786 23.6278 11.0445 24.6667 14.0001 24.6667Z"
                fill={colour}
            />
        </svg>
    );
}
