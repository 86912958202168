import styled from 'styled-components';
import { FONTSIZE, theme } from 'theme';

type BulletListProps = {
    displayText: string | string[];
};

export default function BulletList({ displayText }: BulletListProps): JSX.Element {
    displayText = typeof displayText === 'string' ? [displayText] : displayText;

    return (
        <>
            {displayText.length >= 1 && (
                <ul>
                    {displayText.map((text, index) => (
                        <List key={index}>{text}</List>
                    ))}
                </ul>
            )}
        </>
    );
}

const List = styled.li`
    font-size: ${FONTSIZE.BodyRegular};
    color: ${theme.textColour};
    margin-left: 1.5rem;
`;
