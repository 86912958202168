import useKeyboardTrap from 'hooks/useKeyboardTrap';
import styled from 'styled-components';
import { theme, sizes } from 'theme';
import NotificationsPanel from './NotificationsPanel';

export default function NotificationWrapper({ closePanel }: { closePanel: () => void }) {
    const { ref } = useKeyboardTrap(() => closePanel());

    return (
        <PanelBackground>
            <Panel id="notification-container" ref={ref}>
                <NotificationsPanel closePanel={closePanel} />
            </Panel>
        </PanelBackground>
    );
}

const Panel = styled.div`
    background-color: ${theme.cardSecondaryBackgroundColour};
    min-height: 420px;
    width: 460px;
    position: absolute;
    right: 0;
    border-radius: ${sizes.borderRadiusLg};
`;

const PanelBackground = styled.div`
    position: fixed;
    top: 80px;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
`;
