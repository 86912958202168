import React from 'react';

type CustomBackgroundColourState = {
    backgroundColour: string;
    setBackgroundColour: (newBackgroundColour: string | null) => void;
};

export const CustomBackgroundColourContext = React.createContext<CustomBackgroundColourState>({
    backgroundColour: '',
    setBackgroundColour: (_: string | null) => {},
});
