import { getHeaders } from './_api-helpers';

export type DownloadCsvResponse = {
    ok: boolean;
    status: number;
};

export async function downloadCsvFile(
    url: string,
    accessToken: string,
    csvTitle: string,
): Promise<DownloadCsvResponse> {
    const headers = getHeaders(accessToken);

    const response = await fetch(url, { headers });

    if (response.status === 204) {
        return { status: response.status, ok: response.ok };
    }

    if (!response.ok) {
        return { status: response.status, ok: response.ok };
    }

    const responseString = await response.text();

    if (responseString === '') {
        return { status: 204, ok: response.ok };
    }

    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(responseString);
    hiddenElement.target = '_blank';
    const timeNow =
        new Date().toLocaleDateString().toString() +
        '-' +
        new Date()
            .toLocaleTimeString('en-GB', { hour12: false, hour: 'numeric', minute: 'numeric' })
            .toString()
            .replaceAll(':', '_');

    const fullTitle = csvTitle + '-' + timeNow + '.csv';
    const illegalFilenameRegex: RegExp = /[~"#%&*:<>?/\\{|}]+/g;
    hiddenElement.download = fullTitle.replaceAll(illegalFilenameRegex, '').replaceAll(' ', '_');
    hiddenElement.click();

    return { ok: true, status: 200 };
}
