import { UserForAdminDashboard } from '../../../models/UserForAdminDashboard';
import { APIError } from '../../../lib/_api-helpers';
import strings from '../../../strings/strings.json';
import Skeleton from 'react-loading-skeleton';
import Notification, { NotificationType } from '../../../components/notifiers/Notification';
import RolesTags from './RolesTags';
import Table, { TableRow } from '../../../components/table/Table';
import { EmptyTableContent } from '../../../components/table/EmptyTableContent';
import { useNavigate } from 'react-router-dom';
import useWindowWidth from 'hooks/useWindowWidth';
import EditIcon from 'assets/icons/controls/EditIcon';
import SecondaryButton, { SecondaryButtonColour } from '../../../components/buttons/SecondaryButton';
import { IconSize } from 'assets/icons/icon-sizes';
import { breakpoints, theme } from 'theme';
import BinIcon from 'assets/icons/controls/BinIcon';
import useDeleteRequest from 'hooks/useDeleteRequest';
import React from 'react';
import Alert from 'components/alert/Alert';
import { logError } from 'lib/debug-helpers';
import OrganisationIconNew from 'assets/icons/navigation/OrganisationIcon';

type UserListProps = {
    users: UserForAdminDashboard[];
    loading: boolean;
    errors: APIError[] | null;
    refetchUsers: () => Promise<void>;
};

export default function UsersList({ users, loading, errors, refetchUsers }: UserListProps): JSX.Element {
    const navigate = useNavigate();
    const { isMobileWidth } = useWindowWidth();

    const [idOfUserToDelete, setIdOfUserToDelete] = React.useState<string | null>(null);
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
    const [deleteUserError, setDeleteUserError] = React.useState<boolean>(false);

    const { deleteData } = useDeleteRequest(`admin/users/${idOfUserToDelete}`);

    if (loading) {
        return <Skeleton count={3} height={120} />;
    }

    if (errors && errors.length > 0) {
        return (
            <Notification
                notificationType={NotificationType.DANGER}
                title={strings.adminDashboardPage.errorFetchingUsersMessage}
            />
        );
    }

    async function onDeleteUser() {
        const response = await deleteData();

        if (response.errors) {
            logError(response.errors);
            setDeleteUserError(true);
            return;
        }

        setIdOfUserToDelete('');
        setAlertOpen(false);
        await refetchUsers();
    }

    if (alertOpen) {
        return (
            <Alert
                alertText={strings.adminDashboardPage.deleteUserAlert.text}
                buttonText={strings.adminDashboardPage.deleteUserAlert.button.text}
                ariaButtonText={strings.adminDashboardPage.deleteUserAlert.button.aria}
                closeAlert={() => {
                    setDeleteUserError(false);
                    setAlertOpen(false);
                    setIdOfUserToDelete('');
                }}
                onSubmit={onDeleteUser}
                error={
                    deleteUserError
                        ? {
                              title: strings.adminDashboardPage.deleteUserAlert.error.title,
                              description: strings.adminDashboardPage.deleteUserAlert.error.description,
                          }
                        : undefined
                }
            />
        );
    }

    const userRows: TableRow[] = users.map((user) => {
        const userName = `${user.firstName} ${user.lastName}`;

        return {
            id: user.id,
            cells: [
                {
                    name: strings.adminDashboardPage.userName,
                    value: userName,
                    isBold: true,
                },
                {
                    name: strings.adminDashboardPage.userEmailAddress,
                    value: user.email,
                },
                {
                    name: strings.adminDashboardPage.userRole,
                    value: <RolesTags roles={user.roles} />,
                },
                {
                    name: '',
                    value: (
                        <div>
                            <SecondaryButton
                                title={isMobileWidth ? '' : strings.adminDashboardPage.changeRole}
                                aria={strings.stepper.backButtonMobileAria}
                                onClick={() => navigate(`/admin/change-user-role/${user.id}`)}
                                icon={<EditIcon />}
                                compact={isMobileWidth}
                            />
                        </div>
                    ),
                },
                {
                    name: '',
                    value: (
                        <SecondaryButton
                            title={isMobileWidth ? '' : strings.adminDashboardPage.deleteUserButton.title}
                            aria={strings.adminDashboardPage.deleteUserButton.aria}
                            onClick={() => {
                                setIdOfUserToDelete(user.id);
                                setAlertOpen(true);
                            }}
                            alternateColour={SecondaryButtonColour.DANGER}
                            icon={<BinIcon colour={theme.errorColour} />}
                            compact={isMobileWidth}
                        />
                    ),
                },
            ],
        };
    });

    return (
        <Table
            headers={[
                { name: strings.adminDashboardPage.userName, order: 1, rowPercentage: 15 },
                { name: strings.adminDashboardPage.userEmailAddress, order: 2, rowPercentage: 30 },
                { name: strings.adminDashboardPage.userRole, order: 3, rowPercentage: 25 },
                { name: '', order: 4, rowPercentage: 16, mobileRightColumn: true },
                { name: '', order: 5, rowPercentage: 14, mobileRightColumn: true },
            ]}
            rows={userRows}
            emptyTableContent={
                <EmptyTableContent
                    icon={<OrganisationIconNew size={IconSize.XLARGE} colour={theme.textColour} />}
                    title={strings.adminDashboardPage.emptyOrganisationTable.title}
                    description={strings.adminDashboardPage.emptyOrganisationTable.description}
                />
            }
            mobileViewBelow={breakpoints.xl}
        />
    );
}
