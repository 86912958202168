type CustomEventListener = {
    (event: CustomEvent): void;
};

function subscribe(eventName: string, listener: CustomEventListener) {
    document.addEventListener(eventName, listener as EventListener);
}

function unsubscribe(eventName: string, listener: CustomEventListener) {
    document.removeEventListener(eventName, listener as EventListener);
}

function publish(eventName: string, data: { [k: string]: string | number }) {
    const event = new CustomEvent(eventName, { detail: data, bubbles: true });
    document.dispatchEvent(event);
}

export { publish, subscribe, unsubscribe };
