import { BodyLarge, BodyRegular, Headline3, NavigationLink } from '../Elements';
import * as React from 'react';
import styled from 'styled-components';
import { breakpoints, FONTSIZE, sizes, theme } from '../../theme';
import ValidateIcon from '../../assets/icons/indicators/ValidateIcon';
import { IconSize } from '../../assets/icons/icon-sizes';
import InformationIcon from '../../assets/icons/indicators/InformationIcon';
import WarningIcon from '../../assets/icons/indicators/WarningIcon';
import ErrorIcon from '../../assets/icons/indicators/ErrorIcon';
import strings from '../../strings/strings.json';

export type LinkToHelp = {
    text: string;
    aria: string;
    url: string;
};

export enum NotificationType {
    WARNING = 'WARNING',
    DANGER = 'DANGER',
    SUCCESS = 'SUCCESS',
    INFO = 'INFO',
}

type NotificationProps = {
    notificationType: NotificationType;
    title?: string;
    description?: string;
    big?: boolean;
    linkToHelp?: LinkToHelp;
    maxWidth?: string | '37rem';
};

function getIcon(notificationType: NotificationType, big: boolean = false): JSX.Element {
    if (big) {
        if (notificationType === NotificationType.DANGER) {
            return (
                <BigIconContainer>
                    <ErrorIcon size={IconSize.LARGE} />
                </BigIconContainer>
            );
        }
        if (notificationType === NotificationType.SUCCESS) {
            return (
                <BigIconContainer>
                    <ValidateIcon size={IconSize.LARGE} />
                </BigIconContainer>
            );
        }
        if (notificationType === NotificationType.WARNING) {
            return (
                <BigIconContainer>
                    <WarningIcon size={IconSize.LARGE} />
                </BigIconContainer>
            );
        }
        return (
            <BigIconContainer>
                <InformationIcon size={IconSize.LARGE} colour={theme.textColour} />
            </BigIconContainer>
        );
    } else {
        if (notificationType === NotificationType.DANGER)
            return (
                <SmallIconContainer>
                    <ErrorIcon />
                </SmallIconContainer>
            );
        if (notificationType === NotificationType.SUCCESS) {
            return (
                <SmallIconContainer>
                    <ValidateIcon />
                </SmallIconContainer>
            );
        }
        if (notificationType === NotificationType.WARNING) {
            return (
                <SmallIconContainer>
                    <WarningIcon />
                </SmallIconContainer>
            );
        }
        return (
            <SmallIconContainer>
                <InformationIcon colour={theme.textColour} />
            </SmallIconContainer>
        );
    }
}

export default function Notification({
    notificationType,
    title,
    description,
    big = false,
    linkToHelp,
    maxWidth,
}: NotificationProps): JSX.Element {
    const notificationTitle = title ?? strings.general.saveSuccess;

    return (
        <NotificationContainer $notificationType={notificationType} $big={big} $maxWidth={maxWidth}>
            <div>{getIcon(notificationType, big)}</div>
            {big ? (
                <div>
                    <Headline3>{notificationTitle}</Headline3>
                    {description && <BigDescriptionSection>{description}</BigDescriptionSection>}
                </div>
            ) : (
                <NotificationBodyContainer>
                    <BodyLarge $bold>{notificationTitle}</BodyLarge>
                    {description && <DescriptionSection>{description}</DescriptionSection>}
                    {linkToHelp && (
                        <p>
                            <HelpNavigationLink to={linkToHelp.url} aria-label={linkToHelp.aria} target="_blank">
                                {linkToHelp.text}
                            </HelpNavigationLink>
                        </p>
                    )}
                </NotificationBodyContainer>
            )}
        </NotificationContainer>
    );
}

const HelpNavigationLink = styled(NavigationLink)`
    padding: unset;
`;

const NotificationBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${sizes.spacingSm};
`;

const BigIconContainer = styled.div`
    margin-top: 0.8rem;
`;

const SmallIconContainer = styled.div`
    margin-top: 0.3rem;
`;

const BigDescriptionSection = styled.p`
    padding-top: ${sizes.spacingMd};
    color: ${theme.textSecondaryColour};
    font-size: ${FONTSIZE.Headline5};
    font-weight: 600;
    white-space: pre-wrap;
`;

const DescriptionSection = styled(BodyRegular)`
    white-space: pre-wrap;
`;

const NotificationContainer = styled.div<{
    $notificationType?: NotificationType;
    $big?: boolean;
    $maxWidth?: string;
}>`
    @media (max-width: ${breakpoints.sm}) {
        width: 100%;
    }
    display: flex;
    border-radius: ${sizes.borderRadiusMd};
    padding: ${({ $big }) => {
        return $big ? sizes.spacingLg : sizes.spacingMd;
    }};
    gap: ${sizes.spacingMd};
    border-width: 3px;
    border-style: solid;
    max-width: ${({ $maxWidth }) => {
        return $maxWidth ? $maxWidth : '37rem';
    }};
    border-color: ${({ $notificationType }) => {
        if ($notificationType === NotificationType.SUCCESS) return theme.notificationSuccessBorder;
        if ($notificationType === NotificationType.DANGER) return theme.notificationDangerBorder;
        if ($notificationType === NotificationType.WARNING) return theme.notificationWarningBorder;
        return theme.notificationInfoBorder;
    }};
    background-color: ${({ $notificationType }) => {
        if ($notificationType === NotificationType.SUCCESS) return theme.notificationSuccessBackground;
        if ($notificationType === NotificationType.DANGER) return theme.notificationDangerBackground;
        if ($notificationType === NotificationType.WARNING) return theme.notificationWarningBackground;
        return theme.notificationInfoBackground;
    }};
`;
