import { BodySmall, Headline6 } from 'components/Elements';
import { PercentageProgressBar } from 'components/progressBar/ProgressBar';
import { styled } from 'styled-components';
import strings from '../../../../../strings/strings.json';
import { GenerateTypeContentComponent } from '../GenerateTypeContentComponent';
import { ModuleItem } from '../ModuleItem';
import { CardContent, ItemCard, ModuleListItem, ModulesList } from '../SharedStyles';

type QueuedGeneratedContentTabProps = {
    moduleItems: ModuleItem[];
};

export default function QueuedGeneratedContentTab({ moduleItems }: QueuedGeneratedContentTabProps): JSX.Element {
    if (moduleItems.length === 0) {
        return <BodySmall>{strings.courseAIGeneratedContentComponent.queueEmpty}</BodySmall>;
    }

    const now = new Date();

    return (
        <ModulesList>
            {moduleItems.map((module, moduleIndex) => {
                return (
                    <ModuleListItem key={moduleIndex}>
                        <Headline6>{`${strings.courseAIGeneratedContentComponent.moduleTitlePrefix} ${module.moduleOrder}: ${module.moduleTitle}`}</Headline6>

                        {module.taskGenerateItems.map((taskContent, taskIndex) => {
                            const expectedMinsToComplete = 5;

                            const completionPercentage = getCompletionPercentage(
                                expectedMinsToComplete,
                                now,
                                taskContent.dateUpdated,
                            );

                            return (
                                <ItemCard key={taskIndex}>
                                    <CardContent>
                                        <BodySmall>{`${strings.courseAIGeneratedContentComponent.taskPrefix} ${taskContent.taskOrder}: ${taskContent.taskTitle}`}</BodySmall>
                                        <GenerateTypeContentComponent generateType={taskContent.generateType} />
                                    </CardContent>
                                    <EstimatedTimeBase>
                                        <PercentageProgressBar
                                            label={`${strings.courseAIGeneratedContentComponent.estimatedTime}: ${expectedMinsToComplete} ${strings.courseAIGeneratedContentComponent.min}`}
                                            percentage={completionPercentage}
                                        />
                                    </EstimatedTimeBase>
                                </ItemCard>
                            );
                        })}
                    </ModuleListItem>
                );
            })}
        </ModulesList>
    );
}

const EstimatedTimeBase = styled.div`
    display: flex;
    justify-content: end;
    flex: 35%;
    width: 100%;
`;

function getCompletionPercentage(expectedMinsToComplete: number, now: Date, dateCreated: Date): number {
    const tenMinsAfterDateCreated = new Date(dateCreated);
    tenMinsAfterDateCreated.setMinutes(tenMinsAfterDateCreated.getMinutes() + expectedMinsToComplete);

    const differenceInMinutes = (tenMinsAfterDateCreated.getTime() - new Date(now).getTime()) / (1000 * 60);

    const minsElapsed = expectedMinsToComplete - differenceInMinutes;

    const percentageComplete =
        minsElapsed >= expectedMinsToComplete ? 99 : Math.round((minsElapsed / expectedMinsToComplete) * 100);

    return percentageComplete;
}
