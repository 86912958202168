import useGetRequest from 'hooks/useGetRequest';
import { ActivityType } from 'models/Activity';
import { ActivityMeeting, LocationType } from 'models/ActivityMeeting';
import { ActivityStatus } from 'models/ActivityStatus';
import { Course } from 'models/Course';
import { CourseSchedule } from 'models/CourseSchedule';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

type PreviewContextValue = {
    scheduledCourse: CourseSchedule | undefined;
    setScheduledCourse: (scheduledCourse: CourseSchedule) => void;
};

export const PreviewContext = React.createContext<PreviewContextValue>({
    scheduledCourse: undefined,
    setScheduledCourse: () => {},
});

export function PreviewProvider(props: { children: React.ReactNode | React.ReactNode[] }): JSX.Element {
    const [scheduledCourseState, setScheduledCourseState] = React.useState<CourseSchedule>();
    const { courseId } = useParams();
    const { data: course, loading } = useGetRequest<Course>(`courses/${courseId}`, !courseId);

    useEffect(() => {
        if (!loading && course) {
            const now = new Date();

            const meetings: ActivityMeeting[] = course.modules
                .flatMap((module) => module.activities)
                .filter((activity) => activity.type === ActivityType.LIVE_TRAINING)
                .map((activity) => {
                    const meeting: ActivityMeeting = {
                        id: uuidv4(),
                        locationType: LocationType.ONLINE,
                        activityId: activity.id,
                        meetingDatetime: now,
                        attendees: [],
                        activity: activity,
                    };

                    return meeting;
                });

            course.modules.forEach((module) => {
                module.activities.forEach((activity) => {
                    const activityStatus: ActivityStatus = {
                        completed: null,
                        activity: activity,
                    };
                    activity.statuses = [activityStatus];
                    activity.questions.forEach((q) => (q.answers = []));
                });
            });

            const scheduledCourse: CourseSchedule = {
                id: uuidv4(),
                courseId: course.id,
                course: course,
                startDate: now.toDateString(),
                endDate: now.toDateString(),
                meetings: meetings,
                dateCreated: now.toDateString(),
                dateUpdated: now.toDateString(),
            };

            setScheduledCourseState(scheduledCourse);
        }
    }, [loading, course]);

    return (
        <PreviewContext.Provider
            value={{
                scheduledCourse: scheduledCourseState,
                setScheduledCourse: (scheduledCourse) => {
                    setScheduledCourseState(scheduledCourse);
                },
            }}
        >
            {props.children}
        </PreviewContext.Provider>
    );
}
