import { BodyRegular, Card, Headline5 } from 'components/Elements';
import styled from 'styled-components';
import { breakpoints, sizes } from 'theme';
import strings from 'strings/strings.json';
// @ts-ignore
import { ReactComponent as NeveLogo } from '../../../../assets/icons/nav-icons/neve-logo-small.svg';

export default function WhyIsEditingLimited(): JSX.Element {
    return (
        <CardContainer>
            <Headline5>{strings.whyIsEditingLimited.heading}</Headline5>
            <BodyRegular>{strings.whyIsEditingLimited.paragraphOne}</BodyRegular>
            <BodyRegular>{strings.whyIsEditingLimited.paragraphTwo}</BodyRegular>
            <StyledTextContainer>
                <NeveLogo />
                <BodyRegular $bold>{strings.whyIsEditingLimited.teamName}</BodyRegular>
            </StyledTextContainer>
        </CardContainer>
    );
}

const CardContainer = styled(Card)`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
    max-width: 400px;
    height: max-content;
    margin-top: ${sizes.rem.sm};
    @media (max-width: ${breakpoints.sm}) {
        margin-top: ${sizes.rem.rg};
    }
`;

const StyledTextContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${sizes.rem.sm};
`;
