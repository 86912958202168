import { theme } from '../../../theme';
import { IconSize } from '../icon-sizes';

export default function TrainerIcon({
    colour = theme.primaryBrandColour,
    size = IconSize.MEDIUM,
}: {
    colour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.16895 16.3555C3.84538 16.3555 3.56966 16.2415 3.3418 16.0137C3.11393 15.7858 3 15.5078 3 15.1797C3 14.8561 3.11393 14.5827 3.3418 14.3594C3.56966 14.1406 3.84538 14.0312 4.16895 14.0312H12.0645C12.584 14.0312 12.8438 13.7738 12.8438 13.2588V3.35352C12.8438 2.9069 12.6182 2.68359 12.167 2.68359H3.69043V14.7148L3 15.1797V3.24414C3 2.81576 3.10938 2.5013 3.32812 2.30078C3.55143 2.10026 3.85449 2 4.2373 2H12.208C12.4997 2 12.7344 2.05013 12.9121 2.15039C13.0944 2.24609 13.2357 2.39876 13.3359 2.6084H13.9102C14.1927 2.6084 14.4274 2.69954 14.6143 2.88184C14.8011 3.05957 14.8945 3.28288 14.8945 3.55176V14.8857C14.8945 15.3916 14.7646 15.763 14.5049 16C14.2451 16.237 13.8874 16.3555 13.4316 16.3555H4.16895ZM3.41699 14.6465V2.54688L12.9121 2.6084L12.8438 14.0437L3.41699 14.6465ZM6.28125 14.4414V2.30762H7.10156V14.4414H6.28125ZM14.2656 15.6104C14.0423 15.5693 13.819 15.4896 13.5957 15.3711C13.377 15.2526 13.1947 15.0703 13.0488 14.8242C12.9076 14.5781 12.8369 14.2454 12.8369 13.8262L13.5205 13.3066C13.5205 13.735 13.5615 14.0586 13.6436 14.2773C13.7301 14.4961 13.8464 14.6465 13.9922 14.7285C14.138 14.8105 14.2975 14.863 14.4707 14.8857L14.2656 15.6104ZM4.16895 15.6719H13.4316C13.9512 15.6719 14.2109 15.4144 14.2109 14.8994V3.59961C14.1107 3.57682 13.999 3.53809 13.876 3.4834C13.7529 3.42415 13.6344 3.36035 13.5205 3.29199C13.5251 3.31934 13.5273 3.34896 13.5273 3.38086C13.5273 3.4082 13.5273 3.43783 13.5273 3.46973V13.2451C13.5273 13.751 13.3975 14.1224 13.1377 14.3594C12.8779 14.5964 12.5202 14.7148 12.0645 14.7148H4.16895C4.03678 14.7148 3.92285 14.7604 3.82715 14.8516C3.736 14.9473 3.69043 15.0612 3.69043 15.1934C3.69043 15.3255 3.736 15.4372 3.82715 15.5283C3.92285 15.624 4.03678 15.6719 4.16895 15.6719ZM8.53711 14.4414V2.30762H9.35742V14.4414H8.53711ZM10.793 14.4414V2.30762H11.6133V14.4414H10.793Z"
                fill={colour}
            />
        </svg>
    );
}
