import { theme } from 'theme';
import { IconSize } from './icon-sizes';

export default function FileMultipleIcon({
    colour = theme.primaryBrandColour,
    size = IconSize.MEDIUM,
}: {
    colour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.6663 13.1667H5.33301C4.96634 13.1667 4.65245 13.0361 4.39134 12.775C4.13023 12.5139 3.99967 12.2 3.99967 11.8334V2.50002C3.99967 2.13335 4.13023 1.81946 4.39134 1.55835C4.65245 1.29724 4.96634 1.16669 5.33301 1.16669H9.99967L13.9997 5.16669V11.8334C13.9997 12.2 13.8691 12.5139 13.608 12.775C13.3469 13.0361 13.033 13.1667 12.6663 13.1667ZM9.33301 5.83335V2.50002H5.33301V11.8334H12.6663V5.83335H9.33301ZM2.66634 15.8334C2.29967 15.8334 1.98579 15.7028 1.72467 15.4417C1.46356 15.1806 1.33301 14.8667 1.33301 14.5V5.16669H2.66634V14.5H9.99967V15.8334H2.66634Z"
                fill={colour}
            />
        </svg>
    );
}
