import { theme } from '../../../theme';
import { IconSize } from '../icon-sizes';

export default function ValidateIcon({
    colour = theme.successColour,
    size = IconSize.MEDIUM,
    inverse = false,
}: {
    colour?: string;
    size?: IconSize;
    inverse?: boolean;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="12" fill={inverse ? 'white' : colour} />
            <path
                d="M5.3335 11.7619L10.2718 17L18.6668 7"
                stroke={inverse ? colour : 'white'}
                strokeWidth="1.74603"
                strokeLinecap="round"
            />
        </svg>
    );
}
