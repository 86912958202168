import type { ErrorOption } from 'react-hook-form';
import strings from '../strings/strings.json';
import { logError } from '../lib/debug-helpers';

export const fieldErrorNames: Record<string, string> = {
    firstName: strings.errorMessages.labelFirstName,
    lastName: strings.errorMessages.labelLastName,
    email: strings.errorMessages.labelEmail,
    password: strings.errorMessages.labelPassword,
    accessCode: strings.errorMessages.labelAccessCode,
    confirmation: strings.errorMessages.labelTerms,
    newPassword: strings.errorMessages.labelNewPassword,
    title: strings.errorMessages.labelTitle,
    answer: strings.errorMessages.labelAnswer,
    description: strings.errorMessages.labelDescription,
    url: strings.errorMessages.labelUrl,
    trainingDuration: strings.errorMessages.labelDuration,
};

export const fieldErrorCodes: Record<string, string> = {
    requiredField: strings.errorMessages.fieldErrorRequiredField,
    acceptCheckbox: strings.errorMessages.fieldErrorAcceptCheckbox,
    alreadyTaken: strings.errorMessages.fieldErrorAlreadyUsed,
    minLength: strings.errorMessages.fieldErrorMinLength,
    maxLength: strings.errorMessages.fieldErrorMaxLength,
    invalidEmail: strings.errorMessages.fieldErrorInvalidEmail,
    minDate: strings.errorMessages.fieldErrorMinDate,
    isPositive: strings.errorMessages.fieldErrorIsPositive,
};

export const globalErrorCodes: Record<string, string> = {
    // Auth
    invalidEmailPasswordCombo: strings.errorMessages.globalErrorInvalidEmailPasswordCombo,
    invalidToken: strings.errorMessages.globalErrorInvalidToken,
    invalidPermissions: 'invalidPermissions',
    tokenExpired: 'tokenExpired',
    noUserFound: strings.errorMessages.globalErrorNoUserFound,
    notVerified: strings.errorMessages.globalNotVerified,
    alreadyEnrolled: strings.errorMessages.globalAlreadyEnrolled,
    // Generic
    notFound: 'notFound',
    badRequest: strings.errorMessages.globalBadRequest,
    unknownError: strings.errorMessages.globalErrorUnknown,
    serviceError: strings.errorMessages.globalErrorUnknown,
    noOrganisationSpecifiedForOrganisationRole: strings.errorMessages.noOrganisationSpecifiedForOrganisationRole,
    trainerIsRunningCourses: strings.errorMessages.trainerIsRunningCourses,
    userAlreadyInvitedToBeTrainerForOrganisation: strings.errorMessages.userAlreadyInvitedToBeTrainerForOrganisation,
    userAlreadyTrainerForOrganisation: strings.errorMessages.userAlreadyTrainerForOrganisation,
    userWithEmailAlreadyExists: strings.errorMessages.userWithEmailAlreadyExists,
    organisationAlreadyExists: strings.errorMessages.organisationAlreadyExists,
};

export function requiredFieldErrorMessage(field: string): string {
    return `${field} ${fieldErrorCodes.requiredField}`;
}

export function createErrorMessage(field: string, errorMessage: string): string {
    return `${field} ${errorMessage}`;
}

export function createFieldErrorFromAPIError(field: string, code: string): ErrorOption {
    return {
        type: 'manual',
        message: JSON.stringify([field, code]),
    };
}

export function createGlobalErrorFromAPIError(code: string): string {
    const error = globalErrorCodes[code];
    if (!error) {
        logError('Unknown error returned from API');
        return globalErrorCodes.unknownError;
    }
    return error;
}
