import * as React from 'react';
import { DayPicker, SelectSingleEventHandler } from 'react-day-picker';
import styled from 'styled-components';
import { sizes, theme } from '../../theme';
import strings from '../../strings/strings.json';
import { BodyRegular } from '../Elements';
import 'react-day-picker/dist/style.css';

type DateInputProps = {
    selectedDate: Date | undefined;
    setSelectedDate: SelectSingleEventHandler;
    earliestDate?: Date;
    latestDate?: Date;
    labelTitle: string;
    required?: boolean;
};

export default function EmbeddedDateInput({
    earliestDate,
    latestDate,
    selectedDate,
    setSelectedDate,
    labelTitle,
    required,
}: DateInputProps): JSX.Element {
    return (
        <>
            <DayPicker
                mode="single"
                required={required}
                fromDate={earliestDate || new Date()}
                toDate={latestDate}
                selected={selectedDate}
                onSelect={setSelectedDate}
                defaultMonth={!!selectedDate ? selectedDate : earliestDate}
                weekStartsOn={1}
            />
            <div>
                <LabelText>
                    {labelTitle}
                    <b>{selectedDate ? selectedDate.toLocaleDateString() : strings.datePickerModal.noneSelected}</b>
                </LabelText>
            </div>
        </>
    );
}

const LabelText = styled(BodyRegular)`
    display: inline-block;
    background: ${theme.cardSecondaryBackgroundColour};
    padding: ${sizes.spacingSm};
    border-radius: ${sizes.borderRadiusMd};
    margin: 0 1em;
    white-space: nowrap;
`;
