import { theme } from '../../../theme';
import { IconSize } from '../icon-sizes';

export default function CoursesIcon({
    colour = theme.primaryBrandColour,
    size = IconSize.MEDIUM,
}: {
    colour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 38 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.7917 30.75L6.83333 24.2583V14.0083L0 10.25L18.7917 0L37.5833 10.25V23.9167H34.1667V12.1292L30.75 14.0083V24.2583L18.7917 30.75ZM18.7917 16.5708L30.4937 10.25L18.7917 3.92917L7.08958 10.25L18.7917 16.5708ZM18.7917 26.8635L27.3333 22.251V15.8021L18.7917 20.5L10.25 15.8021V22.251L18.7917 26.8635Z"
                fill={colour}
            />
        </svg>
    );
}
