import ArrowDownIcon from 'assets/icons/controls/ArrowDownIcon';
import ArrowUpIcon from 'assets/icons/controls/ArrowUpIcon';
import styled from 'styled-components';
import strings from '../../strings/strings.json';

type AccordionToggleProps = {
    onClick: () => void;
    isOpen: boolean;
};

export function AccordionPanelToggle({ onClick, isOpen }: AccordionToggleProps): JSX.Element {
    return (
        <PanelToggle
            onClick={onClick}
            aria-label={isOpen ? strings.accordion.closePanel : strings.accordion.openPanel}
        >
            {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </PanelToggle>
    );
}

const PanelToggle = styled.button`
    display: flex;
    grid-area: toggle;
    background-color: transparent;
    border: none;
    cursor: pointer;
`;
