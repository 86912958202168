import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { sizes, theme } from 'theme';

export type AccordionPanelItemProps = {
    url: string;
    aria: string;
    content: JSX.Element;
};

export default function AccordionPanelItem({ url, aria, content }: AccordionPanelItemProps): JSX.Element {
    const navigate = useNavigate();

    return (
        <PanelItem onClick={() => navigate(url)} aria-label={aria}>
            {content}
        </PanelItem>
    );
}

const PanelItem = styled.button`
    cursor: pointer;
    display: flex;
    gap: ${sizes.spacingRg};
    padding: 0 ${sizes.spacingSm};
    align-items: center;
    justify-content: space-between;
    min-height: 55px;
    background-color: inherit;
    border: none;

    &:focus {
        background-color: ${theme.cardNavigation.backgroundFocused};
        border-radius: ${sizes.borderRadiusLg};
        border: 1px solid ${theme.cardNavigation.borderFocused};
    }
    &:hover {
        background-color: ${theme.secondaryButtonHoverBackgroundColour};
        border-radius: ${sizes.borderRadiusLg};
        border: 1px solid ${theme.secondaryButtonBorderColour};
    }
`;
