import { VIDEO_TRANSCRIPT_MAX_LENGTH } from 'models/FileDocument';
import { TextAreaField } from './forms/TextAreaField';
import strings from '../strings/strings.json';

type VideoTranscriptInputProps = {
    setTempVideoTranscript: (transcript: string) => void;
    tempVideoTranscript?: string;
};

export function VideoTranscriptInput({
    setTempVideoTranscript,
    tempVideoTranscript,
}: VideoTranscriptInputProps): JSX.Element {
    return (
        <TextAreaField
            fieldName={strings.videoTranscriptInput.title}
            aria={strings.videoTranscriptInput.aria}
            errorMessage={undefined}
            inputProps={{
                onChange: (event: { target: { value: string } }) => {
                    setTempVideoTranscript(event.target.value);
                },
            }}
            maxLength={VIDEO_TRANSCRIPT_MAX_LENGTH}
            charactersLeft={VIDEO_TRANSCRIPT_MAX_LENGTH - (tempVideoTranscript ?? '').length}
        />
    );
}
