import { theme } from 'theme';
import { IconSize } from '../icon-sizes';

export default function EditIcon({ size = IconSize.SMALL, colour = theme.primaryBrandColour }): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.1833 2.60602L13.6889 5.07291C13.7944 5.17684 13.7944 5.3464 13.6889 5.45033L7.62222 11.4234L5.04445 11.7051C4.7 11.7434 4.40833 11.4562 4.44722 11.1171L4.73333 8.57906L10.8 2.60602C10.9056 2.5021 11.0778 2.5021 11.1833 2.60602ZM15.6833 1.97973L14.3278 0.645093C13.9056 0.229386 13.2194 0.229386 12.7944 0.645093L11.8111 1.61325C11.7056 1.71718 11.7056 1.88674 11.8111 1.99067L14.3167 4.45756C14.4222 4.56148 14.5944 4.56148 14.7 4.45756L15.6833 3.4894C16.1056 3.07096 16.1056 2.39543 15.6833 1.97973ZM10.6667 9.79883V12.583H1.77778V3.83126H8.16111C8.25 3.83126 8.33333 3.79571 8.39722 3.73554L9.50833 2.64158C9.71944 2.43372 9.56944 2.08092 9.27222 2.08092H1.33333C0.597222 2.08092 0 2.66893 0 3.39368V13.0206C0 13.7453 0.597222 14.3333 1.33333 14.3333H11.1111C11.8472 14.3333 12.4444 13.7453 12.4444 13.0206V8.70487C12.4444 8.41224 12.0861 8.26729 11.875 8.4724L10.7639 9.56637C10.7028 9.62927 10.6667 9.71132 10.6667 9.79883Z"
                fill={colour}
            />
        </svg>
    );
}
