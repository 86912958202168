import Notification, { NotificationType } from './Notification';

type ErrorNotifierProps = {
    title: string;
    description: string;
    maxWidth?: string;
};

export function ErrorNotifier({ title, description, maxWidth }: ErrorNotifierProps): JSX.Element {
    return (
        <Notification
            notificationType={NotificationType.DANGER}
            title={title}
            description={description}
            maxWidth={maxWidth}
        />
    );
}
