import * as React from 'react';
import { useRef } from 'react';
import MainSection from '../../components/sections/MainSection';
import styled from 'styled-components';
import { breakpoints, FONTSIZE, sizes, theme } from '../../theme';
import { BodyLarge, BodyRegular, Headline5 } from '../../components/Elements';
import LogoutIcon from '../../assets/icons/navigation/LogoutIcon';
import SecondaryButton from '../../components/buttons/SecondaryButton';
import { useNavigate } from 'react-router';
import PageInfoIcon from '../../assets/icons/controls/PageInfoIcon';
import FlowerIcon from '../../assets/icons/accessibility/FlowerIcon';
import VideoCallIcon from '../../assets/icons/controls/VideoCallIcon';
import ChangeRoleButton from './components/ChangeRoleButton';
import { AuthContext } from '../../contextProviders/AuthContext';
import { getFriendlyRoleName, RoleType, UserModel } from '../../models/User';
import MyDetailsSection from './components/MyDetailsSection';
import ZoomIntegrationSection from './components/ZoomIntegrationSection';
import AccessibilitySection from './components/AccessibilitySection';
import Footer from '../../components/footer/Footer';
import strings from '../../strings/strings.json';
import useGetRequest from '../../hooks/useGetRequest';
import useWindowWidth from '../../hooks/useWindowWidth';
import TabStructureComponent from '../../components/tabs/TabStructureComponent';
import { TabDefinition } from 'components/tabs/Tabs';
import ProfileImageModal from './components/ProfileImageModal';
import Skeleton from 'react-loading-skeleton';
import Notification, { NotificationType } from '../../components/notifiers/Notification';
import { DOCUMENTS_URL } from '../../lib/_api-helpers';
import EditIcon from 'assets/icons/controls/EditIcon';
import { useLocation } from 'react-router-dom';
import { ImageCircle } from 'components/ImageCircle';
import ExternalLinkIcon from '../../assets/icons/navigation/ExternalLinkIcon';
import { IconSize } from '../../assets/icons/icon-sizes';

type State = { activeTabId: string };

export default function ProfilePage(): JSX.Element {
    const navigate = useNavigate();
    const {
        data: userDetails,
        loading: userDetailsLoading,
        errors: userDetailsErrors,
        refetchData: refetchUserDetails,
    } = useGetRequest<UserModel>('users/me/details');
    const { userData } = React.useContext(AuthContext);
    const { version } = require('../../../package.json');
    const { data: backendVersion } = useGetRequest<string>('version');
    const { state } = useLocation();
    const [activeTabId] = React.useState((state as State)?.activeTabId);

    const { currentWindowWidth } = useWindowWidth();

    const [imageUploadModalOpen, setImageUploadModalOpen] = React.useState<boolean>(false);
    const [showSavedBio, setShowSavedBio] = React.useState<boolean>(false);

    const imageButtonRef = useRef<HTMLButtonElement | null>(null);

    function closeModal() {
        setImageUploadModalOpen(false);
        if (imageButtonRef.current) {
            imageButtonRef.current.focus();
        }
    }

    if (userDetailsErrors.length > 0) {
        return (
            <Notification
                notificationType={NotificationType.DANGER}
                title={strings.profilePage.userDetailsLoadErrorTitle}
                description={strings.errorMessages.genericDetail}
            />
        );
    }

    const NON_TRAINER_TAB_LIST: TabDefinition[] = [
        {
            id: 'details',
            name: 'My Details',
            icon: PageInfoIcon,
            content: (
                <MyDetailsSection
                    bio={userDetails?.bio ?? ''}
                    refetchUserDetails={refetchUserDetails}
                    showSavedBio={showSavedBio}
                    setShowSavedBio={setShowSavedBio}
                />
            ),
        },
        { id: 'accessibility', name: 'Accessibility', icon: FlowerIcon, content: <AccessibilitySection /> },
    ];

    const FULL_TAB_LIST: TabDefinition[] = [
        ...NON_TRAINER_TAB_LIST,
        { id: 'integrate', name: 'Integrate with zoom', icon: VideoCallIcon, content: <ZoomIntegrationSection /> },
    ];

    const tabs = userData.roles.includes(RoleType.TRAINER) ? FULL_TAB_LIST : NON_TRAINER_TAB_LIST;

    const neveTermsAndConditionsURL = 'https://www.nevelearning.co.uk/terms-and-conditions/';
    const neveCommitmentToAccessibilityURL = 'https://www.nevelearning.co.uk/accessibility-commitment/';
    const neveReleasesPageURL = 'https://www.nevelearning.co.uk/releases/';

    const profileImageFilepath = userDetails?.profileImageS3Filepath
        ? `${DOCUMENTS_URL}/${userDetails.profileImageS3Filepath}`
        : '';

    return (
        <ProfilePageContainer>
            <MainSection>
                <HeaderRow>
                    <NameAndImage>
                        {!userDetails && userDetailsLoading && (
                            <>
                                <Skeleton circle />
                                <Skeleton count={2} />
                            </>
                        )}
                        {userDetails && (
                            <>
                                <ButtonContainer
                                    ref={imageButtonRef}
                                    onClick={() => {
                                        setImageUploadModalOpen(true);
                                    }}
                                    aria-label={strings.profilePage.changeProfilePictureAriaLabel}
                                >
                                    <ImageCircle $imageurl={profileImageFilepath}>
                                        <EditIconContainer>
                                            <EditIcon colour={theme.secondaryButtonTextColour} />
                                        </EditIconContainer>
                                    </ImageCircle>
                                </ButtonContainer>
                                <div>
                                    <Headline5>
                                        {userDetails.firstName}&nbsp;{userDetails.lastName}
                                    </Headline5>
                                    {userData?.organisation && <BodyRegular>{userData.organisation.name}</BodyRegular>}
                                </div>
                            </>
                        )}
                    </NameAndImage>
                    <SecondaryButton
                        title="Logout"
                        aria="logout"
                        onClick={() => {
                            navigate('/logout');
                        }}
                        icon={currentWindowWidth > parseInt(breakpoints.sm) ? <LogoutIcon /> : undefined}
                    />
                </HeaderRow>
                <TabStructureComponent
                    tabs={tabs}
                    initialActiveTabId={activeTabId}
                    rightElement={
                        <RoleContainer>
                            <BodyLarge>
                                Role:&nbsp;<BoldSpan>{getFriendlyRoleName(userData.activeRole)}</BoldSpan>
                            </BodyLarge>
                            <div>
                                <ChangeRoleButton />
                            </div>
                        </RoleContainer>
                    }
                />
            </MainSection>
            <Footer>
                <FooterInfoContainer>
                    <FooterVersion>
                        <Headline5 $inverted>{strings.profilePage.footerHeading}</Headline5>
                        {version && backendVersion && (
                            <BodyRegular $inverted>
                                {strings.versionInformation.neveLearningVersionText}{' '}
                                {strings.versionInformation.frontEndText} {version} -{' '}
                                {strings.versionInformation.backEndText} {backendVersion}
                            </BodyRegular>
                        )}
                    </FooterVersion>
                    <FooterLinks>
                        <FooterContent>
                            <ExternalLinkIcon size={IconSize.MEDIUM} colour={theme.textColourInverted} />
                            <FooterLink href={neveTermsAndConditionsURL} target="_blank">
                                {strings.profilePage.termsAndConditionsTitle}{' '}
                            </FooterLink>
                        </FooterContent>
                        <FooterContent>
                            <ExternalLinkIcon size={IconSize.MEDIUM} colour={theme.textColourInverted} />
                            <FooterLink href={neveCommitmentToAccessibilityURL} target="_blank">
                                {strings.profilePage.commitmentToAccessibilityTitle}
                            </FooterLink>
                        </FooterContent>
                        <FooterContent>
                            <ExternalLinkIcon size={IconSize.MEDIUM} colour={theme.textColourInverted} />
                            <FooterLink href={neveReleasesPageURL} target="_blank">
                                {strings.profilePage.neveReleasesPageTitle}
                            </FooterLink>
                        </FooterContent>
                    </FooterLinks>
                </FooterInfoContainer>
            </Footer>
            {imageUploadModalOpen && (
                <ProfileImageModal
                    closeModal={closeModal}
                    refetchUser={refetchUserDetails}
                    currentProfileImage={profileImageFilepath}
                />
            )}
        </ProfilePageContainer>
    );
}

const ButtonContainer = styled.button`
    cursor: pointer;
    padding: ${sizes.spacingXs};
    background-color: unset;
    border: 2px solid ${theme.cardBackground};
    box-sizing: border-box;
    border-radius: ${sizes.borderRadiusLg};
    &:hover {
        background-color: ${theme.secondaryButtonHoverBackgroundColour};
        border: 2px solid ${theme.secondaryButtonBorderColour};
    }
    &:focus {
        background-color: ${theme.secondaryButtonFocusBackgroundColour};
        border: 2px solid ${theme.secondaryButtonBorderColour};
        outline: none;
    }
`;

const EditIconContainer = styled.div`
    top: 42px;
    left: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    z-index: 1;
    background-color: ${theme.cardBackground};
    border: 2px solid ${theme.secondaryButtonTextColour};
    padding: ${sizes.spacingXs};
`;

const FooterLinks = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingSm};
`;

const FooterVersion = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingSm};
`;

const FooterLink = styled.a`
    font-size: ${FONTSIZE.BodyRegular};
    text-decoration: underline;
    font-weight: bold;
    color: ${theme.textColourInverted};
    &:hover {
        background-color: ${theme.primaryBrandColour};
        border-radius: 4px;
    }
    &:visited {
        color: ${theme.textColourInverted};
    }
    &:focus {
        background-color: ${theme.primaryBrandColour};
        border: 1px solid ${theme.linkFocusBorderColour};
        outline: ${theme.linkFocusBorderColour};
        border-radius: 4px;
    }
`;

const FooterContent = styled.div`
    display: flex;
    align-items: center;
    gap: ${sizes.spacingSm};
`;

const ProfilePageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    height: calc(100% - 80px);
    @media (max-width: ${breakpoints.sm}) {
        height: calc(100% - 113px);
    }
`;

const HeaderRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: ${sizes.spacingLg} 0;
    min-height: 82px;

    @media (max-width: ${breakpoints.sm}) {
        gap: ${sizes.spacingMd};
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: flex-start;
        min-height: 146px;
    }
`;

const NameAndImage = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${sizes.spacingMd};
    @media (max-width: ${breakpoints.sm}) {
        gap: ${sizes.spacingSm};
    }
`;

const RoleContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${sizes.spacingLg};
    @media (max-width: ${breakpoints.sm}) {
        width: 100%;
        justify-content: space-between;
    }
`;

const BoldSpan = styled.span`
    font-weight: bold;
`;

const FooterInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: ${sizes.spacingXl};
    @media (max-width: ${breakpoints.md}) {
        padding: 0 ${sizes.spacingSm};
        flex-direction: column;
        gap: ${sizes.spacingLg};
    }
`;
