import * as React from 'react';
import styled from 'styled-components';
import { breakpoints, sizes } from 'theme';
import { StepNavigation } from './StepNavigation';

type PageFooterProps = {
    href: string;
    title: string;
    aria: string;
};

export default function PageFooter({ title, aria, href }: PageFooterProps): JSX.Element {
    return (
        <PageFooterContainer>
            <StepNavigation title={title} aria={aria} href={href} />
        </PageFooterContainer>
    );
}

const PageFooterContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media (max-width: ${breakpoints.sm}) {
        width: 100%;
        margin-bottom: ${sizes.spacingLg};
    }
`;
