import { theme } from '../../../theme';
import { IconSize } from '../icon-sizes';

export default function QuestionnaireIcon({
    colour = theme.textColour,
    size = IconSize.MEDIUM,
}: {
    colour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 25 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.3403 0C15.5302 0 17.4926 0.29941 19.2275 0.898229C20.9908 1.46854 22.3702 2.35251 23.3656 3.55015C24.3894 4.74779 24.9013 6.27335 24.9013 8.12684C24.9013 9.89479 24.5316 11.3491 23.7922 12.4897C23.0527 13.6018 22.0715 14.4287 20.8486 14.9705C19.6257 15.5123 18.2605 15.8687 16.7532 16.0398L16.1559 19.4189H8.77564L8.8183 12.8746C10.4963 12.7606 11.8188 12.5467 12.7857 12.233C13.7812 11.9194 14.4922 11.5059 14.9188 10.9926C15.3454 10.4793 15.5587 9.90905 15.5587 9.28171C15.5587 8.48328 15.3312 7.88446 14.8761 7.48525C14.4211 7.05752 13.7243 6.84366 12.7857 6.84366C11.7903 6.84366 11.0082 7.15732 10.4394 7.78466C9.89904 8.41199 9.54353 9.353 9.37289 10.6077L0.96875 7.95575C1.33848 6.47296 1.9926 5.13274 2.93114 3.9351C3.86967 2.70895 5.17793 1.75369 6.85591 1.06932C8.56234 0.356439 10.7238 0 13.3403 0ZM12.6151 21.2581C14.0656 21.2581 15.189 21.6003 15.9853 22.2847C16.8101 22.969 17.2224 23.91 17.2224 25.1077C17.2224 26.3338 16.8101 27.2891 15.9853 27.9734C15.189 28.6578 14.0656 29 12.6151 29C11.1931 29 10.0839 28.6578 9.28757 27.9734C8.49124 27.2891 8.09307 26.3338 8.09307 25.1077C8.09307 23.91 8.49124 22.969 9.28757 22.2847C10.0839 21.6003 11.1931 21.2581 12.6151 21.2581Z"
                fill={colour}
            />
        </svg>
    );
}
