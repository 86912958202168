import { theme } from '../../../theme';
import { IconSize } from '../icon-sizes';

export default function InformationIcon({
    colour = theme.primaryBrandColour,
    size = IconSize.MEDIUM,
}: {
    colour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.55 14.75H10.45V9.05H8.55V14.75ZM9.5 7.15C9.76917 7.15 9.99479 7.05896 10.1769 6.87687C10.359 6.69479 10.45 6.46917 10.45 6.2C10.45 5.93083 10.359 5.70521 10.1769 5.52312C9.99479 5.34104 9.76917 5.25 9.5 5.25C9.23083 5.25 9.00521 5.34104 8.82312 5.52312C8.64104 5.70521 8.55 5.93083 8.55 6.2C8.55 6.46917 8.64104 6.69479 8.82312 6.87687C9.00521 7.05896 9.23083 7.15 9.5 7.15ZM9.5 19.5C8.18583 19.5 6.95083 19.2506 5.795 18.7519C4.63917 18.2531 3.63375 17.5763 2.77875 16.7213C1.92375 15.8663 1.24688 14.8608 0.748125 13.705C0.249375 12.5492 0 11.3142 0 10C0 8.68583 0.249375 7.45083 0.748125 6.295C1.24688 5.13917 1.92375 4.13375 2.77875 3.27875C3.63375 2.42375 4.63917 1.74688 5.795 1.24813C6.95083 0.749375 8.18583 0.5 9.5 0.5C10.8142 0.5 12.0492 0.749375 13.205 1.24813C14.3608 1.74688 15.3663 2.42375 16.2213 3.27875C17.0763 4.13375 17.7531 5.13917 18.2519 6.295C18.7506 7.45083 19 8.68583 19 10C19 11.3142 18.7506 12.5492 18.2519 13.705C17.7531 14.8608 17.0763 15.8663 16.2213 16.7213C15.3663 17.5763 14.3608 18.2531 13.205 18.7519C12.0492 19.2506 10.8142 19.5 9.5 19.5ZM9.5 17.6C11.6217 17.6 13.4187 16.8638 14.8912 15.3912C16.3638 13.9187 17.1 12.1217 17.1 10C17.1 7.87833 16.3638 6.08125 14.8912 4.60875C13.4187 3.13625 11.6217 2.4 9.5 2.4C7.37833 2.4 5.58125 3.13625 4.10875 4.60875C2.63625 6.08125 1.9 7.87833 1.9 10C1.9 12.1217 2.63625 13.9187 4.10875 15.3912C5.58125 16.8638 7.37833 17.6 9.5 17.6Z"
                fill={colour}
            />
        </svg>
    );
}
