import styled from 'styled-components';
import { breakpoints, sizes, theme } from '../../../theme';
import { BodyLarge, BodyRegular, BodySmall, ButtonRowRight } from '../../../components/Elements';
import strings from '../../../strings/strings.json';
import VisibilityIcon from '../../../assets/icons/accessibility/VisibilityIcon';
import AccessibilityIcon from '../../../assets/icons/accessibility/AccessibilityIcon';
import { IconSize } from '../../../assets/icons/icon-sizes';
import HearingIcon from '../../../assets/icons/accessibility/HearingIcon';
import Checkboxes from '../../../components/forms/Checkboxes';
import { TextAreaField } from '../../../components/forms/TextAreaField';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import SaveNotifier from '../../../components/notifiers/SaveNotifier';
import Notification, { NotificationType } from '../../../components/notifiers/Notification';
import React from 'react';
import useNeveForm from '../../../components/forms/NeveForm';
import useGetRequest from '../../../hooks/useGetRequest';
import {
    createUserPreferences,
    CreateUserPreferencesRequest,
    makeStringArrayFromAccessNeeds,
    UserPreferences,
} from '../../../models/UserPreferences';
import { createErrorMessage } from '../../../shared/error-messages';
import useModifyRequest from '../../../hooks/useModifyRequest';
import { logError } from '../../../lib/debug-helpers';
import { ACCESSIBILITY_OPTIONS } from 'models/AccessibilityOptions';
import { animated, useSpring } from '@react-spring/web';
import Skeleton from 'react-loading-skeleton';
import ToggleInput from 'components/forms/ToggleInput';
import Spinner from 'components/Spinner';
import { ErrorNotifierSlim } from "components/notifiers/ErrorNotifierSlim";

type AccessNeedsFormValues = {
    accessibilityOptions: string[];
    otherNeeds: string;
};

export default function AccessibilitySection(): JSX.Element {
    const [showSaveError, setShowSaveError] = React.useState<boolean>(false);
    const [showSaveSuccess, setShowSaveSuccess] = React.useState<boolean>(false);
    const [expandOptions, setExpandOptions] = React.useState<boolean>(false);
    const [showUpdateAccessibilityRequirementsError, setShowUpdateAccessibilityRequirementError] =
        React.useState<boolean>(false);
    const [showUpdateSpinner, setShowUpdateSpinner] = React.useState<boolean>(false);

    const optionsRef = React.useRef<HTMLDivElement>(null);

    const {
        data: userPreferences,
        loading: loadingUserPreferences,
        called: calledUserPreferences,
        errors: getUserPreferencesErrors,
        refetchData,
    } = useGetRequest<UserPreferences>('users/me/preferences');

    const { modifyData: postPreferences, loading: savingPreferences } = useModifyRequest(
        'users/me/preferences',
        'POST',
    );

    const { modifyData: updateHasAccessibilityRequirements, loading: loadingUpdate } = useModifyRequest(
        'users/me/preferences/has-accessibility-requirements',
        'POST',
    );

    const { register, handleSubmit, watch, reset } = useNeveForm<AccessNeedsFormValues>({
        accessibilityOptions: [],
        otherNeeds: '',
    });

    React.useEffect(() => {
        if (calledUserPreferences && userPreferences) {
            const newAccessNeedsFormValues: AccessNeedsFormValues = {
                accessibilityOptions: makeStringArrayFromAccessNeeds(userPreferences),
                otherNeeds: userPreferences.otherNeeds,
            };

            reset(newAccessNeedsFormValues);
            setExpandOptions(userPreferences.hasAccessibilityRequirements);
        }
    }, [userPreferences, calledUserPreferences, reset]);

    React.useEffect(() => {
        if (loadingUpdate) {
            setShowUpdateSpinner(true);
        } else {
            const timer = setTimeout(() => {
                setShowUpdateSpinner(false);
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [loadingUpdate]);

    async function onSubmitUserPreferences(formValues: AccessNeedsFormValues) {
        setShowSaveError(false);

        const newUserPreferences: CreateUserPreferencesRequest = createUserPreferences(
            formValues.accessibilityOptions,
            formValues.otherNeeds,
        );

        const serviceResponse = await postPreferences<CreateUserPreferencesRequest, UserPreferences>(
            newUserPreferences,
        );

        if (!serviceResponse || serviceResponse.errors) {
            logError(serviceResponse.errors);
            setShowSaveError(true);
            return;
        }

        await refetchData();

        setShowSaveSuccess(true);
    }

    const handleToggleAccessNeeds = async () => {
        setShowSaveError(false);
        setShowSaveSuccess(false);
        setShowUpdateAccessibilityRequirementError(false);

        const hasAccessibility = userPreferences?.hasAccessibilityRequirements ?? false;

        const serviceResponse = await updateHasAccessibilityRequirements({
            hasAccessibilityRequirements: !hasAccessibility,
        });

        if (!serviceResponse || serviceResponse.errors) {
            logError(serviceResponse.errors);
            setShowUpdateAccessibilityRequirementError(true);
            return;
        }

        await refetchData();
    };

    const { height: optionsHeight } = useSpring({
        height: expandOptions ? `${optionsRef.current?.scrollHeight}px` : `0px`,
        config: { friction: 40 },
    });

    if (!calledUserPreferences) {
        if (loadingUserPreferences) {
            return <Skeleton height={100} />;
        }
        return <></>;
    }

    if (getUserPreferencesErrors && getUserPreferencesErrors.length > 0) {
        return (
            <Notification
                notificationType={NotificationType.DANGER}
                title={strings.profilePage.accessNeedsLoadErrorTitle}
                description={strings.profilePage.accessNeedsLoadErrorDescription}
            />
        );
    }

    return (
        <AccessibilityForm onSubmit={handleSubmit(onSubmitUserPreferences)}>
            <IntroductionText>
                <DoYouHaveAccessNeeds>
                    <BodyLarge $bold>{strings.profilePage.accessNeedsTitle}</BodyLarge>
                    <ToggleInput
                        checked={userPreferences?.hasAccessibilityRequirements ?? false}
                        onToggle={handleToggleAccessNeeds}
                        leftLabel={strings.profilePage.accessNeedsToggle.leftLabel}
                        rightLabel={strings.profilePage.accessNeedsToggle.rightLabel}
                        aria={
                            userPreferences?.hasAccessibilityRequirements ?? false
                                ? strings.profilePage.accessNeedsToggle.aria.no
                                : strings.profilePage.accessNeedsToggle.aria.yes
                        }
                    />
                    {showUpdateAccessibilityRequirementsError ? (
                        <ErrorNotifierSlim description={strings.profilePage.accessNeedsToggle.error} />
                    ) : (
                        showUpdateSpinner && (
                            <SpinnerContainer>
                                <Spinner />
                                <BodySmall>{strings.profilePage.accessNeedsToggleLoading}</BodySmall>
                            </SpinnerContainer>
                        )
                    )}
                </DoYouHaveAccessNeeds>
                <BodyRegular>{strings.profilePage.accessNeedsDescription}</BodyRegular>
            </IntroductionText>

            <animated.div
                ref={optionsRef}
                style={{
                    height: optionsHeight,
                    overflow: 'hidden',
                }}
            >
                <AccessibilityOptionsSection>
                    <AccessibilityGroup>
                        <GroupTitle>
                            <VisibilityIcon />
                            <BodyRegular>{strings.profilePage.accessNeedsVisionGroupTitle}</BodyRegular>
                        </GroupTitle>
                        <Checkboxes
                            options={ACCESSIBILITY_OPTIONS.filter((x) => x.section === 'vision').map((x) => {
                                return {
                                    name: x.name,
                                    value: x.value,
                                    inputProps: register('accessibilityOptions'),
                                    unfocusable: !expandOptions,
                                };
                            })}
                        />
                    </AccessibilityGroup>
                    <AccessibilityGroup>
                        <GroupTitle>
                            <HearingIcon />
                            <BodyRegular>{strings.profilePage.accessNeedsHearingGroupTitle}</BodyRegular>
                        </GroupTitle>
                        <Checkboxes
                            options={ACCESSIBILITY_OPTIONS.filter((x) => x.section === 'hearing').map((x) => {
                                return {
                                    name: x.name,
                                    value: x.value,
                                    inputProps: register('accessibilityOptions'),
                                    unfocusable: !expandOptions,
                                };
                            })}
                        />
                    </AccessibilityGroup>
                    <AccessibilityGroup>
                        <GroupTitle>
                            <AccessibilityIcon size={IconSize.MEDIUM} />
                            <BodyRegular>{strings.profilePage.accessNeedsMobilityGroupTitle}</BodyRegular>
                        </GroupTitle>
                        <Checkboxes
                            options={ACCESSIBILITY_OPTIONS.filter((x) => x.section === 'mobility').map((x) => {
                                return {
                                    name: x.name,
                                    value: x.value,
                                    inputProps: register('accessibilityOptions'),
                                    unfocusable: !expandOptions,
                                };
                            })}
                        />
                    </AccessibilityGroup>
                </AccessibilityOptionsSection>
                <OtherNeedsTextArea>
                    <TextAreaField
                        charactersLeft={200 - watch('otherNeeds').length}
                        fieldName={strings.profilePage.accessNeedsOtherFieldTitle}
                        aria={strings.profilePage.accessNeedsOtherFieldAria}
                        errorMessage={''}
                        inputProps={register('otherNeeds', {
                            maxLength: {
                                value: 200,
                                message: createErrorMessage(
                                    strings.profilePage.accessNeedsOtherFieldTitle,
                                    'is too long',
                                ),
                            },
                        })}
                        maxLength={200}
                        unfocusable={!expandOptions}
                    />
                </OtherNeedsTextArea>
                <ButtonRowRight>
                    <PrimaryButton
                        type="submit"
                        title={strings.profilePage.accessNeedsSectionSave}
                        aria={strings.profilePage.accessNeedsSectionSaveAria}
                        disabled={savingPreferences}
                        unfocusable={!expandOptions}
                    />
                </ButtonRowRight>
                {showSaveSuccess && <SaveNotifier />}
                {showSaveError && (
                    <Notification
                        notificationType={NotificationType.DANGER}
                        title={strings.profilePage.accessNeedsSectionSaveError}
                    />
                )}
            </animated.div>
        </AccessibilityForm>
    );
}

const SpinnerContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${sizes.spacingMd};
`;

const OtherNeedsTextArea = styled.div`
    margin-top: ${sizes.spacingLg};
`;

const DoYouHaveAccessNeeds = styled.div`
    display: flex;
    gap: ${sizes.spacingLg};
    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
        gap: ${sizes.spacingMd};
    }
`;

const AccessibilityForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingLg};
`;

const IntroductionText = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingRg};
    margin-top: ${sizes.spacingMd};
    @media (max-width: ${breakpoints.sm}) {
        margin-top: unset;
    }
`;

const AccessibilityOptionsSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    column-gap: ${sizes.spacingXl};
    row-gap: ${sizes.spacingLg};
`;

const AccessibilityGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingSm};
    max-width: 400px;
    width: 100%;
`;

const GroupTitle = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${sizes.spacingSm};
    border-bottom: ${theme.cardBorder} solid 2px;
    padding-bottom: ${sizes.spacingSm};
`;
