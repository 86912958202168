import { AccessibilityTip } from '../../models/metaModels/AccessibilityTip';
import strings from '../../strings/strings.json';
import { BodyRegular } from '../Elements';
import styled from 'styled-components';
import { sizes } from '../../theme';
import AccessibilityIcon from '../../assets/icons/accessibility/AccessibilityIcon';
import LiftIcon from '../../assets/icons/accessibility/LiftIcon';
import ShowerIcon from '../../assets/icons/accessibility/ShowerIcon';
import FileIcon from '../../assets/icons/accessibility/FileIcon';
import FlowerIcon from '../../assets/icons/accessibility/FlowerIcon';
import ChecklistIcon from '../../assets/icons/accessibility/ChecklistIcon';
import EventListIcon from '../../assets/icons/accessibility/EventListIcon';
import HourglassIcon from '../../assets/icons/accessibility/HourglassIcon';
import SplitIcon from '../../assets/icons/accessibility/SplitIcon';
import HistoryIcon from '../../assets/icons/accessibility/HistoryIcon';
import KeyboardCapslockBadgeIcon from '../../assets/icons/accessibility/KeyboardCapslockBadgeIcon';

const IconBulletLine = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${sizes.spacingMd};
`;

const IconContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const ACCESSIBILITY_GUIDE: AccessibilityTip[] = [
    {
        id: 'AccessibleVenues',
        title: strings.accessibilityGuide.AccessibleVenues.title,
        description: (
            <>
                <BodyRegular>{strings.accessibilityGuide.AccessibleVenues.description[0]}</BodyRegular>
                <IconBulletLine>
                    <IconContainer>
                        <AccessibilityIcon />
                    </IconContainer>
                    <BodyRegular>{strings.accessibilityGuide.AccessibleVenues.description[1]}</BodyRegular>
                </IconBulletLine>
                <IconBulletLine>
                    <IconContainer>
                        <LiftIcon />
                    </IconContainer>
                    <BodyRegular>{strings.accessibilityGuide.AccessibleVenues.description[2]}</BodyRegular>
                </IconBulletLine>
                <IconBulletLine>
                    <IconContainer>
                        <ShowerIcon />
                    </IconContainer>
                    <BodyRegular>{strings.accessibilityGuide.AccessibleVenues.description[3]}</BodyRegular>
                </IconBulletLine>
            </>
        ),
        readMore: strings.accessibilityGuide.AccessibleVenues.readMore,
    },
    {
        id: 'noLearningObjectives',
        title: strings.accessibilityGuide.noLearningObjectives.title,
        description: (
            <>
                <IconBulletLine>
                    <IconContainer>
                        <ChecklistIcon />
                    </IconContainer>
                    <BodyRegular>{strings.accessibilityGuide.noLearningObjectives.description[0]}</BodyRegular>
                </IconBulletLine>
                <IconBulletLine>
                    <IconContainer>
                        <FlowerIcon />
                    </IconContainer>
                    <BodyRegular>{strings.accessibilityGuide.noLearningObjectives.description[1]}</BodyRegular>
                </IconBulletLine>
            </>
        ),
        readMore: undefined,
    },
    {
        id: 'noCourseDescription',
        title: strings.accessibilityGuide.noCourseDescription.title,
        description: (
            <>
                <IconBulletLine>
                    <IconContainer>
                        <FileIcon />
                    </IconContainer>
                    <BodyRegular>{strings.accessibilityGuide.noCourseDescription.description[0]}</BodyRegular>
                </IconBulletLine>
                <IconBulletLine>
                    <IconContainer>
                        <FlowerIcon />
                    </IconContainer>
                    <BodyRegular>{strings.accessibilityGuide.noCourseDescription.description[1]}</BodyRegular>
                </IconBulletLine>
            </>
        ),
        readMore: undefined,
    },
    {
        id: 'tooManyTasks',
        title: strings.accessibilityGuide.tooManyActivities.title,
        description: (
            <>
                <IconBulletLine>
                    <IconContainer>
                        <EventListIcon />
                    </IconContainer>
                    <BodyRegular>{strings.accessibilityGuide.tooManyActivities.description[0]}</BodyRegular>
                </IconBulletLine>
                <IconBulletLine>
                    <IconContainer>
                        <FlowerIcon />
                    </IconContainer>
                    <BodyRegular>{strings.accessibilityGuide.tooManyActivities.description[3]}</BodyRegular>
                </IconBulletLine>
            </>
        ),
        readMore: 'https://cognitiontoday.com/why-variation-in-practice-learning-improves-skills-dramatically/',
    },
    {
        id: 'tooManyLiveTrainingActivities',
        title: strings.accessibilityGuide.tooManyActivities.title,
        description: (
            <>
                <IconBulletLine>
                    <IconContainer>
                        <EventListIcon />
                    </IconContainer>
                    <BodyRegular>{strings.accessibilityGuide.tooManyActivities.description[1]}</BodyRegular>
                </IconBulletLine>
                <IconBulletLine>
                    <IconContainer>
                        <FlowerIcon />
                    </IconContainer>
                    <BodyRegular>{strings.accessibilityGuide.tooManyActivities.description[3]}</BodyRegular>
                </IconBulletLine>
            </>
        ),
        readMore: 'https://cognitiontoday.com/why-variation-in-practice-learning-improves-skills-dramatically/',
    },
    {
        id: 'tooManyQuestionnaires',
        title: strings.accessibilityGuide.tooManyActivities.title,
        description: (
            <>
                <IconBulletLine>
                    <IconContainer>
                        <EventListIcon />
                    </IconContainer>
                    <BodyRegular>{strings.accessibilityGuide.tooManyActivities.description[2]}</BodyRegular>
                </IconBulletLine>
                <IconBulletLine>
                    <IconContainer>
                        <FlowerIcon />
                    </IconContainer>
                    <BodyRegular>{strings.accessibilityGuide.tooManyActivities.description[3]}</BodyRegular>
                </IconBulletLine>
            </>
        ),
        readMore: 'https://cognitiontoday.com/why-variation-in-practice-learning-improves-skills-dramatically/',
    },
    {
        id: 'videoTooLong',
        title: strings.accessibilityGuide.videoTooLong.title,
        description: (
            <>
                <IconBulletLine>
                    <IconContainer>
                        <HourglassIcon />
                    </IconContainer>
                    <BodyRegular>{strings.accessibilityGuide.videoTooLong.description[0]}</BodyRegular>
                </IconBulletLine>
                <IconBulletLine>
                    <IconContainer>
                        <SplitIcon />
                    </IconContainer>
                    <BodyRegular>{strings.accessibilityGuide.videoTooLong.description[1]}</BodyRegular>
                </IconBulletLine>
            </>
        ),
        readMore:
            'https://www.researchgate.net/publication/359929863_Maximizing_the_Impact_of_Instructional_Video_Length',
    },
    {
        id: 'liveTrainingTooLong',
        title: strings.accessibilityGuide.liveTrainingTooLong.title,
        description: (
            <>
                <IconBulletLine>
                    <IconContainer>
                        <HourglassIcon />
                    </IconContainer>
                    <BodyRegular>{strings.accessibilityGuide.liveTrainingTooLong.description[0]}</BodyRegular>
                </IconBulletLine>
                <IconBulletLine>
                    <IconContainer>
                        <HistoryIcon />
                    </IconContainer>
                    <BodyRegular>{strings.accessibilityGuide.liveTrainingTooLong.description[1]}</BodyRegular>
                </IconBulletLine>
            </>
        ),
        readMore: 'https://slack.com/intl/en-gb/blog/productivity/run-effective-meetings',
    },
    {
        id: 'textAllCaps',
        title: strings.accessibilityGuide.textAllCaps.title,
        description: (
            <IconBulletLine>
                <IconContainer>
                    <KeyboardCapslockBadgeIcon />
                </IconContainer>
                <BodyRegular>{strings.accessibilityGuide.textAllCaps.description}</BodyRegular>
            </IconBulletLine>
        ),
        readMore:
            'https://www.bdadyslexia.org.uk/advice/employers/creating-a-dyslexia-friendly-workplace/dyslexia-friendly-style-guide',
    },
];
