import Accordion from 'components/accordion/Accordion';
import { AccordionPanelProps } from 'components/accordion/AccordionPanel';
import { AccordionPanelItemProps } from 'components/accordion/AccordionPanelItem';
import { BodyRegular, Headline2, Headline3, WrappingText } from 'components/Elements';
import MainSection from 'components/sections/MainSection';
import useWindowWidth from 'hooks/useWindowWidth';
import { ActivityType, getActivityTypeIcon } from 'models/Activity';
import { LearnerActivity, LearnerLearningObjective, LearnerModule, LearnerTrainerInfo } from 'models/LearnerCourse';
import LearningObjectivesList from 'pages/learner/components/LearningObjectivesList';
import LearnerCoursePageFooter from 'pages/learner/components/LearnerCoursePageFooter';
import strings from 'strings/strings.json';
import styled from 'styled-components';
import { breakpoints, sizes, theme } from 'theme';
import DurationLabel from 'components/DurationLabel';
import NextIcon from 'assets/icons/controls/NextIcon';
import { IconSize } from 'assets/icons/icon-sizes';
import NextActivityComponent from './NextActivityComponent';
import { DOCUMENTS_URL } from 'lib/_api-helpers';
import BackgroundImagery from 'assets/backgrounds/imagery/learner-course-blue.svg';
import ExpandableContentComponent from '../ExpandableContentComponent';
import YourTrainerCard from '../../pages/learner/components/YourTrainerCard';
import { formatDate } from '../../pages/learner/components/CourseCardDateSection';
import NextLiveTrainingMeetingCard from '../../pages/learner/components/NextLiveTrainingMeetingCard';

type LearnerCourseComponentProps = {
    courseTitle: string;
    courseDescription: string;
    learningObjectives: LearnerLearningObjective[];
    modules: LearnerModule[];
    activeModuleIndex?: number;
    startDate: string;
    endDate: string;
    urlPrefix: string;
    trainerInfo: LearnerTrainerInfo;
};

export default function LearnerCourseComponent({
    courseTitle,
    courseDescription,
    learningObjectives,
    modules,
    activeModuleIndex,
    startDate,
    endDate,
    urlPrefix,
    trainerInfo,
}: LearnerCourseComponentProps): JSX.Element {
    const { isMobileWidth } = useWindowWidth();

    const flattenedActivities: LearnerActivity[] = modules.flatMap((module) => module.activities);

    const nextLiveTraining = flattenedActivities
        .filter((x) => x.type === ActivityType.LIVE_TRAINING)
        .filter((x) => new Date(x.startDate ?? 0).getTime() - 60 * 60 * 1000 < new Date().getTime()) // is one hour before the live training starts less than now?
        .filter(
            (x) => new Date(x.startDate ?? 0).getTime() + (x.durationInMins ?? 0) * 60 * 1000 > new Date().getTime(),
        )[0]; // is now earlier than the end time? get the first that satisfies these filters.

    const accordionPanels: AccordionPanelProps[] = modules.map((module, index) => {
        const panelItems = module.activities.map((activity) => {
            const panelItem: AccordionPanelItemProps = {
                content: (
                    <>
                        <IconAndTitleContainer>
                            <IconContainer>{getActivityTypeIcon(activity, IconSize.MEDIUM)}</IconContainer>
                            <WrappingText>{activity.title}</WrappingText>
                        </IconAndTitleContainer>
                        <DurationAndArrowContainer>
                            {!isMobileWidth && !!activity.durationInMins && (
                                <DurationLabel durationInMins={activity.durationInMins} hideTitle />
                            )}
                            <NextIcon size={IconSize.LARGE} />
                        </DurationAndArrowContainer>
                    </>
                ),
                url: `${urlPrefix}/activity/${activity.id}`,
                aria: strings.learnerCoursePage.activityButtonAria,
            };

            return panelItem;
        });

        const moduleOverviewPanelItem: AccordionPanelItemProps = {
            content: (
                <>
                    <BodyRegular $bold>{strings.learnerCoursePage.moduleOverviewButton.title}</BodyRegular>
                    <NextIcon size={IconSize.LARGE} />
                </>
            ),
            url: `module/${module.id}`,
            aria: `${strings.learnerCoursePage.moduleOverviewButton.aria}: ${module.title}`,
        };

        panelItems.unshift(moduleOverviewPanelItem);

        const moduleTitle = `Module ${module.order}: ${module.title}`;
        const moduleCompleted = module.activities.every((activity) => activity.completed);
        const numberOfCompletedActivities = module.activities.filter((activity) => activity.completed).length;
        const completedActivityText = `${numberOfCompletedActivities}/${module.activities.length}`;

        const panel: AccordionPanelProps = {
            headerTitle: moduleTitle,
            panelCompleted: moduleCompleted,
            completedItemsText: completedActivityText,
            panelItems: panelItems,
            openPanel: index === activeModuleIndex,
        };

        return panel;
    });

    return (
        <LearnerCoursePage>
            <LearnerCourseHeader
                courseTitle={courseTitle}
                courseDescription={courseDescription}
                learningObjectives={learningObjectives}
                startDate={startDate}
                endDate={endDate}
                trainerInfo={trainerInfo}
            />
            <Background>
                <HeadlineContainer>
                    <Headline3>{strings.learnerCoursePage.courseMaterialsHeader}</Headline3>
                </HeadlineContainer>
                <LearnerCourseComponentMainSection>
                    {nextLiveTraining ? (
                        <NextLiveTrainingMeetingCard nextActivity={nextLiveTraining} />
                    ) : (
                        <NextActivityComponent modules={modules} />
                    )}
                    <Accordion panels={accordionPanels} width={'780px'} />
                </LearnerCourseComponentMainSection>
            </Background>
            <LearnerCoursePageFooter trainerEmail={trainerInfo.email} organisation={trainerInfo.organisationName} />
        </LearnerCoursePage>
    );
}

type LearnerCourseHeaderProps = {
    courseTitle: string;
    courseDescription: string;
    learningObjectives: LearnerLearningObjective[];
    startDate: string;
    endDate: string;
    trainerInfo: LearnerTrainerInfo;
};

function LearnerCourseHeader({
    courseTitle: title,
    courseDescription: description,
    learningObjectives,
    startDate,
    endDate,
    trainerInfo,
}: LearnerCourseHeaderProps): JSX.Element {
    const profileImageFilepath = trainerInfo.profileImagePath
        ? `${DOCUMENTS_URL}/${trainerInfo.profileImagePath}`
        : '';

    return (
        <HeaderSection>
            <LimitedContainer>
                <PageHeaderSection>
                    <TitleAndLogoContainer>
                        <HeadlineBackground>
                            <Headline2>{title}</Headline2>
                            <DateContainer>
                                <DateText>
                                    <BodyRegular $bold>{strings.learnerCoursePage.starting}</BodyRegular>{' '}
                                    {formatDate(startDate)}
                                </DateText>
                                <DateText>
                                    <BodyRegular $bold>{strings.learnerCoursePage.ending}</BodyRegular>{' '}
                                    {formatDate(endDate)}
                                </DateText>
                            </DateContainer>
                        </HeadlineBackground>

                        {trainerInfo.organisationLogo && (
                            <LogoContainer>
                                <Image
                                    src={`${DOCUMENTS_URL}/${trainerInfo.organisationLogo}`}
                                    alt={`${trainerInfo.organisationName} logo`}
                                />
                            </LogoContainer>
                        )}
                    </TitleAndLogoContainer>

                    {description && (
                        <CourseDescriptionContainer>
                            <ExpandableContentComponent
                                headline={strings.learnerCoursePage.courseDescriptionTitle}
                                content={description}
                                closedHeightInPx={150}
                            />
                        </CourseDescriptionContainer>
                    )}
                    {learningObjectives.length > 0 && (
                        <LearningObjectivesContainer>
                            <ExpandableContentComponent
                                headline={strings.learnerCoursePage.learningObjectivesSectionTitle}
                                content={<LearningObjectivesList learningObjectives={learningObjectives} />}
                                closedHeightInPx={150}
                            />
                        </LearningObjectivesContainer>
                    )}
                    <PageBreak />
                    <YourTrainerCard
                        profileImageFilepath={profileImageFilepath}
                        trainerName={`${trainerInfo.firstName} ${trainerInfo.lastName}`}
                        trainerBio={trainerInfo.bio}
                        organisationName={trainerInfo.organisationName}
                    />
                </PageHeaderSection>
            </LimitedContainer>
        </HeaderSection>
    );
}

const LearnerCoursePage = styled.div`
    background: ${theme.learnerCourseBackgroundColour};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    height: calc(100% - 80px);
    @media (max-width: ${breakpoints.sm}) {
        height: calc(100% - 113px);
    }
`;

const Background = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: inherit;
`;

const HeadlineContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: ${sizes.appMaxWidthWithoutPadding}px;
    padding: 0 ${sizes.edgePadding};
    margin: ${sizes.spacingLg} auto;
`;

const LearnerCourseComponentMainSection = styled(MainSection)`
    background: ${theme.learnerCourseBackgroundColour};
    gap: ${sizes.spacingLg};
    padding-bottom: 100px;
`;

const IconContainer = styled.div`
    display: flex;
`;

const IconAndTitleContainer = styled.div`
    display: flex;
    align-items: center;
    text-align: left;
    gap: ${sizes.spacingMd};
`;

const DurationAndArrowContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${sizes.spacingRg};
`;

const CourseDescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingSm};
    opacity: 0.9;
    background: ${theme.cardBackground};
    padding: ${sizes.spacingMd};
    margin-left: -${sizes.spacingMd};
    border-radius: ${sizes.borderRadiusXl};
    @media (max-width: ${breakpoints.sm}) {
        margin-left: unset;
    }
`;

const HeaderSection = styled.div`
    background-color: ${theme.cardBackground};
    width: 100%;
    padding-bottom: ${sizes.spacingXl};
    background-image: url(${BackgroundImagery});
    background-repeat: no-repeat;
    background-position-x: right;
    @media (max-width: ${breakpoints.sm}) {
        background-size: contain;
    }
`;

const LimitedContainer = styled.header`
    display: flex;
    flex-direction: column;
    max-width: ${sizes.appMaxWidthWithoutPadding}px;
    padding: 0 ${sizes.edgePadding};
    margin: auto;
`;

const PageHeaderSection = styled.div`
    margin-top: ${sizes.headerPadding};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: ${sizes.spacingLg};
    height: 100%;
    width: 100%;
`;

const HeadlineBackground = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingSm};
    padding: ${sizes.spacingMd};
    background: ${theme.cardBackground};
    opacity: 0.9;
    margin-left: -${sizes.spacingMd};
    border-radius: ${sizes.borderRadiusXl};
    @media (max-width: ${breakpoints.sm}) {
        margin-left: unset;
    }
`;

const DateContainer = styled.div`
    display: flex;
    gap: ${sizes.spacingLg};
    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
        gap: ${sizes.spacingSm};
    }
`;

const DateText = styled(BodyRegular)`
    display: flex;
    gap: ${sizes.spacingXs};
`;

const TitleAndLogoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: ${sizes.spacingMd};
    align-items: center;
    @media (max-width: ${breakpoints.md}) {
        align-items: unset;
        flex-direction: column-reverse;
    }
`;

const LogoContainer = styled.div`
    background-color: ${theme.cardBackground};
    border-radius: ${sizes.borderRadiusXl};
    border-top: 1px solid var(--neutral-05, ${theme.cardBorder});
    border-right: 1px solid var(--neutral-05, ${theme.cardBorder});
    border-bottom: 3px solid var(--neutral-05, ${theme.cardBorder});
    border-left: 1px solid var(--neutral-05, ${theme.cardBorder});
    display: flex;
    align-items: center;
    height: auto;
    width: auto;
    padding: ${sizes.spacingSm};
    @media (max-width: ${breakpoints.md}) {
        align-self: flex-start;
    }
`;

const Image = styled.img`
    border-radius: ${sizes.borderRadiusMd};
    max-width: 244px;
    max-height: 88px;
    width: auto;
    height: auto;
    object-fit: contain;
`;

const LearningObjectivesContainer = styled.div`
    width: 100%;
    gap: ${sizes.spacingSm};
    background-color: ${theme.cardBackground};
    border-radius: ${sizes.borderRadiusXl};
    border-top: 1px solid var(--neutral-05, ${theme.cardBorder});
    border-right: 1px solid var(--neutral-05, ${theme.cardBorder});
    border-bottom: 3px solid var(--neutral-05, ${theme.cardBorder});
    border-left: 1px solid var(--neutral-05, ${theme.cardBorder});
    display: flex;
    flex-direction: column;
    padding: ${sizes.spacingMd};
`;

const PageBreak = styled.div`
    border-bottom: 1px solid ${theme.cardBorder};
`;
