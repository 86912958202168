import { API_URL, APIError, ApiResponse, getHeaders, isLaterThanNow } from '../lib/_api-helpers';
import * as React from 'react';
import { AuthContext } from '../contextProviders/AuthContext';

type UseGetRequestData<T> = {
    refetchData: () => Promise<void>;
    data: T | null;
    loading: boolean;
    errors: APIError[];
    called: boolean;
    unauthenticated?: boolean;
};

export default function useGetRequest<T>(
    endpoint: string,
    delay?: boolean,
    unauthenticated?: boolean,
): UseGetRequestData<T> {
    const authContext = React.useContext(AuthContext);
    const url = `${API_URL}/${endpoint}`;

    const [loading, setLoading] = React.useState(false);
    const [errors, setErrors] = React.useState<APIError[]>([]);
    const [data, setData] = React.useState<T | null>(null);
    const [called, setCalled] = React.useState(false);

    React.useEffect(() => {
        if (delay) return;

        refetchData().then();
        // eslint-disable-next-line
    }, [delay]);

    const refetchData = async () => {
        let headers: HeadersInit = {};

        setLoading(true);

        if (!unauthenticated) {
            let token = authContext.userData.tokens.accessToken ?? '';

            if (isLaterThanNow(authContext.tokenExpiryTime)) {
                token = await authContext.refreshToken();
            }

            headers = getHeaders(token);
        }

        try {
            const response = await fetch(url, {
                headers,
            });

            const responseObject: ApiResponse<T> = await response.json();

            setData(responseObject.value ?? null);
            setErrors(responseObject.errors ?? []);
        } catch (error) {
            setErrors([{ field: 'global', code: 'serviceError' }]);
            setData(null);
        } finally {
            setLoading(false);
            setCalled(true);
        }
    };

    return {
        refetchData,
        data,
        loading,
        errors,
        called,
    };
}
