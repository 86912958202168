import * as React from 'react';
import { Activity } from 'models/Activity';
import {
    createErrorMessage,
    fieldErrorCodes,
    requiredFieldErrorMessage,
    createGlobalErrorFromAPIError,
    createFieldErrorFromAPIError,
} from 'shared/error-messages';
import TextField from 'components/forms/TextField';
import { ErrorMessage, FormCardWithGaps } from 'components/forms/FormComponents';
import { ButtonRowRight, Headline4 } from 'components/Elements';
import PrimaryButton from 'components/buttons/PrimaryButton';
import useModifyRequest from 'hooks/useModifyRequest';
import strings from 'strings/strings.json';
import { APIError } from 'lib/_api-helpers';
import SaveNotifier from 'components/notifiers/SaveNotifier';
import useNeveForm from 'components/forms/NeveForm';
import { EditCourseContext } from 'contextProviders/EditCourseContext';
import { validateThreeCharacterLimitDurationRequired } from 'lib/custom-form-validation';
import AccessibilityNotifier from 'components/notifiers/AccessibilityNotifier';
import useFormBlocker from 'components/forms/useFormBlocker';
import { styled } from 'styled-components';

type LiveTrainingUpdateInput = {
    title: string;
    trainingDuration: number;
};

export type LiveTrainingUpdateFormProps = {
    activity: Activity;
};

export default function LiveTrainingUpdateForm({ activity }: LiveTrainingUpdateFormProps): JSX.Element {
    const context = React.useContext(EditCourseContext);

    const {
        register,
        handleSubmit,
        setError,
        watch,
        formState: { errors, isDirty },
        reset,
    } = useNeveForm<LiveTrainingUpdateInput>({
        title: activity?.title ?? '',
        trainingDuration: activity?.durationInMins ?? 0,
    });

    useFormBlocker(isDirty, strings.courseEditorPage.unsavedChangesPrompt);

    const [globalErrorMessage, setGlobalErrorMessage] = React.useState<string>('');
    const [saved, setSaved] = React.useState<boolean>(false);

    const trainingDuration = watch('trainingDuration');

    const titleInputProps = register('title', {
        required: {
            value: true,
            message: requiredFieldErrorMessage(strings.activityForm.titleInputLabel),
        },
        maxLength: {
            value: 120,
            message: createErrorMessage(strings.activityForm.titleInputLabel, fieldErrorCodes.maxLength),
        },
        onChange: () => saved && setSaved(false),
    });

    const durationInputProps = register('trainingDuration', {
        required: {
            value: true,
            message: requiredFieldErrorMessage(strings.liveTrainingDetailsForm.durationInputLabel),
        },
        validate: {
            validateThreeCharacterLimitDurationRequired,
        },
        onChange: () => saved && setSaved(false),
    });

    function updateErrors(apiErrors: APIError[]): void {
        const fieldMap: Record<string, string> = {
            title: 'title',
            duration: 'duration',
        };

        apiErrors.forEach((apiError) => {
            const { field, code } = apiError;

            if (field === 'global') {
                return setGlobalErrorMessage(createGlobalErrorFromAPIError(code));
            }
            setError(
                fieldMap[field] as keyof LiveTrainingUpdateInput,
                createFieldErrorFromAPIError(fieldMap[field], code),
            );
        });
    }

    const { modifyData: updateLiveTraining, loading: createLoading } = useModifyRequest(
        `activities/live-training/${activity.id}`,
        'PUT',
    );

    async function onSubmit(formData: LiveTrainingUpdateInput) {
        const updateLiveTrainingRequest = {
            title: formData.title,
            duration: formData.trainingDuration,
        };

        const response = await updateLiveTraining(updateLiveTrainingRequest);

        const { errors } = response;

        if (errors) {
            updateErrors(errors);
            return;
        }

        setGlobalErrorMessage('');
        setSaved(true);
        reset(formData);

        await context.refetchCourse();
    }

    return (
        <FormCard onSubmit={handleSubmit(onSubmit)} id="activityForm">
            <Headline4>{strings.updateTrainingDetailsForm.instructionEdit}</Headline4>
            <div>
                <TextField
                    fieldName="activityTitle"
                    labelText={strings.updateTrainingDetailsForm.titleInputLabel}
                    inputAria={strings.updateTrainingDetailsForm.titleInputAriaLabel}
                    inputProps={titleInputProps}
                    errorMessage={errors.title?.message}
                    required
                />
                <TextField
                    fieldName="activityTrainingDuration"
                    labelText={strings.updateTrainingDetailsForm.durationInputLabel}
                    inputAria={strings.updateTrainingDetailsForm.durationInputAria}
                    inputProps={durationInputProps}
                    errorMessage={errors.trainingDuration?.message}
                    inputType="number"
                    width="8rem"
                    flavourText={strings.updateTrainingDetailsForm.durationInputMins}
                    required
                />
            </div>
            {trainingDuration > 45 && <AccessibilityNotifier accessibilityGuideKey={'liveTrainingTooLong'} />}
            {globalErrorMessage && <ErrorMessage id="errorMessage">{globalErrorMessage}</ErrorMessage>}
            <ButtonRowRight>
                <PrimaryButton
                    type="submit"
                    title={strings.updateTrainingDetailsForm.submitButton}
                    aria={strings.updateTrainingDetailsForm.submitButtonAria}
                    disabled={createLoading}
                />
            </ButtonRowRight>
            {saved && !isDirty && (
                <>
                    <SaveNotifier />
                </>
            )}
        </FormCard>
    );
}

const FormCard = styled(FormCardWithGaps)`
    width: 100%;
`;
