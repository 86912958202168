import styled from 'styled-components';
import { Headline2 } from 'components/Elements';
import strings from '../../../strings/strings.json';
import { breakpoints, sizes, theme } from 'theme';
import { Organisation } from 'models/Organisation';
import Skeleton from 'react-loading-skeleton';
import { APIError } from '../../../lib/_api-helpers';
import Notification, { NotificationType } from '../../../components/notifiers/Notification';
import Table, { RightAlignedButtonCell, TableRow } from 'components/table/Table';
import { EmptyTableContent } from 'components/table/EmptyTableContent';
import useWindowWidth from 'hooks/useWindowWidth';
import EditIcon from 'assets/icons/controls/EditIcon';
import { useNavigate } from 'react-router-dom';
import SecondaryButton from '../../../components/buttons/SecondaryButton';
import PlusIcon from 'assets/icons/controls/PlusIcon';
import { IconSize } from 'assets/icons/icon-sizes';
import OrganisationAdminIcon from 'assets/icons/role-icons/OrganisationAdminIcon';
import PrimaryButton from '../../../components/buttons/PrimaryButton';

type OrganisationProps = {
    organisations: Organisation[];
    loading: boolean;
    errors: APIError[] | null;
};

export default function OrganisationList({ organisations, loading, errors }: OrganisationProps): JSX.Element {
    const { isMobileWidth } = useWindowWidth();
    const navigate = useNavigate();

    if (errors && errors.length > 0) {
        return (
            <>
                <HeadingContainer>
                    <Headline2>{strings.organisationPage.pageHeading}</Headline2>
                </HeadingContainer>
                <Notification
                    notificationType={NotificationType.DANGER}
                    title={strings.organisationPage.errorsFetchingDataMessage}
                />
            </>
        );
    }

    const organisationRows: TableRow[] = organisations
        .sort((a, b) => {
            return a.name > b.name ? 1 : -1;
        })
        .map(({ id, name }) => {
            return {
                id: id,
                cells: [
                    {
                        name: strings.organisationPage.name,
                        value: name,
                        isBold: true,
                    },
                    {
                        name: '',
                        value: (
                            <RightAlignedButtonCell>
                                <SecondaryButton
                                    title={isMobileWidth ? '' : strings.organisationPage.editOrganisationButton}
                                    aria={strings.organisationPage.editOrganisationButtonAriaLabel}
                                    onClick={() => navigate(`/admin/organisation/${id}`)}
                                    icon={<EditIcon />}
                                />
                            </RightAlignedButtonCell>
                        ),
                    },
                ],
            };
        });

    return (
        <div>
            <HeadingContainer>
                <Headline2>{strings.organisationPage.pageHeading}</Headline2>
                {loading ? (
                    <Skeleton borderRadius={30} width={200} height={40} />
                ) : (
                    organisationRows.length > 0 && (
                        <PrimaryButton
                            title={strings.organisationPage.addOrganisationButton}
                            aria={strings.organisationPage.addOrganisationButtonAriaLabel}
                            onClick={() => {
                                navigate('/admin/create-organisation');
                            }}
                            icon={<PlusIcon plusColour={theme.primaryButtonBackgroundColour} size={IconSize.LARGE} />}
                        />
                    )
                )}
            </HeadingContainer>
            <Table
                headers={[
                    { name: strings.organisationPage.name, order: 1, rowPercentage: 50 },
                    { name: '', order: 2, rowPercentage: 50, mobileRightColumn: true },
                ]}
                rows={organisationRows}
                emptyTableContent={
                    <EmptyTableContent
                        icon={<OrganisationAdminIcon size={IconSize.XLARGE} />}
                        title={strings.organisationPage.emptyUsersTable.title}
                        description={strings.organisationPage.emptyUsersTable.description}
                        buttonTitle={strings.organisationPage.addOrganisationButton}
                        buttonAria={strings.organisationPage.addOrganisationButtonAriaLabel}
                        buttonIcon={<PlusIcon plusColour={theme.primaryButtonBackgroundColour} size={IconSize.LARGE} />}
                        buttonOnClick={() => navigate('/admin/create-organisation')}
                    />
                }
            />
        </div>
    );
}

const HeadingContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: ${sizes.spacingLg};
    margin-bottom: ${sizes.spacingMd};

    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
        gap: ${sizes.spacingMd};
    }
`;
