import styled from 'styled-components';
import { BodyRegular } from 'components/Elements';
import { FormFieldContainer, FormLabel, FormTextInput } from './FormComponents';
import { ConditionalValidationErrorMessage } from './ValidationErrorMessage';
import { theme } from 'theme';
import Hint from 'components/Hint';

type TextFieldProps = {
    fieldName: string;
    labelText: string;
    inputAria: string;
    inputProps: Record<string, unknown>;
    errorMessage: string | undefined;
    hideErrorMessage?: boolean; //will highlight the field as 'in error' but not show the error message
    inputType?: 'text' | 'email' | 'password' | 'number';
    disabled?: boolean | false;
    required?: boolean;
    onChange?: (() => void) | ((event: React.ChangeEvent<HTMLInputElement>) => void);
    width?: string;
    flavourText?: string | JSX.Element;
    maxLength?: number;
    autoFocus?: boolean;
    hint?: string;
};

export default function TextField(props: TextFieldProps): JSX.Element {
    const {
        fieldName,
        labelText,
        inputAria,
        inputProps,
        errorMessage,
        hideErrorMessage,
        inputType,
        disabled,
        required,
        onChange,
        width,
        flavourText,
        maxLength,
        autoFocus,
        hint,
    } = props;

    return (
        <FormFieldContainer>
            <FormLabel id={`${fieldName}Label`} htmlFor={fieldName}>
                {labelText}
                {required && ' *'}
            </FormLabel>
            {hint && <Hint hint={hint} />}
            <FormTextInputContainer>
                {onChange ? (
                    <FormTextInput
                        maxLength={maxLength}
                        id={fieldName}
                        type={inputType ?? 'text'}
                        aria-required={required}
                        aria-label={inputAria}
                        {...inputProps}
                        $error={!!errorMessage}
                        disabled={disabled}
                        onChange={onChange}
                        width={width}
                        autoFocus={autoFocus}
                    />
                ) : (
                    <FormTextInput
                        maxLength={maxLength}
                        id={fieldName}
                        type={inputType ?? 'text'}
                        aria-required={required}
                        aria-label={inputAria}
                        {...inputProps}
                        $error={!!errorMessage}
                        disabled={disabled}
                        width={width}
                        autoFocus={autoFocus}
                    />
                )}
                {flavourText && typeof flavourText === 'string' && <FlavourText>{flavourText}</FlavourText>}
                {flavourText && typeof flavourText !== 'string' && <>{flavourText}</>}
            </FormTextInputContainer>
            {!hideErrorMessage && <ConditionalValidationErrorMessage error={errorMessage ?? ''} />}
        </FormFieldContainer>
    );
}

const FormTextInputContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const FlavourText = styled(BodyRegular)`
    color: ${theme.textSecondaryColour};
`;
