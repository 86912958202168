import React from 'react';
import styled from 'styled-components';
import { sizes, theme } from '../../theme';

type IconButtonProps = {
    icon: JSX.Element;
    aria: string;
    onClick: () => void;
};
export function ExpandableContentIconButton({ icon, aria, onClick }: IconButtonProps) {
    return (
        <IconButtonContainer aria-label={aria} onClick={onClick}>
            <div>{icon}</div>
        </IconButtonContainer>
    );
}

const IconButtonContainer = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 0;
    height: 48px;
    padding: ${sizes.spacingRg};
    border: 2px solid rgba(0, 0, 0, 0);
    border-radius: 24px;
    background-color: ${theme.secondaryButtonBackgroundColour};
    cursor: pointer;
    &:hover {
        background-color: ${theme.secondaryButtonHoverBackgroundColour};
    }
    &:focus {
        background-color: ${theme.primaryButtonFocusBackgroundColour};
        border: 2px solid ${theme.primaryButtonBorderColour};
        outline: ${theme.primaryButtonBorderColour};
        color: ${theme.primaryButtonFocusTextColour};
    }
`;
