import WarningIcon from 'assets/icons/indicators/WarningIcon';
import { BodySmall } from '../Elements';
import { NotifierSlimProps } from './ErrorNotifierSlim';
import { NotifierSlimContainer } from './NotifierSlimContainer';

export function WarningNotifierSlim({ description }: NotifierSlimProps): JSX.Element {
    return (
        <NotifierSlimContainer>
            <WarningIcon />
            <BodySmall>{description}</BodySmall>
        </NotifierSlimContainer>
    );
}
