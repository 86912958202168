import * as React from 'react';
import { AuthContext } from '../contextProviders/AuthContext';
import { API_URL, ApiResponse, genericServiceError, getHeaders, isLaterThanNow } from '../lib/_api-helpers';

type UseDeleteRequestData = {
    deleteData: <U>() => Promise<ApiResponse<U>>;
    deleteInProgress: boolean;
};

export default function useDeleteRequest(endpoint: string): UseDeleteRequestData {
    const authContext = React.useContext(AuthContext);

    const url = `${API_URL}/${endpoint}`;

    const [deleteInProgress, setLoading] = React.useState(false);

    const deleteData = async <U>() => {
        setLoading(true);

        let token = authContext.userData.tokens.accessToken ?? '';

        if (isLaterThanNow(authContext.tokenExpiryTime)) {
            token = await authContext.refreshToken();
        }

        const headers = getHeaders(token);

        try {
            const response = await fetch(url, {
                headers,
                method: 'DELETE',
            });

            return await response.json();
        } catch (error) {
            return genericServiceError<U>();
        } finally {
            setLoading(false);
        }
    };

    return { deleteData, deleteInProgress };
}
