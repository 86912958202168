import { theme } from '../../../theme';
import { IconSize } from '../icon-sizes';

export default function DownloadIcon({
    size = IconSize.MEDIUM,
    colour = theme.secondaryButtonTextColour,
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.385019 10.2552L2.54134 10.2552L2.54134 17.8023L17.6356 17.8023L17.6356 10.2552L19.7919 10.2552L19.7919 17.8023C19.7919 18.3953 19.5807 18.9029 19.1585 19.3252C18.7362 19.7475 18.2285 19.9586 17.6356 19.9586L2.54134 19.9586C1.94835 19.9586 1.44071 19.7475 1.01844 19.3252C0.596158 18.9029 0.385019 18.3953 0.385019 17.8023L0.385019 10.2552ZM4.69765 8.09887L6.26098 6.6164L9.01029 9.3657L9.01029 0.551757L11.1666 0.551757L11.1666 9.3657L13.9159 6.6164L15.4792 8.09887L10.0884 13.4897L4.69765 8.09887Z"
                fill={colour}
            />
        </svg>
    );
}
