export type IntegrationEmployee = {
    id: string;
    sourceId: string;
    status: IntegrationEmployeeStatus;
    lastSyncDate: Date | null;
    errorReason: string | null;
    errorDate: Date | null;
    firstName: string | null;
    lastName: string | null;
    jobDescription: string | null;
    email: string | null;
};

export enum IntegrationEmployeeStatus {
    'NOT_IMPORTED' = 'NOT_IMPORTED',
    'UP_TO_DATE' = 'UP_TO_DATE',
    'OUT_OF_SYNC' = 'OUT_OF_SYNC',
}
