import React, { useContext } from 'react';
import strings from '../../../strings/strings.json';
import Table, { RightAlignedButtonCell, TableRow } from 'components/table/Table';
import { EmptyTableContent } from 'components/table/EmptyTableContent';
import useWindowWidth from 'hooks/useWindowWidth';
import { AuthContext } from 'contextProviders/AuthContext';
import useGetRequest from 'hooks/useGetRequest';
import Skeleton from 'react-loading-skeleton';
import { breakpoints } from 'theme';
import Notification, { NotificationType } from '../../../components/notifiers/Notification';
import { Course } from 'models/Course';
import NavigateWithArrow from 'components/actions/NavigateWithArrow';
import { IconSize } from 'assets/icons/icon-sizes';
import { Headline3 } from 'components/Elements';
import { HeaderSection } from './HeaderSection';
import { ButtonsSection } from './ScheduledCoursesTable';

export default function PublishedCoursesTable(): JSX.Element {
    const { userData } = useContext(AuthContext);
    const { isMobileWidth } = useWindowWidth();

    const {
        data: publishedCourses,
        loading,
        errors,
    } = useGetRequest<Course[]>(
        `organisations/${userData?.organisation?.id}/published-courses`,
        !userData?.organisation?.id,
    );

    if (loading) {
        return (
            <>
                <Headline3>{strings.OAPublishedCoursesTable.heading}</Headline3>
                <Skeleton height={isMobileWidth ? 224 : 100} count={3} />
            </>
        );
    }

    if (errors && errors.length > 0) {
        return (
            <>
                <Headline3>{strings.OAPublishedCoursesTable.heading}</Headline3>
                <Notification
                    notificationType={NotificationType.DANGER}
                    title={strings.OAPublishedCoursesTable.getPublishedCoursesError}
                />
            </>
        );
    }

    const publishedCourseRows: TableRow[] = (publishedCourses ?? [])
        .sort((a, b) => new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime())
        .flatMap((publishedCourse) => {
            const shortDate = new Date(publishedCourse.dateCreated).toLocaleDateString();
            const linkAria = `${strings.OAPublishedCoursesTable.viewCourseDetailsButtonAria} ${publishedCourse.title}`;
            const linkHref = `/organisation/courses/${publishedCourse.id}/view`;
            return {
                id: publishedCourse.id,
                cells: [
                    {
                        name: strings.OAPublishedCoursesTable.courseTitleheading,
                        value: publishedCourse.title,
                    },
                    {
                        name: strings.OAPublishedCoursesTable.dateCreatedHeading,
                        value: shortDate,
                    },
                    {
                        name: '',
                        value: (
                            <ButtonsSection>
                                <RightAlignedButtonCell>
                                    <NavigateWithArrow
                                        title={''}
                                        href={linkHref}
                                        aria={linkAria}
                                        iconSize={IconSize.LARGE}
                                    />
                                </RightAlignedButtonCell>
                            </ButtonsSection>
                        ),
                    },
                ],
            };
        });

    return (
        <>
            <HeaderSection>
                <Headline3>{strings.OAPublishedCoursesTable.heading}</Headline3>
            </HeaderSection>
            <div>
                <Table
                    headers={[
                        {
                            name: strings.OAPublishedCoursesTable.courseTitleheading,
                            order: 1,
                            rowPercentage: 50,
                        },
                        { name: strings.OAPublishedCoursesTable.dateCreatedHeading, order: 2, rowPercentage: 45 },
                        {
                            name: '',
                            order: 3,
                            rowPercentage: 5,
                            mobileRightColumn: true,
                        },
                    ]}
                    rows={publishedCourseRows}
                    emptyTableContent={
                        <EmptyTableContent title={strings.OAPublishedCoursesTable.emptyTableContentTitle} />
                    }
                    mobileViewBelow={breakpoints.xl}
                />
            </div>
        </>
    );
}
