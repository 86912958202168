import * as React from 'react';
import strings from '../../../strings/strings.json';
import styled from 'styled-components';
import PrimaryButton from 'components/buttons/PrimaryButton';
import useModifyRequest from 'hooks/useModifyRequest';
import { useNavigate, useParams } from 'react-router-dom';
import useGetRequest from 'hooks/useGetRequest';
import { Course } from 'models/Course';
import CoursePublishSuccessCard from '../components/CoursePublishSuccessCard';
import { logError } from 'lib/debug-helpers';
import { BodyRegular, ButtonRowLeft, Headline4, Headline5 } from 'components/Elements';
import { breakpoints, sizes } from 'theme';
import MainSection from 'components/sections/MainSection';
import SubpageHeader from 'components/sections/SubpageHeader';
import WhyIsEditingLimited from './components/WhyIsEditingLimited';
import BulletList from './components/BulletList';
import Notification, { NotificationType } from '../../../components/notifiers/Notification';
import SecondaryButton from '../../../components/buttons/SecondaryButton';

export default function PublishCourseWarningPage(): JSX.Element {
    const params = useParams();
    const { courseId } = params;
    const navigate = useNavigate();

    const { data: course } = useGetRequest<Course>(`courses/${courseId}`, !courseId);
    const { modifyData: updateCourse, loading } = useModifyRequest(`courses/${courseId}/publish`, 'POST');

    const [published, setPublished] = React.useState<boolean>(!!course?.published);
    const [error, setError] = React.useState<boolean>(false);

    const onPublish = async () => {
        setError(false);
        const response = await updateCourse({ ...course, published: true });

        if (response.errors) {
            logError(response.errors);
            setError(true);
        } else {
            setPublished(true);
        }
    };

    if (!course) return <div></div>;

    if (published)
        return (
            <MainSection>
                <CoursePublishSuccessCard courseTitle={course.title} courseId={course.id} />
            </MainSection>
        );

    return (
        <>
            <SubpageHeader title={!published ? strings.publishCourseWarningPage.headerPublishTitle : ''} />
            <MainSection>
                <Container>
                    <WhatSection>
                        <Headline4>{strings.publishCourseWarningPage.headingPublish}</Headline4>
                        <BodyRegular>{strings.publishCourseWarningPage.publishNote}</BodyRegular>
                    </WhatSection>
                    <EditSection>
                        <BulletSection>
                            <Headline5>{strings.publishCourseWarningPage.publishSubtitleOne}</Headline5>
                            <BulletList displayText={strings.bulletList.canEdit} />
                        </BulletSection>
                        <BulletSection>
                            <Headline5>{strings.publishCourseWarningPage.publishSubtitleTwo}</Headline5>
                            <BulletList displayText={strings.bulletList.cannotEdit} />
                        </BulletSection>
                        {error && (
                            <Notification
                                notificationType={NotificationType.DANGER}
                                title={strings.publishCourseAlert.alertErrorTitle}
                                description={strings.publishCourseAlert.alertErrorDescription}
                                linkToHelp={{
                                    ...strings.publishCourseAlert.linkToHelp,
                                    url: `/trainer/course/${courseId}/content`,
                                }}
                                maxWidth={'25rem'}
                            />
                        )}
                        <ButtonRowLeft>
                            <SecondaryButton
                                onClick={() => navigate(`/trainer/course/${courseId}/view`)}
                                title={strings.publishCourseWarningPage.cancelButton}
                                aria={strings.publishCourseWarningPage.cancelButtonAria}
                                disabled={loading}
                            />
                            <PrimaryButton
                                onClick={onPublish}
                                title={strings.publishCourseWarningPage.publishButtonTitle}
                                aria={strings.publishCourseWarningPage.publishButtonTitleAria}
                                disabled={loading}
                            />
                        </ButtonRowLeft>
                    </EditSection>
                    <WhySection>
                        <WhyIsEditingLimited />
                    </WhySection>
                </Container>
            </MainSection>
        </>
    );
}

const Container = styled.div`
    display: grid;
    height: 100%;
    grid-template-areas: 'what why' 'edit why';
    grid-template-columns: 1fr;
    grid-gap: ${sizes.spacingLg};

    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
        grid-template-areas: 'what' 'why' 'edit';
        grid-template-columns: unset;
        grid-gap: ${sizes.spacingMd};
    }
`;

const WhatSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingSm};
    grid-area: what;
`;

const EditSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingLg};
    grid-area: edit;
`;

const BulletSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingSm};
`;

const WhySection = styled.div`
    grid-area: why;
`;
