import { theme } from '../../../theme';
import { IconSize } from '../icon-sizes';

export default function InformationIcon({
    colour = theme.textColour,
    size = IconSize.MEDIUM,
}: {
    colour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 27.6454V24.393H19.5144V27.6454H0ZM21.1406 16.262C18.891 16.262 16.9734 15.4692 15.3879 13.8837C13.8024 12.2981 13.0096 10.3806 13.0096 8.13099C13.0096 5.88142 13.8024 3.96386 15.3879 2.37832C16.9734 0.792772 18.891 0 21.1406 0C23.3902 0 25.3077 0.792772 26.8933 2.37832C28.4788 3.96386 29.2716 5.88142 29.2716 8.13099C29.2716 10.3806 28.4788 12.2981 26.8933 13.8837C25.3077 15.4692 23.3902 16.262 21.1406 16.262ZM0 14.6358V11.3834H10.2451C10.4348 11.9797 10.6516 12.5488 10.8955 13.0909C11.1395 13.633 11.4376 14.1479 11.7899 14.6358H0ZM0 21.1406V17.8882H15.2863C15.9096 18.2676 16.5737 18.5861 17.2784 18.8436C17.983 19.1011 18.7284 19.284 19.5144 19.3924V21.1406H0ZM20.3275 13.0096H21.9537V6.50479H20.3275V13.0096ZM21.1406 4.8786C21.3574 4.8786 21.5471 4.79729 21.7098 4.63467C21.8724 4.47205 21.9537 4.28232 21.9537 4.0655C21.9537 3.84867 21.8724 3.65895 21.7098 3.49633C21.5471 3.33371 21.3574 3.2524 21.1406 3.2524C20.9238 3.2524 20.734 3.33371 20.5714 3.49633C20.4088 3.65895 20.3275 3.84867 20.3275 4.0655C20.3275 4.28232 20.4088 4.47205 20.5714 4.63467C20.734 4.79729 20.9238 4.8786 21.1406 4.8786Z"
                fill={colour}
            />
        </svg>
    );
}
