import styled from 'styled-components';
import { sizes, theme } from '../../theme';
import React from 'react';

export default function Footer({ children }: { children: React.ReactNode | React.ReactNode[] }): JSX.Element {
    return (
        <FooterBackground>
            <FooterInfoSection>{children}</FooterInfoSection>
        </FooterBackground>
    );
}

const FooterInfoSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${sizes.spacingRg};
    max-width: ${sizes.appMaxWidthWithoutPadding}px;
    padding: ${sizes.spacingSm};
`;

const FooterBackground = styled.div`
    display: flex;
    background-color: ${theme.footerBackground};
    width: 100%;
    padding: ${sizes.spacingMd} 0 60px 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
