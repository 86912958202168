import { FormLabel, FormTextInput } from './FormComponents';

type ReadOnlyTextFieldProps = {
    fieldName: string;
    labelText: string;
    labelTextAria: string;
    inputValue: string;
};

export default function TextFieldReadOnly(props: ReadOnlyTextFieldProps): JSX.Element {
    const { fieldName, labelText, labelTextAria, inputValue } = props;

    return (
        <div>
            <FormLabel id={`${fieldName}Label`} htmlFor={fieldName}>
                {labelText}
            </FormLabel>
            <FormTextInput id={fieldName} value={inputValue} aria-label={labelTextAria} type="text" readOnly />
        </div>
    );
}
