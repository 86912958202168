import { theme } from '../../../theme';
import { IconSize } from '../icon-sizes';

export default function TaskIcon({
    colour = theme.textColour,
    size = IconSize.MEDIUM,
}: {
    colour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.1414 3.14445H16.2909C17.5647 3.15818 18.6068 4.17321 18.6667 5.4586V21.8287C18.6667 23.0858 17.5354 24 16.2909 24H2.37577C1.13131 24 0 23.0858 0 21.8287V5.45855C0.0598733 4.17321 1.10197 3.15818 2.37577 3.14445H4.52531V2.17311C4.52531 1.85884 4.83642 1.71601 5.14753 1.71601H6.78793C7.16856 0.652364 8.18624 -0.0406392 9.3051 0.0018498C10.4364 0.0018498 11.4263 0.573237 11.8223 1.71601H13.4909C13.802 1.71601 14.1414 1.85884 14.1414 2.17311V3.14445ZM7.21211 2.8588H5.65654V5.14434H13.0101V2.8588H11.398C11.1156 2.83741 10.8831 2.62606 10.8323 2.34455C10.6766 1.61218 10.0463 1.08164 9.30508 1.05894C8.55693 1.07885 7.917 1.60775 7.74951 2.34455C7.70176 2.61666 7.48365 2.82533 7.21211 2.8588Z"
                fill={colour}
            />
            <path
                d="M6.36367 12.744L4.55356 14.4867L3.78993 13.6867C3.57778 13.4608 3.22558 13.4481 2.99799 13.6582C2.77891 13.89 2.77891 14.2548 2.99799 14.4867L4.15756 15.6866C4.25793 15.8001 4.4029 15.8629 4.55351 15.858C4.7027 15.8559 4.84502 15.7943 4.94945 15.6866L7.15551 13.5725C7.16687 13.562 7.17776 13.5509 7.18815 13.5395C7.38877 13.3186 7.37416 12.9752 7.15551 12.7726C6.94346 12.5466 6.59126 12.5339 6.36367 12.744Z"
                fill="white"
            />
            <path
                d="M15.2728 14.0007H8.76774C8.45533 14.0007 8.20209 14.2565 8.20209 14.5721C8.20209 14.8876 8.45533 15.1434 8.76774 15.1434H15.2728C15.5852 15.1434 15.8384 14.8876 15.8384 14.5721C15.8384 14.2565 15.5852 14.0007 15.2728 14.0007Z"
                fill="white"
            />
            <path
                d="M15.2728 9.42969H8.76774C8.45533 9.42969 8.20209 9.6855 8.20209 10.0011C8.20209 10.3167 8.45533 10.5725 8.76774 10.5725H15.2728C15.5852 10.5725 15.8384 10.3167 15.8384 10.0011C15.8384 9.6855 15.5852 9.42969 15.2728 9.42969Z"
                fill="white"
            />
            <path
                d="M6.36367 17.3149L4.55356 19.0576L3.78993 18.2576C3.57778 18.0318 3.22558 18.0191 2.99799 18.2291C2.77891 18.461 2.77891 18.8258 2.99799 19.0576L4.15756 20.2575C4.25793 20.3711 4.4029 20.4338 4.55351 20.429C4.7027 20.4268 4.84502 20.3652 4.94945 20.2575L7.15551 18.1434C7.16687 18.1329 7.17776 18.1219 7.18815 18.1104C7.38877 17.8895 7.37416 17.5462 7.15551 17.3435C6.94346 17.1176 6.59126 17.1049 6.36367 17.3149Z"
                fill="white"
            />
            <path
                d="M15.2728 18.5716H8.76774C8.45533 18.5716 8.20209 18.8274 8.20209 19.143C8.20209 19.4586 8.45533 19.7144 8.76774 19.7144H15.2728C15.5852 19.7144 15.8384 19.4586 15.8384 19.143C15.8384 18.8274 15.5852 18.5716 15.2728 18.5716Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.64647 12.0583H3.28084C2.98451 12.0746 2.73127 11.845 2.71519 11.5457C2.71416 11.5261 2.71416 11.5065 2.71519 11.4869V8.40147C2.71519 8.08589 2.96843 7.83008 3.28084 7.83008H6.64647C6.95889 7.83008 7.21213 8.08589 7.21213 8.40147V11.4869C7.22821 11.7863 7.00095 12.0421 6.70461 12.0583C6.68523 12.0594 6.66585 12.0594 6.64647 12.0583ZM6.08076 8.97285H3.84645V10.9155H6.08076V8.97285Z"
                fill="white"
            />
        </svg>
    );
}
