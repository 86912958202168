import { LearnerQuestion } from '../../../../../models/LearnerQuestionnaire';
import { BodyRegular, BodySmall, Headline4 } from '../../../../../components/Elements';
import strings from '../../../../../strings/strings.json';
import styled from 'styled-components';
import { breakpoints, sizes, theme } from '../../../../../theme';
import * as React from 'react';
import TextHelperIcon from '../../../../../assets/icons/indicators/TextHelperIcon';
import { TextAreaField } from '../../../../../components/forms/TextAreaField';
import { createErrorMessage, fieldErrorCodes, requiredFieldErrorMessage } from '../../../../../shared/error-messages';
import useNeveForm from '../../../../../components/forms/NeveForm';
import NavButton from '../../../../../components/buttons/NavButton';
import PrimaryButton from '../../../../../components/buttons/PrimaryButton';
import useWindowWidth from '../../../../../hooks/useWindowWidth';
import { ErrorNotifierSlim } from '../../../../../components/notifiers/ErrorNotifierSlim';

type QuestionnaireResponseInput = {
    answer: string;
};

type QuestionnaireQuestionFormProps = {
    totalQuestions: number;
    currentQuestion: LearnerQuestion | undefined;
    currentQuestionNumber: number;
    nextQuestion: (answer: string) => Promise<boolean>;
    previousQuestion: (answer: string) => Promise<boolean>;
    firstQuestion: boolean;
    lastQuestion: boolean;
};

export default function QuestionnaireQuestionForm({
    totalQuestions,
    currentQuestion,
    currentQuestionNumber,
    nextQuestion,
    previousQuestion,
    firstQuestion,
    lastQuestion,
}: QuestionnaireQuestionFormProps): JSX.Element {
    const [submitError, setSubmitError] = React.useState<boolean>(false);

    const { currentWindowWidth } = useWindowWidth();

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        watch,
        formState: { errors },
        clearErrors,
        trigger,
    } = useNeveForm<QuestionnaireResponseInput>({
        answer: currentQuestion?.answer ?? '',
    });

    React.useEffect(() => {
        setValue('answer', currentQuestion?.answer ?? '');
        clearErrors();
    }, [currentQuestion, currentQuestionNumber, setValue, clearErrors]);

    async function next() {
        const formData = getValues();

        const success = await nextQuestion(formData.answer);

        if (!success) {
            setSubmitError(true);
        }
    }

    async function previous() {
        const response = await trigger('answer', { shouldFocus: true });

        if (!response) return;

        const formData = getValues();

        const success = await previousQuestion(formData.answer);

        if (!success) {
            setSubmitError(true);
        }
    }

    if (!currentQuestion) return <div>loading...</div>;

    return (
        <QuestionForm onSubmit={handleSubmit(next)}>
            <BodySmall>
                {strings.questionnairePage.questionNumberLabel} {currentQuestionNumber + 1}/{totalQuestions}
            </BodySmall>
            <QuestionSection>
                <Headline4>{currentQuestion.question}</Headline4>
                {currentQuestion.helpText && (
                    <HelperTextContainer>
                        <TextHelperIcon colour={theme.textColour} />
                        <HelperText>{currentQuestion.helpText}</HelperText>
                    </HelperTextContainer>
                )}
            </QuestionSection>
            <TextAreaField
                maxLength={2000}
                charactersLeft={2000 - watch('answer').length}
                fieldName={strings.questionnaireAnswerForm.answerInputLabel}
                required={true}
                aria={`${strings.questionnaireAnswerForm.answerInputAria} ${currentQuestion.question}`}
                errorMessage={errors.answer?.message}
                inputProps={register('answer', {
                    required: {
                        value: true,
                        message: requiredFieldErrorMessage(strings.questionnaireAnswerForm.answerInputLabel),
                    },
                    maxLength: {
                        value: 2000,
                        message: createErrorMessage(
                            strings.questionnaireAnswerForm.answerInputLabel,
                            fieldErrorCodes.maxLength,
                        ),
                    },
                })}
            />
            {submitError && <ErrorNotifierSlim description={strings.questionnairePage.questionSubmitError} />}
            <ButtonContainer>
                {!lastQuestion ? (
                    <Container>
                        <NavButton
                            title={
                                currentWindowWidth > parseInt(breakpoints.md)
                                    ? strings.questionnaireAnswerForm.nextQuestionButton
                                    : strings.questionnaireAnswerForm.nextButton
                            }
                            aria={strings.questionnaireAnswerForm.nextQuestionButtonAria}
                            type="submit"
                            inverted
                        />
                    </Container>
                ) : (
                    <div>
                        <PrimaryButton
                            type="submit"
                            title={
                                currentWindowWidth > parseInt(breakpoints.md)
                                    ? strings.questionnaireAnswerForm.submitAndCompleteButton
                                    : strings.questionnaireAnswerForm.submitButton
                            }
                            aria={strings.questionnaireAnswerForm.submitAndCompleteButtonAria}
                        />
                    </div>
                )}

                {!firstQuestion && (
                    <Container>
                        <NavButton
                            title={
                                currentWindowWidth > parseInt(breakpoints.md)
                                    ? strings.questionnaireAnswerForm.previousQuestionButton
                                    : strings.questionnaireAnswerForm.previousButton
                            }
                            aria={strings.questionnaireAnswerForm.previousQuestionButtonAria}
                            back
                            onClick={() => previous()}
                            type="button"
                            inverted
                        />
                    </Container>
                )}
            </ButtonContainer>
        </QuestionForm>
    );
}

const QuestionForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
`;

const QuestionSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingSm};
`;

const HelperTextContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: ${sizes.spacingSm};
    background-color: ${theme.adminSubpageHeaderColour};
    border-radius: ${sizes.borderRadiusMd};
    padding: ${sizes.spacingMd};
    @media (max-width: ${breakpoints.sm}) {
        width: 100%;
    }
`;

const HelperText = styled(BodyRegular)`
    white-space: pre-wrap;
`;
const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
`;

const Container = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: ${sizes.rem.xs};
`;
