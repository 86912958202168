import { theme } from 'theme';
import { IconSize } from '../icon-sizes';

export default function DurationIcon({
    colour = theme.textColour,
    size = IconSize.SMALL,
}: {
    colour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 24 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.5 0H1.5C0.671562 0 0 0.671562 0 1.5V2.5C0 3.32844 0.671562 4 1.5 4C1.5 9.68531 4.6885 14.4834 9.05262 16C4.6885 17.5166 1.5 22.3147 1.5 28C0.671562 28 0 28.6716 0 29.5V30.5C0 31.3284 0.671562 32 1.5 32H22.5C23.3284 32 24 31.3284 24 30.5V29.5C24 28.6716 23.3284 28 22.5 28C22.5 22.3147 19.3115 17.5166 14.9474 16C19.3115 14.4834 22.5 9.68531 22.5 4C23.3284 4 24 3.32844 24 2.5V1.5C24 0.671562 23.3284 0 22.5 0ZM17.8076 24H6.1925C7.25869 21.0752 9.4485 19 12 19C14.5513 19 16.7414 21.0747 17.8076 24ZM17.8088 8H6.19238C5.74925 6.78425 5.5 5.42175 5.5 4H18.5C18.5 5.42531 18.2508 6.78669 17.8088 8Z"
                fill={colour}
            />
        </svg>
    );
}
