import { ButtonRowRight, BodyLarge } from 'components/Elements';
import PrimaryButton from 'components/buttons/PrimaryButton';
import useNeveForm from 'components/forms/NeveForm';
import TextField from 'components/forms/TextField';
import useModifyRequest from 'hooks/useModifyRequest';
import React from 'react';
import { requiredFieldErrorMessage, createErrorMessage } from 'shared/error-messages';
import strings from '../../../strings/strings.json';
import { TrainerMeetingProvider } from 'models/User';
import SaveNotifier from 'components/notifiers/SaveNotifier';
import Notification, { NotificationType } from 'components/notifiers/Notification';
import styled from 'styled-components';
import { sizes } from '../../../theme';

type ZoomIntegrationFormProps = {
    meetingProvider: TrainerMeetingProvider | null;
};

type ZoomIntegrationUseFormProps = {
    accountId: string;
    clientId: string;
    clientSecret: string;
};

export default function ZoomIntegrationForm({ meetingProvider }: ZoomIntegrationFormProps): JSX.Element {
    const { modifyData: addMeetingProvider } = useModifyRequest('users/me/meeting-provider', 'POST');
    const { modifyData: updateMeetingProvider } = useModifyRequest('users/me/meeting-provider', 'PATCH');

    const [showSuccess, setShowSuccess] = React.useState<boolean>(false);
    const [showError, setShowError] = React.useState<boolean>(false);
    const [trainerHasMeetingProvider, setTrainerHasMeetingProvider] = React.useState<boolean>(meetingProvider != null);

    const { register, handleSubmit, formState } = useNeveForm<ZoomIntegrationUseFormProps>({
        accountId: meetingProvider?.accountId,
        clientId: meetingProvider?.clientId,
        clientSecret: meetingProvider?.clientSecret,
    });

    async function onSubmit(formValues: ZoomIntegrationUseFormProps) {
        const body = {
            accountId: formValues.accountId,
            clientId: formValues.clientId,
            clientSecret: formValues.clientSecret,
        };

        const response = trainerHasMeetingProvider
            ? await updateMeetingProvider(body)
            : await addMeetingProvider(body);

        const error = (response && response.errors && response.errors.length >= 1) ?? false;

        if (!error && !trainerHasMeetingProvider) setTrainerHasMeetingProvider(true);
        setShowSuccess(!error);
        setShowError(error);
    }

    function hideResultMessage() {
        setShowSuccess(false);
        setShowError(false);
    }

    return (
        <ZoomForm onSubmit={handleSubmit(onSubmit)}>
            <BodyLargeExtraSpacing>{strings.profilePage.zoomIntegrationForm.heading}</BodyLargeExtraSpacing>
            <TextField
                fieldName={'accountId'}
                labelText={strings.profilePage.zoomIntegrationForm.accountId}
                inputAria={strings.profilePage.zoomIntegrationForm.accountIdAria}
                errorMessage={formState.errors.accountId?.message}
                required
                inputProps={register('accountId', {
                    required: {
                        value: true,
                        message: requiredFieldErrorMessage(strings.profilePage.zoomIntegrationForm.accountId),
                    },
                    maxLength: {
                        value: 40,
                        message: createErrorMessage(strings.profilePage.zoomIntegrationForm.accountId, 'is too long'),
                    },
                })}
                onChange={hideResultMessage}
            />
            <TextField
                fieldName={'clientId'}
                labelText={strings.profilePage.zoomIntegrationForm.clientId}
                inputAria={strings.profilePage.zoomIntegrationForm.clientIdAria}
                errorMessage={formState.errors.clientId?.message}
                required
                inputProps={register('clientId', {
                    required: {
                        value: true,
                        message: requiredFieldErrorMessage(strings.profilePage.zoomIntegrationForm.clientId),
                    },
                    maxLength: {
                        value: 40,
                        message: createErrorMessage(strings.profilePage.zoomIntegrationForm.clientId, 'is too long'),
                    },
                })}
                onChange={hideResultMessage}
            />
            <TextField
                fieldName={'clientSecret'}
                labelText={strings.profilePage.zoomIntegrationForm.clientSecret}
                inputAria={strings.profilePage.zoomIntegrationForm.clientSecretAria}
                errorMessage={formState.errors.clientSecret?.message}
                required
                inputProps={register('clientSecret', {
                    required: {
                        value: true,
                        message: requiredFieldErrorMessage(strings.profilePage.zoomIntegrationForm.clientSecret),
                    },
                    maxLength: {
                        value: 40,
                        message: createErrorMessage(
                            strings.profilePage.zoomIntegrationForm.clientSecret,
                            'is too long',
                        ),
                    },
                })}
                onChange={hideResultMessage}
            />
            <ButtonRowRight>
                <PrimaryButton
                    type="submit"
                    title={strings.profilePage.zoomIntegrationForm.saveButton}
                    aria={strings.profilePage.zoomIntegrationForm.saveButtonAria}
                />
            </ButtonRowRight>
            {showSuccess && <SaveNotifier />}
            {showError && (
                <Notification
                    notificationType={NotificationType.DANGER}
                    title={strings.profilePage.zoomIntegrationForm.error}
                />
            )}
        </ZoomForm>
    );
}

const BodyLargeExtraSpacing = styled(BodyLarge)`
    padding-bottom: ${sizes.spacingMd};
`;

const ZoomForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
    max-width: 700px;
`;
