import useGetRequest from '../../../../hooks/useGetRequest';
import { IntegrationEmployee } from '../../../../models/hr-integration/IntegrationEmployee';
import React, { useState } from 'react';
import styled from 'styled-components';
import CheckIcon from '../../../../assets/icons/form-icons/CheckIcon';
import { BodyLarge, BodyRegular } from '../../../../components/Elements';
import strings from '../../../../strings/strings.json';
import { IconSize } from '../../../../assets/icons/icon-sizes';
import { breakpoints, sizes, theme } from '../../../../theme';
import Table, { TableRow } from '../../../../components/table/Table';
import Spinner from '../../../../components/Spinner';
import Skeleton from 'react-loading-skeleton';
import { ErrorNotifier } from '../../../../components/notifiers/ErrorNotifier';
import CharlieHRConfigureButton from './CharlieHRConfigureButton';
import { ErrorNotifierSlim } from '../../../../components/notifiers/ErrorNotifierSlim';
import PrimaryButton from '../../../../components/buttons/PrimaryButton';
import CheckboxInput from '../../../../components/forms/CheckboxInput';
import useNeveForm from '../../../../components/forms/NeveForm';
import useModifyRequest from '../../../../hooks/useModifyRequest';
import { EmployeeToImport, ImportEmployeesRequest } from '../../../../models/inputModels/ImportEmployeesRequest';
import CharlieHRStatus from './CharlieHRStatus';
import Notification, { NotificationType } from '../../../../components/notifiers/Notification';
import { ImportEmployeesResponse } from '../../../../models/hr-integration/ImportEmployeesResponse';

type CharlieHREmployeesProps = {
    refreshConnection: () => void;
};

type ImportEmployeesInput = {
    employeesToImport: boolean[];
};

export default function CharlieHREmployees({ refreshConnection }: CharlieHREmployeesProps): JSX.Element {
    const { data, loading, errors, called, refetchData } =
        useGetRequest<IntegrationEmployee[]>('hr-integration/employees');
    const { modifyData: importEmployees, loading: importProcessing } = useModifyRequest(
        'hr-integration/employees/import',
        'POST',
    );

    const [processing, setProcessing] = React.useState<boolean>(false);
    const [importStatus, setImportStatus] = React.useState<'success' | 'partial success' | 'fail' | 'not run'>(
        'not run',
    );

    const [configurationError, setConfigurationError] = React.useState<string | null>(null);
    const [allSelectedCheckbox, setAllSelectedCheckbox] = useState<boolean>(false);

    const { register, handleSubmit, setValue, getValues } = useNeveForm<ImportEmployeesInput>({
        employeesToImport: [],
    });

    function selectAllCheckboxOnCheck(event: any) {
        const selectedEmployee = getValues('employeesToImport');

        const selectAll = event?.target?.checked as boolean;

        for (let i = 0; i < selectedEmployee.length; i++) {
            selectedEmployee[i] = selectAll;
        }

        setValue(`employeesToImport`, selectedEmployee, { shouldDirty: true });
        setAllSelectedCheckbox(!allSelectedCheckbox);
    }

    async function importSelectedEmployees(formData: ImportEmployeesInput) {
        setProcessing(true);

        let employeesToImport: EmployeeToImport[] = [];

        if (!data) {
            setProcessing(false);
            setImportStatus('fail');
            return;
        }

        for (let i = 0; i < data.length; i++) {
            if (!formData.employeesToImport[i]) continue;

            const employee = data[i];

            const newLearner: EmployeeToImport = {
                email: employee.email,
                firstName: employee.firstName,
                jobDescription: employee.jobDescription,
                lastName: employee.lastName,
                sourceId: employee.sourceId,
            };

            employeesToImport.push(newLearner);
        }

        const response = await importEmployees<ImportEmployeesRequest, ImportEmployeesResponse>({
            employees: employeesToImport,
        });

        if (!response) {
            setImportStatus('fail');
            setProcessing(false);
            return;
        }
        if (!response.value) {
            setImportStatus('fail');
            setProcessing(false);
            return;
        }

        await refetchData();

        setProcessing(false);
        setImportStatus(response.value.status);
    }

    if (loading && !called) {
        return (
            <>
                <CharlieEmployeesHeader>
                    <Spinner />
                    <div>
                        <Skeleton height={40} width={400} />
                    </div>
                    <div>
                        <Skeleton height={40} width={80} />
                    </div>
                </CharlieEmployeesHeader>
                <Skeleton height={40} count={5} />
            </>
        );
    }

    if (errors.length > 0) {
        return (
            <>
                <CharlieEmployeesHeader>
                    <LinkedSection>
                        <CheckIcon size={IconSize.LARGE} />
                        <LinkedText $bold>{strings.organisationIntegrationEmployeesPage.linkedText}</LinkedText>
                    </LinkedSection>
                </CharlieEmployeesHeader>
                <ErrorNotifier
                    title={strings.organisationIntegrationEmployeesPage.genericConnectionError}
                    description={strings.errorMessages.genericDetail}
                />
            </>
        );
    }

    if (!data) return <></>;

    const rows: TableRow[] = data.map((employee, index) => {
        const employeeName = `${employee.firstName} ${employee.lastName}`;

        return {
            id: employee.sourceId,
            cells: [
                {
                    name: strings.organisationIntegrationEmployeesPage.employeesTable.fullName,
                    value: employeeName,
                    isBold: true,
                },
                {
                    name: strings.organisationIntegrationEmployeesPage.employeesTable.email,
                    value: employee.jobDescription,
                },
                {
                    name: strings.organisationIntegrationEmployeesPage.employeesTable.status,
                    value: (
                        <CharlieHRStatus
                            status={employee.status}
                            errorReason={employee.errorReason}
                            lastSyncDate={employee.lastSyncDate ? new Date(employee.lastSyncDate) : null}
                            errorDate={employee.errorDate ? new Date(employee.errorDate) : null}
                        />
                    ),
                },
                {
                    name: strings.organisationIntegrationEmployeesPage.employeesTable.jobDescription,
                    value: (
                        <CheckboxContainer>
                            <CheckboxInput
                                id={`attendedCheckbox-${employee.id}`}
                                name={employeeName}
                                value={''}
                                props={{ ...register(`employeesToImport.${index}`) }}
                                aria={`${strings.organisationIntegrationEmployeesPage.employeesTable.selectForImportAria}: ${employeeName}.`}
                            />
                        </CheckboxContainer>
                    ),
                },
            ],
        };
    });

    return (
        <form onSubmit={handleSubmit(importSelectedEmployees)}>
            <CharlieEmployeesHeader>
                <LinkedSection>
                    <CheckIcon size={IconSize.LARGE} />
                    <LinkedText $bold>{strings.organisationIntegrationEmployeesPage.linkedText}</LinkedText>
                </LinkedSection>
                <div>
                    <BodyRegular>
                        {strings.organisationIntegrationEmployeesPage.instructionTextOne}
                        {data?.length}
                        {strings.organisationIntegrationEmployeesPage.instructionTextTwo}
                    </BodyRegular>
                </div>
                <CharlieHRConfigureButton
                    buttonTitle={strings.organisationIntegrationEmployeesPage.reconfigureButtonTitle}
                    setError={setConfigurationError}
                    onClose={refreshConnection}
                />
            </CharlieEmployeesHeader>
            {configurationError && <ErrorNotifierSlim description={configurationError} />}
            <Table
                mobileViewBelow={breakpoints.md}
                headers={[
                    {
                        name: strings.organisationIntegrationEmployeesPage.employeesTable.fullName,
                        rowPercentage: 25,
                        order: 1,
                    },
                    {
                        name: strings.organisationIntegrationEmployeesPage.employeesTable.jobDescription,
                        rowPercentage: 30,
                        order: 2,
                    },
                    {
                        name: strings.organisationIntegrationEmployeesPage.employeesTable.status,
                        rowPercentage: 25,
                        order: 3,
                    },
                    {
                        name: (
                            <SelectedHeader>
                                <BodyRegular>
                                    {strings.organisationIntegrationEmployeesPage.employeesTable.selectAll}
                                </BodyRegular>
                                <CheckboxInput
                                    id={'selectAllCheckbox'}
                                    name={'selectAllCheckbox'}
                                    value={''}
                                    props={{
                                        onChange: selectAllCheckboxOnCheck,
                                        checked: allSelectedCheckbox,
                                    }}
                                    aria={strings.organisationIntegrationEmployeesPage.employeesTable.selectAllAria}
                                />
                            </SelectedHeader>
                        ),
                        rowPercentage: 20,
                        order: 4,
                        mobileRightColumn: true,
                        rightAligned: true,
                    },
                ]}
                rows={rows}
            />
            <ImportNote>
                <BodyRegular>{strings.organisationIntegrationEmployeesPage.instructionTextThree}</BodyRegular>
            </ImportNote>
            <EmployeesFooter>
                <PrimaryButton
                    title={strings.organisationIntegrationEmployeesPage.importButton.title}
                    aria={strings.organisationIntegrationEmployeesPage.importButton.aria}
                    type={'submit'}
                    disabled={importProcessing}
                    icon={importProcessing || processing ? <Spinner /> : undefined}
                />
            </EmployeesFooter>
            {importStatus !== 'not run' && (
                <NotificationFooter>
                    {importStatus === 'success' && (
                        <Notification
                            notificationType={NotificationType.SUCCESS}
                            title={strings.organisationIntegrationEmployeesPage.importSuccess}
                        />
                    )}
                    {importStatus === 'partial success' && (
                        <Notification
                            notificationType={NotificationType.WARNING}
                            title={strings.organisationIntegrationEmployeesPage.importPartialSuccess}
                            description={strings.organisationIntegrationEmployeesPage.importPartialSuccessDetail}
                        />
                    )}
                    {importStatus === 'fail' && (
                        <ErrorNotifier
                            title={strings.organisationIntegrationEmployeesPage.importFail}
                            description={strings.errorMessages.genericDetail}
                        />
                    )}
                </NotificationFooter>
            )}
        </form>
    );
}

const LinkedText = styled(BodyLarge)`
    color: ${theme.successColour};
    word-break: keep-all;
`;

const LinkedSection = styled.div`
    display: flex;
    align-items: center;
    gap: ${sizes.spacingMd};
`;

const CharlieEmployeesHeader = styled.div`
    margin: ${sizes.spacingMd};
    margin-bottom: ${sizes.spacingLg};
    display: flex;
    align-items: center;
    gap: ${sizes.spacingXl};
    justify-content: space-between;

    @media (max-width: ${breakpoints.md}) {
        flex-direction: column;
        gap: ${sizes.spacingMd};
        align-items: flex-start;
    }
`;

const ImportNote = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: ${sizes.spacingLg} 0;
`;

const EmployeesFooter = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin: ${sizes.spacingMd};
    margin-top: ${sizes.spacingLg};
`;

const SelectedHeader = styled.div`
    display: flex;
    gap: ${sizes.spacingMd};
`;

const CheckboxContainer = styled.div`
    display: flex;
    justify-content: end;
`;

const NotificationFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
`;
