import CloseIcon from 'assets/icons/controls/CloseIcon';
import { IconSize } from 'assets/icons/icon-sizes';
import WarningIcon from 'assets/icons/indicators/WarningIcon';
import { BodyLarge, BodyRegular, Flex, TertiaryButton } from 'components/Elements';
import styled from 'styled-components';
import { breakpoints, sizes, theme } from 'theme';
import strings from '../../../strings/strings.json';
import { Dispatch, SetStateAction } from 'react';

type NotificationProps = {
    title: string;
    description: string;
    setShowPublishWarning: Dispatch<SetStateAction<boolean>>;
};

export default function CourseBuilderValidationWarning({
    title,
    description,
    setShowPublishWarning,
}: NotificationProps): JSX.Element {
    return (
        <NotificationContainer>
            <SmallIconContainer>
                <WarningIcon />
            </SmallIconContainer>
            <NotificationBodyContainer>
                <TitleAndButtonContainer>
                    <BodyLarge $bold>{title}</BodyLarge>
                    <ButtonContainer>
                        <CloseButton
                            onClick={() => {
                                setShowPublishWarning(false);
                            }}
                            aria-label={strings.hint.closeButtonAria}
                        >
                            <CloseIcon size={IconSize.SMALL} colour={theme.textColour} />
                        </CloseButton>
                    </ButtonContainer>
                </TitleAndButtonContainer>
                <DescriptionSection>{description}</DescriptionSection>
            </NotificationBodyContainer>
        </NotificationContainer>
    );
}

const NotificationContainer = styled.div`
    display: flex;
    border-radius: ${sizes.borderRadiusMd};
    padding: ${sizes.spacingMd};
    gap: ${sizes.spacingMd};
    border-width: 3px;
    border-style: solid;
    border-color: ${theme.notificationWarningBorder};
    background-color: ${theme.notificationWarningBackground};
    @media (max-width: ${breakpoints.sm}) {
        width: 100%;
    }
`;

const NotificationBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${sizes.spacingSm};
`;

const TitleAndButtonContainer = styled(Flex)`
    justify-content: space-between;
    align-items: center;
    gap: ${sizes.spacingXs};
`;

const ButtonContainer = styled(Flex)`
    align-self: flex-start;
`;

const CloseButton = styled(TertiaryButton)`
    padding: ${sizes.spacingXs};
`;

const DescriptionSection = styled(BodyRegular)`
    white-space: pre-wrap;
`;

const SmallIconContainer = styled.div`
    margin-top: 0.3rem;
`;
