import { theme } from '../../../theme';
import { IconSize } from '../icon-sizes';

export default function LogoutIcon({
    colour = theme.primaryBrandColour,
    size = IconSize.MEDIUM,
}: {
    colour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.125 29.25C3.23125 29.25 2.46615 28.9318 1.82969 28.2953C1.19323 27.6589 0.875 26.8937 0.875 26V3.25C0.875 2.35625 1.19323 1.59115 1.82969 0.954688C2.46615 0.318229 3.23125 0 4.125 0H15.5V3.25H4.125V26H15.5V29.25H4.125ZM22 22.75L19.7656 20.3938L23.9094 16.25H10.625V13H23.9094L19.7656 8.85625L22 6.5L30.125 14.625L22 22.75Z"
                fill={colour}
            />
        </svg>
    );
}
