// @ts-ignore
import { ModalBackground, ModalBody } from 'components/Elements';
import { ResourcesForm, ResourcesInput } from './ResourcesForm';
import styled from 'styled-components';
import { breakpoints, theme } from 'theme';
import useKeyboardTrap from 'hooks/useKeyboardTrap';

type LearningResourcesFormModalProps = {
    closeModal: () => void;
    addResource: (resources: ResourcesInput) => void;
};

export function LearningResourcesFormModal({ closeModal, addResource }: LearningResourcesFormModalProps): JSX.Element {
    const { ref: modalRef } = useKeyboardTrap(closeModal);

    const addAndClose = (resources: ResourcesInput) => {
        addResource(resources);
        closeModal();
    };

    return (
        <ModalBackgroundContainer>
            <ModalBodyContainer ref={modalRef}>
                <ModalCard>
                    <ResourcesForm closeModal={closeModal} addResource={addAndClose} />
                </ModalCard>
            </ModalBodyContainer>
        </ModalBackgroundContainer>
    );
}

const ModalBackgroundContainer = styled(ModalBackground)`
    @media (max-width: ${breakpoints.sm}) {
        background-color: ${theme.cardBackground};
    }
`;

const ModalBodyContainer = styled(ModalBody)`
    @media (max-width: ${breakpoints.sm}) {
        width: 100%;
        height: 100%;
    }
`;

const ModalCard = styled.div`
    @media (max-width: ${breakpoints.sm}) {
        padding-top: 130px;
    }
`;
