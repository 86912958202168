import { ContentColumn, LearnerCourseContentError, Page } from '../../../components/shared';
import { BodyLarge, Card, Headline2 } from '../../../../../components/Elements';
import QuestionnaireComplete from './QuestionnaireComplete';
import Notification, { NotificationType } from '../../../../../components/notifiers/Notification';
import strings from '../../../../../strings/strings.json';
import StartQuestionnaireCard from './StartQuestionnaireCard';
import QuestionnaireQuestionForm from './QuestionnaireQuestionForm';
import React from 'react';
import { LearnerActivity } from '../../../../../models/LearnerCourse';
import { LearnerQuestion, LearnerQuestionnaire } from '../../../../../models/LearnerQuestionnaire';
import styled from 'styled-components';
import { sizes } from '../../../../../theme';
import { logError } from '../../../../../lib/debug-helpers';
import { APIError, ApiResponse } from '../../../../../lib/_api-helpers';
import { QuestionnaireAnswer } from '../../../../../models/QuestionnaireAnswer';
import { CourseNavigationActivity } from '../../../components/CourseNavigation';
import Skeleton from 'react-loading-skeleton';
import ActivityTypeLabel from '../../../../edit-course/components/ActivityTypeLabel';
import { ActivityType } from 'models/Activity';
import { HeadlineAndLabelContainer } from '../task/TaskContentLayout';

type QuestionnaireContentLayoutProps = {
    activitySummary: LearnerActivity;
    loading: boolean;
    errors: APIError[];
    currentQuestionNumber: number | null;
    currentQuestion: LearnerQuestion | undefined;
    setQuestionNumber: (newQuestionNumber: number) => void;
    answerQuestion: (answer: string) => Promise<ApiResponse<QuestionnaireAnswer>>;
    setActivityToComplete: () => Promise<ApiResponse<boolean>>;
    questionnaire: LearnerQuestionnaire | null;
    refetchQuestionnaire: () => Promise<void>;
};

export default function QuestionnaireContentLayout({
    activitySummary,
    loading,
    errors,
    setActivityToComplete,
    answerQuestion,
    setQuestionNumber,
    currentQuestionNumber,
    refetchQuestionnaire,
    questionnaire,
    currentQuestion,
}: QuestionnaireContentLayoutProps): JSX.Element {
    const [questionnairePreviouslyCompleted, setQuestionnairePreviouslyCompleted] = React.useState<boolean>(false);
    const [questionnaireCompleted, setQuestionnaireCompleted] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (questionnaire?.completed) setQuestionnairePreviouslyCompleted(true);
    }, [questionnaire]);

    if (loading && !questionnaire)
        return (
            <Page>
                <ContentColumn>
                    <Skeleton height={'50vh'} />
                </ContentColumn>
            </Page>
        );

    if (errors.length > 0) {
        return (
            <LearnerCourseContentError
                title={strings.modulePage.error.title}
                description={strings.modulePage.error.description}
            />
        );
    }

    const submitAnswer = async (answer: string): Promise<boolean> => {
        const response = await answerQuestion(answer);

        if (response.errors && response.errors.length > 0) {
            logError(response.errors);

            return false;
        }

        await refetchQuestionnaire();

        return true;
    };

    const nextQuestion = async (answer: string) => {
        const submitSuccess = await submitAnswer(answer);

        if (!submitSuccess) return false;

        if ((currentQuestionNumber ?? 0) < activitySummary.questionsTotal - 1) {
            setQuestionNumber(currentQuestionNumber === null ? 0 : currentQuestionNumber + 1);

            return true;
        }

        const response = await setActivityToComplete();

        if (response.errors && response.errors.length > 0) {
            logError(response.errors);

            return false;
        }

        await refetchQuestionnaire();

        setQuestionnaireCompleted(true);

        return true;
    };

    const previousQuestion = async (answer: string) => {
        const submitSuccess = await submitAnswer(answer);

        if (!submitSuccess) return false;

        setQuestionNumber(currentQuestionNumber === null ? 0 : currentQuestionNumber - 1);

        return true;
    };

    if (!questionnaire)
        return (
            <LearnerCourseContentError
                title={strings.modulePage.error.title}
                description={strings.modulePage.error.description}
            />
        );

    return (
        <Page>
            <ContentColumn>
                <HeadlineAndLabelContainer>
                    <Headline2>{activitySummary.title}</Headline2>
                    <ActivityTypeLabel activityType={ActivityType.QUESTIONNAIRE} />
                </HeadlineAndLabelContainer>
                {questionnaireCompleted && <QuestionnaireComplete />}
                {!questionnaireCompleted && questionnairePreviouslyCompleted && (
                    <AnswersAlreadySubmittedContainer>
                        <BodyLarge>{questionnaire.description}</BodyLarge>
                        <Notification
                            notificationType={NotificationType.SUCCESS}
                            title={strings.questionnairePage.questionnaireAlreadyCompletedTitle}
                            description={strings.questionnairePage.questionnaireAlreadyCompletedDescription}
                        />
                    </AnswersAlreadySubmittedContainer>
                )}
                {!questionnaireCompleted && !questionnairePreviouslyCompleted && (
                    <QuestionnaireCard>
                        {currentQuestionNumber === null ? (
                            <StartQuestionnaireCard
                                description={questionnaire.description}
                                totalQuestions={activitySummary.questionsTotal}
                                startQuestionnaire={() => setQuestionNumber(0)}
                            />
                        ) : (
                            <QuestionnaireQuestionForm
                                totalQuestions={activitySummary.questionsTotal}
                                currentQuestionNumber={currentQuestionNumber}
                                currentQuestion={currentQuestion}
                                firstQuestion={currentQuestionNumber === 0}
                                lastQuestion={currentQuestionNumber === activitySummary.questionsTotal - 1}
                                nextQuestion={nextQuestion}
                                previousQuestion={previousQuestion}
                            />
                        )}
                    </QuestionnaireCard>
                )}
            </ContentColumn>
            <Footer>
                <CourseNavigationActivity
                    activityId={activitySummary.id}
                    questionnaireActive={
                        currentQuestion && !questionnaireCompleted && !questionnairePreviouslyCompleted
                    }
                />
            </Footer>
        </Page>
    );
}

const AnswersAlreadySubmittedContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${sizes.spacingMd};
`;

const QuestionnaireCard = styled(Card)`
    min-height: 400px;
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingLg};
`;

const Footer = styled.div`
    width: 100%;
`;
