import * as React from 'react';
import strings from '../../../../strings/strings.json';
import PrimaryButton from '../../../../components/buttons/PrimaryButton';
import { ButtonRowRight } from '../../../../components/Elements';
import SaveNotifier from '../../../../components/notifiers/SaveNotifier';
import { logError } from '../../../../lib/debug-helpers';
import { Course, CourseDetailsAreComplete } from '../../../../models/Course';
import { APIError } from '../../../../lib/_api-helpers';
import { ErrorMessage, FormCardWithGaps } from '../../../../components/forms/FormComponents';
import {
    createGlobalErrorFromAPIError,
    createFieldErrorFromAPIError,
    requiredFieldErrorMessage,
    createErrorMessage,
    fieldErrorCodes,
} from '../../../../shared/error-messages';
import TextField from '../../../../components/forms/TextField';
import useModifyRequest from '../../../../hooks/useModifyRequest';
import useNeveForm from 'components/forms/NeveForm';
import Alert from 'components/alert/Alert';
import { TextAreaField } from 'components/forms/TextAreaField';
import AccessibilityNotifier from '../../../../components/notifiers/AccessibilityNotifier';
import { capitalsRegex } from '../../../../components/accessibilityGuide/utils';
import NumberOfLearnersSection, { NumberOfLearnersSectionInput } from '../../components/NumberOfLearnersSection';
import { styled } from 'styled-components';
import useFormBlocker from 'components/forms/useFormBlocker';
import Notification, { NotificationType } from 'components/notifiers/Notification';

type CourseDetailsFormProps = {
    course: Course;
    refetchCourse?: () => Promise<void>;
    showIncompleteStepsWarning?: boolean;
};

export function CourseDetailsForm({
    course,
    refetchCourse,
    showIncompleteStepsWarning,
}: CourseDetailsFormProps): JSX.Element {
    const { modifyData: updateCourse, loading } = useModifyRequest(`courses/${course.id}`, 'PUT');

    const formValues = {
        title: course.title,
        description: course.description,
        learners: {
            minimumLearners: course.minimumLearners,
            maximumLearners: course.maximumLearners,
        },
    };

    const {
        register,
        handleSubmit,
        setError,
        clearErrors,
        watch,
        formState: { errors, isDirty },
        reset,
    } = useNeveForm<CourseDetailsInput>(formValues);

    useFormBlocker(isDirty, strings.courseEditorPage.unsavedChangesPrompt);

    const [globalErrorMessage, setGlobalErrorMessage] = React.useState<string>('');
    const [saved, setSaved] = React.useState<boolean>(false);
    const [alertOpen, setAlertOpen] = React.useState(false);

    const toggleAlert = () => {
        setAlertOpen(!alertOpen);
    };

    async function onSubmit(formData: CourseDetailsInput) {
        if (!formData.title) return;
        setSaved(false);

        const newCourse = {
            ...course,
            title: formData.title,
            description: formData.description,
            minimumLearners: formData.learners.minimumLearners,
            maximumLearners: formData.learners.maximumLearners,
        };

        const response = await updateCourse(newCourse as Course);

        if (alertOpen) {
            setAlertOpen(false);
        }

        const { value: moduleId, errors } = response;
        if (errors) {
            logError(errors);
            updateErrors(errors);
            return;
        }
        if (moduleId) {
            reset(formData);
            setSaved(true);
            setGlobalErrorMessage('');
            !!refetchCourse && (await refetchCourse());
            return;
        }

        logError('CourseDetails: No Errors, and no Value');
    }

    function updateErrors(apiErrors: APIError[]): void {
        apiErrors.forEach((apiError) => {
            const { field, code } = apiError;
            if (field === 'global') {
                return setGlobalErrorMessage(createGlobalErrorFromAPIError(code));
            }
            setError(field as keyof CourseDetailsInput, createFieldErrorFromAPIError(field, code));
        });
    }

    const courseTitle = watch('title');
    const courseDescription = watch('description');

    return (
        <>
            {!CourseDetailsAreComplete(course) && showIncompleteStepsWarning && (
                <NotificationContainer>
                    <Notification
                        title={strings.courseEditorPage.minMaxLearnersNotSet.title}
                        notificationType={NotificationType.WARNING}
                        maxWidth="100%"
                    />
                </NotificationContainer>
            )}
            <Form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <TextField
                        maxLength={100}
                        fieldName="courseTitle"
                        labelText={strings.courseDetailsForm.courseTitleInputLabel}
                        inputAria={strings.courseDetailsForm.courseTitleInputLabelAria}
                        inputProps={register('title', {
                            required: {
                                value: true,
                                message: requiredFieldErrorMessage(strings.courseDetailsForm.courseTitleInputLabel),
                            },
                            onChange: () => saved && setSaved(false),
                        })}
                        errorMessage={errors.title?.message}
                        required
                        autoFocus
                    />
                    {capitalsRegex.test(courseTitle) && (
                        <AccessibilityNotifier accessibilityGuideKey={'textAllCaps'} />
                    )}
                    <TextAreaField
                        maxLength={1000}
                        charactersLeft={1000 - watch('description').length}
                        fieldName={strings.courseDetailsForm.courseDescriptionInputLabel}
                        aria={strings.courseDetailsForm.courseDescriptionInputLabelAria}
                        errorMessage={errors.description?.message}
                        inputProps={register('description', {
                            maxLength: {
                                value: 1000,
                                message: createErrorMessage(
                                    strings.courseDetailsForm.courseDescriptionInputLabel,
                                    fieldErrorCodes.maxLength,
                                ),
                            },
                        })}
                    />
                    {capitalsRegex.test(courseDescription) && (
                        <AccessibilityNotifier accessibilityGuideKey={'textAllCaps'} />
                    )}
                </div>

                <NumberOfLearnersSection
                    errors={errors}
                    register={register}
                    watch={watch}
                    setError={setError}
                    clearErrors={clearErrors}
                />

                {globalErrorMessage && <ErrorMessage id="errorMessage">{globalErrorMessage}</ErrorMessage>}
                <ButtonRowRight>
                    {course.published ? (
                        <PrimaryButton
                            title={strings.courseDetailsForm.submitButton}
                            aria={strings.courseDetailsForm.submitButtonAria}
                            disabled={loading}
                            onClick={toggleAlert}
                        />
                    ) : (
                        <PrimaryButton
                            type="submit"
                            title={strings.courseDetailsForm.submitButton}
                            aria={strings.courseDetailsForm.submitButtonAria}
                            disabled={loading}
                        />
                    )}
                </ButtonRowRight>
                {saved && !isDirty && (
                    <>
                        <SaveNotifier />
                    </>
                )}
            </Form>
            {alertOpen && (
                <Alert
                    buttonText={strings.editTitleAndDescriptionPage.alertButton}
                    ariaButtonText={strings.editTitleAndDescriptionPage.alertButtonAria}
                    alertText={strings.editTitleAndDescriptionPage.alertText}
                    closeAlert={toggleAlert}
                    onSubmit={handleSubmit(onSubmit)}
                />
            )}
        </>
    );
}

const Form = styled(FormCardWithGaps)`
    width: 100%;
`;

const NotificationContainer = styled.div`
    width: 100%;
`;

export interface CourseDetailsInput extends NumberOfLearnersSectionInput {
    title: string;
    description: string;
}
