import * as React from 'react';
import { AuthContext } from '../../contextProviders/AuthContext';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { LoginState } from '../../pages/auth/LoginPage';

export default function LogoutIfUnauthenticated(): JSX.Element {
    const { isAuthenticated } = React.useContext(AuthContext);
    const location = useLocation();

    if (!isAuthenticated)
        return <Navigate to="/auth/login" state={{ returnPath: location.pathname } as LoginState} replace />;

    return <Outlet />;
}
