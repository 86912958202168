import styled from 'styled-components';
import { sizes } from 'theme';
import { useNavigate } from 'react-router-dom';
import SecondaryButton from 'components/buttons/SecondaryButton';
import { NotificationModel } from 'models/Notification';
import useGetRequest from 'hooks/useGetRequest';
import Skeleton from 'react-loading-skeleton';
import { Notifications } from './Notifications';
import strings from '../../strings/strings.json';
import Notification, { NotificationType } from 'components/notifiers/Notification';

export default function NotificationsPanel({ closePanel }: { closePanel: () => void }) {
    const {
        data: notifications,
        loading: loadingNotifications,
        errors,
    } = useGetRequest<NotificationModel[]>('users/me/notifications');

    const navigate = useNavigate();

    function viewAllNotifications() {
        navigate('/notifications');
        closePanel();
    }

    function navigateAndClosePanel(url: string, urlState: {}) {
        navigate(url, urlState);
        closePanel();
    }

    const threeMostRecentNotifications = (notifications ?? [])
        .sort((a, b) => {
            return a.dateCreated > b.dateCreated ? -1 : 1;
        })
        .slice(0, 3);

    let body: JSX.Element = <></>;

    if (loadingNotifications) {
        body = (
            <>
                <Skeleton count={3} height={86} />
                <ViewAllNotifications>
                    <Skeleton borderRadius={24} width={255} height={48} />
                </ViewAllNotifications>
            </>
        );
    } else if (!!errors && errors.length > 0) {
        body = (
            <ErrorNotificationContainer>
                <Notification
                    notificationType={NotificationType.DANGER}
                    title={strings.notificationsPage.fetchError}
                />
            </ErrorNotificationContainer>
        );
    } else {
        body = (
            <>
                <Notifications
                    notifications={threeMostRecentNotifications}
                    disableButtons={loadingNotifications}
                    navigate={navigateAndClosePanel}
                />
                <ViewAllNotifications>
                    <SecondaryButton
                        title={strings.notificationsPanel.viewAllButton.title}
                        aria={strings.notificationsPanel.viewAllButton.aria}
                        onClick={viewAllNotifications}
                    />
                </ViewAllNotifications>
            </>
        );
    }

    return <BodyContainer>{body}</BodyContainer>;
}

const ErrorNotificationContainer = styled.div`
    margin: 0 ${sizes.spacingMd};
`;

const ViewAllNotifications = styled.div`
    margin: ${sizes.spacingMd};
`;

const BodyContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
