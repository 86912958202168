import * as React from 'react';
import strings from '../../strings/strings.json';
import OrganisationForm from './components/OrganisationForm';
import MainSection from '../../components/sections/MainSection';
import SubpageHeader from '../../components/sections/SubpageHeader';

export default function CreateOrganisationPage(): JSX.Element {
    const backButtonDetails = React.useMemo(() => {
        return {
            title: strings.updateOrganisationPage.backButtonTitle,
            aria: strings.updateOrganisationPage.backButtonTitleAria,
            href: '/admin/dashboard',
        };
    }, []);

    return (
        <>
            <SubpageHeader title={strings.organisationForm.title} backButtonDetails={backButtonDetails} />
            <MainSection>
                <OrganisationForm />
            </MainSection>
        </>
    );
}
