import {
    BodyLarge,
    BodyRegular,
    ExternalSecondaryLinkButton,
    Headline2,
    Headline4,
    Headline5,
    TertiaryButton,
} from 'components/Elements';
import useGetRequest from 'hooks/useGetRequest';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { theme, sizes, breakpoints, FONTSIZE } from 'theme';
import BackgroundImagery from 'assets/backgrounds/imagery/learner-course-blue.svg';
import PrimaryButton, { PrimaryButtonColour } from 'components/buttons/PrimaryButton';
import SecondaryButton from 'components/buttons/SecondaryButton';
import CheckIcon from 'assets/icons/form-icons/CheckIcon';
import { LearnerLearningObjective } from 'models/LearnerCourse';
import { PublicCourseOverview, PublicModuleOverviews } from 'models/PublicCourseOverview';
import { DOCUMENTS_URL } from 'lib/_api-helpers';
import strings from '../../strings/strings.json';
import Skeleton from 'react-loading-skeleton';
import { IconSize } from 'assets/icons/icon-sizes';
import Notification, { NotificationType } from 'components/notifiers/Notification';
import ScheduleIcon from 'assets/icons/navigation/ScheduleIcon';
import OnlineMaterialsIcon from 'assets/icons/public-course/OnlineMaterialsIcon';
import { ErrorMessage } from 'components/forms/FormComponents';
import React from 'react';
import { CopyLinkModal, CopyLinkModalProps, CopyLinkModalState } from 'components/modals/CopyLinkModal';
import ErrorIcon from 'assets/icons/indicators/ErrorIcon';
import NextIcon from 'assets/icons/controls/NextIcon';
import VideoCallIcon from 'assets/icons/controls/VideoCallIcon';
import PageInfoIcon from 'assets/icons/controls/PageInfoIcon';
import { AuthContext } from 'contextProviders/AuthContext';
import Alert from 'components/alert/Alert';
import YourTrainerCard from '../learner/components/YourTrainerCard';
import ProgressBar from '../../components/progressBar/ProgressBar';
import WarningIcon from '../../assets/icons/indicators/WarningIcon';

export default function PublicCoursePage(): JSX.Element {
    const { id } = useParams();
    const { login, isAuthenticated } = React.useContext(AuthContext);
    const navigate = useNavigate();
    const {
        data: course,
        loading,
        errors,
        called,
    } = useGetRequest<PublicCourseOverview>(`public/course/${id}`, undefined, true);

    const [enrollAlertOpen, setEnrollAlertOpen] = React.useState<boolean>(false);

    function closeCancelAlert() {
        setEnrollAlertOpen(false);
    }

    const [copyLinkState, setCopyLinkState] = React.useState<CopyLinkModalState>({
        modalProps: {} as CopyLinkModalProps,
        modalOpen: false,
    });

    function openCopyModal(url: string) {
        setCopyLinkState({
            modalOpen: true,
            modalProps: {
                title: `${strings.copyLinkModal.shareLink.title}`,
                inputValue: url,
                primaryButtonTitle: `${strings.copyLinkModal.shareLink.primaryButtonTitle}`,
                primaryButtonAria: `${strings.copyLinkModal.shareLink.primaryButtonAria}`,
                closeButtonAria: `${strings.copyLinkModal.shareLink.closeButtonAria}`,
                onClickCloseButton: () => {},
            },
        });
    }

    function closeCopyModal() {
        setCopyLinkState({
            modalOpen: false,
            modalProps: {} as CopyLinkModalProps,
        });
    }

    if (!loading && !called) return <></>;

    if (loading)
        return (
            <>
                <HeaderBackground>
                    <AppWidthContainer>
                        <HeaderContainer>
                            <TitleAndLogoContainer>
                                <Headline4Blue>
                                    <Skeleton height={100} />
                                </Headline4Blue>
                                <LogoContainer>
                                    <Skeleton height={100} width={100} />
                                </LogoContainer>
                            </TitleAndLogoContainer>
                            <HeaderButtonContainer>
                                <Skeleton height={50} count={1} />
                            </HeaderButtonContainer>
                        </HeaderContainer>
                    </AppWidthContainer>
                </HeaderBackground>

                <MainContainer>
                    <DateContentBlock>
                        <DateAndIconContainer>
                            <Skeleton height={100} width={100} count={1} />
                        </DateAndIconContainer>
                        <DateAndIconContainer>
                            <Skeleton height={100} width={100} count={1} />
                        </DateAndIconContainer>
                    </DateContentBlock>
                    <Border />

                    <ContentBlockColumn>
                        <Headline4Blue>
                            <Skeleton height={50} count={1} />
                        </Headline4Blue>
                        <Skeleton height={140} count={1} />
                    </ContentBlockColumn>
                    <Border />

                    <ContentBlock>
                        <Headline4Blue>
                            <Skeleton height={50} count={1} />
                        </Headline4Blue>
                        <ContentBodyText>
                            <Skeleton height={450} count={1} />
                        </ContentBodyText>
                    </ContentBlock>
                    <Border />

                    <ContentBlock>
                        <Headline4Blue>
                            <Skeleton height={50} count={1} />
                        </Headline4Blue>
                        <ContentBodyText>
                            <Skeleton height={450} count={1} />
                        </ContentBodyText>
                    </ContentBlock>
                    <Border />

                    <ContentBlock>
                        <Headline4Blue>
                            <Skeleton height={50} count={1} />
                        </Headline4Blue>
                        <ContentBodyText>
                            <Skeleton height={450} count={1} />
                        </ContentBodyText>
                    </ContentBlock>
                    <Border />

                    <ContentBlockColumn>
                        <Headline4Blue>
                            <Skeleton height={50} count={1} />
                        </Headline4Blue>
                        <ModuleContainer>
                            <Skeleton height={65} count={1} />
                            <Skeleton height={65} count={1} />
                            <Skeleton height={65} count={1} />
                        </ModuleContainer>
                    </ContentBlockColumn>
                    <Border />
                </MainContainer>
            </>
        );

    if (!course || (errors && errors.length > 0)) {
        return (
            <PublicCoursePageContainer>
                <HeaderBackground>
                    <AppWidthContainer>
                        <HeaderContainer></HeaderContainer>
                    </AppWidthContainer>
                </HeaderBackground>
                <ErrorContainer>
                    <Notification
                        notificationType={NotificationType.DANGER}
                        title={strings.publicCoursePage.errors.courseNotFoundError.title}
                        description={strings.publicCoursePage.errors.courseNotFoundError.description}
                        maxWidth="1240"
                    />
                </ErrorContainer>
            </PublicCoursePageContainer>
        );
    }

    const startDate = new Date(course.startDate);
    const formattedStartDate = startDate.toLocaleDateString('en-US', {
        month: 'short',
        day: '2-digit',
    });

    let endDate;
    let formattedEndDate;

    if (course.endDate) {
        endDate = new Date(course.endDate);
        formattedEndDate = endDate.toLocaleDateString('en-US', {
            month: 'short',
            day: '2-digit',
        });
    }

    const learningObjectives: LearnerLearningObjective[] = course.learningObjectives;
    const modules: PublicModuleOverviews[] = course.modules;

    const profileImageFilepath = course.trainer.profileImage ? `${DOCUMENTS_URL}/${course.trainer.profileImage}` : '';

    const courseFull = course.currentLearners >= course.maximumLearners;

    const renderJoinCourseButton = () => {
        return (
            <PrimaryButton
                title={strings.publicCoursePage.joinCourseButton.title}
                aria={strings.publicCoursePage.joinCourseButton.aria}
                disabled={course.cancelled || courseFull}
                onClick={isAuthenticated ? () => setEnrollAlertOpen(true) : () => login(`/learner/enroll/${id}`)}
            />
        );
    };

    return (
        <PublicCoursePageContainer>
            <HeaderBackground>
                <AppWidthContainer>
                    <HeaderContainer>
                        <TitleAndLogoContainer>
                            <HeadlineWithBackground>{course.title}</HeadlineWithBackground>
                            {course.organisation.logoS3Filepath && (
                                <LogoContainer>
                                    <Image
                                        src={`${DOCUMENTS_URL}/${course.organisation.logoS3Filepath}`}
                                        alt={strings.OrganisationOverviewPage.logoAltText}
                                    />
                                </LogoContainer>
                            )}
                        </TitleAndLogoContainer>
                        <HeaderButtonContainer>
                            {renderJoinCourseButton()}
                            <SecondaryButton
                                title={strings.publicCoursePage.shareCourseButton.title}
                                aria={strings.publicCoursePage.shareCourseButton.aria}
                                onClick={() => {
                                    const copyLinkUrl = `${window.location.origin}/public/course/${course.scheduledCourseId}`;
                                    openCopyModal(copyLinkUrl);
                                }}
                                disabled={courseFull}
                            />
                            {copyLinkState.modalOpen && (
                                <CopyLinkModal {...copyLinkState.modalProps} onClickCloseButton={closeCopyModal} />
                            )}
                            {enrollAlertOpen && (
                                <Alert
                                    title={strings.publicCoursePage.joinCourseConfirmation.title}
                                    alertText={`${strings.publicCoursePage.joinCourseConfirmation.alertText} ${course.title}`}
                                    buttonText={strings.publicCoursePage.joinCourseConfirmation.buttonText}
                                    ariaButtonText={`${strings.publicCoursePage.joinCourseConfirmation.buttonAria} ${course.title}`}
                                    buttonColour={PrimaryButtonColour.DEFAULT}
                                    onSubmit={() => navigate(`/learner/enroll/${id}`)}
                                    closeAlert={closeCancelAlert}
                                />
                            )}
                        </HeaderButtonContainer>
                        {course.cancelled && (
                            <ErrorMessageContainer>
                                <ErrorIcon />
                                <ErrorMessage $bold>
                                    {strings.publicCoursePage.errors.courseCancelledError.description}
                                </ErrorMessage>
                            </ErrorMessageContainer>
                        )}
                        {courseFull && (
                            <ErrorMessageContainer>
                                <WarningIcon />
                                <ErrorMessage $bold>
                                    {strings.publicCoursePage.errors.courseFullError.description}
                                </ErrorMessage>
                            </ErrorMessageContainer>
                        )}
                    </HeaderContainer>
                </AppWidthContainer>
            </HeaderBackground>

            <MainContainer>
                {!course.cancelled && (
                    <>
                        <DateContentBlock>
                            <ProgressBarContainer>
                                <ProgressBar
                                    label={strings.publicCoursePage.progressBarLabel}
                                    current={course.currentLearners}
                                    max={course.maximumLearners}
                                    reverseFractionAmount
                                />
                            </ProgressBarContainer>
                            <DateAndIconContainer>
                                <ScheduleIcon size={IconSize.XLARGE} />
                                <DateContainer>
                                    <BodyLarge>{strings.publicCoursePage.startDate}</BodyLarge>
                                    <LargeDateText $bold>{formattedStartDate}</LargeDateText>
                                </DateContainer>
                            </DateAndIconContainer>
                            {endDate && (
                                <DateAndIconContainer>
                                    <ScheduleIcon size={IconSize.XLARGE} />
                                    <DateContainer>
                                        <BodyLarge>{strings.publicCoursePage.endDate}</BodyLarge>
                                        <LargeDateText $bold>{formattedEndDate}</LargeDateText>
                                    </DateContainer>
                                </DateAndIconContainer>
                            )}
                        </DateContentBlock>
                        <Border />
                    </>
                )}

                <ContentBlockColumn>
                    <YourTrainerCard
                        profileImageFilepath={profileImageFilepath}
                        trainerName={`${course.trainer.firstName} ${course.trainer.lastName}`}
                        trainerBio={course.trainer.bio}
                        organisationName={course.organisation.name}
                    />
                </ContentBlockColumn>
                <Border />

                {course.description && (
                    <>
                        <ContentBlock>
                            <Headline4Blue>{strings.publicCoursePage.courseDescriptionHeadline}</Headline4Blue>
                            <ContentBodyText>{course.description}</ContentBodyText>
                        </ContentBlock>
                        <Border />
                    </>
                )}

                <ContentBlock>
                    <Headline4Blue>{strings.publicCoursePage.learningObjectivesHeadline}</Headline4Blue>
                    <LearningObjectiveContainer>
                        {learningObjectives
                            .sort((a, b) => a.order - b.order)
                            .map((x) => (
                                <LearningObjective key={x.id}>
                                    <LearningObjectiveIconContainer>
                                        <CheckIcon />
                                    </LearningObjectiveIconContainer>
                                    <BodyRegular>{x.description}</BodyRegular>
                                </LearningObjective>
                            ))}
                        <div>{renderJoinCourseButton()}</div>
                    </LearningObjectiveContainer>
                </ContentBlock>
                <Border />

                <ContentBlockColumn>
                    <Headline4Blue>{strings.publicCoursePage.modulesHeadline}</Headline4Blue>
                    <ModuleContainer>
                        {modules
                            .sort((a, b) => a.order - b.order)
                            .map((module) => (
                                <ModuleCard key={module.order}>
                                    <ModuleNumberText $bold>Module {module.order}:</ModuleNumberText>{' '}
                                    <BodyLarge>{module.title}</BodyLarge>
                                </ModuleCard>
                            ))}
                    </ModuleContainer>
                </ContentBlockColumn>
                <Border />

                <CourseInfoContentBlock>
                    <Headline4Blue>{strings.publicCoursePage.courseInfoSection.headline}</Headline4Blue>
                    <InfoItemContainer>
                        <InfoItem>
                            <VideoCallIcon size={IconSize.XLARGE} />
                            <HeadlineTextBlue $bold>
                                {strings.publicCoursePage.courseInfoSection.liveClasses}
                            </HeadlineTextBlue>
                            <BodyLarge>{strings.publicCoursePage.courseInfoSection.liveClassesDescription}</BodyLarge>
                        </InfoItem>
                        <InfoItem>
                            <PageInfoIcon size={IconSize.XLARGE} />
                            <HeadlineTextBlue $bold>
                                {strings.publicCoursePage.courseInfoSection.interactiveMaterials}
                            </HeadlineTextBlue>
                            <BodyLarge>
                                {strings.publicCoursePage.courseInfoSection.interactiveMaterialsDescription}
                            </BodyLarge>
                        </InfoItem>
                        <InfoItem>
                            <OnlineMaterialsIcon size={IconSize.XLARGE} />
                            <HeadlineTextBlue $bold>
                                {strings.publicCoursePage.courseInfoSection.OnlineMaterials}
                            </HeadlineTextBlue>
                            <BodyLarge>
                                {strings.publicCoursePage.courseInfoSection.OnlineMaterialsDescription}
                            </BodyLarge>
                        </InfoItem>
                    </InfoItemContainer>
                    {renderJoinCourseButton()}
                </CourseInfoContentBlock>
                <Border />

                <OrganisationBlock>
                    <OrganisationAboutAndLogoColumn>
                        <AboutOrganisationHeadline>
                            {strings.publicCoursePage.organisationSection.aboutOrganisation}
                        </AboutOrganisationHeadline>
                        {course.organisation.logoS3Filepath && (
                            <Image
                                src={`${DOCUMENTS_URL}/${course.organisation.logoS3Filepath}`}
                                alt={`${course.organisation.name} logo`}
                            />
                        )}
                    </OrganisationAboutAndLogoColumn>
                    <OrganisationTitleAndDescriptionColumn>
                        <OrganisationTitle>{course.organisation.name}</OrganisationTitle>
                        {course.organisation.description && (
                            <BodyRegular>{course.organisation.description}</BodyRegular>
                        )}
                        <FindOutMoreButton
                            title={strings.publicCoursePage.organisationSection.findOutMoreButton.title}
                            aria-label={strings.publicCoursePage.organisationSection.findOutMoreButton.aria}
                            onClick={() => {
                                navigate(`/public/organisation/${course.organisation.name.replace(' ', '')}`);
                            }}
                        >
                            {strings.publicCoursePage.organisationSection.findOutMoreButton.title}
                        </FindOutMoreButton>
                    </OrganisationTitleAndDescriptionColumn>
                </OrganisationBlock>
                <Border />

                <NeveInfoContentBlock>
                    <Headline4Blue>{strings.publicCoursePage.neveInfoSection.headline}</Headline4Blue>
                    <NeveInfoItem>
                        <HeadlineTextBlue $bold>
                            {strings.publicCoursePage.neveInfoSection.accessibleAndInclusive}
                        </HeadlineTextBlue>
                        <BodyLarge>
                            {strings.publicCoursePage.neveInfoSection.accessibleAndInclusiveDescription}
                        </BodyLarge>
                    </NeveInfoItem>
                    <NeveInfoItem>
                        <HeadlineTextBlue $bold>
                            {strings.publicCoursePage.neveInfoSection.flippedClassroom}
                        </HeadlineTextBlue>
                        <BodyLarge>{strings.publicCoursePage.neveInfoSection.flippedClassroomDescription}</BodyLarge>
                    </NeveInfoItem>
                    <ButtonContainer>
                        {renderJoinCourseButton()}
                        <StyledLinkButton
                            aria-label={strings.publicCoursePage.neveInfoSection.neveLearningLinkAria}
                            href="https://www.nevelearning.co.uk/"
                            target="_blank"
                        >
                            {strings.publicCoursePage.neveInfoSection.neveLearningLink}
                            <NextIcon size={IconSize.LARGE} colour={`${theme.textColour}`} />
                        </StyledLinkButton>
                    </ButtonContainer>
                </NeveInfoContentBlock>
            </MainContainer>
        </PublicCoursePageContainer>
    );
}

const ProgressBarContainer = styled.div`
    width: 400px;
    @media (max-width: ${breakpoints.sm}) {
        width: 100%;
    }
`;

const PublicCoursePageContainer = styled.div`
    background: ${theme.cardBackground};
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 100%;
`;

const HeaderBackground = styled.header`
    width: 100%;
    padding-bottom: ${sizes.spacingXl};
    border-bottom: 1px solid ${theme.textColour};
    background-image: url(${BackgroundImagery});
    background-repeat: no-repeat;
    background-position-x: right;
    background-size: contain;
    @media (max-width: ${breakpoints.sm}) {
        background-size: contain;
    }
`;

const AppWidthContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: ${sizes.appMaxWidthWithoutPadding}px;
    padding: 0 ${sizes.edgePadding};
    margin: auto;
`;

const HeaderContainer = styled.div`
    margin-top: ${sizes.headerPadding};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: ${sizes.spacingLg};
    height: 100%;
    width: 100%;
    min-height: 200px;
`;

const TitleAndLogoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: ${sizes.spacingMd};
    align-items: center;
    @media (max-width: ${breakpoints.sm}) {
        align-items: unset;
        flex-direction: column-reverse;
    }
`;

const HeadlineWithBackground = styled(Headline2)`
    color: ${theme.primaryBrandColour};
    padding: ${sizes.spacingMd};
    background: ${theme.cardBackground};
    opacity: 0.9;
    margin-left: -${sizes.spacingMd};
    border-radius: ${sizes.borderRadiusXl};
    @media (max-width: ${breakpoints.sm}) {
        margin-left: unset;
    }
`;

const LogoContainer = styled.div`
    background-color: ${theme.cardBackground};
    border-radius: ${sizes.borderRadiusXl};
    border-top: 1px solid var(--neutral-05, ${theme.cardBorder});
    border-right: 1px solid var(--neutral-05, ${theme.cardBorder});
    border-bottom: 3px solid var(--neutral-05, ${theme.cardBorder});
    border-left: 1px solid var(--neutral-05, ${theme.cardBorder});
    display: flex;
    align-items: center;
    height: auto;
    width: auto;
    padding: ${sizes.spacingSm};
    @media (max-width: ${breakpoints.md}) {
        align-self: flex-start;
    }
`;

const Image = styled.img`
    border-radius: ${sizes.borderRadiusMd};
    max-width: 244px;
    max-height: 88px;
    width: auto;
    height: auto;
    object-fit: contain;
`;

const HeaderButtonContainer = styled.div`
    display: flex;
    gap: ${sizes.spacingRg};
    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
    }
`;

const ErrorMessageContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${sizes.spacingSm};
`;

const Border = styled.div`
    border-bottom: 1px solid ${theme.secondaryButtonDisabledBackgroundColour};
`;

const Headline4Blue = styled(Headline4)`
    color: ${theme.primaryBrandColour};
    min-width: 320px;
`;

const AboutOrganisationHeadline = styled(Headline4)`
    color: ${theme.primaryBrandColour};
`;

const OrganisationTitle = styled(Headline5)`
    color: ${theme.primaryBrandColour};
    margin-top: 7px;
`;

const HeadlineTextBlue = styled(BodyLarge)`
    font-size: ${FONTSIZE.Headline6};
    color: ${theme.primaryBrandColour};
`;

const ContentBodyText = styled(BodyLarge)`
    width: 100%;
    white-space: pre-wrap;
`;

const MainContainer = styled.main`
    width: 100%;
    display: flex;
    flex-direction: column;
    background: inherit;
    max-width: ${sizes.appMaxWidthWithoutPadding}px;
    padding: 0 ${sizes.edgePadding};
    margin: 0 auto;
`;

const ErrorContainer = styled(MainContainer)`
    margin: ${sizes.spacingLg} 0;
`;

const DateContentBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 150px;
    padding: ${sizes.spacingLg} 0;
    @media (max-width: ${breakpoints.md}) {
        flex-direction: column;
        align-items: unset;
        gap: ${sizes.spacingLg};
    }
`;

const DateAndIconContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${sizes.spacingMd};
`;

const DateContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const LargeDateText = styled(BodyLarge)`
    font-size: ${FONTSIZE.Headline4};
    @media (max-width: ${breakpoints.sm}) {
        font-size: ${FONTSIZE.Headline5};
    }
`;

const ContentBlock = styled.div`
    display: flex;
    gap: 3rem;
    padding: ${sizes.spacingXl} 0;
    @media (max-width: ${breakpoints.md}) {
        flex-direction: column;
    }
    @media (max-width: ${breakpoints.sm}) {
        padding: ${sizes.spacingLg} 0;
        gap: ${sizes.spacingLg};
    }
`;

const ContentBlockColumn = styled.div`
    padding: ${sizes.spacingXl} 0;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    @media (max-width: ${breakpoints.sm}) {
        padding: ${sizes.spacingLg} 0;
        gap: ${sizes.spacingLg};
    }
`;

const LearningObjectiveContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingLg};
    width: 100%;
`;

const LearningObjective = styled.div`
    display: flex;
    align-items: center;
    gap: ${sizes.spacingSm};
`;

const LearningObjectiveIconContainer = styled.div`
    padding: ${sizes.spacingMd};
    height: 48px;
    width: 48px;
    border-radius: 50%;
    background-color: ${theme.notificationSuccessBackground};
`;

const ModuleContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
    width: 100%;
`;

const ModuleCard = styled.div`
    width: 100%;
    display: flex;
    gap: ${sizes.spacingMd};
    background: ${theme.contentBackgroundColourTrainer};
    padding: ${sizes.spacingMd};
    border: 2px solid ${theme.primaryButtonBackgroundColour};
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: ${sizes.spacingSm};
    white-space: pre-wrap;
    @media (max-width: ${breakpoints.md}) {
        flex-direction: column;
    }
`;

const ModuleNumberText = styled(BodyLarge)`
    min-width: 100px;
`;

const CourseInfoContentBlock = styled.div`
    padding: ${sizes.spacingXl} 0;
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingXl};
    text-align: center;
    align-items: center;
    @media (max-width: ${breakpoints.sm}) {
        padding: ${sizes.spacingLg} 0;
    }
`;

const InfoItemContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media (max-width: ${breakpoints.lg}) {
        flex-direction: column;
        gap: ${sizes.spacingLg};
    }
`;

const InfoItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${sizes.spacingRg};
    width: 300px;
    @media (max-width: ${breakpoints.lg}) {
        width: 100%;
        gap: ${sizes.spacingMd};
    }
`;

const OrganisationBlock = styled.div`
    display: flex;
    align-items: start;
    gap: ${sizes.spacingXl};
    padding: ${sizes.spacingXl} 0;

    @media (max-width: ${breakpoints.lg}) {
        flex-direction: column;
        gap: unset;
        padding: ${sizes.spacingLg} 0;
    }
`;

const OrganisationAboutAndLogoColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    word-break: keep-all;
    gap: ${sizes.spacingMd};
    flex-wrap: wrap;

    @media (max-width: ${breakpoints.lg}) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
`;

const OrganisationTitleAndDescriptionColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
    align-items: start;
    word-break: break-word;
`;

const FindOutMoreButton = styled(TertiaryButton)`
    color: ${theme.primaryBrandColour};
`;

const NeveInfoContentBlock = styled.div`
    padding: ${sizes.spacingXl} 0;
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingXl};
    margin-bottom: 120px;
    @media (max-width: ${breakpoints.sm}) {
        padding: ${sizes.spacingLg} 0;
    }
`;

const NeveInfoItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingRg};
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
        gap: ${sizes.spacingLg};
    }
`;

const StyledLinkButton = styled(ExternalSecondaryLinkButton)`
    color: ${theme.textColour};
    font-size: ${FONTSIZE.Headline5};
    display: flex;
    gap: ${sizes.spacingSm};
    align-items: center;
    @media (max-width: ${breakpoints.sm}) {
        font-size: ${FONTSIZE.BodyLarge};
        gap: unset;
    }
`;
