import { theme } from '../../../theme';
import { IconSize } from '../icon-sizes';

export default function FileIcon({
    colour = theme.textColour,
    size = IconSize.MEDIUM,
}: {
    colour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 21 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.07186 20.6619H15.1438V18.144H5.07186V20.6619ZM5.07186 15.626H15.1438V13.108H5.07186V15.626ZM2.55387 25.6979C1.86143 25.6979 1.26865 25.4514 0.775547 24.9583C0.282441 24.4652 0.0358887 23.8724 0.0358887 23.1799V3.03605C0.0358887 2.34361 0.282441 1.75083 0.775547 1.25772C1.26865 0.764619 1.86143 0.518066 2.55387 0.518066H12.6258L20.1798 8.07202V23.1799C20.1798 23.8724 19.9332 24.4652 19.4401 24.9583C18.947 25.4514 18.3542 25.6979 17.6618 25.6979H2.55387ZM11.3668 9.33101V3.03605H2.55387V23.1799H17.6618V9.33101H11.3668Z"
                fill={colour}
            />
        </svg>
    );
}
