import * as React from 'react';
import strings from '../../../strings/strings.json';
import styled from 'styled-components';
import { Headline4 } from 'components/Elements';
import { useNavigate, useParams } from 'react-router-dom';
import useModifyRequest from 'hooks/useModifyRequest';
import { logError } from 'lib/debug-helpers';
import { APIError } from 'lib/_api-helpers';
import { createGlobalErrorFromAPIError } from 'shared/error-messages';
import { FormCardWithGaps } from 'components/forms/FormComponents';
import RadioFields from '../../../components/forms/RadioFields';
import { StepperFormProps } from 'components/stepper/stepperTypes';
import useGetRequest from 'hooks/useGetRequest';
import { Organisation } from 'models/Organisation';
import StepperButtonRow from 'components/stepper/StepperButtonRow';
import useNeveForm from 'components/forms/NeveForm';
import { UserRole } from '../../../models/inputModels/UserRole';
import Skeleton from 'react-loading-skeleton';
import Notification, { NotificationType } from '../../../components/notifiers/Notification';
import { sizes } from '../../../theme';

type ChangeUserRoleInput = {
    organisation: string | null;
};

export default function SelectOrganisationForm(props: StepperFormProps<UserRole>): JSX.Element {
    const { previous, newObject, setNewObject } = props;
    const navigate = useNavigate();
    const params = useParams();
    const { id } = params;

    const { modifyData: updateUser } = useModifyRequest(`users/${id}`, 'PUT');
    const { data: organisations, loading } = useGetRequest<Organisation[]>('organisations');

    const [globalErrorMessage, setGlobalErrorMessage] = React.useState<string>('');

    const { register, handleSubmit, getValues } = useNeveForm<ChangeUserRoleInput>({
        organisation: newObject.organisationId,
    });

    const onSubmit = async (formData: ChangeUserRoleInput) => {
        const { organisation } = formData;

        const response = await updateUser({
            organisationId: organisation,
            roles: newObject.roles,
        });

        if (response.errors) {
            logError(response.errors);
            updateErrors(response.errors);
            return;
        }
        navigate('/admin/dashboard');
    };

    function updateErrors(apiErrors: APIError[]): void {
        apiErrors.forEach((apiError) => {
            const { field, code } = apiError;
            if (field === 'global') {
                return setGlobalErrorMessage(createGlobalErrorFromAPIError(code));
            }
        });
    }

    function goBack() {
        setNewObject({
            ...newObject,
            organisationId: getValues('organisation'),
        });

        previous();
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Headline4>{strings.selectOrganisationForm.instructionText}</Headline4>
            {!loading && organisations ? (
                <>
                    <RadioFields
                        options={organisations.map((organisation) => {
                            return {
                                value: organisation.id,
                                title: organisation.name,
                                aria: organisation.name,
                                inputProps: register('organisation'),
                                name: 'organisation',
                            };
                        })}
                    />
                    {globalErrorMessage && (
                        <Notification
                            notificationType={NotificationType.DANGER}
                            title={strings.selectOrganisationForm.roleChangeError}
                            description={globalErrorMessage}
                        />
                    )}
                    <StepperButtonRowWithPadding
                        submitButtonTitle={strings.selectOrganisationForm.saveButton}
                        submitButtonAria={strings.selectOrganisationForm.saveButtonAriaLabel}
                        previousButtonFunction={goBack}
                    />
                </>
            ) : (
                <Skeleton height={80} count={3} />
            )}
        </Form>
    );
}

const StepperButtonRowWithPadding = styled(StepperButtonRow)`
    padding-top: ${sizes.spacingSm};
`;

const Form = styled(FormCardWithGaps)`
    width: 100%;
    border: none;
    box-shadow: none;
`;
