import { theme } from '../../../theme';
import { IconSize } from '../icon-sizes';

export default function ErrorIcon({
    colour = theme.errorColour,
    size = IconSize.MEDIUM,
}: {
    colour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="12" fill={colour} />
            <path
                d="M13.1562 13.7373H11.0117L10.5635 5.15039H13.6045L13.1562 13.7373ZM10.5283 16.7432C10.5283 16.251 10.6602 15.8789 10.9238 15.627C11.1875 15.375 11.5713 15.249 12.0752 15.249C12.5615 15.249 12.9365 15.3779 13.2002 15.6357C13.4697 15.8936 13.6045 16.2627 13.6045 16.7432C13.6045 17.2061 13.4697 17.5723 13.2002 17.8418C12.9307 18.1055 12.5557 18.2373 12.0752 18.2373C11.583 18.2373 11.2021 18.1084 10.9326 17.8506C10.6631 17.5869 10.5283 17.2178 10.5283 16.7432Z"
                fill="white"
            />
        </svg>
    );
}
