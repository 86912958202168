import { ButtonRowLeft } from 'components/Elements';
import Notification, { NotificationType } from './Notification';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import styled from 'styled-components';
import { sizes } from 'theme';

export type SuccessCardProps = {
    notificationTitle: string;
    notificationDescription: string;
    primaryButtonTitle: string;
    primaryButtonAria: string;
    primaryButtonClick: () => void;
    secondaryButtonTitle?: string;
    secondaryButtonAria?: string;
    secondaryButtonClick?: () => void;
    big?: boolean;
};

export default function SuccessCard({
    notificationTitle,
    notificationDescription,
    primaryButtonClick,
    primaryButtonTitle,
    primaryButtonAria,
    secondaryButtonClick,
    secondaryButtonTitle,
    secondaryButtonAria,
    big,
}: SuccessCardProps): JSX.Element {
    return (
        <SuccessCardContent>
            <div>
                <Notification
                    notificationType={NotificationType.SUCCESS}
                    title={notificationTitle}
                    description={notificationDescription}
                    big={big ?? false}
                />
            </div>
            <ButtonRowLeft>
                <PrimaryButton title={primaryButtonTitle} aria={primaryButtonAria} onClick={primaryButtonClick} />
                {secondaryButtonTitle && secondaryButtonAria && secondaryButtonClick && (
                    <SecondaryButton
                        title={secondaryButtonTitle}
                        aria={secondaryButtonAria}
                        onClick={secondaryButtonClick}
                    />
                )}
            </ButtonRowLeft>
        </SuccessCardContent>
    );
}

const SuccessCardContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
    margin-top: ${sizes.spacingMd};
`;
