import React from 'react';
import styled from 'styled-components';
import { breakpoints, sizes, theme } from '../../theme';
import { BodyLarge, Headline1 } from '../Elements';
// @ts-ignore
import { ReactComponent as NeveImageHeader } from 'assets/backgrounds/neve-img-header.svg';

export default function LearnerHeader({ title, subtitle }: { title: string; subtitle?: string }): JSX.Element {
    return (
        <BannerBackground>
            <LimitedContainer>
                <HeaderArea>
                    <TitleSection>
                        <Headline1>{title}</Headline1>
                        {subtitle && <BodyLarge>{subtitle}</BodyLarge>}
                    </TitleSection>
                    <ImageSection>
                        <NeveImageHeader />
                    </ImageSection>
                </HeaderArea>
            </LimitedContainer>
        </BannerBackground>
    );
}

const HeaderArea = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
`;

const TitleSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
`;

const LimitedContainer = styled.header`
    display: flex;
    flex-direction: column;
    max-width: ${sizes.appMaxWidthWithoutPadding}px;
    padding: 0 ${sizes.edgePadding};
    margin: 0 auto;
    @media (max-width: ${breakpoints.sm}) {
        margin: unset;
    }
`;

const ImageSection = styled.div`
    @media (max-width: ${breakpoints.sm}) {
        display: none;
    }
`;

const BannerBackground = styled.div`
    min-height: 150px;
    background: ${theme.courseBannerBackground};
    width: 100%;
    @media (max-width: ${breakpoints.sm}) {
        display: flex;
        align-items: center;
    }
`;
