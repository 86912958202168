import { theme } from '../../../theme';
import { IconSize } from '../icon-sizes';

export default function VideoIcon({
    colour = theme.primaryButtonBorderColour,
    size = IconSize.LARGE,
}: {
    colour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.2963 0.296387C7.47336 0.296387 0.296295 7.47414 0.296295 16.2964C0.296295 25.1186 7.47336 32.2964 16.2963 32.2964C25.1192 32.2964 32.2963 25.1186 32.2963 16.2964C32.2963 7.47414 25.1192 0.296387 16.2963 0.296387ZM22.657 16.8569L13.3237 22.8569C13.2143 22.9279 13.088 22.9631 12.963 22.9631C12.8536 22.9631 12.7429 22.9357 12.644 22.8817C12.4291 22.7645 12.2963 22.5405 12.2963 22.2964V10.2964C12.2963 10.0523 12.4291 9.82826 12.644 9.71107C12.8549 9.5952 13.1192 9.60232 13.3237 9.73582L22.657 15.7358C22.8471 15.8582 22.963 16.0698 22.963 16.2964C22.963 16.5229 22.8471 16.7345 22.657 16.8569Z"
                fill={colour}
            />
        </svg>
    );
}
