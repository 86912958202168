import { theme } from 'theme';
import { IconSize } from '../icon-sizes';

export default function CheckIcon({
    colour = theme.notificationSuccessBorder,
    size = IconSize.SMALL,
}: {
    colour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.3335 5.7619L6.27177 11L14.6668 1"
                stroke={colour}
                strokeWidth="1.74603"
                strokeLinecap="round"
            />
        </svg>
    );
}
