import strings from '../../../strings/strings.json';
import { BodyRegular } from '../../../components/Elements';
import { IconSize } from '../../../assets/icons/icon-sizes';
import Table, { TableRow } from 'components/table/Table';
import { EmptyTableContent } from 'components/table/EmptyTableContent';
import LearnerIcon from 'assets/icons/role-icons/LearnerIcon';
import { ImageCircle } from 'components/ImageCircle';
import { DOCUMENTS_URL } from 'lib/_api-helpers';
import styled from 'styled-components';
import { breakpoints, sizes } from 'theme';
import useWindowWidth from 'hooks/useWindowWidth';
import CheckboxInput from 'components/forms/CheckboxInput';
import { useId } from 'react';

export type AttendeeTableRow = {
    attendeeName: string;
    attendeeImagePath: string;
    attendeeEmail: string;
    checkboxProps: object;
    checkboxValue: boolean;
    checkboxName: string;
};

type AttendeesTableProps = {
    attendeeRows: AttendeeTableRow[];
    attendedHeaderCheckboxOnCheck: Function;
    attendedHeaderCheckboxInitialValue: boolean;
};

export function AttendeesTable({
    attendeeRows,
    attendedHeaderCheckboxOnCheck,
    attendedHeaderCheckboxInitialValue,
}: AttendeesTableProps): JSX.Element {
    const { isMobileWidth, isDesktopWidth } = useWindowWidth();

    const rows = attendeeRows.map((attendee) => {
        const attendedCheckbox = (
            <AttendedCheckbox
                checkboxName={attendee.checkboxName}
                checkboxProps={attendee.checkboxProps}
                attendeeName={attendee.attendeeName}
            />
        );

        const row: TableRow = {
            id: 'attendeeRow',
            cells: [
                {
                    name: strings.liveTrainingDetailPage.attendeesTable.cellNames.learner,
                    value: (
                        <AttendeeRowContent>
                            {isDesktopWidth && (
                                <ImageCircle
                                    $imageurl={
                                        attendee.attendeeImagePath
                                            ? `${DOCUMENTS_URL}/${attendee.attendeeImagePath}`
                                            : ''
                                    }
                                />
                            )}

                            <BodyRegular $bold>{attendee.attendeeName}</BodyRegular>

                            {isMobileWidth && attendedCheckbox}
                        </AttendeeRowContent>
                    ),
                },
            ],
        };

        if (!isMobileWidth) {
            row.cells.push(
                {
                    name: strings.liveTrainingDetailPage.attendeesTable.cellNames.email,
                    value: attendee.attendeeEmail,
                },
                {
                    name: strings.liveTrainingDetailPage.attendeesTable.cellNames.attended,
                    value: attendedCheckbox,
                },
            );
        }

        return row;
    });

    const attendedHeader = (
        <AttendedHeader>
            <BodyRegular>{strings.liveTrainingDetailPage.attendeesTable.attended}</BodyRegular>
            <CheckboxInput
                id={'allAttendeesCheckbox'}
                name={'allAttendeesCheckbox'}
                value={''}
                props={{ onChange: attendedHeaderCheckboxOnCheck, checked: attendedHeaderCheckboxInitialValue }}
                aria={strings.liveTrainingDetailPage.attendeesTable.attendanceAllCheckboxAria}
            />
        </AttendedHeader>
    );

    return (
        <Table
            mobileViewBelow={breakpoints.sm}
            headers={[
                {
                    name: strings.liveTrainingDetailPage.attendeesTable.name,
                    rowPercentage: 40,
                    order: 1,
                },
                {
                    name: strings.liveTrainingDetailPage.attendeesTable.email,
                    rowPercentage: 35,
                    order: 2,
                },
                {
                    name: attendedHeader,
                    rowPercentage: 25,
                    order: 3,
                    mobileRightColumn: true,
                    rightAligned: true,
                },
            ]}
            rows={rows}
            emptyTableContent={
                <EmptyTableContent
                    icon={<LearnerIcon size={IconSize.XLARGE} />}
                    title={strings.enrolledUsers.emptyTable.title}
                />
            }
        />
    );
}

type AttendedCheckboxProps = {
    checkboxName: string;
    checkboxProps: object;
    attendeeName: string;
};

function AttendedCheckbox({ checkboxName, checkboxProps, attendeeName }: AttendedCheckboxProps): JSX.Element {
    const id = useId();

    return (
        <CheckboxContainer>
            <CheckboxInput
                id={`attendedCheckbox-${id}`}
                name={checkboxName}
                value={''}
                props={checkboxProps}
                aria={`${strings.liveTrainingDetailPage.attendeesTable.attendanceCheckboxAria}: ${attendeeName}.`}
            />
        </CheckboxContainer>
    );
}

const AttendedHeader = styled.div`
    display: flex;
    gap: ${sizes.spacingMd};
`;

const CheckboxContainer = styled.div`
    display: flex;
    justify-content: end;
`;

const AttendeeRowContent = styled.div`
    display: flex;
    gap: ${sizes.spacingRg};
    align-items: center;
    max-width: 500px;

    @media (max-width: ${breakpoints.sm}) {
        justify-content: space-between;
    }
`;
