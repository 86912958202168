import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useGetRequest from 'hooks/useGetRequest';
import styled from 'styled-components';
import { breakpoints, sizes, theme } from 'theme';
import { BodyRegular, Card, Headline3, Headline4, Headline5 } from 'components/Elements';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { IconSize } from 'assets/icons/icon-sizes';
import { DOCUMENTS_URL } from 'lib/_api-helpers';
import { LogoContainer, Image } from 'pages/OA/OrganisationOverviewPage';
import strings from '../../strings/strings.json';
import CoursesIcon from 'assets/icons/navigation/CoursesIcon';
import { ErrorNotifier } from 'components/notifiers/ErrorNotifier';
import Skeleton from 'react-loading-skeleton';
import ArrowRightIcon from 'assets/icons/controls/ArrowRightIcon';
import Notification, { NotificationType } from '../../components/notifiers/Notification';
import { OrganisationPublic } from '../../models/OrganisationPublic';
import CourseCardDateSection from '../learner/components/CourseCardDateSection';
import ProgressBar from '../../components/progressBar/ProgressBar';
import WarningIcon from '../../assets/icons/indicators/WarningIcon';
import { ErrorMessage } from '../../components/forms/FormComponents';

export default function PublicOrganisationPage(): JSX.Element {
    const params = useParams();
    const { name } = params;
    const {
        data: organisation,
        loading,
        errors,
        called,
    } = useGetRequest<OrganisationPublic>(`public/organisations/${name}`, undefined, true);

    const navigate = useNavigate();

    if (!called && !loading) return <></>;

    if (loading) {
        return (
            <LoadingOrErrorSection>
                <LoadingOrErrorContent>
                    <Skeleton height={100} />
                </LoadingOrErrorContent>
            </LoadingOrErrorSection>
        );
    }

    if (!organisation || errors.length > 0) {
        return (
            <LoadingOrErrorSection>
                <LoadingOrErrorContent>
                    <ErrorNotifier
                        title={''}
                        description={strings.publicOrganisationPage.noOrganisation}
                        maxWidth={'400px'}
                    />
                </LoadingOrErrorContent>
            </LoadingOrErrorSection>
        );
    }

    const courses = organisation?.courses ?? [];

    return (
        <>
            <OrganisationHeader id="org-header">
                <OrganisationHeaderContent id="org-header-content">
                    <OrganisationHeaderLogoAndTitle id="org-logo-title">
                        {organisation?.logoUrl && (
                            <LogoContainer>
                                <Image
                                    src={`${DOCUMENTS_URL}/${organisation?.logoUrl}`}
                                    alt={strings.OrganisationOverviewPage.logoAltText}
                                />
                            </LogoContainer>
                        )}
                        <Headline3>{organisation?.name}</Headline3>
                    </OrganisationHeaderLogoAndTitle>
                    {organisation?.description && (
                        <OrganisationDescription id="org-description">
                            <BodyRegular>{organisation?.description}</BodyRegular>
                        </OrganisationDescription>
                    )}
                </OrganisationHeaderContent>
            </OrganisationHeader>
            <CoursesSection id="courses-section">
                <CoursesSectionContent id="courses-section-content">
                    <OurCourses id="our-courses">
                        <CoursesIcon size={IconSize.XLARGE} colour={theme.textColour} />
                        <Headline4>{strings.publicOrganisationPage.heading}</Headline4>
                    </OurCourses>
                    {courses.length > 0 ? (
                        <CourseGrid id="course-grid">
                            {courses.map((course, index) => (
                                <CourseCard key={index}>
                                    <Headline5>{course.courseTitle}</Headline5>
                                    <CourseCardDateSection
                                        startDate={course.startDate}
                                        endDate={course.endDate}
                                        cancelled={!!course.cancelledDate}
                                    />
                                    <ProgressBar
                                        label={strings.publicCoursePage.progressBarLabel}
                                        max={course.maximumLearners}
                                        current={course.currentLearners}
                                        reverseFractionAmount
                                    />
                                    {course.currentLearners >= course.maximumLearners && (
                                        <WarningContainer>
                                            <WarningIcon />
                                            <ErrorMessage $bold>
                                                {strings.scheduledCoursesPage.courseFullWarning}
                                            </ErrorMessage>
                                        </WarningContainer>
                                    )}
                                    <BodyRegular>{course.courseDescription}</BodyRegular>
                                    <FindOutMoreButtonContainer>
                                        <PrimaryButton
                                            title={strings.publicOrganisationPage.findOutMoreButton.title}
                                            aria={strings.publicOrganisationPage.findOutMoreButton.aria}
                                            icon={<ArrowRightIcon />}
                                            iconPositionRight
                                            fullwidth
                                            onClick={() => {
                                                navigate(`/public/course/${course.courseId}`);
                                            }}
                                        />
                                    </FindOutMoreButtonContainer>
                                </CourseCard>
                            ))}
                        </CourseGrid>
                    ) : (
                        <NotificationContainer>
                            <Notification
                                notificationType={NotificationType.INFO}
                                title={strings.publicOrganisationPage.organisationHasNoCourses}
                            />
                        </NotificationContainer>
                    )}
                </CoursesSectionContent>
            </CoursesSection>
        </>
    );
}

const WarningContainer = styled.div`
    display: flex;
    gap: ${sizes.spacingSm};
    align-items: center;
`;

const OrganisationHeaderLogoAndTitle = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    gap: ${sizes.spacingLg};
`;

const OrganisationDescription = styled.div`
    width: 100%;
`;

const OrganisationHeaderContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingRg};
    align-items: center;
    width: ${sizes.appMaxWidthWithoutPadding}px;
    padding: ${sizes.spacingLg} ${sizes.spacingMd};

    @media (max-width: ${breakpoints.xl}) {
        width: 100%;
    }
`;

const OrganisationHeader = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    background: ${theme.cardThirdBackgroundColour};
`;

const NotificationContainer = styled.div`
    margin: 0 ${sizes.spacingMd};
`;

const LoadingOrErrorSection = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

const LoadingOrErrorContent = styled.div`
    width: ${sizes.appMaxWidthWithoutPadding}px;
    margin: ${sizes.spacingMd};
`;

const OurCourses = styled.div`
    display: flex;
    align-items: center;
    gap: ${sizes.spacingRg};
    margin: 0 ${sizes.spacingMd};
`;

const CoursesSectionContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingLg};
    width: ${sizes.appMaxWidthWithoutPadding}px;
`;

const CoursesSection = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: ${sizes.spacingLg};
`;

const CourseGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: ${sizes.spacingLg};
    max-width: ${sizes.appMaxWidthWithoutPadding}px;
    margin: 0 ${sizes.spacingMd};
`;

const CourseCard = styled(Card)`
    flex: 0 0 31%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: ${sizes.spacingMd};
    border-radius: ${sizes.borderRadiusXl};
    border-top: 100px solid ${theme.cardThirdBackgroundColour};
    max-width: 400px;

    @media (max-width: ${breakpoints.lg}) {
        flex: 0 0 47%;
    }

    @media (max-width: ${breakpoints.md}) {
        flex: 0 0 80%;
        border-top: 50px solid ${theme.cardThirdBackgroundColour};
    }

    @media (max-width: ${breakpoints.sm}) {
        flex: 0 0 100%;
    }
`;

const FindOutMoreButtonContainer = styled.div`
    margin-bottom: ${sizes.spacingSm};
`;
