import useGetRequest from 'hooks/useGetRequest';
import { APIError } from 'lib/_api-helpers';
import { Course } from 'models/Course';
import * as React from 'react';
import { useParams } from 'react-router-dom';

type EditCourseContextValue = {
    course: Course | undefined;
    refetchCourse: () => Promise<void>;
    errors: APIError[];
    loading: boolean;
    called: boolean;
    activityIdToShowGenerateContentModal: string | undefined;
    setActivityIdToShowGenerateContentModal: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export const EditCourseContext = React.createContext<EditCourseContextValue>({
    course: undefined,
    refetchCourse: async () => {},
    errors: [],
    loading: false,
    called: false,
    activityIdToShowGenerateContentModal: undefined,
    setActivityIdToShowGenerateContentModal: () => {},
});

export function EditCourseProvider(props: { children: React.ReactNode | React.ReactNode[] }): JSX.Element {
    const params = useParams();
    const { courseId } = params;
    const {
        data: course,
        loading,
        called,
        errors,
        refetchData,
    } = useGetRequest<Course>(`courses/${courseId}`, !courseId);

    const [courseState, setCourseState] = React.useState<Course>();
    const [activityIdToShowGenerateContentModal, setActivityIdToShowGenerateContentModal] = React.useState<string>();

    React.useEffect(() => {
        if (called && course) {
            setCourseState(course);
        }
    }, [called, course]);

    return (
        <EditCourseContext.Provider
            value={{
                course: courseState,
                refetchCourse: refetchData,
                errors: errors,
                loading: loading,
                called: called,
                activityIdToShowGenerateContentModal: activityIdToShowGenerateContentModal,
                setActivityIdToShowGenerateContentModal: setActivityIdToShowGenerateContentModal,
            }}
        >
            {props.children}
        </EditCourseContext.Provider>
    );
}
