import { RoleType, UserModel } from '../User';

export type UserRole = {
    userId: string;
    firstName: string;
    lastName: string;
    organisationId: string | null;
    roles: RoleType[];
    hasTrainerScheduledCourses: boolean;
};

export function createEmptyUserRole(userId: string): UserRole {
    return {
        organisationId: null,
        userId: userId,
        firstName: '',
        lastName: '',
        roles: [],
        hasTrainerScheduledCourses: false,
    };
}

export function createUserRoleFromUserModel(userModel: UserModel, id: string): UserRole {
    return {
        userId: id,
        organisationId: userModel.organisation?.id ?? null,
        firstName: userModel.firstName,
        lastName: userModel.lastName,
        roles: userModel.roles.map((x) => x.name),
        hasTrainerScheduledCourses: userModel.trainerCourseSchedules?.length > 0,
    };
}
