import * as React from 'react';

type WindowHeight = {
    currentWindowHeight: number;
};

export default function useWindowHeight(): WindowHeight {
    const [windowHeight, setWindowHeight] = React.useState<number>(window.innerHeight);

    const resetWindowSize = () => {
        setWindowHeight(window.innerHeight);
    };

    React.useEffect(() => {
        window.addEventListener('resize', resetWindowSize);

        return () => {
            window.removeEventListener('resize', resetWindowSize);
        };
    });

    return { currentWindowHeight: windowHeight };
}
