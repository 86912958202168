import React, { useContext } from 'react';
import strings from '../../../strings/strings.json';
import styled from 'styled-components';
import { AuthContext } from 'contextProviders/AuthContext';
import useGetRequest from 'hooks/useGetRequest';
import Skeleton from 'react-loading-skeleton';
import { breakpoints, sizes, theme } from 'theme';
import Table, { RightAlignedButtonCell, TableHeader, TableRow } from 'components/table/Table';
import { EmptyTableContent } from 'components/table/EmptyTableContent';
import { Headline3, WrappingText } from 'components/Elements';
import useWindowWidth from 'hooks/useWindowWidth';
import { CourseScheduleOverview } from 'models/CourseScheduleOverview';
import CancelledIndicator from './CancelledIndicator';
import Notification, { NotificationType } from 'components/notifiers/Notification';
import { HeaderSection } from './HeaderSection';
import { useLocation } from 'react-router-dom';
import ValidateIcon from 'assets/icons/indicators/ValidateIcon';
import NavigateWithArrow from 'components/actions/NavigateWithArrow';
import { IconSize } from 'assets/icons/icon-sizes';
import ScheduleIcon from 'assets/icons/pathway/ScheduleIcon';
import WarningIcon from '../../../assets/icons/indicators/WarningIcon';
import { ErrorMessage } from '../../../components/forms/FormComponents';

type State = {
    newScheduledCourseId: string;
};

export default function ScheduledCoursesTable(): JSX.Element {
    const { userData } = useContext(AuthContext);
    const { isMobileWidth, currentWindowWidth } = useWindowWidth();

    const { state } = useLocation();
    const { newScheduledCourseId } = (state as State) ?? {};

    const scrollRef = React.createRef<any>();

    React.useEffect(() => {
        if (!scrollRef.current) return;

        scrollRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
    });

    const { data, loading, errors } = useGetRequest<CourseScheduleOverview[]>(
        `organisations/${userData?.organisation?.id}/scheduled-courses`,
        !userData?.organisation?.id,
    );

    if (!data && loading) {
        return (
            <>
                <Headline3>{strings.OAScheduledCoursesListPage.heading}</Headline3>
                <Skeleton height={isMobileWidth ? 224 : 100} count={3} />
            </>
        );
    }

    if (errors && errors.length > 0) {
        return (
            <>
                <Headline3>{strings.OAScheduledCoursesListPage.heading}</Headline3>
                <Notification
                    notificationType={NotificationType.DANGER}
                    title={strings.OAScheduledCoursesListPage.getScheduledCoursesError}
                />
            </>
        );
    }

    const tableHeaders: TableHeader[] = [
        {
            name: strings.OAScheduledCoursesListPage.courseTitleheading,
            order: 1,
            rowPercentage: 35,
        },
        { name: strings.OAScheduledCoursesListPage.startDateHeading, order: 2, rowPercentage: 15 },
        { name: strings.OAScheduledCoursesListPage.trainerHeading, order: 3, rowPercentage: 15 },
        {
            name: strings.OAScheduledCoursesListPage.enroledLearnersHeading,
            order: 4,
            rowPercentage: 10,
        },
        {
            name: strings.OAScheduledCoursesListPage.statusHeading,
            order: 5,
            rowPercentage: 15,
        },
        {
            name: '',
            order: 6,
            rowPercentage: 10,
            mobileRightColumn: true,
        },
    ];

    const scheduledCourseRows: TableRow[] = (data ?? [])
        .sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime())
        .sort((a, b) => {
            if (a.cancelled && !b.cancelled) {
                return 1;
            } else if (!a.cancelled && b.cancelled) {
                return -1;
            } else {
                return 0;
            }
        })
        .map((scheduledCourse) => {
            const shortDate = new Date(scheduledCourse.startDate).toLocaleDateString();
            const linkAria = `${strings.OAScheduledCoursesListPage.viewCourseArrow.aria} ${scheduledCourse.courseTitle}`;

            return {
                id: scheduledCourse.id,
                cells: [
                    {
                        name: strings.OAScheduledCoursesListPage.courseTitleheading,
                        value: (
                            <ScheduledCourseContainer
                                ref={newScheduledCourseId === scheduledCourse.id ? scrollRef : null}
                            >
                                {currentWindowWidth > parseInt(breakpoints.xl) &&
                                    scheduledCourse.id === newScheduledCourseId && <ValidateIcon />}
                                <WrappingText>{scheduledCourse.courseTitle}</WrappingText>
                            </ScheduledCourseContainer>
                        ),
                        isBold: true,
                    },
                    {
                        name: strings.OAScheduledCoursesListPage.startDateHeading,
                        value: shortDate,
                    },
                    {
                        name: strings.OAScheduledCoursesListPage.trainerHeading,
                        isBold: true,
                        value:
                            currentWindowWidth < parseInt(breakpoints.xl)
                                ? scheduledCourse.trainer?.email ?? ''
                                : `${scheduledCourse.trainer?.firstName} ${scheduledCourse.trainer?.lastName}`,
                    },
                    {
                        name: strings.OAScheduledCoursesListPage.enroledLearnersHeading,
                        value:
                            currentWindowWidth < parseInt(breakpoints.xl)
                                ? ''
                                : `${scheduledCourse.enrolledLearnersCount}`,
                    },
                    {
                        name: strings.OAScheduledCoursesListPage.statusHeading,
                        value: scheduledCourse.cancelled ? (
                            <div>
                                <StatusContainer>
                                    <CancelledIndicator />
                                </StatusContainer>
                            </div>
                        ) : (
                            <div>
                                {scheduledCourse.enrolledLearnersCount >= scheduledCourse.maximumLearners ? (
                                    <EnrolmentFullContainer>
                                        <WarningIcon />
                                        <ErrorMessage $bold>
                                            {strings.scheduledCoursesPage.courseFullWarning}
                                        </ErrorMessage>
                                    </EnrolmentFullContainer>
                                ) : (
                                    ''
                                )}
                            </div>
                        ),
                    },
                    {
                        name: '',
                        value: scheduledCourse.cancelled ? (
                            ''
                        ) : (
                            <ButtonsSection>
                                <RightAlignedButtonCell>
                                    <NavigateWithArrow
                                        title={''}
                                        aria={linkAria}
                                        href={`/schedule/courses/${scheduledCourse.id}`}
                                        iconSize={IconSize.LARGE}
                                    />
                                </RightAlignedButtonCell>
                            </ButtonsSection>
                        ),
                    },
                ],
            };
        });

    return (
        <>
            <HeaderSection>
                <Headline3>{strings.OAScheduledCoursesListPage.heading}</Headline3>
            </HeaderSection>
            <div>
                <Table
                    headers={tableHeaders}
                    rows={scheduledCourseRows}
                    activeRow={newScheduledCourseId}
                    mobileViewBelow={breakpoints.xl}
                    emptyTableContent={
                        <EmptyTableContent
                            icon={<ScheduleIcon size={IconSize.XLARGE} colour={theme.textColour} />}
                            title={strings.scheduledCoursesPage.emptyTableContent.title}
                        />
                    }
                />
            </div>
        </>
    );
}

const EnrolmentFullContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${sizes.spacingSm};
`;

export const ButtonsSection = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: ${sizes.spacingSm};
    @media (max-width: ${breakpoints.xl}) {
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
    }
`;

const StatusContainer = styled.div`
    display: inline-block;
`;

const ScheduledCourseContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${sizes.spacingMd};
`;
