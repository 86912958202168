import DurationIcon from 'assets/icons/form-icons/DurationIcon';
import strings from 'strings/strings.json';
import styled from 'styled-components';
import { theme, sizes } from 'theme';
import { BodyRegular, BodySmall } from './Elements';

type DurationLabelProps = {
    durationInMins: number;
    hideTitle?: boolean;
    boldDurationText?: boolean;
};

export default function DurationLabel({
    durationInMins,
    hideTitle,
    boldDurationText,
}: DurationLabelProps): JSX.Element {
    return (
        <DurationLabelContainer>
            {!hideTitle && <BodySmall>{strings.OAScheduleCourseForm.durationLabel}</BodySmall>}
            <DurationIconNumberRow>
                <DurationIcon />
                <DurationText $bold={boldDurationText}>{`${durationInMins} ${
                    durationInMins === 1 ? 'min' : 'mins'
                }`}</DurationText>
            </DurationIconNumberRow>
        </DurationLabelContainer>
    );
}

const DurationText = styled(BodyRegular)`
    white-space: nowrap;
`;

const DurationIconNumberRow = styled.div`
    display: flex;
    gap: ${sizes.spacingSm};
    align-items: center;
`;

const DurationLabelContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: ${theme.cardThirdBackgroundColour};
    padding: ${sizes.spacingXs} ${sizes.spacingSm};
    gap: ${sizes.spacingXs};
    border-radius: ${sizes.borderRadiusMd};
    max-width: 120px;
`;
