import strings from '../../../strings/strings.json';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import { ButtonRowRight } from '../../../components/Elements';
import { logError } from '../../../lib/debug-helpers';
import { createErrorMessage, fieldErrorCodes, requiredFieldErrorMessage } from '../../../shared/error-messages';
import TextField from '../../../components/forms/TextField';
import { useNavigate } from 'react-router-dom';
import useModifyRequest from '../../../hooks/useModifyRequest';
import useNeveForm from 'components/forms/NeveForm';
import { FormCardWithGaps } from '../../../components/forms/FormComponents';
import { capitalsRegex } from '../../../components/accessibilityGuide/utils';
import AccessibilityNotifier from '../../../components/notifiers/AccessibilityNotifier';
import { styled } from 'styled-components';
import { sizes } from 'theme';

type CreateCourseInput = {
    courseTitle: string;
};

export default function CreateCourseForm(): JSX.Element {
    const navigate = useNavigate();
    const { modifyData: createCourse } = useModifyRequest('courses/', 'POST');

    const {
        register,
        watch,
        handleSubmit,
        formState: { errors },
    } = useNeveForm<CreateCourseInput>();

    async function onSubmit(data: CreateCourseInput) {
        const response = await createCourse({
            title: data.courseTitle,
        });

        const { value: courseId, errors } = response;
        if (errors) {
            logError(errors);
            return;
        }

        if (courseId) {
            navigate(`/edit-course/${courseId}/`, { replace: true });
        }
    }

    const courseTitle = watch('courseTitle');

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <TextField
                maxLength={100}
                fieldName="courseTitle"
                labelText={strings.createCoursePage.courseNameInputLabel}
                inputAria={strings.createCoursePage.courseNameInputLabelAria}
                inputProps={register('courseTitle', {
                    required: {
                        value: true,
                        message: requiredFieldErrorMessage(strings.createCoursePage.courseNameInputLabel),
                    },
                    maxLength: {
                        value: 100,
                        message: createErrorMessage(
                            strings.createCoursePage.courseNameInputLabel,
                            fieldErrorCodes.maxLength,
                        ),
                    },
                })}
                errorMessage={errors.courseTitle?.message}
                required
            />
            {capitalsRegex.test(courseTitle) && <AccessibilityNotifier accessibilityGuideKey={'textAllCaps'} />}
            <ButtonRowRight>
                <PrimaryButton
                    type="submit"
                    title={strings.createCoursePage.createButton}
                    aria={strings.createCoursePage.createButtonAriaLabel}
                />
            </ButtonRowRight>
        </Form>
    );
}

const Form = styled(FormCardWithGaps)`
    border: unset;
    box-shadow: unset;
    padding: 0 ${sizes.spacingMd};
`;
