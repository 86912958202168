import { theme } from '../../../theme';
import { IconSize } from '../icon-sizes';

export default function ScheduleIcon({
    colour = theme.primaryBrandColour,
    backgroundColour = theme.secondaryButtonBackgroundColour,
    size = IconSize.MEDIUM,
}: {
    colour?: string;
    backgroundColour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.66667 27.3334C1.93333 27.3334 1.30556 27.0722 0.783333 26.55C0.261111 26.0278 0 25.4 0 24.6667V6.00002C0 5.26669 0.261111 4.63891 0.783333 4.11669C1.30556 3.59446 1.93333 3.33335 2.66667 3.33335H4V0.666687H6.66667V3.33335H17.3333V0.666687H20V3.33335H21.3333C22.0667 3.33335 22.6944 3.59446 23.2167 4.11669C23.7389 4.63891 24 5.26669 24 6.00002V24.6667C24 25.4 23.7389 26.0278 23.2167 26.55C22.6944 27.0722 22.0667 27.3334 21.3333 27.3334H2.66667ZM2.66667 24.6667H21.3333V11.3334H2.66667V24.6667ZM2.66667 8.66669H21.3333V6.00002H2.66667V8.66669Z"
                fill={colour}
            />
        </svg>
    );
}
