import * as React from 'react';
import strings from '../../../strings/strings.json';
import ActivityWizardButtonRow from './ActivityWizardButtonRow';
import useNeveForm from 'components/forms/NeveForm';
import { EditCourseContext } from 'contextProviders/EditCourseContext';
import { Headline4 } from 'components/Elements';
import { ErrorMessage } from 'components/forms/FormComponents';
import TextField from 'components/forms/TextField';
import AccessibilityNotifier from 'components/notifiers/AccessibilityNotifier';
import { StepperFormProps } from 'components/stepper/stepperTypes';
import useModifyRequest from 'hooks/useModifyRequest';
import { validateThreeCharacterLimitDurationRequired } from 'lib/custom-form-validation';
import { logError } from 'lib/debug-helpers';
import { APIError } from 'lib/_api-helpers';
import { LiveTrainingActivity } from 'models/inputModels/LiveTrainingActivity';
import { FormWithGaps } from 'pages/schedule/components/commonElements';
import {
    createGlobalErrorFromAPIError,
    createFieldErrorFromAPIError,
    requiredFieldErrorMessage,
    createErrorMessage,
    fieldErrorCodes,
} from 'shared/error-messages';
import Notification, { NotificationType } from 'components/notifiers/Notification';

type LiveTrainingInput = {
    title: string;
    trainingDuration: number;
};

export default function LiveTrainingDetailsForm(props: StepperFormProps<LiveTrainingActivity>): JSX.Element {
    const { modifyData: createLiveTraining, loading: createLoading } = useModifyRequest(
        `activities/live-training`,
        'POST',
    );

    const context = React.useContext(EditCourseContext);

    const [globalErrorMessage, setGlobalErrorMessage] = React.useState<string>('');
    const { newObject, complete } = props;

    const {
        register,
        handleSubmit,
        watch,
        setError,
        formState: { errors },
    } = useNeveForm<LiveTrainingInput>({
        title: newObject.title ?? '',
        trainingDuration: newObject.trainingDuration ?? 0,
    });

    const trainingDuration = watch('trainingDuration');

    async function onSubmit(formData: LiveTrainingInput) {
        const newLiveTraining = {
            title: formData.title,
            duration: +formData.trainingDuration,
            moduleId: newObject.moduleId,
        };

        const response = await createLiveTraining(newLiveTraining);

        const { errors } = response;

        if (errors) {
            logError(errors);
            updateErrors(errors);
            return;
        }

        await context.refetchCourse();
        !!complete && complete();
    }

    function updateErrors(apiErrors: APIError[]): void {
        const fieldMap: Record<string, string> = {
            title: 'title',
            duration: 'trainingDuration',
        };

        apiErrors.forEach((apiError) => {
            const { field, code } = apiError;

            if (field === 'global') {
                return setGlobalErrorMessage(createGlobalErrorFromAPIError(code));
            }
            setError(fieldMap[field] as keyof LiveTrainingInput, createFieldErrorFromAPIError(fieldMap[field], code));
        });
    }

    return (
        <FormWithGaps onSubmit={handleSubmit(onSubmit)}>
            <Headline4>{strings.liveTrainingDetailsForm.instruction}</Headline4>
            <div>
                <TextField
                    fieldName="activityTitle"
                    maxLength={100}
                    labelText={strings.liveTrainingDetailsForm.titleInputLabel}
                    inputAria={strings.liveTrainingDetailsForm.titleInputAria}
                    inputProps={register('title', {
                        required: {
                            value: true,
                            message: requiredFieldErrorMessage(strings.liveTrainingDetailsForm.titleInputLabel),
                        },
                        maxLength: {
                            value: 100,
                            message: createErrorMessage(
                                strings.liveTrainingDetailsForm.titleInputLabel,
                                fieldErrorCodes.maxLength,
                            ),
                        },
                    })}
                    errorMessage={errors.title?.message}
                    required
                />
                <TextField
                    fieldName="activityTrainingDuration"
                    maxLength={3}
                    labelText={strings.liveTrainingDetailsForm.durationInputLabel}
                    inputAria={strings.liveTrainingDetailsForm.durationInputAria}
                    inputProps={register('trainingDuration', {
                        validate: {
                            validateThreeCharacterLimitDurationRequired,
                        },
                        required: {
                            value: true,
                            message: requiredFieldErrorMessage(strings.liveTrainingDetailsForm.durationInputLabel),
                        },
                    })}
                    errorMessage={errors.trainingDuration?.message}
                    inputType="number"
                    width="8rem"
                    flavourText={strings.liveTrainingDetailsForm.durationInputMins}
                    required
                />
                {trainingDuration > 45 && <AccessibilityNotifier accessibilityGuideKey={'liveTrainingTooLong'} />}
                <Notification
                    notificationType={NotificationType.INFO}
                    title={strings.liveTrainingDetailsForm.scheduleLiveTrainingInfoTitle}
                    description={strings.liveTrainingDetailsForm.scheduleLiveTrainingInfoDescription}
                />
            </div>
            {globalErrorMessage && <ErrorMessage id="errorMessage">{globalErrorMessage}</ErrorMessage>}
            <ActivityWizardButtonRow
                submitButtonTitle={strings.liveTrainingDetailsForm.submitButton}
                submitButtonAria={strings.liveTrainingDetailsForm.submitButtonAria}
                buttonsDisabled={createLoading}
                showPreviousButton={false}
            />
        </FormWithGaps>
    );
}
