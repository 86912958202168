import React from 'react';
import styled from 'styled-components';
import strings from '../../../strings/strings.json';
import Tabs from 'components/structure/Tabs';
import PublishedCoursesTable from '../components/PublishedCoursesTable';
import ScheduledCoursesTable from '../components/ScheduledCoursesTable';
import { MainSectionWithGaps } from 'components/sections/MainSection';
import { sizes, theme } from 'theme';
import { Headline2 } from 'components/Elements';

export default function OACoursesPage(): JSX.Element {
    const [activeTab, setActiveTab] = React.useState<number>(0);
    return (
        <>
            <BannerBackground>
                <LimitedContainer>
                    <CoursePageHeadline>{strings.OACoursesPage.coursesPageHeader}</CoursePageHeadline>
                    <Tabs
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        tabs={[
                            { id: 0, name: strings.OACoursesPage.scheduledCoursesTabLabel },
                            { id: 1, name: strings.OACoursesPage.publishedCoursesTabLabel },
                        ]}
                    />
                </LimitedContainer>
            </BannerBackground>
            <MainSectionWithGaps>
                {activeTab === 0 ? <ScheduledCoursesTable /> : <PublishedCoursesTable />}
            </MainSectionWithGaps>
        </>
    );
}

const CoursePageHeadline = styled(Headline2)`
    margin: ${sizes.spacingXl} 0;
`;

const LimitedContainer = styled.header`
    display: flex;
    flex-direction: column;
    margin: auto;
    height: 100%;
    justify-content: end;
    max-width: ${sizes.appMaxWidthWithoutPadding}px;
    padding-left: ${sizes.spacingMd};
    padding-right: ${sizes.spacingMd};
`;

const BannerBackground = styled.div`
    background: ${theme.notificationDangerBackground};
    width: 100%;
    height: 260px;
`;
