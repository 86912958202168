import * as React from 'react';
import strings from '../../strings/strings.json';
import Stepper from 'components/stepper/Stepper';
import ChangeUserRoleForm from './components/ChangeUserRoleForm';
import { createEmptyUserRole, createUserRoleFromUserModel, UserRole } from 'models/inputModels/UserRole';
import SelectOrganisationForm from './components/SelectOrganisationForm';
import MainSection from '../../components/sections/MainSection';
import SubpageHeader from '../../components/sections/SubpageHeader';
import { useParams } from 'react-router-dom';
import useGetRequest from '../../hooks/useGetRequest';
import { UserModel } from '../../models/User';
import Notification, { NotificationType } from '../../components/notifiers/Notification';
import Skeleton from 'react-loading-skeleton';

export default function ChangeUserRolePage(): JSX.Element {
    const params = useParams();
    const { id } = params;

    const { data: user, loading: userLoading, errors: userErrors } = useGetRequest<UserModel>(`users/${id}`);

    const backButtonDetails = React.useMemo(() => {
        return {
            title: strings.updateOrganisationPage.backButtonTitle,
            aria: strings.updateOrganisationPage.backButtonTitleAria,
            href: '/admin/dashboard',
        };
    }, []);

    const steps = [
        { name: 'User role', Form: ChangeUserRoleForm },
        { name: 'Organisation', Form: SelectOrganisationForm },
    ];

    if (!user && !userLoading && userErrors && userErrors.length > 0) {
        return (
            <>
                <SubpageHeader title={strings.changeUserRolePage.title} backButtonDetails={backButtonDetails} />
                <Notification
                    notificationType={NotificationType.DANGER}
                    title={strings.changeUserRolePage.errorLoadingUserRoles}
                />
            </>
        );
    }

    return (
        <>
            <SubpageHeader title={strings.changeUserRolePage.title} backButtonDetails={backButtonDetails} />
            {userLoading ? (
                <Skeleton height={300} />
            ) : (
                <MainSection>
                    <Stepper<UserRole>
                        defaultObject={
                            user ? createUserRoleFromUserModel(user, id ?? '') : createEmptyUserRole(id ?? '')
                        }
                        steps={steps}
                        defaultStep={0}
                        isSmall={true}
                    />
                </MainSection>
            )}
        </>
    );
}
