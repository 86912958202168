import styled from 'styled-components';
import { HeaderSection } from './HeaderSection';
import { breakpoints, sizes, theme } from '../../../theme';
import { BodyRegular } from '../../../components/Elements';
import * as React from 'react';

export default function HeaderWithCount({
    countText,
    actionButton,
    spacesLeftCounter,
}: {
    countText?: string;
    actionButton?: JSX.Element;
    spacesLeftCounter?: JSX.Element;
}): JSX.Element {
    return (
        <>
            {spacesLeftCounter ? (
                <SpacesLeftAndButtonContainer>
                    <SpacesLeftContainer>{spacesLeftCounter}</SpacesLeftContainer>
                    <ButtonContainer>{actionButton}</ButtonContainer>
                </SpacesLeftAndButtonContainer>
            ) : (
                <HeaderContainerRightAligned>
                    <SecondaryBodyRegular>{countText}</SecondaryBodyRegular>
                    {actionButton}
                </HeaderContainerRightAligned>
            )}
        </>
    );
}

const HeaderContainerRightAligned = styled(HeaderSection)`
    display: flex;
    justify-content: end;
    align-items: center;
    gap: ${sizes.spacingLg};
    @media (max-width: ${breakpoints.sm}) {
        display: flex;
        flex-direction: column;
        align-items: unset;
        justify-content: unset;
        gap: ${sizes.spacingSm};
    }
`;

const SpacesLeftAndButtonContainer = styled.div`
    display: flex;
    gap: ${sizes.spacingLg};
    align-items: center;
    justify-content: space-between;
    margin: ${sizes.spacingMd} 0;
    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
        align-items: unset;
        gap: ${sizes.spacingMd};
    }
`;

const SpacesLeftContainer = styled.div`
    width: 50%;
    @media (max-width: ${breakpoints.md}) {
        width: 250px;
    }
    @media (max-width: ${breakpoints.sm}) {
        width: 100%;
    }
`;

const ButtonContainer = styled.div`
    width: 250px;
    @media (max-width: ${breakpoints.sm}) {
        width: 100%;
    }
`;

const SecondaryBodyRegular = styled(BodyRegular)`
    color: ${theme.textSecondaryColour};
`;
