import * as React from 'react';
import styled from 'styled-components';
import { IconLinkButtonText } from '../Elements';
import { sizes, theme } from 'theme';
// @ts-ignore
import { ReactComponent as ArrowBackLarge } from '../../assets/icons/nav-icons/arrow-back.svg';
// @ts-ignore
import { ReactComponent as ArrowForward } from '../../assets/icons/nav-icons/arrow-forward.svg';

type NavButtonProps = {
    title: string;
    aria: string;
    onClick?: () => void;
    type?: 'button' | 'submit' | 'reset' | undefined;
    inverted?: boolean;
    back?: boolean;
};

export default function NavButton({ title, aria, onClick, type, inverted, back }: NavButtonProps): JSX.Element {
    return (
        <>
            {back ? (
                <NavButtonContainer
                    aria-label={aria}
                    type={type ?? 'button'}
                    onClick={() => (onClick ? onClick() : null)}
                    inverted={inverted}
                >
                    <ArrowBackIcon />
                    <IconLinkButtonText>{title}</IconLinkButtonText>
                </NavButtonContainer>
            ) : (
                <NavButtonContainer
                    aria-label={aria}
                    type={type ?? 'button'}
                    onClick={() => (onClick ? onClick() : null)}
                    inverted={inverted}
                >
                    {title.length > 0 && <IconLinkButtonText>{title}</IconLinkButtonText>}

                    <ArrowForwardIcon />
                </NavButtonContainer>
            )}
        </>
    );
}

const NavButtonContainer = styled.button<{
    inverted?: boolean;
}>`
    display: flex;
    align-items: center;
    gap: 0.3rem;
    border: none;
    background: none;
    cursor: pointer;
    color: ${({ inverted }) => {
        return inverted ? theme.linkColour : theme.textColour;
    }};
    &:focus {
        background-color: ${theme.linkFocusBackgroundColour};
        border: 1px solid ${theme.linkFocusBorderColour};
        outline: none;
        border-radius: ${sizes.borderRadiusMd};
    }
    &:hover {
        background-color: ${theme.linkHoverBackgroundColour};
        border-radius: ${sizes.borderRadiusMd};
    }
`;

const ArrowBackIcon = styled(ArrowBackLarge)`
    height: 41px;
    width: 40px;
`;

const ArrowForwardIcon = styled(ArrowForward)`
    height: 41px;
    width: 40px;
`;
