import { theme } from 'theme';
import { IconSize } from '../icon-sizes';

export default function TickIcon({
    colour = theme.textColour,
    size = IconSize.SMALL,
}: {
    colour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 6.80952L6.44444 11L14 3" stroke={colour} strokeWidth="4" strokeLinecap="round" />
        </svg>
    );
}
