import { theme } from 'theme';
import { IconSize } from './icon-sizes';

export default function RefreshIcon({
    colour = theme.primaryBrandColour,
    size = IconSize.MEDIUM,
}: {
    colour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.67502 14.7125C4.33891 14.132 4.08683 13.5361 3.91877 12.925C3.75071 12.3139 3.66669 11.6875 3.66669 11.0459C3.66669 8.99863 4.3771 7.25696 5.79794 5.82085C7.21877 4.38474 8.9528 3.66669 11 3.66669H11.1604L9.69377 2.20002L10.9771 0.916687L14.6438 4.58335L10.9771 8.25002L9.69377 6.96669L11.1604 5.50002H11C9.47224 5.50002 8.17363 6.03856 7.10419 7.11565C6.03474 8.19273 5.50002 9.5028 5.50002 11.0459C5.50002 11.4431 5.54585 11.8327 5.63752 12.2146C5.72919 12.5965 5.86669 12.9709 6.05002 13.3375L4.67502 14.7125ZM11.0229 21.0834L7.35627 17.4167L11.0229 13.75L12.3063 15.0334L10.8396 16.5H11C12.5278 16.5 13.8264 15.9615 14.8959 14.8844C15.9653 13.8073 16.5 12.4972 16.5 10.9542C16.5 10.557 16.4542 10.1674 16.3625 9.78544C16.2709 9.40349 16.1334 9.02919 15.95 8.66252L17.325 7.28752C17.6611 7.86808 17.9132 8.46391 18.0813 9.07502C18.2493 9.68613 18.3334 10.3125 18.3334 10.9542C18.3334 13.0014 17.6229 14.7431 16.2021 16.1792C14.7813 17.6153 13.0472 18.3334 11 18.3334H10.8396L12.3063 19.8L11.0229 21.0834Z"
                fill={colour}
            />
        </svg>
    );
}
