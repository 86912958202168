import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import strings from 'strings/strings.json';
import useModifyRequest from 'hooks/useModifyRequest';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { CourseSchedule } from 'models/CourseSchedule';
import useGetRequest from 'hooks/useGetRequest';
import { logError } from 'lib/debug-helpers';
import { AuthContext } from 'contextProviders/AuthContext';
import Notification, { NotificationType } from '../../components/notifiers/Notification';
import MainSection, { MainSectionWithSmallGaps } from '../../components/sections/MainSection';
import SubpageHeader from '../../components/sections/SubpageHeader';

export default function EnrollPage(): JSX.Element {
    const { courseScheduleId } = useParams();
    const { data: courseSchedule, loading } = useGetRequest<CourseSchedule>(
        `courses/learner/schedule-overview/${courseScheduleId}`,
    );
    const authContext = React.useContext(AuthContext);
    const { modifyData: enrol } = useModifyRequest(`courses/enroll/${courseScheduleId}`, 'POST');
    const [enrolledStatus, setEnrolledStatus] = React.useState<string | null>(null);
    const { refreshUserData } = React.useContext(AuthContext);
    const navigate = useNavigate();

    React.useEffect(() => {
        async function enroll() {
            const response = await enrol({});
            const { errors } = response;

            if (errors) {
                logError(errors);

                if (errors.find((x) => x.code === 'alreadyEnrolled')) {
                    setEnrolledStatus('already-enrolled');
                } else if (errors.find((x) => x.code === 'scheduledCourseIsFull')) {
                    setEnrolledStatus('scheduled-course-is-full');
                } else {
                    setEnrolledStatus('fail');
                }
            } else {
                setEnrolledStatus('success');
                authContext.updateUserDataItem('courseScheduleId', courseScheduleId as string);
                refreshUserData();
            }
        }

        if (!courseSchedule) return;

        if (!enrolledStatus) enroll().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [courseSchedule]);

    if (loading) return <MainSection>loading course...</MainSection>;

    if (!courseSchedule)
        return (
            <MainSection>
                <Notification
                    notificationType={NotificationType.DANGER}
                    title={strings.enrollPage.courseNotFoundTitle}
                    description={strings.enrollPage.courseNotFoundDescription}
                />
            </MainSection>
        );

    async function onClick(courseSchedule: string) {
        navigate(`/learner/${courseSchedule}`);
    }

    return (
        <>
            <SubpageHeader title={courseSchedule?.course.title ?? null} subtitle={null} />
            <MainSectionWithSmallGaps>
                {!enrolledStatus && <p>Enrolling in course, please wait...</p>}
                {enrolledStatus === 'success' && courseScheduleId && (
                    <>
                        <Notification
                            notificationType={NotificationType.SUCCESS}
                            title={`${strings.enrollPage.saveSuccessTitle}${courseSchedule.course.title}!`}
                        />
                        <PrimaryButton
                            type="button"
                            title={strings.enrollPage.goToCourseButtonTitle}
                            aria={strings.enrollPage.goToCourseButtonTitleAria}
                            onClick={() => onClick(courseScheduleId)}
                        />
                    </>
                )}
                {enrolledStatus === 'already-enrolled' && courseScheduleId && (
                    <>
                        <Notification
                            notificationType={NotificationType.WARNING}
                            title={strings.enrollPage.alreadyEnrolledTitle}
                        />
                        <PrimaryButton
                            type="button"
                            title={strings.enrollPage.goToCourseButtonTitle}
                            aria={strings.enrollPage.goToCourseButtonTitleAria}
                            onClick={() => onClick(courseScheduleId)}
                        />
                    </>
                )}
                {enrolledStatus === 'scheduled-course-is-full' && courseScheduleId && (
                    <>
                        <Notification
                            notificationType={NotificationType.WARNING}
                            title={strings.enrollPage.courseFullTitle}
                            description={strings.enrollPage.courseFullDescription}
                        />
                    </>
                )}
                {enrolledStatus === 'fail' && (
                    <Notification
                        notificationType={NotificationType.DANGER}
                        title={strings.enrollPage.enrolFailTitle}
                    />
                )}
            </MainSectionWithSmallGaps>
        </>
    );
}
