import { BodyRegular } from 'components/Elements';
import { styled } from 'styled-components';
import { sizes, theme } from 'theme';

type ToggleInputProps = {
    leftLabel: string;
    rightLabel: string;
    checked: boolean;
    onToggle: () => void;
    aria: string;
};

export default function ToggleInput({ checked, onToggle, leftLabel, rightLabel, aria }: ToggleInputProps) {
    return (
        <ToggleInputContainer>
            <BodyRegular>{leftLabel}</BodyRegular>
            <Background
                aria-label={aria}
                tabIndex={0}
                checked={checked}
                onClick={onToggle}
                onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                    if (event.key === 'Enter' || event.key === ' ') {
                        event.preventDefault();
                        onToggle();
                    }
                }}
            >
                <Knob checked={checked} />
                <HiddenCheckbox type="checkbox" />
            </Background>
            <BodyRegular>{rightLabel}</BodyRegular>
        </ToggleInputContainer>
    );
}

const ToggleInputContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${sizes.spacingMd};
`;

const Knob = styled.div<{ checked: boolean }>`
    background: ${(p) => (p.checked ? theme.secondaryButtonBackgroundColour : theme.primaryBrandColour)};
    border-radius: 64px;
    height: 26px;
    width: 26px;
    top: 2px;
    left: ${(p) => (p.checked ? '28px' : '4px')};
    position: absolute;
    transition: cubic-bezier(0.56, 0, 0.38, 1) 0.3s;
`;

const Background = styled.div<{ checked: boolean }>`
    background: ${(p) => (p.checked ? theme.primaryBrandColour : theme.secondaryButtonBackgroundColour)};
    border: 2px solid ${theme.primaryBrandColour};
    border-radius: 64px;
    height: 34px;
    width: 60px;
    position: relative;
    transition: cubic-bezier(0.56, 0, 0.38, 1) 0.3s;
    cursor: pointer;
`;

const HiddenCheckbox = styled.input`
    display: none;
`;
