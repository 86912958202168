import { theme } from 'theme';
import { IconSize } from './icon-sizes';

export default function ProfileImageEmptyIcon({
    colour = theme.primaryBrandColour,
    size = IconSize.XXXXLARGE,
}: {
    colour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 141 141" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M124.934 28.1734L16.9344 114.173M139 70.5C139 108.332 108.332 139 70.5 139C32.6685 139 2 108.332 2 70.5C2 32.6685 32.6685 2 70.5 2C108.332 2 139 32.6685 139 70.5Z"
                stroke={colour}
                strokeWidth="3"
            />
        </svg>
    );
}
