import strings from '../../../strings/strings.json';
import SuccessCard from '../../../components/notifiers/SuccessCard';
import { useNavigate } from 'react-router-dom';

type CoursePublishSuccessCardProps = {
    courseTitle: string;
    courseId: string;
};

export default function CoursePublishSuccessCard({
    courseTitle,
    courseId,
}: CoursePublishSuccessCardProps): JSX.Element {
    const navigate = useNavigate();

    return (
        <SuccessCard
            notificationTitle={'Course published!'}
            notificationDescription={`Your "${courseTitle}" course has been successfully published.`}
            primaryButtonClick={() =>
                navigate(`/schedule/${courseId}`, {
                    state: { returnUrl: `/trainer/scheduled-courses`, backUrl: `/trainer/course/${courseId}/view` },
                })
            }
            primaryButtonTitle={strings.courseEditorPage.scheduleButtonTitle}
            primaryButtonAria={strings.courseEditorPage.scheduleButtonTitleAria}
            secondaryButtonClick={() => navigate(`/trainer/dashboard`)}
            secondaryButtonTitle={strings.publishCourseWarningPage.returnToDashboardButtonTitle}
            secondaryButtonAria={strings.publishCourseWarningPage.returnToDashboardButtonAria}
            big
        />
    );
}
