import * as React from 'react';
import styled from 'styled-components';
import { BodyRegular, IconLinkButtonText } from '../Elements';
import { useNavigate } from 'react-router-dom';
import { sizes, theme } from 'theme';
import NextIcon from 'assets/icons/controls/NextIcon';
import { IconSize } from 'assets/icons/icon-sizes';
import PreviousIcon from 'assets/icons/controls/PreviousIcon';

type StepNavigationProps = {
    title: string;
    aria: string;
    href?: string;
    navigateState?: any;
    isBackButton?: boolean; // icon on the left
    asBackButton?: boolean; // use router.back()
    buttonFunctionOverride?: Function;
    inverted?: boolean;
};

export function StepNavigation({
    title,
    aria,
    href,
    navigateState,
    isBackButton,
    asBackButton,
    buttonFunctionOverride,
    inverted,
}: StepNavigationProps): JSX.Element {
    const navigate = useNavigate();

    function navigateBack(): void {
        navigate(-1);
    }

    function navigateTo(href: string): void {
        navigate(href, navigateState);
    }

    function buttonFunction() {
        if (buttonFunctionOverride) {
            buttonFunctionOverride();
            return;
        }

        if (asBackButton) {
            navigateBack();
            return;
        } else {
            navigateTo(href ?? '');
            return;
        }
    }

    return (
        <StepNavigatorContainer aria-label={aria} onClick={buttonFunction}>
            {isBackButton && (
                <StepNavigatorIcon>
                    <PreviousIcon size={IconSize.XLARGE} />
                </StepNavigatorIcon>
            )}
            <StepNavigatorText>
                <BodyRegular $inverted={inverted} $bold>
                    {isBackButton ? 'Back' : 'Next'}
                </BodyRegular>
                <IconLinkButtonText inverted={inverted}>{title}</IconLinkButtonText>
            </StepNavigatorText>
            {!isBackButton && (
                <StepNavigatorIcon>
                    <NextIcon size={IconSize.XLARGE} colour={inverted ? theme.primaryButtonTextColour : undefined} />
                </StepNavigatorIcon>
            )}
        </StepNavigatorContainer>
    );
}

const StepNavigatorContainer = styled.button`
    all: unset;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: ${sizes.spacingSm};
    align-items: center;
    min-height: 64px;
    cursor: pointer;
    &:focus {
        background-color: ${theme.primaryButtonFocusBackgroundColour};
        border: 1px solid ${theme.primaryButtonBorderColour};
        outline: ${theme.primaryButtonBorderColour};
        color: ${theme.primaryButtonFocusTextColour};
        border-radius: 4px;
    }
    &:hover {
        background-color: ${theme.secondaryButtonHoverBackgroundColour};
        border-radius: 4px;
    }
`;
const StepNavigatorText = styled.div`
    display: flex;
    flex-direction: column;
    font-weight: bold;
`;
const StepNavigatorIcon = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
