import strings from '../../../strings/strings.json';
import styled from 'styled-components';
import { breakpoints, sizes } from 'theme';
import PrimaryButton from 'components/buttons/PrimaryButton';
import SecondaryButton, { SecondaryButtonColour } from 'components/buttons/SecondaryButton';
import { Headline5, ButtonRowRight } from 'components/Elements';
import { FormCard } from 'components/forms/FormComponents';
import TextField from 'components/forms/TextField';
import { createErrorMessage, fieldErrorCodes } from 'shared/error-messages';
import React from 'react';

export type ResourcesInput = {
    url: string;
    title: string;
    id?: string;
    deleted?: boolean;
};

export type ResourcesFormProps = {
    closeModal: () => void;
    addResource: (materialResources: ResourcesInput) => void;
};

export function ResourcesForm({ closeModal, addResource }: ResourcesFormProps): JSX.Element {
    const [linkUrl, setLinkUrl] = React.useState<string>('');
    const [textToDisplay, setTextToDisplay] = React.useState<string>('');
    const [linkUrlError, setLinkUrlError] = React.useState<string>('');
    const [textToDisplayError, setTextToDisplayError] = React.useState<string>('');

    const firstUpdateLinkUrl = React.useRef(true);
    const firstUpdateTextToDisplay = React.useRef(true);

    const validateLinkUrl: () => string = () => {
        if (!linkUrl) {
            return `${strings.resourcesForm.urlInputLabel} is a required field`;
        }

        // ensure its a url - i.e. something like https://www.duckduckgo.com/
        const regex =
            /([Hh][Tt][Tt][Pp]([Ss])?:\/\/)?(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*\.[a-zA-Z\u00a1-\uffff]{2,}(?::\d{2,5})?(?:\/[^\s]*)?/;
        const isValidUrl = regex.test(linkUrl);

        if (!isValidUrl) {
            return strings.resourcesForm.invalidLinkUrl;
        } else if (linkUrl.length > 400) {
            return createErrorMessage(strings.resourcesForm.urlInputLabel, fieldErrorCodes.maxLength);
        } else {
            return '';
        }
    };

    const validateTextToDisplay: () => string = () => {
        if (!textToDisplay) {
            return `${strings.resourcesForm.titleInputLabel} is a required field`;
        } else if (textToDisplay.length > 400) {
            return createErrorMessage(strings.resourcesForm.titleInputLabel, fieldErrorCodes.maxLength);
        } else {
            return '';
        }
    };

    React.useEffect(() => {
        if (firstUpdateLinkUrl.current) {
            firstUpdateLinkUrl.current = false;
            return;
        }

        setLinkUrlError(validateLinkUrl());
    }, [linkUrl]); // eslint-disable-line

    React.useEffect(() => {
        if (firstUpdateTextToDisplay.current) {
            firstUpdateTextToDisplay.current = false;
            return;
        }

        setTextToDisplayError(validateTextToDisplay());
    }, [textToDisplay]); // eslint-disable-line

    function onSubmit() {
        const validateLinkUrlError = validateLinkUrl();
        const validateTextToDisplayError = validateTextToDisplay();

        if (validateLinkUrlError || validateTextToDisplayError) {
            setLinkUrlError(validateLinkUrlError);
            setTextToDisplayError(validateTextToDisplayError);
            return;
        }

        let url = linkUrl;

        if (!url.startsWith('https://') && !url.startsWith('http://')) {
            url = 'https://' + url;
        }

        addResource({ url, title: textToDisplay });
    }

    return (
        <Form as="div">
            <Headline5>{strings.resourcesForm.title}</Headline5>
            <div>
                <TextField
                    fieldName={'url'}
                    labelText={strings.resourcesForm.urlInputLabel}
                    inputAria={strings.resourcesForm.urlLabelAria}
                    onChange={(e) => {
                        setLinkUrl(e.target.value);
                    }}
                    inputProps={{}}
                    errorMessage={linkUrlError}
                    required
                />
                <TextField
                    fieldName={'title'}
                    labelText={strings.resourcesForm.titleInputLabel}
                    inputAria={strings.resourcesForm.titleInputLabelAria}
                    onChange={(e) => {
                        setTextToDisplay(e.target.value);
                    }}
                    inputProps={{}}
                    errorMessage={textToDisplayError}
                    required
                />
            </div>
            <ButtonRowRight>
                <SecondaryButton
                    title={strings.resourcesForm.cancelButton}
                    aria={strings.resourcesForm.cancelButtonAria}
                    onClick={closeModal}
                    alternateColour={SecondaryButtonColour.ALTERNATE}
                />
                <PrimaryButton
                    type="button"
                    title={strings.resourcesForm.addResourcesButton}
                    aria={strings.resourcesForm.addResourcesButtonAria}
                    onClick={onSubmit}
                />
            </ButtonRowRight>
        </Form>
    );
}

const Form = styled(FormCard)`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
    @media (max-width: ${breakpoints.sm}) {
        border: none;
        box-shadow: none;
    }
`;
