import { StrategyProps } from './strategies';
import TextField from '../../forms/TextField';
import strings from '../../../strings/strings.json';
import React from 'react';
import Notification, { NotificationType } from '../../notifiers/Notification';

export default function ImageStrategy({
    localFile,
    localFileValid,
    fileMetaData,
    setFileMetaData,
    setFileMetaDataValid,
}: StrategyProps): JSX.Element {
    React.useEffect(() => {
        setFileMetaData(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localFile]);

    React.useEffect(() => {
        if (!fileMetaData?.imageAltText) {
            return setFileMetaDataValid(false);
        }
        return setFileMetaDataValid(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileMetaData]);

    return (
        <>
            {localFileValid && (
                <>
                    <TextField
                        fieldName={strings.fileUpload.imageAltTextLabel}
                        labelText={strings.fileUpload.imageAltTextLabel}
                        inputAria={strings.fileUpload.imageAltTextAria}
                        inputProps={{
                            onChange: (event: { target: { value: string } }) => {
                                setFileMetaData({ ...fileMetaData, imageAltText: event.target.value });
                            },
                        }}
                        errorMessage={
                            !fileMetaData?.imageAltText ? strings.fileUpload.imageAltTextRequiredError : undefined
                        }
                        required={true}
                    />
                    <Notification
                        notificationType={NotificationType.INFO}
                        title={strings.fileUpload.imageAltTextExplanationTitle}
                        description={strings.fileUpload.imageAltTextExplanationDescription}
                        maxWidth={'100%'}
                    />
                </>
            )}
        </>
    );
}
