import styled from 'styled-components';
import { BodyRegular, Card, Headline4 } from 'components/Elements';
import { breakpoints, sizes, theme } from 'theme';
import strings from 'strings/strings.json';
import { LearnerCourseOverview } from '../../../models/LearnerCourseOverview';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import CourseCardDateSection from './CourseCardDateSection';

type CourseCardProps = {
    courseOverview: LearnerCourseOverview;
};

export default function LearnerCourseCard({ courseOverview }: CourseCardProps): JSX.Element {
    const navigate = useNavigate();
    const linkTitle = strings.learnerCourseCard.linkTitle;
    const linkTitleAria = `${strings.learnerCourseCard.linkTitleAria}: ${courseOverview.title}`;
    const linkHref = `/learner/${courseOverview.id}/`;

    return (
        <CourseCard>
            <CourseTitleHeadine>{courseOverview.title}</CourseTitleHeadine>

            <CourseCardDateSection
                startDate={courseOverview.startDate}
                endDate={courseOverview.endDate}
                cancelled={!!courseOverview.cancelledDate}
            />

            <DescriptionText>{courseOverview.courseDescription}</DescriptionText>

            <PrimaryButton
                title={linkTitle}
                aria={linkTitleAria}
                onClick={() => {
                    navigate(linkHref);
                }}
                fullwidth
            />
        </CourseCard>
    );
}

const CourseCard = styled(Card)`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    padding: ${sizes.spacingRg};
    flex-basis: 30%;
    flex-grow: 1;
    max-width: calc(33% - 16px);
    border-top: 80px solid ${theme.cardThirdBackgroundColour};
    border-radius: 30px;
    gap: ${sizes.spacingMd};
    @media (max-width: ${breakpoints.xl}) {
        flex-basis: 50%;
        flex-grow: 1;
        max-width: calc(50% - 16px);
    }
    @media (max-width: ${breakpoints.md}) {
        border-top: 50px solid ${theme.cardThirdBackgroundColour};
    }
    @media (max-width: ${breakpoints.sm}) {
        border-top: 50px solid ${theme.cardThirdBackgroundColour};
        max-width: unset;
    }
`;

const CourseTitleHeadine = styled(Headline4)`
    min-height: 9rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: anywhere;
    @media (max-width: ${breakpoints.sm}) {
        min-height: unset;
    }
`;

const DescriptionText = styled(BodyRegular)`
    min-height: 5rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: anywhere;
    @media (max-width: ${breakpoints.sm}) {
        min-height: unset;
    }
`;
