import Stepper from 'components/stepper/Stepper';
import { TaskActivity, createEmptyTaskActivity } from 'models/inputModels/TaskActivity';
import EmptyForm from './EmptyForm';
import TaskContentForm from './taskContentForms/TaskContentForm';
import TaskDetailsForm from './TaskDetailsForm';

type TaskCreateComponentProps = {
    saveActivityClick: () => void;
    moduleId: string;
};

export default function TaskCreateComponent({ saveActivityClick, moduleId }: TaskCreateComponentProps): JSX.Element {
    return (
        <Stepper<TaskActivity>
            defaultObject={createEmptyTaskActivity(moduleId)}
            steps={[
                { name: 'Activity Type', Form: EmptyForm },
                { name: 'Details', Form: TaskDetailsForm },
                { name: 'Content', Form: TaskContentForm },
            ]}
            defaultStep={1}
            complete={saveActivityClick}
        />
    );
}
