import * as React from 'react';
import strings from '../../../strings/strings.json';
import { useParams } from 'react-router-dom';
import useGetRequest from '../../../hooks/useGetRequest';
import { Course } from '../../../models/Course';
import MainSection from '../../../components/sections/MainSection';
import CourseLearningObjectivesForm from '../course/components/CourseLearningObjectivesForm';
import SubpageHeader from 'components/sections/SubpageHeader';
import Notification, { NotificationType } from '../../../components/notifiers/Notification';
import Skeleton from 'react-loading-skeleton';

export default function EditLearningObjectivesPage(): JSX.Element {
    const params = useParams();
    const { courseId } = params;
    const { data: selectedCourse, loading } = useGetRequest<Course>(`courses/${courseId}`, !courseId);

    const backButtonDetails = React.useMemo(() => {
        return {
            title: strings.editLearningObjectivesPage.backButtonLabel,
            aria: strings.editLearningObjectivesPage.backButtonAria,
            href: `/edit-published-course/${selectedCourse?.id}`,
        };
    }, [selectedCourse]);

    if (!selectedCourse) {
        return (
            <MainSection>
                <Notification
                    notificationType={NotificationType.DANGER}
                    title={strings.editLearningObjectivesPage.learningObjectivesNotFound}
                />
            </MainSection>
        );
    }

    return (
        <>
            <SubpageHeader
                title={strings.editLearningObjectivesPage.headerTitle}
                backButtonDetails={backButtonDetails}
            />
            <MainSection>
                {loading ? <Skeleton height={400} /> : <CourseLearningObjectivesForm course={selectedCourse} />}
            </MainSection>
        </>
    );
}
