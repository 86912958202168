import * as React from 'react';
import strings from '../../../strings/strings.json';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { ButtonRowRight } from '../../../components/Elements';
import { logError } from '../../../lib/debug-helpers';
import { Module } from '../../../models/Module';
import { APIError } from '../../../lib/_api-helpers';
import { ErrorMessage, FormCardWithGaps } from 'components/forms/FormComponents';
import {
    createErrorMessage,
    createFieldErrorFromAPIError,
    createGlobalErrorFromAPIError,
    fieldErrorCodes,
    requiredFieldErrorMessage,
} from 'shared/error-messages';
import TextField from 'components/forms/TextField';
import SaveNotifier from 'components/notifiers/SaveNotifier';
import useModifyRequest from 'hooks/useModifyRequest';
import useNeveForm from 'components/forms/NeveForm';
import Alert from 'components/alert/Alert';
import styled from 'styled-components';
import { breakpoints } from 'theme';
import { TextAreaField } from 'components/forms/TextAreaField';
import SecondaryButton, { SecondaryButtonColour } from 'components/buttons/SecondaryButton';
import useDeleteRequest from 'hooks/useDeleteRequest';
import BinIcon from 'assets/icons/controls/BinIcon';
import { IconSize } from 'assets/icons/icon-sizes';
import { EditCourseContext } from 'contextProviders/EditCourseContext';
import useFormBlocker from 'components/forms/useFormBlocker';

type ModuleInput = {
    title: string;
    description: string;
};

type ModuleFormProps = {
    mode: 'Create' | 'Edit' | 'Edit and Delete';
    module?: Module | null;
    courseId?: string;
    closeForm: (moduleId?: string) => void;
};

export function ModuleForm({ mode, module, courseId, closeForm }: ModuleFormProps): JSX.Element {
    const { modifyData: updateModule, loading: updateLoading } = useModifyRequest(`modules/${module?.id}`, 'PUT');
    const { modifyData: createModule, loading: createLoading } = useModifyRequest(`modules`, 'POST');
    const { deleteData: deleteModule, deleteInProgress } = useDeleteRequest(`modules/${module?.id}`);

    const context = React.useContext(EditCourseContext);

    const loading = updateLoading || createLoading || deleteInProgress;

    const {
        register,
        handleSubmit,
        setError,
        watch,
        formState: { errors, isSubmitting, isDirty },
        reset,
    } = useNeveForm<ModuleInput>({
        title: module?.title ?? '',
        description: module?.description ?? '',
    });

    useFormBlocker(isDirty, strings.courseEditorPage.unsavedChangesPrompt);

    React.useEffect(() => {
        if (isSubmitting) {
            setEditVerificationAlertOpen(false);
        }
    }, [isSubmitting]);

    const [globalErrorMessage, setGlobalErrorMessage] = React.useState<string>('');
    const [saved, setSaved] = React.useState<boolean>(false);
    const [editVerificationAlertOpen, setEditVerificationAlertOpen] = React.useState(false);
    const [deleteVerificationAlertOpen, setDeleteVerificationAlertOpen] = React.useState(false);

    async function onSubmit(formData: ModuleInput) {
        setSaved(false);

        const response =
            mode === 'Create' ? await createModule({ ...formData, courseId: courseId }) : await updateModule(formData);

        const { errors } = response;

        if (errors) {
            logError(errors);
            updateErrors(errors);
            return;
        }

        setGlobalErrorMessage('');
        setSaved(true);
        reset(formData);

        if (context.course) {
            await context.refetchCourse();
        }

        if (mode === 'Create') {
            closeForm(response.value as string);
        }
    }

    const deleteModuleHandler = async () => {
        await deleteModule();
        if (context.course) {
            await context.refetchCourse();
        }
        closeForm();
    };

    function updateErrors(apiErrors: APIError[]): void {
        apiErrors.forEach((apiError) => {
            const { field, code } = apiError;
            if (field === 'global') {
                return setGlobalErrorMessage(createGlobalErrorFromAPIError(code));
            }
            setError(field as keyof ModuleInput, createFieldErrorFromAPIError(field, code));
        });
    }

    return (
        <>
            <ModuleFormContainer onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <TextField
                        fieldName="moduleTitle"
                        maxLength={100}
                        labelText={strings.moduleForm.titleInputLabel}
                        inputAria={strings.moduleForm.titleInputAria}
                        inputProps={register('title', {
                            required: {
                                value: true,
                                message: requiredFieldErrorMessage(strings.moduleForm.titleInputLabel),
                            },
                            maxLength: {
                                value: 100,
                                message: createErrorMessage(
                                    strings.moduleForm.titleInputLabel,
                                    fieldErrorCodes.maxLength,
                                ),
                            },
                        })}
                        errorMessage={errors.title?.message}
                        disabled={loading}
                        required
                        autoFocus
                    />
                    <TextAreaField
                        maxLength={400}
                        charactersLeft={400 - watch('description').length}
                        fieldName={strings.moduleForm.descriptionLabel}
                        aria={strings.moduleForm.descriptionAria}
                        errorMessage={errors.description?.message}
                        inputProps={register('description', {
                            maxLength: {
                                value: 400,
                                message: createErrorMessage(
                                    strings.moduleForm.descriptionLabel,
                                    fieldErrorCodes.maxLength,
                                ),
                            },
                        })}
                    />
                    {globalErrorMessage && <ErrorMessage id="errorMessage">{globalErrorMessage}</ErrorMessage>}
                </div>
                <ButtonRowRightContainer>
                    {mode === 'Edit' ? (
                        <PrimaryButton
                            title={strings.moduleForm.submitButton}
                            aria={strings.moduleForm.submitButtonAria}
                            disabled={loading}
                            onClick={() => setEditVerificationAlertOpen(true)}
                        />
                    ) : (
                        <>
                            {mode === 'Edit and Delete' && (
                                <SecondaryButton
                                    title={strings.moduleUpdatePage.removeModuleButton}
                                    aria={strings.moduleUpdatePage.removeModuleButtonAria}
                                    onClick={() => setDeleteVerificationAlertOpen(true)}
                                    icon={<BinIcon size={IconSize.MEDIUM} />}
                                    disabled={deleteInProgress}
                                    alternateColour={SecondaryButtonColour.ALTERNATE}
                                />
                            )}
                            <PrimaryButton
                                type="submit"
                                title={strings.moduleForm.submitButton}
                                aria={strings.moduleForm.submitButtonAria}
                                disabled={loading}
                            />
                        </>
                    )}
                </ButtonRowRightContainer>
                {saved && !isDirty && (
                    <>
                        <SaveNotifier />
                    </>
                )}
            </ModuleFormContainer>
            {editVerificationAlertOpen && (
                <Alert
                    buttonText={strings.editTitleModulePage.alertButton}
                    ariaButtonText={strings.editTitleModulePage.alertButtonAria}
                    alertText={strings.editTitleModulePage.alertText}
                    closeAlert={() => setEditVerificationAlertOpen(false)}
                    onSubmit={handleSubmit(onSubmit)}
                />
            )}
            {deleteVerificationAlertOpen && (
                <Alert
                    buttonText={strings.moduleUpdatePage.alertButton}
                    ariaButtonText={strings.moduleUpdatePage.alertButtonAria}
                    alertText={strings.moduleUpdatePage.alertText}
                    closeAlert={() => setDeleteVerificationAlertOpen(false)}
                    onSubmit={deleteModuleHandler}
                />
            )}
        </>
    );
}

const ModuleFormContainer = styled(FormCardWithGaps)`
    @media (max-width: ${breakpoints.xl}) {
        width: 100%;
    }
`;

const ButtonRowRightContainer = styled(ButtonRowRight)`
    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column-reverse;
    }
`;
