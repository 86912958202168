import useGetRequest from 'hooks/useGetRequest';
import { Organisation } from 'models/Organisation';
import { AuthContext } from 'contextProviders/AuthContext';
import { useContext } from 'react';
import LargeCreateButton from 'components/buttons/LargeCreateButton';
import styled from 'styled-components';
import { breakpoints, sizes, theme } from 'theme';
import { IconSize } from 'assets/icons/icon-sizes';
import strings from '../../strings/strings.json';
import { Headline2 } from 'components/Elements';
import UploadIcon from 'assets/icons/controls/UploadIcon';
import SecondaryButton, { SecondaryButtonColour } from 'components/buttons/SecondaryButton';
import { useNavigate } from 'react-router';
import { DOCUMENTS_URL } from 'lib/_api-helpers';
import Skeleton from 'react-loading-skeleton';
import Notification, { NotificationType } from 'components/notifiers/Notification';
import BinIcon from 'assets/icons/controls/BinIcon';
import useDeleteRequest from 'hooks/useDeleteRequest';
import React from 'react';
import Alert from 'components/alert/Alert';
import { logError } from 'lib/debug-helpers';
import { OrganisationDescriptionForm } from './components/OrganisationDescriptionForm';
import TabStructureComponent from 'components/tabs/TabStructureComponent';
import { ManageTrainersContent } from './components/ManageTrainersContent';
import { useLocation } from 'react-router-dom';
import InformationIcon from 'assets/icons/other/InformationIcon';
import FileIcon from 'assets/icons/other/FileIcon';
import ManageTrainersIcon from 'assets/icons/other/ManageTrainersIcon';
import CharlieHRIntegrationContent from './components/CharlieHR/CharlieHRIntegrationContent';

type State = {
    activeTabId: string;
};

export default function OrganisationOverviewPage(): JSX.Element {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { activeTabId } = (state as State) ?? {};

    const { userData, refreshUserData } = useContext(AuthContext);
    const organisationId = userData.organisation?.id;
    const [removeLogoWarning, setRemoveLogoWarning] = React.useState<boolean>(false);
    const [removeLogoError, setRemoveLogoError] = React.useState<boolean>(false);

    const {
        data: organisation,
        loading,
        errors,
        called,
        refetchData: refetchOrganisation,
    } = useGetRequest<Organisation>(`organisations/${organisationId}`, !organisationId);

    const { deleteData } = useDeleteRequest(`organisations/${userData?.organisation?.id}/logo`);

    const [savedDescription, setSavedDescription] = React.useState<boolean>(false);

    async function RemoveLogo() {
        const response = await deleteData();

        if (response.errors) {
            logError(response.errors);
            setRemoveLogoError(true);
            return;
        }

        await refetchOrganisation();
        await refreshUserData();
        setRemoveLogoWarning(false);
    }

    if (!called && !loading && !organisation) {
        return <></>;
    }

    if (errors.length > 0)
        return (
            <>
                <BannerBackground>
                    <LimitedContainer>
                        <OrgOverviewPageHeadlineError>
                            {strings.OrganisationOverviewPage.loadingError.pageTitle}
                        </OrgOverviewPageHeadlineError>
                    </LimitedContainer>
                </BannerBackground>
                <ErrorNotificationContainer>
                    <Notification
                        notificationType={NotificationType.DANGER}
                        title={strings.OrganisationOverviewPage.loadingError.title}
                        description={strings.OrganisationOverviewPage.loadingError.description}
                        maxWidth="800px"
                    />
                </ErrorNotificationContainer>
            </>
        );

    return (
        <>
            <BannerBackground>
                <LimitedContainer>
                    {loading ? (
                        <LoadingHeaderRow>
                            <LoadingHeaderItem>
                                <Skeleton height={'100%'} />
                            </LoadingHeaderItem>
                        </LoadingHeaderRow>
                    ) : (
                        <>
                            <Headline2>{organisation?.name}</Headline2>
                            <HeaderLogoAndButtonsContainer>
                                {organisation?.logoS3Filepath && (
                                    <LogoContainer>
                                        <Image
                                            src={`${DOCUMENTS_URL}/${organisation?.logoS3Filepath}`}
                                            alt={strings.OrganisationOverviewPage.logoAltText}
                                        />
                                    </LogoContainer>
                                )}
                                <HeaderButtonContainer>
                                    <SecondaryButton
                                        title={
                                            organisation?.logoS3Filepath
                                                ? strings.OrganisationOverviewPage.updateButton.title
                                                : strings.OrganisationOverviewPage.uploadButton.title
                                        }
                                        aria={
                                            organisation?.logoS3Filepath
                                                ? strings.OrganisationOverviewPage.updateButton.aria
                                                : strings.OrganisationOverviewPage.uploadButton.aria
                                        }
                                        icon={<UploadIcon size={IconSize.SMALL} />}
                                        onClick={() => {
                                            navigate(`/organisation/logo`);
                                        }}
                                    />
                                    {organisation?.logoS3Filepath && (
                                        <SecondaryButton
                                            title={strings.OrganisationOverviewPage.removeLogoButton.title}
                                            aria={strings.OrganisationOverviewPage.removeLogoButton.aria}
                                            onClick={() => {
                                                setRemoveLogoWarning(true);
                                            }}
                                            alternateColour={SecondaryButtonColour.ALTERNATE}
                                            icon={<BinIcon />}
                                        />
                                    )}
                                </HeaderButtonContainer>
                            </HeaderLogoAndButtonsContainer>
                        </>
                    )}
                </LimitedContainer>
            </BannerBackground>
            <BodyContent id="tabsBody">
                <TabStructureComponent
                    initialActiveTabId={activeTabId}
                    tabs={[
                        {
                            id: 'organisationDetails',
                            name: strings.OrganisationOverviewPage.tabs.organisationDetails,
                            icon: InformationIcon,
                            content: loading ? (
                                <Skeleton height={100} />
                            ) : organisation ? (
                                <OrganisationDescriptionForm
                                    organisation={organisation}
                                    refetchOrganisation={refetchOrganisation}
                                    savedDescription={savedDescription}
                                    setSavedDescription={setSavedDescription}
                                />
                            ) : (
                                <></>
                            ),
                        },
                        {
                            id: 'manageTrainers',
                            name: strings.OrganisationOverviewPage.tabs.manageTrainers,
                            icon: ManageTrainersIcon,
                            content: <ManageTrainersContent />,
                        },
                        {
                            id: 'manageFiles',
                            name: strings.OrganisationOverviewPage.tabs.manageFiles,
                            icon: FileIcon,
                            content: (
                                <LargeCreateButton
                                    key={strings.OrganisationOverviewPage.manageFileButton}
                                    title={strings.OrganisationOverviewPage.manageFileButton}
                                    aria={strings.OrganisationOverviewPage.manageFileButtonAria}
                                    onClick={() => navigate('/organisation/files')}
                                    icon={
                                        <FileIcon
                                            size={IconSize.XLARGE}
                                            colour={theme.secondaryButtonBackgroundColour}
                                        />
                                    }
                                />
                            ),
                        },
                        {
                            id: 'integration',
                            name: strings.OrganisationOverviewPage.tabs.integration,
                            icon: FileIcon,
                            content: <CharlieHRIntegrationContent />,
                        },
                    ]}
                />
            </BodyContent>
            {removeLogoWarning && (
                <Alert
                    buttonText={strings.OrganisationOverviewPage.removeLogoAlert.button.text}
                    ariaButtonText={strings.OrganisationOverviewPage.removeLogoAlert.button.aria}
                    alertText={strings.OrganisationOverviewPage.removeLogoAlert.alertText}
                    closeAlert={() => setRemoveLogoWarning(false)}
                    onSubmit={RemoveLogo}
                    error={
                        removeLogoError
                            ? {
                                  title: strings.OrganisationOverviewPage.removeLogoAlert.removeLogoError.title,
                                  description:
                                      strings.OrganisationOverviewPage.removeLogoAlert.removeLogoError.description,
                              }
                            : undefined
                    }
                />
            )}
        </>
    );
}

const LoadingHeaderRow = styled.div`
    display: flex;
    height: 100px;
    width: 100%;
`;

const LoadingHeaderItem = styled.div`
    width: 100%;
`;

const BodyContent = styled.div`
    display: flex;
    flex-direction: column;
    max-width: ${sizes.appMaxWidthWithoutPadding}px;
    width: 100%;
    margin-top: ${sizes.spacingMd};
    padding: 0 ${sizes.spacingMd};
`;

const LimitedContainer = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    height: 100%;
    max-width: ${sizes.appMaxWidthWithoutPadding}px;
    padding: ${sizes.spacingMd};
    @media (max-width: ${breakpoints.md}) {
        flex-direction: column;
        gap: ${sizes.spacingMd};
        justify-content: unset;
        align-items: flex-start;
    }
`;

const BannerBackground = styled.div`
    background: ${theme.notificationDangerBackground};
    width: 100%;
    height: 250px; //stops excessive movement of banner on re-loading organisation

    @media (max-width: ${breakpoints.md}) {
        height: auto;
    }
`;

const HeaderLogoAndButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${sizes.spacingLg};
    height: 100%;

    @media (max-width: ${breakpoints.sm}) {
        width: 100%;
    }
`;

const HeaderButtonContainer = styled.div`
    display: flex;
    gap: ${sizes.spacingRg};
    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
        width: 100%;
    }
`;

const OrgOverviewPageHeadlineError = styled(Headline2)`
    margin: 140px 0;
    @media (max-width: ${breakpoints.sm}) {
        margin: 64px 0;
    }
`;

const ErrorNotificationContainer = styled.div`
    display: flex;
    max-width: ${sizes.appMaxWidthWithoutPadding}px;
    width: 100%;
    padding: 0 ${sizes.edgePadding};
    margin-top: ${sizes.spacingLg};
`;

export const LogoContainer = styled.div`
    background-color: ${theme.cardBackground};
    border-radius: ${sizes.borderRadiusXl};
    display: flex;
    align-items: center;
    height: auto;
    width: auto;
    padding: ${sizes.spacingSm};
    @media (max-width: ${breakpoints.md}) {
        align-self: flex-start;
    }
`;

export const Image = styled.img`
    border-radius: ${sizes.borderRadiusMd};
    max-width: 244px;
    max-height: 88px;
    width: auto;
    height: auto;
    object-fit: contain;
`;
