import strings from '../../strings/strings.json';
import { Card, Headline5, WrappingText } from 'components/Elements';
import { styled } from 'styled-components';
import { breakpoints, sizes } from 'theme';

type TrainerCardProps = {
    firstName: string;
    lastName: string;
    email: string;
};

export function TrainerCard({ firstName, lastName, email }: TrainerCardProps): JSX.Element {
    return (
        <TrainerCardContainer id="trainerCard">
            <TrainerCardHeadline>{strings.scheduledCourseDetails.schedule.trainerHeaderText}</TrainerCardHeadline>
            <WrappingText $bold>
                {firstName} {lastName}
            </WrappingText>
            <WrappingText>{email}</WrappingText>
        </TrainerCardContainer>
    );
}

const TrainerCardContainer = styled(Card)`
    max-width: 420px;
    max-height: 150px;
    width: 100%;
    margin: 0 ${sizes.spacingLg};
    @media (max-width: ${breakpoints.sm}) {
        margin: unset;
        max-width: unset;
    }
`;

const TrainerCardHeadline = styled(Headline5)`
    margin-bottom: ${sizes.spacingMd};
`;
