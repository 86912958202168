import * as React from 'react';
import styled from 'styled-components';
import strings from '../../strings/strings.json';
import { MAGNIFICATION_VALUE, MagnificationContext } from '../../contextProviders/MagnificationContextProvider';
import { breakpoints, FONTSIZE, sizes, theme } from '../../theme';

type MagnificationMap = {
    label: string;
    value: MAGNIFICATION_VALUE;
    ariaLabelText: string;
    styles: {};
};

const MAGNIFICATION_VALUES: MagnificationMap[] = [
    {
        label: strings.accessibilityPanel.smallLabel,
        value: MAGNIFICATION_VALUE.Small,
        ariaLabelText: strings.accessibilityPanel.smallAria,
        styles: { fontSize: '16px' },
    },
    {
        label: strings.accessibilityPanel.mediumLabel,
        value: MAGNIFICATION_VALUE.Medium,
        ariaLabelText: strings.accessibilityPanel.mediumAria,
        styles: { fontSize: '22px' },
    },
    {
        label: strings.accessibilityPanel.largeLabel,
        value: MAGNIFICATION_VALUE.Large,
        ariaLabelText: strings.accessibilityPanel.largeAria,
        styles: { fontSize: '32px' },
    },
];

export function MagnificationChanger(): JSX.Element {
    const { magnificationValue, updateMagnification } = React.useContext(MagnificationContext);

    function onChange(e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement>) {
        const { value } = e.target as HTMLButtonElement;
        const i = parseInt(value);
        updateMagnification(MAGNIFICATION_VALUES[i].value);
    }

    return (
        <Container>
            <TitleRow>{strings.accessibilityPanel.magnificationTitle}</TitleRow>
            <Wrapper>
                {MAGNIFICATION_VALUES.map((m, i) => {
                    return (
                        <Content key={i} $alignLeft={m.value === MAGNIFICATION_VALUE.Small}>
                            <Button
                                style={m.styles}
                                onClick={onChange}
                                value={i}
                                id={`button-label-${m.label}`}
                                aria-label={m.ariaLabelText}
                                $active={m.value === magnificationValue}
                            >
                                Aa
                            </Button>
                            <LabelContainer>{m.label}</LabelContainer>
                        </Content>
                    );
                })}
            </Wrapper>
        </Container>
    );
}

const Container = styled.div`
    height: 100%;
    margin-bottom: 16px;
    @media (max-width: ${breakpoints.sm}) {
        width: 90%;
    }
`;

const LabelContainer = styled.div`
    color: ${theme.accessibilityPanel.textColour};
    font-size: 24px;
`;

const TitleRow = styled.div`
    font-size: 24px;
    color: ${theme.accessibilityPanel.textColour};
    margin-top: 10px;
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 50px;
`;

const Content = styled.div<{ $alignLeft: boolean }>`
    font-family: ${theme.fontFamilyMain};
    font-size: ${FONTSIZE.BodySmall};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: ${({ $alignLeft }) => {
        return $alignLeft ? 'flex-start' : 'center';
    }};
`;

const Button = styled.button<{ $active: boolean }>`
    position: relative;
    border: 1px solid ${theme.accessibilityPanel.buttonBorder};
    border-radius: ${sizes.borderRadiusLg};
    padding: 10px;
    height: max-content;
    color: ${theme.accessibilityPanel.textColour};
    cursor: pointer;
    background-color: ${({ $active }) => {
        return $active ? theme.accessibilityPanel.butonFocusBackgroundColour : 'transparent';
    }};
    border: ${({ $active }) => {
        return $active
            ? `1px solid ${theme.accessibilityPanel.buttonFocusBorder}`
            : `1px solid ${theme.accessibilityPanel.buttonBorder}`;
    }};
    &:focus {
        outline-width: 5px;
    }
`;
