import styled from 'styled-components';
import { sizes, theme } from 'theme';
import { BodyRegular, Card } from '../Elements';
import strings from '../../strings/strings.json';

export type NumberOfLearnersSectionProps = {
    minimumLearners: number;
    maximumLearners: number;
};

export default function NumberOfLearnersSection({
    minimumLearners,
    maximumLearners,
}: NumberOfLearnersSectionProps): JSX.Element {
    return (
        <NumberCardsContainer>
            <NumberCard>
                <BodyRegular $bold>{strings.numberOfLearnersSection.minimum}</BodyRegular>
                <BodyRegular>{minimumLearners}</BodyRegular>
            </NumberCard>
            <NumberCard>
                <BodyRegular $bold>{strings.numberOfLearnersSection.maximum}</BodyRegular>
                <BodyRegular>{maximumLearners}</BodyRegular>
            </NumberCard>
        </NumberCardsContainer>
    );
}

const NumberCard = styled(Card)`
    display: flex;
    gap: ${sizes.spacingSm};
    border: 0.2px solid ${theme.cardSecondaryBorder};
    padding: ${sizes.spacingMd};
`;

const NumberCardsContainer = styled.div`
    display: flex;
    gap: ${sizes.spacingRg};
    flex-wrap: wrap;
`;
