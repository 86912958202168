import { FormFieldContainer, FormLabel, FormTextAreaInput } from './FormComponents';
import { ConditionalValidationErrorMessage } from './ValidationErrorMessage';
import styled from 'styled-components';
import { breakpoints, sizes, theme } from 'theme';
import strings from '../../strings/strings.json';
import { BodyRegular } from 'components/Elements';
import useWindowWidth from 'hooks/useWindowWidth';
import ErrorIcon from '../../assets/icons/indicators/ErrorIcon';

type TextAreaFieldProps = {
    fieldName: string;
    required?: boolean;
    aria: string;
    errorMessage: string | undefined;
    inputProps: any;
    charactersLeft?: number;
    maxLength: number;
    autoFocus?: boolean;
    unfocusable?: boolean;
};

export function TextAreaField({
    fieldName,
    required,
    aria,
    errorMessage,
    inputProps,
    charactersLeft,
    maxLength,
    autoFocus,
    unfocusable,
}: TextAreaFieldProps): JSX.Element {
    const { currentWindowWidth } = useWindowWidth();

    return (
        <FormFieldContainer>
            <FormLabel id={`${fieldName} ${strings.textAreaField.formLabel}`} htmlFor={fieldName}>
                {fieldName}
                {required && ' *'}
            </FormLabel>
            <FormTextAreaInput
                tabIndex={unfocusable ? -1 : 0}
                id={fieldName}
                maxLength={maxLength}
                aria-label={aria}
                {...inputProps}
                $error={errorMessage}
                autoFocus={autoFocus}
            />
            {charactersLeft !== 0 ? (
                <TextLimitContainer>
                    <BodySmallContainer $bold>{strings.textAreaField.formTextAreaLimit}</BodySmallContainer>
                    <BodySmallContainer $bold>{charactersLeft}</BodySmallContainer>
                </TextLimitContainer>
            ) : (
                <TextLimitContainer>
                    <TextAreaLimitErrorContainer>
                        <ErrorIcon />
                        {currentWindowWidth > parseInt(breakpoints.sm) && (
                            <BodySmallContainer $errorColour $bold>
                                {strings.textAreaField.formTextAreaLimitError}
                            </BodySmallContainer>
                        )}
                    </TextAreaLimitErrorContainer>
                    <BodySmallContainer $errorColour $bold>
                        {charactersLeft}
                    </BodySmallContainer>
                </TextLimitContainer>
            )}
            {errorMessage && <ConditionalValidationErrorMessage error={errorMessage ?? ''} />}
        </FormFieldContainer>
    );
}

const TextLimitContainer = styled.div`
    padding-top: ${sizes.spacingSm};
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const BodySmallContainer = styled(BodyRegular)<{ $errorColour?: boolean }>`
    color: ${({ $errorColour }) => {
        return $errorColour ? theme.errorColour : theme.textColour;
    }};
`;

const TextAreaLimitErrorContainer = styled.div`
    display: flex;
    gap: ${sizes.spacingSm};
    align-items: center;
    justify-content: center;
`;
