import strings from '../../../strings/strings.json';
import * as React from 'react';
import useModifyRequest from 'hooks/useModifyRequest';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { FormCard } from 'components/forms/FormComponents';
import useNeveForm from 'components/forms/NeveForm';
import { TextAreaField } from 'components/forms/TextAreaField';
import { ErrorNotifierSlim } from "components/notifiers/ErrorNotifierSlim";
import { createErrorMessage, fieldErrorCodes } from 'shared/error-messages';
import styled from 'styled-components';
import { sizes } from 'theme';

export type TrainerBioFormProps = {
    bio: string;
    refetchUserDetails: () => Promise<void>;
    setShowSavedBio: React.Dispatch<React.SetStateAction<boolean>>;
    setShowBioForm: React.Dispatch<React.SetStateAction<boolean>>;
};

export type TrainerBio = {
    bio: string;
};

export function TrainerBioForm({
    bio,
    refetchUserDetails,
    setShowSavedBio,
    setShowBioForm,
}: TrainerBioFormProps): JSX.Element {
    const [showError, setShowError] = React.useState<boolean>(false);

    const { modifyData: updateBio, loading: updatingBio } = useModifyRequest(`users/me/bio`, 'PATCH');

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        reset,
    } = useNeveForm<TrainerBio>({
        bio: bio ?? '',
    });

    const watchedBio = watch('bio');

    React.useEffect(() => {
        setShowError(false);
    }, [watchedBio]);

    async function onUpdateTrainerBio({ bio }: TrainerBio) {
        setShowError(false);

        const response = await updateBio({ bio: bio });

        if (response.errors && response.errors.length > 0) {
            setShowError(true);
        } else {
            await refetchUserDetails();
            setShowSavedBio(true);
            setShowBioForm(false);
            reset({ bio });
        }
    }

    return (
        <FormCard onSubmit={handleSubmit(onUpdateTrainerBio)}>
            <TextAreaField
                maxLength={500}
                charactersLeft={500 - watchedBio.length}
                fieldName={strings.updateTrainerBioForm.bioInput.title}
                aria={strings.updateTrainerBioForm.bioInput.aria}
                errorMessage={errors.bio?.message}
                inputProps={register('bio', {
                    maxLength: {
                        value: 500,
                        message: createErrorMessage(
                            strings.updateTrainerBioForm.bioInput.title,
                            fieldErrorCodes.maxLength,
                        ),
                    },
                })}
                autoFocus
            />
            <ErrorAndButtonContainer>
                {showError && <ErrorNotifierSlim description={strings.updateTrainerBioForm.error} />}
                <SaveButtonContainer>
                    <PrimaryButton
                        type="submit"
                        title={strings.updateTrainerBioForm.submitButton.title}
                        aria={strings.updateTrainerBioForm.submitButton.aria}
                        disabled={updatingBio}
                    />
                </SaveButtonContainer>
            </ErrorAndButtonContainer>
        </FormCard>
    );
}

const SaveButtonContainer = styled.div`
    display: flex;
    justify-content: end;
`;

const ErrorAndButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingRg};
`;
