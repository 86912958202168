import { theme } from '../../../theme';
import { IconSize } from '../icon-sizes';

export default function AdminIcon({
    colour = theme.textColour,
    size = IconSize.MEDIUM,
}: {
    colour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.02344 16.2188C4.89583 16.2188 4.78646 16.1732 4.69531 16.082C4.60417 15.9954 4.55859 15.8861 4.55859 15.7539C4.55859 15.681 4.5791 15.5944 4.62012 15.4941C4.66113 15.3984 4.74544 15.2458 4.87305 15.0361L7.62793 10.5312H3.51953C3.37826 10.5312 3.25521 10.4857 3.15039 10.3945C3.05013 10.2988 3 10.1803 3 10.0391C3 9.85677 3.07064 9.71094 3.21191 9.60156L12.3652 2.20508C12.4427 2.14128 12.5133 2.09115 12.5771 2.05469C12.6455 2.01823 12.7116 2 12.7754 2C12.9622 2 13.1035 2.07747 13.1992 2.23242C13.2995 2.38737 13.2926 2.55827 13.1787 2.74512L10.1709 7.65332H13.8281C13.974 7.65332 14.097 7.69889 14.1973 7.79004C14.2975 7.88119 14.3477 7.99967 14.3477 8.14551C14.3477 8.32324 14.2793 8.46908 14.1426 8.58301L5.61816 15.8906C5.47689 16.0137 5.36068 16.098 5.26953 16.1436C5.18294 16.1937 5.10091 16.2188 5.02344 16.2188Z"
                fill={colour}
            />
        </svg>
    );
}
