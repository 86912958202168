import { breakpoints, sizes, theme } from '../../theme';
import { IconSize } from '../../assets/icons/icon-sizes';
import { BodyRegular } from '../Elements';
import * as React from 'react';
import styled from 'styled-components';
import useWindowWidth from '../../hooks/useWindowWidth';
import ArrowDownIcon from '../../assets/icons/controls/ArrowDownIcon';
import ArrowUpIcon from '../../assets/icons/controls/ArrowUpIcon';
import { useHeight } from '../../hooks/useHeight';
import { animated, useSpring } from '@react-spring/web';

export type TabDefinition = {
    id: string;
    name: string;
    icon: React.ElementType;
    content: JSX.Element;
};

export default function Tabs({
    tabs,
    activeTabId,
    onClickTab,
    rightElement,
}: {
    tabs: TabDefinition[];
    activeTabId: string;
    onClickTab: (newId: string) => void;
    rightElement?: JSX.Element;
}): JSX.Element {
    const { currentWindowWidth } = useWindowWidth();

    return (
        <TabsContainer id="tabHeadings">
            {currentWindowWidth > parseInt(breakpoints.sm) && (
                <TabsSection>
                    {tabs.map((tabDefinition, index) => {
                        const Icon = tabDefinition.icon;

                        return tabDefinition.id === activeTabId ? (
                            <ActiveTab key={index} aria-label={tabDefinition.name}>
                                <TextSection>
                                    <Icon colour={theme.primaryBrandColour} size={IconSize.MEDIUM} />
                                    <ActiveBodyRegular $bold>{tabDefinition.name}</ActiveBodyRegular>
                                </TextSection>
                            </ActiveTab>
                        ) : (
                            <Tab
                                key={index}
                                onClick={() => onClickTab(tabDefinition.id)}
                                aria-label={tabDefinition.name}
                            >
                                <TextSection $underlined>
                                    <Icon colour={theme.textColour} size={IconSize.MEDIUM} />
                                    <BodyRegular>{tabDefinition.name}</BodyRegular>
                                </TextSection>
                            </Tab>
                        );
                    })}
                </TabsSection>
            )}
            {currentWindowWidth <= parseInt(breakpoints.sm) && (
                <MobileTabs tabs={tabs} activeTabId={activeTabId} onClickTab={onClickTab} />
            )}
            {!!rightElement && rightElement}
        </TabsContainer>
    );
}

function MobileTabs({
    tabs,
    activeTabId,
    onClickTab,
}: {
    tabs: TabDefinition[];
    activeTabId: string;
    onClickTab: (newId: string) => void;
}): JSX.Element {
    const [tabMenuOpen, setTabMenuOpen] = React.useState<boolean>(false);

    const [heightRef, height] = useHeight();

    const { height: animatedHeight } = useSpring({
        from: { height: 0 },
        to: {
            height: height,
        },
    });

    const activeTab = tabs.find((x) => x.id === activeTabId);

    if (!activeTab) return <></>;

    const Icon = activeTab.icon;

    function onMobileTabClick(newTabId: string) {
        setTabMenuOpen(false);
        onClickTab(newTabId);
    }

    return (
        <MobileContainer>
            <MobileActiveTabButton
                onClick={() => setTabMenuOpen((tabMenuOpen) => !tabMenuOpen)}
                aria-label={activeTab.name}
            >
                <MobileTextSection>
                    <Icon colour={theme.primaryBrandColour} size={IconSize.MEDIUM} />
                    <ActiveBodyRegular $bold>{activeTab.name}</ActiveBodyRegular>
                </MobileTextSection>
                {tabMenuOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </MobileActiveTabButton>
            <MobileMenu>
                <animated.div style={{ overflow: 'hidden', height: animatedHeight }}>
                    <MobileMenuContent ref={heightRef}>
                        {tabMenuOpen && (
                            <>
                                {tabs
                                    .filter((x) => x.id !== activeTabId)
                                    .map((tabDefinition, index) => {
                                        const Icon = tabDefinition.icon;

                                        return (
                                            <MobileTabButton
                                                key={index}
                                                onClick={() => onMobileTabClick(tabDefinition.id)}
                                                disabled={!tabMenuOpen}
                                                aria-label={tabDefinition.name}
                                            >
                                                <MobileTextSection>
                                                    <Icon colour={theme.primaryBrandColour} size={IconSize.MEDIUM} />
                                                    <ActiveBodyRegular $bold>{tabDefinition.name}</ActiveBodyRegular>
                                                </MobileTextSection>
                                            </MobileTabButton>
                                        );
                                    })}
                            </>
                        )}
                    </MobileMenuContent>
                </animated.div>
                {tabMenuOpen && <TabMenuOverlay />}
            </MobileMenu>
        </MobileContainer>
    );
}

const TabMenuOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
`;

const MobileMenu = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const MobileMenuContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const MobileContainer = styled.div`
    position: relative;
    width: 100%;
`;

const MobileActiveTabButton = styled.button`
    position: relative;
    font-family: ${theme.fontFamilyMain};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: ${sizes.spacingSm};
    padding: ${sizes.spacingRg} ${sizes.spacingMd};
    border: solid 1px ${theme.cardBorder};
    width: 100%;
    background-color: ${theme.cardBackground};
    z-index: 2;

    &:hover {
        background-color: ${theme.secondaryButtonHoverBackgroundColour};
    }
    &:focus {
        background-color: ${theme.secondaryButtonFocusBackgroundColour};
    }
`;

const MobileTabButton = styled.button`
    font-family: ${theme.fontFamilyMain};
    color: ${theme.textColour};
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${sizes.spacingRg} ${sizes.spacingMd};
    border-color: ${theme.cardBorder};
    border-width: 1px;
    border-style: solid;
    width: 100%;
    background-color: ${theme.cardBackground};
    z-index: 2;

    &:hover {
        background-color: ${theme.secondaryButtonHoverBackgroundColour};
    }
    &:focus {
        background-color: ${theme.secondaryButtonFocusBackgroundColour};
    }
`;

const MobileTextSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${sizes.spacingSm};
`;

const TextSection = styled.div<{ $underlined?: boolean }>`
    border-bottom: ${({ $underlined }) => {
            return $underlined ? theme.textColour : 'none';
        }}
        solid 2px;
    display: flex;
    align-items: center;
    gap: ${sizes.spacingMd};
    padding-bottom: ${sizes.spacingXs};
`;

const TabsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: ${theme.cardBorder} solid 1px;
    @media (max-width: ${breakpoints.lg}) {
        flex-direction: column-reverse;
        gap: ${sizes.spacingMd};
        align-items: flex-start;
    }
`;

const TabsSection = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: ${sizes.spacingMd};
`;

const TabBase = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: end;
    gap: ${sizes.spacingSm};
    padding: ${sizes.spacingMd} ${sizes.spacingRg};
    background-color: ${theme.cardBackground};
    border-width: 1px;
    border-style: solid;
    border-color: ${theme.cardBorder};
    border-top-left-radius: ${sizes.borderRadiusLg};
    border-top-right-radius: ${sizes.borderRadiusLg};
    height: 100%;
    cursor: pointer;
`;

const Tab = styled(TabBase).attrs({ as: 'button' })`
    &:focus {
        background-color: ${theme.secondaryButtonFocusBackgroundColour};
    }
    &:hover {
        background-color: ${theme.secondaryButtonHoverBackgroundColour};
    }
`;

const ActiveTab = styled(TabBase)`
    border-color: ${theme.cardBorder} ${theme.cardBorder} ${theme.cardBackground};
    margin-bottom: -1px;
`;

const ActiveBodyRegular = styled(BodyRegular)`
    color: ${theme.primaryBrandColour};
`;
