import { Headline5, BodyRegular } from 'components/Elements';
import styled from 'styled-components';
import { sizes } from 'theme';

type AccordionPanelTitleProps = {
    title: string;
    description?: string;
};

export function AccordionPanelHeaderTitle({ title, description }: AccordionPanelTitleProps): JSX.Element {
    return (
        <PanelTitle>
            <Headline5>{title}</Headline5>
            {description && <DescriptionText>{description}</DescriptionText>}
        </PanelTitle>
    );
}

const PanelTitle = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingSm};
    overflow: hidden;
    text-align: left;
`;

const DescriptionText = styled(BodyRegular)`
    white-space: pre-wrap;
`;
