import React from 'react';
import styled from 'styled-components';
import { breakpoints, sizes, theme } from 'theme';
import strings from '../../../strings/strings.json';
import useWindowHeight from 'hooks/useWindowHeight';
import { AuthContext } from 'contextProviders/AuthContext';
import useGetRequest from 'hooks/useGetRequest';
import SecondaryButton from 'components/buttons/SecondaryButton';
import { FileDocument } from 'models/FileDocument';
import SelectFileForm from './SelectFileForm';
import { FileMetaData, FileType } from 'components/fileUpload/strategies/strategies';

type FileSelectionComponentProps = {
    setFileFromLocal: (newFile: File | null | undefined) => void;
    setFileFromProvider: (video: FileDocument) => void;
    setFileFromLocalMetaData: (newMetaData: FileMetaData | undefined) => void;
    disableButton: boolean;
    selectFileButtonRef: React.RefObject<HTMLButtonElement>;
};

export function VideoSelectionComponent({
    setFileFromLocal,
    setFileFromProvider,
    setFileFromLocalMetaData,
    disableButton,
    selectFileButtonRef,
}: FileSelectionComponentProps) {
    const authContext = React.useContext(AuthContext);
    const userOrganisation = authContext.userData.organisation;
    const { data: videos, loading: loadingVideos } = useGetRequest<FileDocument[]>(
        `organisations/${userOrganisation?.id}/documents?document_type=VIDEO`,
    );

    return (
        <FileSelectionComponent
            buttonTitle={strings.learningMaterialsForm.selectVideoButton}
            buttonAria={strings.learningMaterialsForm.selectVideoButtonAria}
            setFileFromLocal={setFileFromLocal}
            setFileFromProvider={setFileFromProvider}
            setFileFromLocalMetaData={setFileFromLocalMetaData}
            disableButton={disableButton}
            organisationName={userOrganisation?.name ?? ''}
            filesFromProvider={videos}
            loadingFiles={loadingVideos}
            chosenFileType={FileType.VIDEO}
            selectFileButtonRef={selectFileButtonRef}
        />
    );
}

export function ImageSelectionComponent({
    setFileFromLocal,
    setFileFromProvider,
    setFileFromLocalMetaData,
    disableButton,
    selectFileButtonRef,
}: FileSelectionComponentProps) {
    const authContext = React.useContext(AuthContext);
    const userOrganisation = authContext.userData.organisation;
    const { data: images, loading: loadingImages } = useGetRequest<FileDocument[]>(
        `organisations/${userOrganisation?.id}/documents?document_type=IMAGE`,
    );

    return (
        <FileSelectionComponent
            buttonTitle={strings.learningMaterialsForm.selectImageButton}
            buttonAria={strings.learningMaterialsForm.selectImageButtonAria}
            setFileFromLocal={setFileFromLocal}
            setFileFromProvider={setFileFromProvider}
            disableButton={disableButton}
            organisationName={userOrganisation?.name ?? ''}
            filesFromProvider={images}
            loadingFiles={loadingImages}
            setFileFromLocalMetaData={setFileFromLocalMetaData}
            chosenFileType={FileType.IMAGE}
            selectFileButtonRef={selectFileButtonRef}
        />
    );
}

export function AudioSelectionComponent({
    setFileFromLocal,
    setFileFromProvider,
    setFileFromLocalMetaData,
    disableButton,
    selectFileButtonRef,
}: FileSelectionComponentProps) {
    const authContext = React.useContext(AuthContext);
    const userOrganisation = authContext.userData.organisation;
    const { data: audio, loading: loadingAudio } = useGetRequest<FileDocument[]>(
        `organisations/${userOrganisation?.id}/documents?document_type=AUDIO`,
    );

    return (
        <FileSelectionComponent
            buttonTitle={strings.learningMaterialsForm.audio.selectAudioButton}
            buttonAria={strings.learningMaterialsForm.audio.selectAudioButtonAria}
            setFileFromLocal={setFileFromLocal}
            setFileFromProvider={setFileFromProvider}
            disableButton={disableButton}
            organisationName={userOrganisation?.name ?? ''}
            filesFromProvider={audio}
            loadingFiles={loadingAudio}
            setFileFromLocalMetaData={setFileFromLocalMetaData}
            chosenFileType={FileType.AUDIO}
            selectFileButtonRef={selectFileButtonRef}
        />
    );
}

type InnerFileSelectionComponentProps = {
    buttonTitle: string;
    buttonAria: string;
    setFileFromLocal: (newFile: File | null | undefined) => void;
    setFileFromProvider: (video: FileDocument) => void;
    setFileFromLocalMetaData: (newMetaData: FileMetaData | undefined) => void;
    disableButton: boolean;
    organisationName: string;
    filesFromProvider: FileDocument[] | null;
    loadingFiles: boolean;
    chosenFileType: FileType;
    selectFileButtonRef: React.RefObject<HTMLButtonElement>;
};

function FileSelectionComponent({
    buttonTitle,
    buttonAria,
    setFileFromLocal,
    setFileFromProvider,
    setFileFromLocalMetaData,
    disableButton,
    organisationName,
    filesFromProvider,
    loadingFiles,
    chosenFileType,
    selectFileButtonRef,
}: InnerFileSelectionComponentProps) {
    const [modalOpen, setModalOpen] = React.useState<boolean>(false);

    const { currentWindowHeight } = useWindowHeight();

    //for the scroll to be visible, the container height has to be set and is dependent on the screen height.
    const containerHeight = currentWindowHeight * 0.75;

    return (
        <>
            <SecondaryButton
                title={buttonTitle}
                aria={buttonAria}
                onClick={() => setModalOpen(true)}
                disabled={disableButton}
                buttonRef={selectFileButtonRef}
            />
            {modalOpen && (
                <SelectFileOverlayBackground>
                    <SelectFileOverlayContainer>
                        <SelectFileOverlayContentContainer $heightInPx={containerHeight}>
                            <SelectFileForm
                                organisationName={organisationName}
                                filesFromProvider={filesFromProvider ?? []}
                                loadingFiles={loadingFiles}
                                setFileFromLocal={setFileFromLocal}
                                setFileFromProvider={setFileFromProvider}
                                setFileFromLocalMetaData={setFileFromLocalMetaData}
                                closeModal={() => {
                                    setModalOpen(false);
                                    selectFileButtonRef.current?.focus();
                                }}
                                chosenFileType={chosenFileType}
                            />
                        </SelectFileOverlayContentContainer>
                    </SelectFileOverlayContainer>
                </SelectFileOverlayBackground>
            )}
        </>
    );
}

const SelectFileOverlayBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${theme.contentBackgroundColourTrainer};
    z-index: 1;
`;

const SelectFileOverlayContainer = styled.div`
    display: flex;
    margin: 100px auto 0 auto;
    max-width: ${sizes.appMaxWidthWithoutPadding}px;
`;

const SelectFileOverlayContentContainer = styled.div<{
    $heightInPx: number;
}>`
    background-color: ${theme.cardBackground};

    margin: ${sizes.spacingMd};
    overflow-y: auto;
    max-height: ${({ $heightInPx }) => {
        return `${$heightInPx}px`;
    }};

    border: 1px solid ${theme.cardBorder};
    border-radius: ${sizes.borderRadiusLg};
    box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
    padding: ${sizes.spacingSm} ${sizes.spacingRg} ${sizes.spacingRg} ${sizes.spacingRg};

    @media (max-width: ${breakpoints.sm}) {
        border: unset;
        border-radius: unset;
        box-shadow: unset;
        padding: unset;
    }
`;
