export type AccessNeedsOptions = {
    readingDifficulty: boolean;
    colourSensitive: boolean;
    guideDog: boolean;
    hearingDifficulty: boolean;
    noisyEnvironments: boolean;
    wheelchairUser: boolean;
    limitedRangeMotion: boolean;
};

export type UserPreferences = AccessNeedsOptions & {
    hasAccessibilityRequirements: boolean;
    otherNeeds: string;
};

export type CreateUserPreferencesRequest = AccessNeedsOptions & {
    otherNeeds: string;
};

type AccessNeedsOptionsTypes = keyof AccessNeedsOptions;

export function createUserPreferences(
    newAccessNeedsOptions: string[],
    otherNeeds: string,
): CreateUserPreferencesRequest {
    let accessNeedsOptions: AccessNeedsOptions = {
        colourSensitive: false,
        guideDog: false,
        hearingDifficulty: false,
        limitedRangeMotion: false,
        noisyEnvironments: false,
        readingDifficulty: false,
        wheelchairUser: false,
    };

    Object.keys(accessNeedsOptions).forEach((x) => {
        let y = x as AccessNeedsOptionsTypes;

        if (newAccessNeedsOptions.includes(x)) accessNeedsOptions[y] = true;
    });

    return {
        ...accessNeedsOptions,
        otherNeeds: otherNeeds,
    };
}

export function makeStringArrayFromAccessNeeds(accessNeedsOptions: AccessNeedsOptions): string[] {
    let accessNeedsArray: string[] = [];

    Object.entries(accessNeedsOptions).forEach(([accessNeed, value]) => {
        if (value) accessNeedsArray.push(accessNeed);
    });

    return accessNeedsArray;
}
