import * as React from 'react';
import StepperButtonRow from 'components/stepper/StepperButtonRow';

type ActivityWizardButtonRowProps = {
    submitButtonTitle: string;
    submitButtonAria: string;
    buttonsDisabled?: boolean;
    previousButtonFunction?: Function;
    submitButtonFunction?: Function;
    submitting?: boolean;
    showPreviousButton?: boolean;
};

export default function ActivityWizardButtonRow({
    submitButtonTitle,
    submitButtonAria,
    buttonsDisabled,
    previousButtonFunction,
    submitButtonFunction,
    submitting,
    showPreviousButton = true,
}: ActivityWizardButtonRowProps): JSX.Element {
    return (
        <StepperButtonRow
            submitButtonTitle={submitButtonTitle}
            submitButtonAria={submitButtonAria}
            buttonsDisabled={buttonsDisabled}
            previousButtonFunction={previousButtonFunction}
            submitButtonFunction={submitButtonFunction}
            submitting={submitting}
            showPreviousButton={showPreviousButton}
        />
    );
}
