import useGetRequest from '../../../hooks/useGetRequest';
import { TrainerMeetingProvider } from '../../../models/User';
import strings from '../../../strings/strings.json';
import ZoomIntegrationForm from './ZoomIntegrationForm';
import React from 'react';

export default function ZoomIntegrationSection(): JSX.Element {
    const {
        data: meetingProvider,
        loading,
        errors: getMeetingProviderErrors,
    } = useGetRequest<TrainerMeetingProvider>('users/me/meeting-provider');

    if (loading) {
        return <p>{strings.general.loading}</p>;
    }

    if (
        getMeetingProviderErrors &&
        getMeetingProviderErrors[0] &&
        getMeetingProviderErrors[0].code !== 'userHasNoMeetingProvider'
    ) {
        return <></>; //unexpected error
    }

    return <ZoomIntegrationForm meetingProvider={meetingProvider} />;
}
