import * as React from 'react';
import strings from '../../../strings/strings.json';
import PageFooter from '../../../components/structure/PageFooter';
import CourseLearningObjectivesForm from './components/CourseLearningObjectivesForm';
import { useParams } from 'react-router-dom';
import useGetRequest from '../../../hooks/useGetRequest';
import { Course } from '../../../models/Course';
import styled from 'styled-components';
import MainSection from '../../../components/sections/MainSection';
import SubpageHeader from '../../../components/sections/SubpageHeader';
import { sizes } from '../../../theme';

export default function CourseLearningObjectivesPage(): JSX.Element {
    const params = useParams();
    const { courseId } = params;
    const { data: selectedCourse } = useGetRequest<Course>(`courses/${courseId}`, !courseId);

    const backButtonDetails = React.useMemo(() => {
        return {
            title: selectedCourse?.title ?? '',
            aria: strings.courseLearningObjectivesPage.backButtonAria,
            href: `/trainer/course/${selectedCourse?.id}`,
        };
    }, [selectedCourse]);

    if (!selectedCourse) {
        return <div></div>;
    }

    return (
        <>
            <SubpageHeader
                title={strings.courseLearningObjectivesPage.headerTitle}
                subtitle={null}
                pageOverlap
                shadedBackground
                backButtonDetails={backButtonDetails}
            />
            <MainSection>
                <Container>
                    <CourseLearningObjectivesForm course={selectedCourse} />
                    <PageFooter
                        title={strings.courseLearningObjectivesPage.footerActionTitle}
                        aria={strings.courseLearningObjectivesPage.footerActionTitleAria}
                        href={`/trainer/course/${courseId}/content`}
                    />
                </Container>
            </MainSection>
        </>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
    max-width: 800px;
`;
