import PrimaryButton from 'components/buttons/PrimaryButton';
import { BodySmall, Card, Headline5 } from 'components/Elements';
import { ActivityType } from 'models/Activity';
import { LearnerModule } from 'models/LearnerCourse';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { breakpoints, sizes, theme } from 'theme';
import strings from 'strings/strings.json';
import VideoChatIcon from 'assets/icons/pathway/VideoChatIcon';
import QuestionnaireIcon from 'assets/icons/pathway/QuestionnaireIcon';
import TaskIcon from 'assets/icons/pathway/TaskIcon';
import { IconSize } from 'assets/icons/icon-sizes';
import useWindowWidth from 'hooks/useWindowWidth';

type NextActivityComponentProps = {
    modules: LearnerModule[];
};

export default function NextActivityComponent({ modules }: NextActivityComponentProps): JSX.Element {
    const navigate = useNavigate();
    const { isMobileWidth } = useWindowWidth();

    const courseIsComplete = modules.flatMap((module) => module.activities).every((activity) => activity.completed);

    if (courseIsComplete) {
        return <></>;
    }

    const firstIncompleteModule = modules
        .filter((module) => module.activities.some((activity) => !activity.completed))
        .sort((moduleA, moduleB) => moduleA.order - moduleB.order)[0];

    const nextActivity = firstIncompleteModule.activities
        .filter((activity) => !activity.completed)
        .sort((activityA, activityB) => activityA.order - activityB.order)[0];

    if (!nextActivity.title) {
        return <></>;
    }

    let icon = <></>;
    const iconSize = isMobileWidth ? IconSize.XLARGE : IconSize.XXXLARGE;

    switch (nextActivity.type) {
        case ActivityType.LIVE_TRAINING:
            icon = <VideoChatIcon size={iconSize} />;
            break;
        case ActivityType.QUESTIONNAIRE:
            icon = <QuestionnaireIcon size={iconSize} />;
            break;
        case ActivityType.TASK:
            icon = <TaskIcon size={iconSize} />;
            break;
        default:
            return <></>;
    }

    const courseNotStarted = modules.flatMap((module) => module.activities).every((activity) => !activity.completed);

    return (
        <NextActivityComponentCard>
            <IconContainer>{icon}</IconContainer>
            <TextAndButtonColumn>
                <div>
                    <BodySmall>{strings.learnerCoursePage.nextActivity.text}</BodySmall>
                    <Headline5>{nextActivity.title}</Headline5>
                </div>
                <ContinueButtonContainer>
                    <PrimaryButton
                        title={
                            courseNotStarted
                                ? strings.learnerCoursePage.nextActivity.button.courseNotStarted.title
                                : strings.learnerCoursePage.nextActivity.button.courseStarted.title
                        }
                        aria={
                            courseNotStarted
                                ? `${strings.learnerCoursePage.nextActivity.button.courseNotStarted.aria}: ${nextActivity.title}`
                                : `${strings.learnerCoursePage.nextActivity.button.courseStarted.aria}: ${nextActivity.title}`
                        }
                        onClick={() => {
                            navigate(`activity/${nextActivity.id}`);
                        }}
                    />
                </ContinueButtonContainer>
            </TextAndButtonColumn>
        </NextActivityComponentCard>
    );
}

const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 150px;
    height: 150px;
    background-color: ${theme.iconBackgroundColour};
    border-radius: ${sizes.borderRadiusLg};

    @media (max-width: ${breakpoints.sm}) {
        min-width: 80px;
        height: 80px;
    }
`;

const TextAndButtonColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingLg};
`;

const NextActivityComponentCard = styled(Card)`
    display: flex;
    gap: ${sizes.spacingMd};
    width: 780px;

    @media (max-width: ${breakpoints.md}) {
        width: 100%;
    }
`;

const ContinueButtonContainer = styled.div`
    width: 130px;

    @media (max-width: ${breakpoints.sm}) {
        width: 100%;
    }
`;
