import { theme } from '../../../theme';
import { IconSize } from '../icon-sizes';

export default function PageInfoIcon({
    colour = theme.primaryBrandColour,
    size = IconSize.MEDIUM,
}: {
    colour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.25 16.5C14.2 16.5 13.3125 16.1375 12.5875 15.4125C11.8625 14.6875 11.5 13.8 11.5 12.75C11.5 11.7 11.8625 10.8125 12.5875 10.0875C13.3125 9.3625 14.2 9 15.25 9C16.3 9 17.1875 9.3625 17.9125 10.0875C18.6375 10.8125 19 11.7 19 12.75C19 13.8 18.6375 14.6875 17.9125 15.4125C17.1875 16.1375 16.3 16.5 15.25 16.5ZM15.25 14.5C15.7333 14.5 16.1458 14.3292 16.4875 13.9875C16.8292 13.6458 17 13.2333 17 12.75C17 12.2667 16.8292 11.8542 16.4875 11.5125C16.1458 11.1708 15.7333 11 15.25 11C14.7667 11 14.3542 11.1708 14.0125 11.5125C13.6708 11.8542 13.5 12.2667 13.5 12.75C13.5 13.2333 13.6708 13.6458 14.0125 13.9875C14.3542 14.3292 14.7667 14.5 15.25 14.5ZM1.5 13.75V11.75H9.5V13.75H1.5ZM3.75 7.5C2.7 7.5 1.8125 7.1375 1.0875 6.4125C0.3625 5.6875 0 4.8 0 3.75C0 2.7 0.3625 1.8125 1.0875 1.0875C1.8125 0.3625 2.7 0 3.75 0C4.8 0 5.6875 0.3625 6.4125 1.0875C7.1375 1.8125 7.5 2.7 7.5 3.75C7.5 4.8 7.1375 5.6875 6.4125 6.4125C5.6875 7.1375 4.8 7.5 3.75 7.5ZM3.75 5.5C4.23333 5.5 4.64583 5.32917 4.9875 4.9875C5.32917 4.64583 5.5 4.23333 5.5 3.75C5.5 3.26667 5.32917 2.85417 4.9875 2.5125C4.64583 2.17083 4.23333 2 3.75 2C3.26667 2 2.85417 2.17083 2.5125 2.5125C2.17083 2.85417 2 3.26667 2 3.75C2 4.23333 2.17083 4.64583 2.5125 4.9875C2.85417 5.32917 3.26667 5.5 3.75 5.5ZM9.5 4.75V2.75H17.5V4.75H9.5Z"
                fill={colour}
            />
        </svg>
    );
}
