import { ApiResponse } from './_api-helpers';
import { MultipartPresignedUrlsResponse, PresignedUrl } from '../models/PresignedUrl';
import manualFetch from './manual-fetch';

export type CompletedPart = {
    PartNo: number;
    ETag: string;
};

export async function getPresignedUrl(file: File, token: string): Promise<ApiResponse<PresignedUrl>> {
    return await manualFetch<PresignedUrl>(`documents/generate-presigned-url/${file.name}`, token);
}

export async function getMultipartPresignedUrls(
    file: File,
    token: string,
): Promise<ApiResponse<MultipartPresignedUrlsResponse>> {
    return await manualFetch<MultipartPresignedUrlsResponse>(
        `documents/generate-multipart-presigned-urls`,
        token,
        'POST',
        JSON.stringify({
            fileName: file.name,
            fileSize: file.size,
        }),
    );
}

export async function uploadFile(
    file: File,
    presignedUrl: string,
    presignedFormFields: any,
): Promise<ApiResponse<boolean>> {
    const formData = new FormData();

    for (const field in presignedFormFields) {
        formData.append(field, presignedFormFields[field]);
    }

    formData.append('file', file);

    const uploadResponse = await fetch(presignedUrl, {
        method: 'POST',
        body: formData,
    });

    return { value: uploadResponse.ok };
}

export async function uploadPart(
    fileChunk: Blob,
    presignedUrl: string,
    partNo: number,
): Promise<ApiResponse<CompletedPart>> {
    const uploadResponse = await fetch(presignedUrl, {
        method: 'PUT',
        body: fileChunk,
    });

    return { value: { ETag: uploadResponse.headers.get('ETag') ?? '', PartNo: partNo } };
}

export async function completeMultipartUpload(
    uploadId: string,
    s3Key: string,
    completedParts: CompletedPart[],
    token: string,
): Promise<ApiResponse<boolean>> {
    return await manualFetch<boolean>(
        `documents/complete-multipart-upload`,
        token,
        'POST',
        JSON.stringify({
            uploadId: uploadId,
            s3Key: s3Key,
            completedParts: completedParts,
        }),
    );
}
