import { theme } from '../../../theme';
import { IconSize } from '../icon-sizes';

export default function LoginIcon({
    colour = theme.textColourInverted,
    size = IconSize.MEDIUM,
}: {
    colour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14 28V24.8889H24.8889V3.11111H14V0H24.8889C25.7444 0 26.4769 0.30463 27.0861 0.913889C27.6954 1.52315 28 2.25556 28 3.11111V24.8889C28 25.7444 27.6954 26.4769 27.0861 27.0861C26.4769 27.6954 25.7444 28 24.8889 28H14ZM10.8889 21.7778L8.75 19.5222L12.7167 15.5556H0V12.4444H12.7167L8.75 8.47778L10.8889 6.22222L18.6667 14L10.8889 21.7778Z"
                fill={colour}
            />
        </svg>
    );
}
