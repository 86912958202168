import BinIcon from 'assets/icons/controls/BinIcon';
import { IconSize } from 'assets/icons/icon-sizes';
import { styled } from 'styled-components';
import SecondaryButton, { SecondaryButtonColour } from './SecondaryButton';

type RemoveButtonProps = {
    title: string;
    aria: string;
    onClick: Function;
    submitting: boolean;
};

export default function RemoveButton({ title, aria, onClick, submitting }: RemoveButtonProps) {
    return (
        <SecondaryButton
            title={title}
            aria={aria}
            onClick={onClick}
            icon={
                <BinIconContainer>
                    <BinIcon size={IconSize.MEDIUM} />
                </BinIconContainer>
            }
            submitting={submitting}
            alternateColour={SecondaryButtonColour.ALTERNATE}
        />
    );
}

const BinIconContainer = styled.div`
    display: flex;
    align-items: center;
`;
