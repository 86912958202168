import * as React from 'react';
import strings from '../../../strings/strings.json';
import { ResourcesInput } from './ResourcesForm';
import ResourcesList from './ResourcesList';
import { LearningResourcesFormModal } from './LearningResourcesFormModal';
import useNeveForm from 'components/forms/NeveForm';
import useUploadRequest from 'hooks/useUploadRequest';
import Spinner from 'components/Spinner';
import { AudioCard, ImageCard, VideoCard } from './FileCard';
import { AudioSelectionComponent, ImageSelectionComponent, VideoSelectionComponent } from './FileSelectionComponent';
import { TextAreaField } from 'components/forms/TextAreaField';
import styled from 'styled-components';
import { EditCourseContext } from 'contextProviders/EditCourseContext';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { Headline5, ButtonRowRight } from 'components/Elements';
import { FileMetaData } from 'components/fileUpload/strategies/strategies';
import { ErrorMessage, FormCard } from 'components/forms/FormComponents';
import TextField from 'components/forms/TextField';
import SaveNotifier from 'components/notifiers/SaveNotifier';
import useModifyRequest from 'hooks/useModifyRequest';
import { validateThreeCharacterLimit } from 'lib/custom-form-validation';
import { logError } from 'lib/debug-helpers';
import { APIError } from 'lib/_api-helpers';
import { Activity } from 'models/Activity';
import { FileDocument } from 'models/FileDocument';
import {
    createGlobalErrorFromAPIError,
    createFieldErrorFromAPIError,
    requiredFieldErrorMessage,
    createErrorMessage,
    fieldErrorCodes,
} from 'shared/error-messages';
import { sizes } from 'theme';
import useFormBlocker from 'components/forms/useFormBlocker';

type TaskInput = {
    title: string;
    description: string;
    durationInMins: number | null;
};

type TaskUpdateFormProps = {
    activity: Activity;
    refetchActivity: () => Promise<void>;
};

export function TaskUpdateForm({ activity: activityProp, refetchActivity }: TaskUpdateFormProps): JSX.Element {
    const context = React.useContext(EditCourseContext);

    const [activity, setActivity] = React.useState<Activity>(activityProp);
    const [globalErrorMessage, setGlobalErrorMessage] = React.useState<string>('');
    const [resources, setResources] = React.useState<ResourcesInput[]>();
    const [modalOpen, setModalOpen] = React.useState(false);
    const [saved, setSaved] = React.useState<boolean>(false);
    const [localVideoToBeUploaded, setLocalVideoToBeUploaded] = React.useState<File | undefined | null>(null);
    const [providerVideoToBeUploaded, setProviderVideoToBeUploaded] = React.useState<FileDocument>();
    const [localVideoMetaData, setLocalVideoMetaData] = React.useState<FileMetaData | undefined>(undefined);
    const [videoCardComponentData, setVideoCardComponentData] = React.useState<FileCardComponentData | null>(
        activityProp.videoDocument
            ? { fileName: activityProp.videoDocument.filename, fileDate: activityProp.videoDocument.dateUpdated }
            : null,
    );
    const [localImageToBeUploaded, setLocalImageToBeUploaded] = React.useState<File | null | undefined>(null);
    const [providerImageToBeUploaded, setProviderImageToBeUploaded] = React.useState<FileDocument>();
    const [localImageMetaData, setLocalImageMetaData] = React.useState<FileMetaData | undefined>();
    const [imageCardComponentData, setImageCardComponentData] = React.useState<FileCardComponentData | null>(
        activityProp.imageDocument
            ? { fileName: activityProp.imageDocument.filename, fileDate: activityProp.imageDocument.dateUpdated }
            : null,
    );

    const [localAudioToBeUploaded, setLocalAudioToBeUploaded] = React.useState<File | null | undefined>(null);
    const [providerAudioToBeUploaded, setProviderAudioToBeUploaded] = React.useState<FileDocument>();
    const [audioCardComponentData, setAudioCardComponentData] = React.useState<FileCardComponentData | null>(
        activityProp.audioDocument
            ? { fileName: activityProp.audioDocument.filename, fileDate: activityProp.audioDocument.dateUpdated }
            : null,
    );

    const selectVideoButtonRef = React.useRef<HTMLButtonElement>(null);
    const selectImageButtonRef = React.useRef<HTMLButtonElement>(null);
    const selectAudioButtonRef = React.useRef<HTMLButtonElement>(null);

    React.useEffect(() => {
        if (activityProp) {
            setActivity(activityProp);
            setResources(activityProp.resources);
        }
    }, [activityProp]);

    React.useEffect(() => {
        let data: FileCardComponentData | null;

        if (localVideoToBeUploaded) {
            data = {
                fileName: localVideoToBeUploaded.name,
                fileDate: new Date(),
            };
        } else if (providerVideoToBeUploaded) {
            data = {
                fileName: providerVideoToBeUploaded.filename,
                fileDate: providerVideoToBeUploaded.dateUpdated,
            };
        } else if (activity.videoDocument) {
            data = {
                fileName: activity.videoDocument.filename,
                fileDate: activity.videoDocument.dateUpdated,
            };
        } else {
            data = null;
        }

        setVideoCardComponentData(data);
    }, [activity, localVideoToBeUploaded, providerVideoToBeUploaded]);

    React.useEffect(() => {
        let data: FileCardComponentData | null;

        if (localImageToBeUploaded) {
            data = {
                fileName: localImageToBeUploaded.name,
                fileDate: new Date(),
            };
        } else if (providerImageToBeUploaded) {
            data = {
                fileName: providerImageToBeUploaded.filename,
                fileDate: providerImageToBeUploaded.dateUpdated,
            };
        } else if (activity.imageDocument) {
            data = {
                fileName: activity.imageDocument.filename,
                fileDate: activity.imageDocument.dateUpdated,
            };
        } else {
            data = null;
        }

        if (
            localVideoToBeUploaded ||
            providerVideoToBeUploaded ||
            localImageToBeUploaded ||
            providerImageToBeUploaded
        ) {
            setSaved(false);
        }

        setImageCardComponentData(data);
    }, [
        activity,
        localVideoToBeUploaded,
        providerVideoToBeUploaded,
        localImageToBeUploaded,
        providerImageToBeUploaded,
    ]);

    React.useEffect(() => {
        let data: FileCardComponentData | null;

        if (localAudioToBeUploaded) {
            data = {
                fileName: localAudioToBeUploaded.name,
                fileDate: new Date(),
            };
        } else if (providerAudioToBeUploaded) {
            data = {
                fileName: providerAudioToBeUploaded.filename,
                fileDate: providerAudioToBeUploaded.dateUpdated,
            };
        } else if (activity.audioDocument) {
            data = {
                fileName: activity.audioDocument.filename,
                fileDate: activity.audioDocument.dateUpdated,
            };
        } else {
            data = null;
        }

        if (localAudioToBeUploaded || providerAudioToBeUploaded) {
            setSaved(false);
        }

        setAudioCardComponentData(data);
    }, [activity, localAudioToBeUploaded, providerAudioToBeUploaded]);

    const { modifyData: updateTask, loading: updatingTask } = useModifyRequest(`activities/${activity.id}`, 'PUT');

    const {
        register,
        handleSubmit,
        setError,
        watch,
        formState: { errors },
        reset,
    } = useNeveForm<TaskInput>({
        title: activity?.title ?? ' ',
        description: activity?.content ?? '',
        durationInMins: activity?.durationInMins ?? null,
    });

    const [formDirty, setFormDirty] = React.useState<boolean>(false);

    useFormBlocker(formDirty, strings.courseEditorPage.unsavedChangesPrompt);

    const title = watch('title');
    const description = watch('description');
    const durationInMins = watch('durationInMins');

    React.useEffect(() => {
        const titleChanged = title !== activityProp.title;
        const descriptionChanged = description !== activityProp.content;
        const durationInMinsChanged = durationInMins != activityProp.durationInMins; // eslint-disable-line eqeqeq
        const videoChanged = videoCardComponentData?.fileName !== activityProp.videoDocument?.filename;
        const imageChanged = imageCardComponentData?.fileName !== activityProp.imageDocument?.filename;

        function arraysEqual<T>(arr1: T[], arr2: T[]) {
            if (arr1 === arr2) return true;
            if (arr1 == null || arr2 == null) return false;
            if (arr1.length !== arr2.length) return false;

            for (let i = 0; i < arr1.length; ++i) {
                if (arr1[i] !== arr2[i]) return false;
            }
            return true;
        }

        const resourcesChanged = resources != undefined && !arraysEqual(resources, activityProp.resources); // eslint-disable-line eqeqeq

        const changed =
            titleChanged ||
            descriptionChanged ||
            durationInMinsChanged ||
            videoChanged ||
            imageChanged ||
            resourcesChanged;

        setFormDirty(changed);
    }, [title, description, durationInMins, videoCardComponentData, imageCardComponentData, resources]); // eslint-disable-line react-hooks/exhaustive-deps

    const { upload, uploading } = useUploadRequest();

    async function addResource(newResource: ResourcesInput) {
        setSaved(false);
        setResources([...(resources ?? []), newResource]);
    }

    function deleteResource(id: string | null, title: string, url: string, deleted: boolean): void {
        setSaved(false);

        if (id) {
            setResources(
                (resources ?? []).map((resource) => {
                    if (resource.id === id && deleted) {
                        return { title: resource.title, id: resource.id, url: resource.url, deleted };
                    } else {
                        return resource;
                    }
                }),
            );
        } else {
            setResources((resources ?? []).filter((resource) => !(resource.title === title && resource.url === url)));
        }

        if (saved) {
            setSaved(false);
        }
    }

    type SubmitFile = {
        filePath: string | null;
        fileName: string | null;
        id: string | null;
    };

    interface SubmitImageFile extends SubmitFile {
        imageAltText: string | null;
    }

    interface SubmitVideoFile extends SubmitFile {
        videoTranscript: string | null;
    }

    interface SubmitAudioFile extends SubmitFile {}

    async function uploadVideo(): Promise<SubmitVideoFile | null> {
        if (localVideoToBeUploaded) {
            const uploadResponse = await upload(localVideoToBeUploaded);

            if (uploadResponse.errors && uploadResponse.errors.length > 0) {
                logError(uploadResponse.errors);
                updateErrors(uploadResponse.errors);
                throw new Error('Video upload failed');
            }

            if (!uploadResponse.value) {
                logError('Get presigned URL failed');
                updateErrors([{ field: 'global', code: 'get presigned URL failed' }]);
                throw new Error('Video upload failed');
            }

            return {
                id: null,
                filePath: uploadResponse.value.s3Key,
                fileName: uploadResponse.value.filename,
                videoTranscript: localVideoMetaData?.videoTranscript ?? null,
            };
        } else if (providerVideoToBeUploaded) {
            return {
                id: providerVideoToBeUploaded.id,
                filePath: null,
                fileName: null,
                videoTranscript: null,
            };
        } else if (activity.videoDocument) {
            return {
                id: activity.videoDocument.id,
                filePath: null,
                fileName: null,
                videoTranscript: null,
            };
        } else {
            return null;
        }
    }

    async function uploadImage(): Promise<SubmitImageFile | null> {
        if (localImageToBeUploaded) {
            const uploadResponse = await upload(localImageToBeUploaded);

            if (uploadResponse.errors && uploadResponse.errors.length > 0) {
                logError(uploadResponse.errors);
                updateErrors(uploadResponse.errors);
                throw new Error('Image upload failed');
            }

            if (!uploadResponse.value) {
                logError('Get presigned URL failed');
                updateErrors([{ field: 'global', code: 'get presigned URL failed' }]);
                throw new Error('Image upload failed');
            }

            return {
                filePath: uploadResponse.value.s3Key,
                fileName: uploadResponse.value.filename,
                id: null,
                imageAltText: localImageMetaData?.imageAltText ?? null,
            };
        } else if (providerImageToBeUploaded) {
            return {
                filePath: null,
                fileName: null,
                id: providerImageToBeUploaded.id,
                imageAltText: null,
            };
        } else if (activity.imageDocument) {
            return {
                filePath: null,
                fileName: null,
                id: activity.imageDocument.id,
                imageAltText: null,
            };
        } else {
            return null;
        }
    }

    async function uploadAudio(): Promise<SubmitAudioFile | null> {
        if (localAudioToBeUploaded) {
            const uploadResponse = await upload(localAudioToBeUploaded);

            if (uploadResponse.errors && uploadResponse.errors.length > 0) {
                logError(uploadResponse.errors);
                updateErrors(uploadResponse.errors);
                throw new Error('Audio upload failed');
            }

            if (!uploadResponse.value) {
                logError('Get presigned URL failed');
                updateErrors([{ field: 'global', code: 'get presigned URL failed' }]);
                throw new Error('Audio upload failed');
            }

            return {
                filePath: uploadResponse.value.s3Key,
                fileName: uploadResponse.value.filename,
                id: null,
            };
        } else if (providerAudioToBeUploaded) {
            return {
                filePath: null,
                fileName: null,
                id: providerAudioToBeUploaded.id,
            };
        } else if (activity.audioDocument) {
            return {
                filePath: null,
                fileName: null,
                id: activity.audioDocument.id,
            };
        } else {
            return null;
        }
    }

    async function onSaveActivity(formData: TaskInput) {
        setSaved(false);

        let videoData: SubmitVideoFile | null = null;
        try {
            videoData = await uploadVideo();
        } catch (error: any) {
            logError(error);
            return;
        }

        let imageData: SubmitImageFile | null = null;
        try {
            imageData = await uploadImage();
        } catch (error: any) {
            logError(error);
            return;
        }

        let audioData: SubmitAudioFile | null = null;
        try {
            audioData = await uploadAudio();
        } catch (error: any) {
            logError(error);
            return;
        }

        const response = await updateTask<any, Activity>({
            title: formData.title,
            content: formData.description,
            durationInMins: formData.durationInMins ? +formData.durationInMins : null,
            resources,
            video: videoData,
            image: imageData,
            audio: audioData,
        });
        const { errors } = response;

        if (errors) {
            logError(errors);
            updateErrors(errors);
            return;
        }

        setLocalVideoToBeUploaded(undefined);
        setProviderVideoToBeUploaded(undefined);
        setLocalImageToBeUploaded(undefined);
        setProviderImageToBeUploaded(undefined);
        setLocalAudioToBeUploaded(undefined);
        setProviderAudioToBeUploaded(undefined);
        setGlobalErrorMessage('');
        setSaved(true);
        reset(formData);
        await refetchActivity();
        await context.refetchCourse();
    }

    function updateErrors(apiErrors: APIError[]): void {
        apiErrors.forEach((apiError) => {
            const { field, code } = apiError;
            if (field === 'global') {
                return setGlobalErrorMessage(createGlobalErrorFromAPIError(code));
            }
            setError(field as keyof TaskInput, createFieldErrorFromAPIError(field, code));
        });
    }

    const removeVideo = () => {
        setSaved(false);
        setLocalVideoToBeUploaded(undefined);
        setProviderVideoToBeUploaded(undefined);
        setVideoCardComponentData(null);
        setActivity({ ...activity, videoDocument: null });
    };

    const removeImage = () => {
        setSaved(false);
        setLocalImageToBeUploaded(undefined);
        setProviderImageToBeUploaded(undefined);
        setImageCardComponentData(null);
        setActivity({ ...activity, imageDocument: null });
    };

    const removeAudio = () => {
        setSaved(false);
        setLocalAudioToBeUploaded(undefined);
        setProviderAudioToBeUploaded(undefined);
        setAudioCardComponentData(null);
        setActivity({ ...activity, audioDocument: null });
    };

    type FileCardComponentData = {
        fileName: string;
        fileDate: Date;
    };

    return (
        <>
            <FormCardWithGaps onSubmit={handleSubmit(onSaveActivity)} id="activityForm">
                <div>
                    <TextField
                        fieldName="activityTitle"
                        labelText={strings.activityForm.titleInputLabel}
                        inputAria={strings.activityForm.titleInputAria}
                        inputProps={register('title', {
                            required: {
                                value: true,
                                message: requiredFieldErrorMessage(strings.activityForm.titleInputLabel),
                            },
                            maxLength: {
                                value: 120,
                                message: createErrorMessage(
                                    strings.activityForm.titleInputLabel,
                                    fieldErrorCodes.maxLength,
                                ),
                            },
                            onChange: () => saved && setSaved(false),
                        })}
                        errorMessage={errors.title?.message}
                        required
                    />
                    <TextAreaField
                        maxLength={2000}
                        charactersLeft={2000 - watch('description').length}
                        fieldName={strings.activityForm.descriptionLabel}
                        aria={strings.activityForm.descriptionAria}
                        errorMessage={errors.description?.message}
                        inputProps={register('description', {
                            maxLength: {
                                value: 2000,
                                message: createErrorMessage(
                                    strings.activityForm.descriptionLabel,
                                    fieldErrorCodes.maxLength,
                                ),
                            },
                        })}
                    />
                    <TextField
                        fieldName="activityTaskDuration"
                        labelText={strings.activityForm.durationInputLabel}
                        inputAria={strings.activityForm.durationInputAria}
                        inputProps={register('durationInMins', {
                            validate: {
                                validateThreeCharacterLimit,
                            },
                            onChange: () => saved && setSaved(false),
                        })}
                        errorMessage={errors.durationInMins?.message}
                        inputType="number"
                        width="8rem"
                        flavourText={strings.activityForm.durationInputMins}
                    />
                </div>
                {globalErrorMessage && <ErrorMessage id="errorMessage">{globalErrorMessage}</ErrorMessage>}
                <ResourceGroup>
                    <Headline5>{strings.learningMaterialsForm.addVideoTitle}</Headline5>
                    {videoCardComponentData && (
                        <VideoCard
                            fileName={videoCardComponentData.fileName}
                            uploadDate={videoCardComponentData.fileDate}
                            removeFile={removeVideo}
                            disabled={uploading || updatingTask}
                        />
                    )}

                    <VideoSelectionComponent
                        setFileFromLocal={(newFile) => {
                            setLocalVideoToBeUploaded(newFile);
                        }}
                        setFileFromProvider={setProviderVideoToBeUploaded}
                        setFileFromLocalMetaData={(newMetaData) => {
                            setLocalVideoMetaData(newMetaData);
                        }}
                        disableButton={uploading || updatingTask}
                        selectFileButtonRef={selectVideoButtonRef}
                    />
                </ResourceGroup>
                <ResourceGroup>
                    <Headline5>{strings.learningMaterialsForm.addImageTitle}</Headline5>
                    {imageCardComponentData && (
                        <ImageCard
                            fileName={imageCardComponentData.fileName}
                            uploadDate={imageCardComponentData.fileDate}
                            removeFile={removeImage}
                            disabled={uploading || updatingTask}
                        />
                    )}
                    <ImageSelectionComponent
                        setFileFromLocal={setLocalImageToBeUploaded}
                        setFileFromProvider={setProviderImageToBeUploaded}
                        setFileFromLocalMetaData={setLocalImageMetaData}
                        disableButton={uploading || updatingTask}
                        selectFileButtonRef={selectImageButtonRef}
                    />
                </ResourceGroup>
                <ResourceGroup>
                    <Headline5>{strings.learningMaterialsForm.addAudioTitle}</Headline5>
                    {audioCardComponentData && (
                        <AudioCard
                            fileName={audioCardComponentData.fileName}
                            uploadDate={audioCardComponentData.fileDate}
                            removeFile={removeAudio}
                            disabled={uploading || updatingTask}
                        />
                    )}
                    <AudioSelectionComponent
                        setFileFromLocal={setLocalAudioToBeUploaded}
                        setFileFromProvider={setProviderAudioToBeUploaded}
                        setFileFromLocalMetaData={() => {}}
                        disableButton={uploading || updatingTask}
                        selectFileButtonRef={selectAudioButtonRef}
                    />
                </ResourceGroup>
                <ResourceGroup>
                    <Headline5>{strings.resourcesList.title}</Headline5>
                    <ResourcesList
                        resources={resources ?? []}
                        toggleModalOpen={() => {
                            setModalOpen(!modalOpen);
                            return saved && setSaved(false);
                        }}
                        deleteResource={deleteResource}
                        disabled={uploading || updatingTask}
                    />
                </ResourceGroup>
                <ButtonRowRight>
                    <PrimaryButton
                        type="submit"
                        title={!uploading ? strings.activityForm.submitButton : strings.activityForm.uploadingFile}
                        aria={strings.activityForm.submitButtonAria}
                        disabled={uploading || updatingTask}
                        icon={uploading || updatingTask ? <Spinner /> : undefined}
                    />
                </ButtonRowRight>
                {saved && !formDirty && <SaveNotifier />}
            </FormCardWithGaps>
            {modalOpen && (
                <LearningResourcesFormModal closeModal={() => setModalOpen(false)} addResource={addResource} />
            )}
        </>
    );
}

const ResourceGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingSm};
`;

const FormCardWithGaps = styled(FormCard)`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingLg};
    width: 100%;
`;
