import { useNavigate } from 'react-router-dom';
import React from 'react';
import useNeveForm from '../../components/forms/NeveForm';
import strings from '../../strings/strings.json';
import SubpageHeader, { BackButtonDetails } from '../../components/sections/SubpageHeader';
import MainSection from '../../components/sections/MainSection';
import { FormCardWithGaps } from '../../components/forms/FormComponents';
import {
    createErrorMessage,
    createGlobalErrorFromAPIError,
    globalErrorCodes,
    requiredFieldErrorMessage,
} from '../../shared/error-messages';
import TextField from '../../components/forms/TextField';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { ButtonRowRight } from '../../components/Elements';
import useModifyRequest from '../../hooks/useModifyRequest';
import { logError } from '../../lib/debug-helpers';
import Notification, { NotificationType } from '../../components/notifiers/Notification';
import styled from 'styled-components';

type AddUserFormInput = {
    firstName: string;
    lastName: string;
    emailAddress: string;
};

export default function AddUserPage(): JSX.Element {
    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = React.useState<string>('');

    const { modifyData: addUser, loading: saving } = useModifyRequest(`admin/users`, 'POST');

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
    } = useNeveForm<AddUserFormInput>({
        firstName: '',
        lastName: '',
        emailAddress: '',
    });

    const backButtonDetails: BackButtonDetails = {
        title: strings.addUserPage.backButton.title,
        aria: strings.addUserPage.backButton.aria,
        href: '/admin/dashboard',
    };

    async function onSubmit(formData: AddUserFormInput) {
        const newUserResponse = await addUser({
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.emailAddress,
        });

        if (newUserResponse.errors) {
            const apiErrorMessage = createGlobalErrorFromAPIError(newUserResponse.errors[0].code);

            logError(apiErrorMessage);

            if (apiErrorMessage === globalErrorCodes.unknownError) {
                setErrorMessage(strings.addUserPage.genericError);
            } else {
                setErrorMessage(apiErrorMessage);
            }

            return;
        }

        navigate('/admin/dashboard', { state: { addUserSuccess: true } });
    }

    return (
        <>
            <SubpageHeader
                title={strings.addUserPage.heading}
                shadedBackground
                backButtonDetails={backButtonDetails}
                pageOverlap
            />
            <MainSection>
                <NarrowFormCard onSubmit={handleSubmit(onSubmit)}>
                    <TextField
                        fieldName={'firstName'}
                        labelText={strings.addUserPage.fields.firstName.title}
                        inputAria={strings.addUserPage.fields.firstName.aria}
                        inputProps={register('firstName', {
                            required: {
                                value: true,
                                message: requiredFieldErrorMessage(strings.addUserPage.fields.firstName.title),
                            },
                        })}
                        errorMessage={errors.firstName?.message}
                        required
                        width={'480px'}
                    />
                    <TextField
                        fieldName={'lastName'}
                        labelText={strings.addUserPage.fields.lastName.title}
                        inputAria={strings.addUserPage.fields.lastName.aria}
                        inputProps={register('lastName', {
                            required: {
                                value: true,
                                message: requiredFieldErrorMessage(strings.addUserPage.fields.lastName.title),
                            },
                        })}
                        errorMessage={errors.lastName?.message}
                        required
                        width={'480px'}
                    />
                    <TextField
                        fieldName={'emailAddress'}
                        labelText={strings.addUserPage.fields.emailAddress.title}
                        inputAria={strings.addUserPage.fields.emailAddress.aria}
                        inputProps={register('emailAddress', {
                            required: {
                                value: true,
                                message: requiredFieldErrorMessage(strings.addUserPage.fields.emailAddress.title),
                            },
                            pattern: {
                                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                message: createErrorMessage(
                                    strings.addUserPage.fields.emailAddress.title,
                                    strings.errorMessages.fieldErrorInvalidEmail,
                                ),
                            },
                        })}
                        errorMessage={errors.emailAddress?.message}
                        required
                        width={'480px'}
                    />
                    <ButtonRowRight>
                        <PrimaryButton
                            type="submit"
                            title={strings.addUserPage.submitButton.title}
                            aria={strings.addUserPage.submitButton.aria}
                            disabled={saving}
                        />
                    </ButtonRowRight>
                    {!isDirty && errorMessage && (
                        <>
                            <Notification notificationType={NotificationType.DANGER} title={errorMessage} />
                        </>
                    )}
                </NarrowFormCard>
            </MainSection>
        </>
    );
}

const NarrowFormCard = styled(FormCardWithGaps)`
    max-width: 480px;
`;
