import { theme } from 'theme';
import { IconSize } from '../icon-sizes';

export default function HearingIcon({ size = IconSize.MEDIUM, colour = theme.textColour }): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask
                id="mask0_113_1646"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_113_1646)">
                <path
                    d="M17 22.0002C15.9667 22.0002 15.1208 21.7419 14.4625 21.2252C13.8042 20.7085 13.3 19.9502 12.95 18.9502C12.6667 18.1169 12.3958 17.5335 12.1375 17.2002C11.8792 16.8669 11.2833 16.3335 10.35 15.6002C9.31667 14.7669 8.5 13.8252 7.9 12.7752C7.3 11.7252 7 10.4669 7 9.0002C7 7.01686 7.67083 5.35436 9.0125 4.0127C10.3542 2.67103 12.0167 2.0002 14 2.0002C15.9833 2.0002 17.6458 2.67103 18.9875 4.0127C20.3292 5.35436 21 7.01686 21 9.0002H19C19 7.58353 18.5208 6.39603 17.5625 5.4377C16.6042 4.47936 15.4167 4.0002 14 4.0002C12.5833 4.0002 11.3958 4.47936 10.4375 5.4377C9.47917 6.39603 9 7.58353 9 9.0002C9 10.1335 9.225 11.1002 9.675 11.9002C10.125 12.7002 10.7667 13.4169 11.6 14.0502C12.4667 14.6835 13.1417 15.3002 13.625 15.9002C14.1083 16.5002 14.4667 17.1502 14.7 17.8502C14.9333 18.5835 15.2125 19.1252 15.5375 19.4752C15.8625 19.8252 16.35 20.0002 17 20.0002C17.55 20.0002 18.0208 19.8044 18.4125 19.4127C18.8042 19.021 19 18.5502 19 18.0002H21C21 19.1002 20.6083 20.0419 19.825 20.8252C19.0417 21.6085 18.1 22.0002 17 22.0002ZM6.2 16.7502C5.21667 15.7502 4.4375 14.5877 3.8625 13.2627C3.2875 11.9377 3 10.5169 3 9.0002C3 7.46686 3.2875 6.03353 3.8625 4.7002C4.4375 3.36686 5.21667 2.2002 6.2 1.2002L7.65 2.6002C6.81667 3.43353 6.16667 4.39603 5.7 5.4877C5.23333 6.57936 5 7.7502 5 9.0002C5 10.2335 5.23333 11.3919 5.7 12.4752C6.16667 13.5585 6.81667 14.5169 7.65 15.3502L6.2 16.7502ZM14 11.5002C13.3 11.5002 12.7083 11.2544 12.225 10.7627C11.7417 10.271 11.5 9.68353 11.5 9.0002C11.5 8.3002 11.7417 7.70853 12.225 7.2252C12.7083 6.74186 13.3 6.5002 14 6.5002C14.7 6.5002 15.2917 6.74186 15.775 7.2252C16.2583 7.70853 16.5 8.3002 16.5 9.0002C16.5 9.68353 16.2583 10.271 15.775 10.7627C15.2917 11.2544 14.7 11.5002 14 11.5002Z"
                    fill={colour}
                />
            </g>
        </svg>
    );
}
