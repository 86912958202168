import { StepperFormProps } from '../../../components/stepper/stepperTypes';
import { NewSchedule } from '../ScheduleCoursePage';
import useNeveForm from '../../../components/forms/NeveForm';
import strings from '../../../strings/strings.json';
import { Headline4 } from '../../../components/Elements';
import RadioFields, { RadioFieldOption } from '../../../components/forms/RadioFields';
import { ErrorNotifierSlim } from "../../../components/notifiers/ErrorNotifierSlim";
import StepperButtonRow from '../../../components/stepper/StepperButtonRow';
import { FormWithGaps } from './commonElements';

enum MeetingProviderOption {
    useZoomIntegration = 0,
    useOwnProvider = 1,
}

type SelectMeetingProviderFormData = {
    meetingProvider: MeetingProviderOption | undefined;
};
export default function SelectMeetingProviderForm(props: StepperFormProps<NewSchedule>): JSX.Element {
    const { next, previous, newObject, setNewObject } = props;

    const {
        handleSubmit,
        register,
        reset,
        formState: { errors },
        watch,
        setError,
    } = useNeveForm<SelectMeetingProviderFormData>({
        meetingProvider: newObject.meetingProvider,
    });

    const meetingProviderOptions: RadioFieldOption[] = [
        {
            value: MeetingProviderOption.useZoomIntegration,
            title: strings.scheduleCourseStepper.selectMeetingProviderForm.meetingProviderOptions.zoomIntegration
                .title,
            description:
                strings.scheduleCourseStepper.selectMeetingProviderForm.meetingProviderOptions.zoomIntegration
                    .description,
            aria: strings.scheduleCourseStepper.selectMeetingProviderForm.meetingProviderOptions.zoomIntegration.aria,
            inputProps: register('meetingProvider'),
        },
        {
            value: MeetingProviderOption.useOwnProvider,
            title: strings.scheduleCourseStepper.selectMeetingProviderForm.meetingProviderOptions.other.title,
            description:
                strings.scheduleCourseStepper.selectMeetingProviderForm.meetingProviderOptions.other.description,
            aria: strings.scheduleCourseStepper.selectMeetingProviderForm.meetingProviderOptions.other.aria,
            inputProps: register('meetingProvider'),
        },
    ];

    const currentMeetingProvider = watch('meetingProvider');

    async function onSubmit(formData: SelectMeetingProviderFormData) {
        if (typeof formData.meetingProvider === 'undefined' || formData.meetingProvider === null) {
            setError('meetingProvider', {
                type: 'manual',
                message: strings.scheduleCourseStepper.selectMeetingProviderForm.meetingProviderOptionError,
            });

            return;
        }

        setNewObject({
            ...newObject,
            meetingProvider: formData.meetingProvider,
        });

        reset(formData);

        next();
    }

    async function onPrevious() {
        setNewObject({
            ...newObject,
            meetingProvider: currentMeetingProvider,
        });

        reset();

        previous();
    }

    function onBlurSelectMeetingProvider() {
        if (!currentMeetingProvider) {
            setError('meetingProvider', {
                type: 'manual',
                message: strings.scheduleCourseStepper.selectMeetingProviderForm.meetingProviderOptionError,
            });
        }
    }

    return (
        <FormWithGaps onSubmit={handleSubmit(onSubmit)}>
            <Headline4>{strings.scheduleCourseStepper.selectMeetingProviderForm.title}</Headline4>
            <RadioFields options={meetingProviderOptions} maxWidth={'600px'} onBlur={onBlurSelectMeetingProvider} />
            {errors.meetingProvider && errors.meetingProvider.message && (
                <ErrorNotifierSlim description={errors.meetingProvider.message} />
            )}
            <StepperButtonRow
                submitButtonTitle={strings.scheduleCourseStepper.selectMeetingProviderForm.submitButton}
                submitButtonAria={strings.scheduleCourseStepper.selectMeetingProviderForm.submitButtonAria}
                previousButtonFunction={onPrevious}
            />
        </FormWithGaps>
    );
}
