import { ErrorNotifier } from 'components/notifiers/ErrorNotifier';
import styled from 'styled-components';
import { breakpoints, sizes } from 'theme';

export const Page = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    height: calc(100% - 80px);
    @media (max-width: ${breakpoints.sm}) {
        height: calc(100% - 85px);
    }
`;

export const ContentColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${sizes.spacingLg};
    width: 780px;
    margin-top: ${sizes.spacingLg};
    margin-bottom: ${sizes.spacingMd};
    padding: 0 ${sizes.spacingMd};

    @media (max-width: ${breakpoints.md}) {
        padding: 0 ${sizes.spacingLg};
        width: 100%;
    }

    @media (max-width: ${breakpoints.sm}) {
        padding: 0 ${sizes.spacingMd};
    }
`;

type LearnerCourseContentErrorProps = {
    title: string;
    description: string;
};

export function LearnerCourseContentError({ title, description }: LearnerCourseContentErrorProps): JSX.Element {
    return (
        <Page>
            <ContentColumn>
                <ErrorNotifier title={title} description={description} />
            </ContentColumn>
        </Page>
    );
}
