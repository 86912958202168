import { theme } from 'theme';
import { IconSize } from './icon-sizes';

export default function BoltIcon({
    colour = theme.textColour,
    size = IconSize.XLARGE,
}: {
    colour?: string;
    boltColour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.748 21.9916L19.0011 14.5H14.1677L15.0438 7.64267L9.45525 15.7083H13.6542L12.748 21.9916ZM9.66671 26.5833L10.875 18.125H4.83337L15.7084 2.41663H18.125L16.9167 12.0833H24.1667L12.0834 26.5833H9.66671Z"
                fill={colour}
            />
        </svg>
    );
}

export function BoltWithCircleIcon({
    colour = theme.aiLogoColour,
    boltColour = theme.textColourInverted,
    size = IconSize.XLARGE,
}: {
    colour?: string;
    boltColour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="24" cy="24" r="24" fill={colour} />
            <path
                d="M19.7083 34.0837L23.5417 26.8962L15.875 25.9378L27.375 14.917H29.2917L25.4583 22.1045L33.125 23.0628L21.625 34.0837H19.7083ZM25.0271 28.1659L28.8844 24.4764L22.4396 23.6618L23.949 20.8587L20.1156 24.5482L26.5365 25.3389L25.0271 28.1659Z"
                fill={boltColour}
            />
        </svg>
    );
}
