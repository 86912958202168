import { Activity } from './Activity';

export enum LocationType {
    ONLINE = 'ONLINE',
    IN_PERSON = 'IN_PERSON',
}

export type ActivityMeeting = {
    id: string;
    activityId: string;
    meetingDatetime: Date;
    locationType: LocationType;
    joinLink?: string;
    location?: string;
    activity: Activity;
    createError?: string;
    attendees: Attendee[];
};

export type Attendee = {
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
    imagePath: string;
    attended?: boolean;
};
