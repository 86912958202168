import { BodyLarge, BodySmall } from '../../../../../components/Elements';
import strings from '../../../../../strings/strings.json';
import PrimaryButton from '../../../../../components/buttons/PrimaryButton';
import styled from 'styled-components';

type StartQuestionnaireCardProps = {
    description: string;
    totalQuestions: number;
    startQuestionnaire: () => void;
};
export default function StartQuestionnaireCard({
    description,
    totalQuestions,
    startQuestionnaire,
}: StartQuestionnaireCardProps): JSX.Element {
    return (
        <>
            <BodyLarge>{description}</BodyLarge>
            <BodySmall>
                {totalQuestions} {strings.questionnairePage.questionsNumberLabel}
            </BodySmall>
            <StartButtonContainer>
                <PrimaryButton
                    title={strings.questionnairePage.startQuestionnaireButton}
                    aria={strings.questionnairePage.startQuestionnaireButtonAria}
                    onClick={startQuestionnaire}
                />
            </StartButtonContainer>
        </>
    );
}

const StartButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;
