import { BodyRegular, Headline2, Headline4 } from 'components/Elements';
import styled from 'styled-components';
import { sizes } from '../../../../theme';

type TitleAndDescriptionProps = {
    title: string;
    description?: string;
    isHeadlineTwo?: boolean;
};

export default function TitleAndDescription({
    title,
    description,
    isHeadlineTwo,
}: TitleAndDescriptionProps): JSX.Element {
    return (
        <Container>
            {isHeadlineTwo ? <Headline2>{title}</Headline2> : <Headline4>{title}</Headline4>}
            <CourseDescriptionText>{description}</CourseDescriptionText>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingSm};
`;

const CourseDescriptionText = styled(BodyRegular)`
    white-space: pre-wrap;
`;
