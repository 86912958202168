import strings from '../../../strings/strings.json';
import { BodySmall } from '../../../components/Elements';
import styled from 'styled-components';
import { breakpoints, sizes, theme } from '../../../theme';

export function formatDate(dateString: string): string {
    return new Date(dateString).toLocaleDateString();
}

export default function CourseCardDateSection({
    startDate,
    endDate,
    cancelled,
}: {
    startDate: string;
    endDate: string;
    cancelled: boolean;
}): JSX.Element {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const endDateFormatted = new Date(endDate);
    endDateFormatted.setHours(0, 0, 0, 0);

    if (cancelled) return <BodySmallRed $bold>{strings.learnerCourseCard.cancelled}</BodySmallRed>;
    else if (endDateFormatted < today)
        return (
            <DateContainer>
                <DateText>
                    <BodySmallRed $bold>{strings.learnerCourseCard.courseEndedOn}</BodySmallRed> {formatDate(endDate)}
                </DateText>
            </DateContainer>
        );
    else
        return (
            <DateContainer>
                <DateText>
                    <BodySmall $bold>{strings.learnerCourseCard.startDate}</BodySmall> {formatDate(startDate)}
                </DateText>
                <DateText>
                    <BodySmall $bold>{strings.learnerCourseCard.endDate}</BodySmall> {formatDate(endDate)}
                </DateText>
            </DateContainer>
        );
}

const BodySmallRed = styled(BodySmall)`
    color: ${theme.errorColour};
`;

const DateContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: ${sizes.spacingRg};
    @media (max-width: ${breakpoints.md}) {
        flex-direction: column;
        justify-content: unset;
        align-items: flex-start;
        gap: ${sizes.spacingSm};
        min-height: unset;
    }
`;

const DateText = styled(BodySmall)`
    display: flex;
    gap: ${sizes.spacingXs};
`;
