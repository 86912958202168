import styled from 'styled-components';
import { BodyRegular } from '../../../components/Elements';
import strings from '../../../strings/strings.json';
import * as React from 'react';
import { AuthContext } from '../../../contextProviders/AuthContext';
import { breakpoints, sizes } from '../../../theme';
import { RoleType } from 'models/User';
import { TrainerBioForm } from './TrainerBioForm';
import { TrainerBioReadonly } from './TrainerBioReadonly';

type MyDetailsSectionProps = {
    bio: string;
    refetchUserDetails: () => Promise<void>;
    showSavedBio: boolean;
    setShowSavedBio: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function MyDetailsSection({
    bio,
    refetchUserDetails,
    showSavedBio,
    setShowSavedBio,
}: MyDetailsSectionProps): JSX.Element {
    const { userTokenDetails, userData } = React.useContext(AuthContext);
    const [showBioForm, setShowBioForm] = React.useState<boolean>(false);

    const bioSection =
        userData.activeRole === RoleType.TRAINER ? (
            showBioForm ? (
                <TrainerBioForm
                    bio={bio}
                    refetchUserDetails={refetchUserDetails}
                    setShowSavedBio={setShowSavedBio}
                    setShowBioForm={setShowBioForm}
                />
            ) : (
                <TrainerBioReadonly bio={bio} showEditableForm={setShowBioForm} showSavedBio={showSavedBio} />
            )
        ) : (
            <></>
        );

    return (
        <DetailsSection id="detailsSection">
            <DetailsContainer>
                <DetailBlock>
                    <BodyRegular $bold>{strings.profilePage.nameLabel}</BodyRegular>
                    <BodyRegular>
                        {userTokenDetails.given_name}&nbsp;{userTokenDetails.family_name}
                    </BodyRegular>
                </DetailBlock>
                <DetailBlock>
                    <BodyRegular $bold>{strings.profilePage.emailLabel}</BodyRegular>
                    <BodyRegular>{userTokenDetails.email}</BodyRegular>
                </DetailBlock>
                <DetailBlock>
                    <BodyRegular $bold>{strings.profilePage.organisation}</BodyRegular>
                    <BodyRegular>{userData.organisation?.name ?? strings.profilePage.noOrganisationLabel}</BodyRegular>
                </DetailBlock>
            </DetailsContainer>
            {bioSection}
        </DetailsSection>
    );
}

const DetailsSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingRg};
`;

const DetailsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: ${sizes.spacingXl};
    @media (max-width: ${breakpoints.md}) {
        flex-direction: column;
        align-items: flex-start;
        gap: ${sizes.spacingLg};
    }
`;

const DetailBlock = styled.div`
    display: flex;
    flex-direction: column;
`;
