import { theme } from 'theme';
import { IconSize } from '../icon-sizes';

export default function SplitIcon({ size = IconSize.LARGE, colour = theme.textColour }): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.625 23.625C5.00625 23.625 4.47656 23.4047 4.03594 22.9641C3.59531 22.5234 3.375 21.9937 3.375 21.375V16.875C3.375 16.2562 3.59531 15.7266 4.03594 15.2859C4.47656 14.8453 5.00625 14.625 5.625 14.625H21.375C21.9937 14.625 22.5234 14.8453 22.9641 15.2859C23.4047 15.7266 23.625 16.2562 23.625 16.875H5.625V21.375H16.875V23.625H5.625ZM5.625 12.375C5.00625 12.375 4.47656 12.1547 4.03594 11.7141C3.59531 11.2734 3.375 10.7437 3.375 10.125V5.625C3.375 5.00625 3.59531 4.47656 4.03594 4.03594C4.47656 3.59531 5.00625 3.375 5.625 3.375H21.375C21.9937 3.375 22.5234 3.59531 22.9641 4.03594C23.4047 4.47656 23.625 5.00625 23.625 5.625V10.125C23.625 10.7437 23.4047 11.2734 22.9641 11.7141C22.5234 12.1547 21.9937 12.375 21.375 12.375H5.625ZM5.625 10.125H21.375V5.625H5.625V10.125ZM21.375 25.875V23.625H19.125V21.375H21.375V19.125H23.625V21.375H25.875V23.625H23.625V25.875H21.375Z"
                fill={colour}
            />
        </svg>
    );
}
