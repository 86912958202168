import { breakpoints, sizes, theme } from '../../theme';
import { BodyRegular, WrappingText } from '../Elements';
import styled from 'styled-components';
import useWindowWidth from '../../hooks/useWindowWidth';
import ValidateIcon from '../../assets/icons/indicators/ValidateIcon';
import { EmptyTableContent } from './EmptyTableContent';
import React from 'react';

export type TableHeader = {
    name: string | JSX.Element;
    rowPercentage: number;
    order: number;
    rightAligned?: boolean;
    mobileRightColumn?: boolean;
};

export type TableCell = {
    name: string;
    value: string | number | JSX.Element | null;
    isBold?: boolean;
};

export type TableRow = {
    id: string;
    cells: TableCell[];
};

type TableProps = {
    headers: TableHeader[];
    rows: TableRow[];
    mobileViewBelow?: typeof breakpoints.sm | typeof breakpoints.md | typeof breakpoints.lg | typeof breakpoints.xl;
    activeRow?: string;
    emptyTableContent?: JSX.Element;
};

/**
 * Displays a responsive table.
 * On a smaller screen, the headers are hidden and the data is displayed in cards instead of table rows.
 *
 * @param headers -
 * For each header, use rowPercentage to configure the width of the header.
 * Use the mobileRightColumn param to specify whether it should be in the right column in mobile view.
 * If there are no headers for which mobileRightColumn is true, there will only be one column in mobile view.
 * @param rows -
 * row data for the table
 * @param mobileViewBelow -
 * The maximum breakpoint size at which the mobile table view should be displayed.
 * @param activeRow
 * The id of the active row (if any)
 * @param emptyTableContent
 * The content shown inside the table if the rows array is empty.
 */
export default function Table({
    headers,
    rows,
    mobileViewBelow = breakpoints.md,
    activeRow = '',
    emptyTableContent,
}: TableProps): JSX.Element {
    const { currentWindowWidth } = useWindowWidth();

    if (rows.length <= 0) {
        return <EmptyTable>{emptyTableContent ?? <EmptyTableContent />}</EmptyTable>;
    }

    // get percentages out of header rows
    const sortedHeaders = headers.sort((a, b) => {
        return a.order < b.order ? -1 : 1;
    });

    const totalHeadersPercentage = sortedHeaders
        .flatMap((header) => header.rowPercentage)
        .reduce((accumulator, percentage) => accumulator + percentage);

    if (totalHeadersPercentage < 100) {
        const nextOrderNumber =
            sortedHeaders
                .map((header) => header.order)
                .sort()
                .reverse()[0] + 1;

        const tableHeader: TableHeader = {
            name: '',
            rowPercentage: 100 - totalHeadersPercentage,
            order: nextOrderNumber,
        };

        sortedHeaders.push(tableHeader);
    }

    const gridPercentagesArray: string[] = sortedHeaders.map((header) => {
        return `${header.rowPercentage}%`;
    });

    const desktopRows = rows.map((row, index) => (
        <TableRowContainer
            key={index}
            $columnPercentages={gridPercentagesArray.join(' ')}
            $mobileViewBelow={mobileViewBelow}
            $activeRow={activeRow === row.id}
        >
            {row.cells.map((cell, cellIndex) => {
                if (typeof cell.value === 'string' || typeof cell.value === 'number') {
                    return (
                        <TableCellContainer key={cellIndex} $bold={cell.isBold}>
                            {cellIndex === 0 && activeRow === row.id && <ValidateIcon />}
                            {cell.value}
                        </TableCellContainer>
                    );
                }

                // then it is a JSX.Element already
                return cell.value && React.cloneElement(cell.value, { key: cellIndex });
            })}
        </TableRowContainer>
    ));

    const mobileCards = rows.map((row, rowIndex) => (
        <CardContainer key={rowIndex} $activeRow={row.id === activeRow}>
            <CardLeftColumn>
                {row.cells
                    .filter((cell) => !headers.find((x) => x.name === cell.name)?.mobileRightColumn)
                    .map((cell, cellIndex) => {
                        if (typeof cell.value === 'string' || typeof cell.value === 'number') {
                            return (
                                <WrappingText key={cellIndex} $bold={cell.isBold}>
                                    {cell.value}
                                </WrappingText>
                            );
                        }
                        // then it is a JSX.Element already
                        return cell.value && React.cloneElement(cell.value, { key: cellIndex });
                    })}
            </CardLeftColumn>
            {headers.some((x) => !x.mobileRightColumn) && (
                <CardRightColumn>
                    {row.cells
                        .filter((cell) => headers.find((x) => x.name === cell.name)?.mobileRightColumn)
                        .map((cell, cellIndex) => {
                            if (typeof cell.value === 'string' || typeof cell.value === 'number') {
                                return (
                                    <WrappingText key={cellIndex} $bold={cell.isBold}>
                                        {cell.value}
                                    </WrappingText>
                                );
                            }
                            // then it is a JSX.Element already

                            return cell.value && React.cloneElement(cell.value, { key: cellIndex });
                        })}
                </CardRightColumn>
            )}
        </CardContainer>
    ));

    return (
        <TableContainer>
            {currentWindowWidth > parseInt(mobileViewBelow) ? (
                <>
                    <HeaderRow $columnPercentages={gridPercentagesArray.join(' ')}>
                        {sortedHeaders.map((header, index) => {
                            return (
                                <HeaderCell key={index} $rightaligned={!!header.rightAligned}>
                                    {typeof header.name === 'string' ? (
                                        <BodyRegular>{header.name}</BodyRegular>
                                    ) : (
                                        header.name //JSX.Element
                                    )}
                                </HeaderCell>
                            );
                        })}
                    </HeaderRow>
                    {desktopRows}
                </>
            ) : (
                <CardListContainer>{mobileCards}</CardListContainer>
            )}
        </TableContainer>
    );
}

const TableContainer = styled.div`
    word-break: break-word;
`;

const EmptyTable = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed ${theme.primaryButtonBorderColour};
    border-radius: ${sizes.borderRadiusLg};
    background: ${theme.cardBackground};
`;

export const RightAlignedButtonCell = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const ContentStyle = styled.div`
    border-top: 1px solid ${theme.cardBorder};
    border-bottom: 1px solid ${theme.cardBorder};
    background-color: ${theme.cardBackground};
    padding: ${sizes.spacingMd};
`;

const CardContainer = styled(ContentStyle)<{ $activeRow: boolean }>`
    display: flex;
    justify-content: space-between;
    background-color: ${({ $activeRow }) => {
        return $activeRow ? theme.notificationSuccessBackground : theme.cardBackground;
    }};
`;

const CardLeftColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${sizes.spacingSm};
    width: 100%;
`;

const CardRightColumn = styled.div`
    display: flex;
    justify-content: center;
    gap: ${sizes.spacingMd};
`;

const CardListContainer = styled.div`
    background-color: ${theme.tableHeaderBackgroundColour};
    padding-top: ${sizes.spacingLg};
    border-top-right-radius: ${sizes.borderRadiusLg};
    border-top-left-radius: ${sizes.borderRadiusLg};
`;

const HeaderRow = styled.div<{ $columnPercentages: string }>`
    display: grid;
    padding: ${sizes.spacingMd};
    background-color: ${theme.tableHeaderBackgroundColour};
    border-bottom: 1px solid ${theme.cardBorder};
    border-top-right-radius: ${sizes.borderRadiusLg};
    border-top-left-radius: ${sizes.borderRadiusLg};
    grid-template-columns: ${({ $columnPercentages }) => {
        return `${$columnPercentages};`;
    }};
`;

const HeaderCell = styled.div<{ $rightaligned?: boolean }>`
    display: flex;
    justify-content: ${({ $rightaligned }) => {
        return $rightaligned ? 'flex-end' : 'flex-start';
    }};
`;

const TableCellContainer = styled(WrappingText)`
    margin-right: ${sizes.spacingLg};
`;

const TableRowContainer = styled(ContentStyle)<{
    $columnPercentages: string;
    $mobileViewBelow: string;
    $activeRow: boolean;
}>`
    display: grid;
    grid-template-columns: ${({ $columnPercentages }) => {
        return `${$columnPercentages};`;
    }};
    background-color: ${({ $activeRow }) => {
        return $activeRow ? theme.notificationSuccessBackground : theme.cardBackground;
    }};
    align-items: center;
    min-height: 70px;
    @media (max-width: ${({ $mobileViewBelow }) => {
            return $mobileViewBelow;
        }}) {
        grid-template-columns: 100%;
        row-gap: ${sizes.spacingSm};
    }
`;
