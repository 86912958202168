import { IconSize } from '../icon-sizes';

export default function WarningIcon({ size = IconSize.MEDIUM }: { size?: IconSize }): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.795 0.999173C11.3997 -0.0362042 12.896 -0.0357475 13.5 1L23.7336 21.3541C24.3424 22.398 23.5894 23.7089 22.3809 23.7089L12.0644 23.7089L1.70082 23.7089C0.491899 23.7089 -0.261076 22.3972 0.348574 21.3532L10.795 0.999173Z"
                fill="#ECA102"
            />
            <path
                d="M13.428 15.5869H11.2834L10.8352 7H13.8762L13.428 15.5869ZM10.8 18.5928C10.8 18.1006 10.9319 17.7285 11.1956 17.4766C11.4592 17.2246 11.843 17.0986 12.3469 17.0986C12.8333 17.0986 13.2083 17.2275 13.4719 17.4854C13.7415 17.7432 13.8762 18.1123 13.8762 18.5928C13.8762 19.0557 13.7415 19.4219 13.4719 19.6914C13.2024 19.9551 12.8274 20.0869 12.3469 20.0869C11.8547 20.0869 11.4739 19.958 11.2043 19.7002C10.9348 19.4365 10.8 19.0674 10.8 18.5928Z"
                fill="#00242C"
            />
        </svg>
    );
}
