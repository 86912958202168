import { theme } from 'theme';
import { IconSize } from '../icon-sizes';

export default function AccessibilityIcon({ size = IconSize.LARGE, colour = theme.textColour }): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask
                id="mask0_6_267"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="42"
                height="42"
            >
                <rect width="42" height="42" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_6_267)">
                <path
                    d="M21.25 10.5C20.2875 10.5 19.4635 10.1573 18.7781 9.47188C18.0927 8.78646 17.75 7.9625 17.75 7C17.75 6.0375 18.0927 5.21354 18.7781 4.52813C19.4635 3.84271 20.2875 3.5 21.25 3.5C22.2125 3.5 23.0365 3.84271 23.7219 4.52813C24.4073 5.21354 24.75 6.0375 24.75 7C24.75 7.9625 24.4073 8.78646 23.7219 9.47188C23.0365 10.1573 22.2125 10.5 21.25 10.5ZM30 38.5V29.75H21.25C20.2875 29.75 19.4635 29.4073 18.7781 28.7219C18.0927 28.0365 17.75 27.2125 17.75 26.25V15.75C17.75 14.7875 18.0927 13.9635 18.7781 13.2781C19.4635 12.5927 20.2875 12.25 21.25 12.25C21.95 12.25 22.5552 12.4031 23.0656 12.7094C23.576 13.0156 24.1229 13.5042 24.7063 14.175C26.3104 16.1 27.7615 17.4198 29.0594 18.1344C30.3573 18.849 31.8375 19.2208 33.5 19.25V22.75C31.9542 22.75 30.3937 22.4146 28.8188 21.7437C27.2437 21.0729 25.8875 20.2708 24.75 19.3375V25.375H30C30.9625 25.375 31.7865 25.7177 32.4719 26.4031C33.1573 27.0885 33.5 27.9125 33.5 28.875V38.5H30ZM17.75 38.5C15.3292 38.5 13.2656 37.6469 11.5594 35.9406C9.85312 34.2344 9 32.1708 9 29.75C9 27.65 9.66354 25.7979 10.9906 24.1938C12.3177 22.5896 13.9875 21.5833 16 21.175V24.7625C14.9792 25.1708 14.1406 25.8198 13.4844 26.7094C12.8281 27.599 12.5 28.6125 12.5 29.75C12.5 31.2083 13.0104 32.4479 14.0312 33.4688C15.0521 34.4896 16.2917 35 17.75 35C18.8875 35 19.901 34.6719 20.7906 34.0156C21.6802 33.3594 22.3292 32.5208 22.7375 31.5H26.325C25.9167 33.5125 24.9104 35.1823 23.3063 36.5094C21.7021 37.8365 19.85 38.5 17.75 38.5Z"
                    fill={colour}
                />
            </g>
        </svg>
    );
}
