import { useLocation, useParams } from 'react-router-dom';
import React, { useContext } from 'react';
import { AuthContext } from '../../contextProviders/AuthContext';
import strings from '../../strings/strings.json';
import useGetRequest from '../../hooks/useGetRequest';
import { Course } from '../../models/Course';
import { ActivityType } from '../../models/Activity';
import SubpageHeader from '../../components/sections/SubpageHeader';
import Skeleton from 'react-loading-skeleton';
import MainSection, { MainSectionWithGaps } from '../../components/sections/MainSection';
import Stepper from '../../components/stepper/Stepper';
import SelectCourseDateForm from './components/SelectCourseDateForm';
import SelectMeetingProviderForm from './components/SelectMeetingProviderForm';
import SelectTrainerForm from './components/SelectTrainerForm';
import { ConfigureMeetingsForm } from './components/ConfigureMeetingsForm';
import ReviewScheduleForm from './components/ReviewScheduleForm';
import { StepInput } from '../../components/stepper/stepperTypes';
import { RoleType } from '../../models/User';
import { LocationType } from '../../models/ActivityMeeting';

export enum MeetingProviderOption {
    useZoomIntegration = 0,
    useOwnProvider = 1,
}

export type NewMeeting = {
    activityId: string;
    title: string;
    moduleName: string;
    order: string;
    startDate: Date | undefined;
    startTime: Date | undefined;
    durationInMins: number | null;
    locationType: LocationType | undefined;
    location: string | undefined;
    joinLink: string | undefined;
};

export type NewSchedule = {
    courseId: string;
    courseName: string;
    courseStartDate: Date | undefined;
    courseEndDate: Date | undefined;
    meetings: NewMeeting[];
    meetingProvider: MeetingProviderOption | undefined;
    trainerId: string | undefined;
    trainerName: string | undefined;
    returnLink: string;
};

type State = {
    returnUrl: string;
    backUrl: string;
};

export default function ScheduleCoursePage(): JSX.Element {
    const params = useParams();
    const { courseId } = params;
    const { userData, userTokenDetails } = useContext(AuthContext);

    const { state } = useLocation();
    const { returnUrl, backUrl } = (state as State) ?? {};

    const [schedule, setSchedule] = React.useState<NewSchedule | undefined>();

    const backButtonDetails = React.useMemo(() => {
        return {
            title: strings.courseSchedulePage.backButtonTitle,
            aria: strings.courseSchedulePage.backButtonTitleAria,
            href: backUrl,
        };
    }, [backUrl]);

    const { data: course, loading, called, errors } = useGetRequest<Course>(`courses/${courseId}`, !courseId);

    const hasMeetings = course?.modules
        .flatMap((module) => module.activities)
        .some((activity) => ActivityType.LIVE_TRAINING === activity.type);

    React.useEffect(() => {
        if (!course) return;

        let meetings: NewMeeting[] = [];

        course.modules.forEach((module) => {
            if (module.activities.some((a) => a.type === ActivityType.LIVE_TRAINING)) {
                module.activities.forEach((activity) => {
                    if (activity.type === ActivityType.LIVE_TRAINING) {
                        meetings.push({
                            activityId: activity.id,
                            title: activity.title,
                            moduleName: module.title,
                            order: module.order.toString() + activity.order.toString(),
                            startDate: undefined,
                            joinLink: undefined,
                            location: undefined,
                            startTime: undefined,
                            durationInMins: activity.durationInMins,
                            locationType: undefined,
                        });
                    }
                });
            }
        });

        let trainerId = undefined;
        let trainerName = undefined;

        if (!userData.roles.includes(RoleType.ORGANISATION_ADMIN)) {
            trainerId = userData.id;
            trainerName = userTokenDetails.given_name + ' ' + userTokenDetails.family_name;
        }

        setSchedule({
            courseId: course.id,
            courseName: course.title,
            courseStartDate: undefined,
            courseEndDate: undefined,
            meetings: meetings,
            meetingProvider:
                userData.hasZoomIntegration && hasMeetings ? undefined : MeetingProviderOption.useOwnProvider,
            trainerId: trainerId,
            trainerName: trainerName,
            returnLink: returnUrl,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [course]);

    let steps: StepInput[] = [{ name: 'Course Date', Form: SelectCourseDateForm }];

    if (userData.roles.includes(RoleType.ORGANISATION_ADMIN)) steps.push({ name: 'Trainer', Form: SelectTrainerForm });
    if (userData.hasZoomIntegration && hasMeetings)
        steps.push({ name: 'Meeting Platform', Form: SelectMeetingProviderForm });
    if (hasMeetings) steps.push({ name: 'Meetings', Form: ConfigureMeetingsForm });

    steps.push({ name: 'Review', Form: ReviewScheduleForm });

    if (!loading && !called) {
        return <></>;
    }

    if (loading)
        return (
            <>
                <SubpageHeader title={strings.courseSchedulePage.loadingText} backButtonDetails={backButtonDetails} />
                <MainSectionWithGaps>
                    <Skeleton height={400} count={1} />
                </MainSectionWithGaps>
            </>
        );

    if (!course || !schedule || errors.length > 0)
        return (
            <>
                <SubpageHeader title="Error loading course" backButtonDetails={backButtonDetails} />
                <MainSectionWithGaps>
                    <Skeleton height={154} count={1} />
                    <Skeleton height={350} count={1} />
                </MainSectionWithGaps>
            </>
        );

    return (
        <>
            <SubpageHeader
                title={`${strings.courseSchedulePage.title} ${course.title}`}
                backButtonDetails={backButtonDetails}
            />
            <MainSection>
                <Stepper<NewSchedule> defaultObject={schedule} steps={steps} />
            </MainSection>
        </>
    );
}
