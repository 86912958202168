export type LiveTrainingActivity = {
    id: string;
    title: string;
    description: string;
    trainingDuration: number;
    moduleId: string;
};

export function createEmptyLiveTrainingActivity(moduleId: string) {
    return {
        id: '',
        title: '',
        description: '',
        trainingDuration: 0,
        moduleId: moduleId,
    };
}
