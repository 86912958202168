import { theme } from 'theme';
import { IconSize } from './icon-sizes';

export default function StepCheckIcon({
    circleColour = theme.stepCheck.circleColour,
    checkColour = theme.stepCheck.checkColour,
    size = IconSize.LARGE,
}: {
    circleColour?: string;
    checkColour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="19.5" cy="19.5" r="19.5" fill={circleColour} />
            <path d="M14.147 19.4998L18.3529 23.7057L25.9999 15.2939" stroke={checkColour} strokeWidth="2" />
        </svg>
    );
}
