import { StepperFormProps } from '../../../components/stepper/stepperTypes';
import { MeetingProviderOption, NewSchedule } from '../ScheduleCoursePage';
import { BodyRegular, BodySmall, Card, Headline3, Headline4, Headline5 } from '../../../components/Elements';
import strings from '../../../strings/strings.json';
import * as React from 'react';
import StepperButtonRow from '../../../components/stepper/StepperButtonRow';
import { logError } from '../../../lib/debug-helpers';
import { APIError } from '../../../lib/_api-helpers';
import useModifyRequest from '../../../hooks/useModifyRequest';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { breakpoints, sizes, theme } from '../../../theme';
import SecondaryButton from '../../../components/buttons/SecondaryButton';
import EditIcon from '../../../assets/icons/controls/EditIcon';
import useWindowWidth from '../../../hooks/useWindowWidth';
import { IconButton } from '../../../components/buttons/IconButton';
import ScheduleIcon from '../../../assets/icons/pathway/ScheduleIcon';
import TimeIcon from '../../../assets/icons/pathway/TimeIcon';
import LinkIcon from '../../../assets/icons/pathway/LinkIcon';
import Notification, { NotificationType } from '../../../components/notifiers/Notification';
import { MeetingCard, MeetingDetailSection, MeetingHeaderSection, MeetingInfo } from './commonElements';
import { LocationType } from '../../../models/ActivityMeeting';
import LocationIcon from '../../../assets/icons/controls/LocationIcon';
import { IconSize } from '../../../assets/icons/icon-sizes';

type CreateMeetingFields = {
    activityId: string;
    meetingStartDate: Date;
    locationType: LocationType;
    location: string | undefined;
    joinLink: string | undefined;
};

type CreateScheduleRequest = {
    courseId: string;
    courseStartDate: Date;
    courseEndDate: Date;
    createMeetingRequests: CreateMeetingFields[];
    useZoom: boolean;
    trainerId: string;
};

export default function ReviewScheduleForm(props: StepperFormProps<NewSchedule>): JSX.Element {
    const { previous, newObject, changeStep, steps } = props;
    const navigate = useNavigate();
    const { currentWindowWidth } = useWindowWidth();

    const [errors, setErrors] = React.useState<APIError[]>([]);

    const { modifyData: createSchedule, loading: tryingToScheduleCourse } = useModifyRequest(
        `courses/${newObject.courseId}/schedule`,
        'POST',
    );
    const { modifyData: createZoomMeetings, loading: creatingZoomMeetings } = useModifyRequest(
        `courses/schedule/create-meetings`,
        'POST',
    );

    async function scheduleCourse() {
        setErrors([]);

        const scheduleMeetingRequests: CreateMeetingFields[] = newObject.meetings.map((meeting) => {
            const date = new Date( //combine date and time
                (meeting.startDate as Date).getFullYear(),
                (meeting.startDate as Date).getMonth(),
                (meeting.startDate as Date).getDate(),
                (meeting.startTime as Date).getHours(),
                (meeting.startTime as Date).getMinutes(),
            );

            return {
                activityId: meeting.activityId,
                meetingStartDate: date,
                locationType: meeting.locationType ?? LocationType.ONLINE,
                joinLink: meeting.locationType === LocationType.ONLINE ? meeting.joinLink : undefined,
                location: meeting.locationType === LocationType.IN_PERSON ? meeting.location : undefined,
            };
        });

        const requestData: CreateScheduleRequest = {
            courseId: newObject.courseId,
            courseStartDate: newObject.courseStartDate as Date,
            courseEndDate: newObject.courseEndDate as Date,
            createMeetingRequests: scheduleMeetingRequests,
            // eslint-disable-next-line eqeqeq
            useZoom: newObject.meetingProvider == MeetingProviderOption.useZoomIntegration,
            trainerId: newObject.trainerId ?? '',
        };

        const response = await createSchedule(requestData);

        const { value: scheduleId, errors } = response;
        if (errors) {
            logError(errors);
            setErrors(errors);
            return;
        }
        if (scheduleId) {
            // eslint-disable-next-line eqeqeq
            if (newObject.meetingProvider == MeetingProviderOption.useZoomIntegration) {
                await createZoomMeetings({ courseScheduleId: scheduleId });
            }

            navigate(newObject.returnLink, { state: { newScheduledCourseId: response.value } });

            return;
        }
    }

    const loading = tryingToScheduleCourse || creatingZoomMeetings;

    return (
        <ReviewBody>
            <Headline3>{strings.scheduleCourseStepper.reviewScheduleForm.title}</Headline3>
            <BodyRegular>{strings.scheduleCourseStepper.reviewScheduleForm.subtitle}</BodyRegular>
            <DetailCard>
                <Headline4>{newObject.courseName}</Headline4>
                <div>
                    <DetailRow>
                        <DetailTextSection>
                            <DetailTitle>
                                {strings.scheduleCourseStepper.reviewScheduleForm.startDateLabel}
                            </DetailTitle>
                            <MainDetail $bold>{newObject.courseStartDate?.toLocaleDateString()}</MainDetail>
                        </DetailTextSection>
                        {currentWindowWidth < parseInt(breakpoints.sm) ? (
                            <IconButton
                                icon={<EditIcon />}
                                aria={strings.scheduleCourseStepper.reviewScheduleForm.startDateEditButtonAria}
                                onClick={() => {
                                    changeStep(0);
                                }}
                                disabled={loading}
                            />
                        ) : (
                            <SecondaryButton
                                title={strings.scheduleCourseStepper.reviewScheduleForm.editButton}
                                aria={strings.scheduleCourseStepper.reviewScheduleForm.startDateEditButtonAria}
                                icon={<EditIcon />}
                                onClick={() => {
                                    changeStep(0);
                                }}
                                disabled={loading}
                            />
                        )}
                    </DetailRow>
                    <DetailRow>
                        <DetailTextSection>
                            <DetailTitle>{strings.scheduleCourseStepper.reviewScheduleForm.endDateLabel}</DetailTitle>
                            <MainDetail $bold>
                                {newObject.courseEndDate?.toLocaleDateString() ??
                                    strings.scheduleCourseStepper.reviewScheduleForm.noDateSelected}
                            </MainDetail>
                        </DetailTextSection>
                        {currentWindowWidth < parseInt(breakpoints.sm) ? (
                            <IconButton
                                icon={<EditIcon />}
                                aria={strings.scheduleCourseStepper.reviewScheduleForm.endDateEditButtonAria}
                                onClick={() => {
                                    changeStep(0);
                                }}
                                disabled={loading}
                            />
                        ) : (
                            <SecondaryButton
                                title={strings.scheduleCourseStepper.reviewScheduleForm.editButton}
                                aria={strings.scheduleCourseStepper.reviewScheduleForm.endDateEditButtonAria}
                                icon={<EditIcon />}
                                onClick={() => {
                                    changeStep(0);
                                }}
                                disabled={loading}
                            />
                        )}
                    </DetailRow>
                    <DetailRow>
                        <DetailTextSection>
                            <DetailTitle>{strings.scheduleCourseStepper.reviewScheduleForm.trainerLabel}</DetailTitle>
                            <MainDetail $bold>{newObject.trainerName}</MainDetail>
                        </DetailTextSection>
                        {steps.some((step) => step.name === 'Trainer') && (
                            <>
                                {currentWindowWidth < parseInt(breakpoints.sm) ? (
                                    <IconButton
                                        icon={<EditIcon />}
                                        aria={strings.scheduleCourseStepper.reviewScheduleForm.trainerEditButtonAria}
                                        onClick={() => {
                                            changeStep(
                                                steps.findIndex((step) => {
                                                    return step.name === 'Trainer';
                                                }),
                                            );
                                        }}
                                        disabled={loading}
                                    />
                                ) : (
                                    <SecondaryButton
                                        title={strings.scheduleCourseStepper.reviewScheduleForm.editButton}
                                        aria={strings.scheduleCourseStepper.reviewScheduleForm.trainerEditButtonAria}
                                        icon={<EditIcon />}
                                        onClick={() => {
                                            changeStep(
                                                steps.findIndex((step) => {
                                                    return step.name === 'Trainer';
                                                }),
                                            );
                                        }}
                                        disabled={loading}
                                    />
                                )}
                            </>
                        )}
                    </DetailRow>
                    <DetailRow>
                        {newObject.meetings.length > 0 && (
                            <DetailTextSection>
                                <DetailTitle>
                                    {strings.scheduleCourseStepper.reviewScheduleForm.meetingPlatformLabel}
                                </DetailTitle>
                                <MainDetail $bold>
                                    {/* eslint-disable-next-line eqeqeq */}
                                    {newObject.meetingProvider == MeetingProviderOption.useOwnProvider
                                        ? strings.scheduleCourseStepper.reviewScheduleForm.useOwnProviderText
                                        : strings.scheduleCourseStepper.reviewScheduleForm.zoomText}
                                </MainDetail>
                            </DetailTextSection>
                        )}

                        {steps.some((step) => step.name === 'Meeting Platform') && (
                            <>
                                {currentWindowWidth < parseInt(breakpoints.sm) ? (
                                    <IconButton
                                        icon={<EditIcon />}
                                        aria={
                                            strings.scheduleCourseStepper.reviewScheduleForm
                                                .meetingPlatformEditButtonAria
                                        }
                                        onClick={() => {
                                            changeStep(
                                                steps.findIndex((step) => {
                                                    return step.name === 'Meeting Platform';
                                                }),
                                            );
                                        }}
                                        disabled={loading}
                                    />
                                ) : (
                                    <SecondaryButton
                                        title={'Edit'}
                                        aria={
                                            strings.scheduleCourseStepper.reviewScheduleForm
                                                .meetingPlatformEditButtonAria
                                        }
                                        icon={<EditIcon />}
                                        onClick={() => {
                                            changeStep(
                                                steps.findIndex((step) => {
                                                    return step.name === 'Meeting Platform';
                                                }),
                                            );
                                        }}
                                        disabled={loading}
                                    />
                                )}
                            </>
                        )}
                    </DetailRow>
                </div>
            </DetailCard>
            {newObject.meetings.length === 0 ? null : (
                <>
                    <Headline4>{strings.scheduleCourseStepper.reviewScheduleForm.meetingsTitle}</Headline4>
                    <MeetingsSection>
                        {newObject.meetings.map((meeting) => {
                            const meetingStartTimeString = meeting.startTime
                                ?.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hourCycle: 'h12' })
                                .replaceAll(' ', '');

                            const meetingEndTime = meeting.startTime
                                ? new Date(meeting.startTime.getTime() + 60000 * (meeting.durationInMins ?? 1))
                                : '';

                            const meetingEndTimeString = meetingEndTime
                                ? meetingEndTime
                                      .toLocaleTimeString('en-GB', {
                                          hour: '2-digit',
                                          minute: '2-digit',
                                          hourCycle: 'h12',
                                      })
                                      .replaceAll(' ', '')
                                : '';

                            return (
                                <MeetingCard>
                                    <MeetingHeaderSection>
                                        <Headline5>{meeting.title}</Headline5>
                                        {currentWindowWidth < parseInt(breakpoints.sm) ? (
                                            <IconButton
                                                icon={<EditIcon />}
                                                aria={
                                                    strings.scheduleCourseStepper.reviewScheduleForm
                                                        .meetingEditButtonAria
                                                }
                                                onClick={() => {
                                                    changeStep(steps.length - 2);
                                                }}
                                                disabled={loading}
                                            />
                                        ) : (
                                            <SecondaryButton
                                                title={strings.scheduleCourseStepper.reviewScheduleForm.editButton}
                                                aria={
                                                    strings.scheduleCourseStepper.reviewScheduleForm
                                                        .meetingEditButtonAria
                                                }
                                                icon={<EditIcon />}
                                                onClick={() => {
                                                    changeStep(steps.length - 2);
                                                }}
                                                disabled={loading}
                                            />
                                        )}
                                    </MeetingHeaderSection>
                                    <MeetingDetailSection>
                                        <MeetingInfo>
                                            <ScheduleIcon />
                                            <BodySmall>{meeting.startDate?.toLocaleDateString()}</BodySmall>
                                        </MeetingInfo>
                                        <MeetingInfo>
                                            <TimeIcon />
                                            <BodySmall>
                                                {meetingStartTimeString + ' - ' + meetingEndTimeString}
                                            </BodySmall>
                                        </MeetingInfo>
                                    </MeetingDetailSection>
                                    {meeting.locationType === LocationType.ONLINE && (
                                        <MeetingInfo>
                                            <LinkIcon />
                                            <BodySmall>
                                                {/* eslint-disable-next-line eqeqeq */}
                                                {newObject.meetingProvider == MeetingProviderOption.useOwnProvider ? (
                                                    <>{meeting.joinLink}</>
                                                ) : (
                                                    'Zoom'
                                                )}
                                            </BodySmall>
                                        </MeetingInfo>
                                    )}
                                    {meeting.locationType === LocationType.IN_PERSON && (
                                        <MeetingInfo>
                                            <LocationIcon size={IconSize.MEDIUM} />
                                            <BodySmall>{meeting.location}</BodySmall>
                                        </MeetingInfo>
                                    )}
                                </MeetingCard>
                            );
                        })}
                    </MeetingsSection>
                </>
            )}
            {errors.length > 0 && !tryingToScheduleCourse && (
                <Notification
                    notificationType={NotificationType.DANGER}
                    title={strings.scheduleCourseStepper.reviewScheduleForm.scheduleCourseErrorTitle}
                    description={strings.scheduleCourseStepper.reviewScheduleForm.scheduleCourseErrorDescription}
                />
            )}
            <StepperButtonRow
                submitButtonTitle={strings.scheduleCourseStepper.reviewScheduleForm.submitButton}
                submitButtonAria={strings.scheduleCourseStepper.reviewScheduleForm.submitButtonAria}
                submitButtonFunction={scheduleCourse}
                previousButtonFunction={previous}
                submitting={loading}
            />
        </ReviewBody>
    );
}

const MeetingsSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
    background: ${theme.cardSecondaryBackgroundColour};
    border-radius: ${sizes.borderRadiusMd};
    padding: ${sizes.spacingMd};
`;

const MainDetail = styled(BodyRegular)`
    flex-grow: 1;
    @media (max-width: ${breakpoints.sm}) {
        flex-grow: 0;
    }
`;

const DetailTitle = styled(BodyRegular)`
    min-width: 200px;
    @media (max-width: ${breakpoints.sm}) {
        min-width: 0;
    }
`;

const DetailTextSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
        gap: ${sizes.spacingXs};
        align-items: flex-start;
        flex-grow: 0;
    }
`;

const DetailRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: ${sizes.spacingMd};
    border-top: 1px solid ${theme.cardBorder};
`;

const DetailCard = styled(Card)`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingLg};
`;

const ReviewBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingLg};
`;
