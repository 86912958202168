import NavButton from 'components/buttons/NavButton';
import { StepperFormProps } from 'components/stepper/stepperTypes';
import { TaskActivity } from 'models/inputModels/TaskActivity';
import TaskContentFormForTextAndImage from './TaskContentFormForTextAndImage';
import TaskContentFormForVideo from './TaskContentFormForVideo';
import TaskContentFormForAudio from './TaskContentFormForAudio';
import strings from '../../../../strings/strings.json';
import { styled } from 'styled-components';
import { sizes } from 'theme';

export default function TaskContentForm(props: StepperFormProps<TaskActivity>): JSX.Element {
    let node: JSX.Element = <></>;

    switch (props.newObject.buildMethod) {
        case 'Text & image':
            node = <TaskContentFormForTextAndImage {...props} />;
            break;
        case 'Video':
            node = <TaskContentFormForVideo {...props} />;
            break;
        case 'Audio':
            node = <TaskContentFormForAudio {...props} />;
            break;
    }

    return <Base previous={props.previous}>{node}</Base>;
}

type BaseProps = {
    previous: () => void;
    children: React.ReactNode;
};

const Base: React.FC<BaseProps> = ({ previous, children }) => {
    return (
        <BaseContainer>
            <div>
                <NavButton
                    title={strings.stepper.backButtonMobileTitle}
                    aria={strings.stepper.backButtonMobileAria}
                    onClick={previous}
                    back
                />
            </div>
            {children}
        </BaseContainer>
    );
};

const BaseContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
`;
