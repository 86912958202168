import { DOCUMENTS_URL } from 'lib/_api-helpers';

type AudioPlayerProps = {
    filepath: string;
};

export default function AudioPlayer({ filepath }: AudioPlayerProps): JSX.Element {
    return (
        <audio
            controls
            controlsList="nodownload"
            style={{
                width: '100%',
            }}
        >
            <source src={`${DOCUMENTS_URL}/${filepath}`} type="audio/mpeg" width="100%" />
        </audio>
    );
}
