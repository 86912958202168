import { theme } from 'theme';
import { IconSize } from '../icon-sizes';

export default function HistoryIcon({ size = IconSize.LARGE, colour = theme.textColour }): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask
                id="mask0_123_235"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="34"
                height="34"
            >
                <rect width="34" height="34" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_123_235)">
                <path
                    d="M17 29.75C13.7417 29.75 10.9024 28.6698 8.48229 26.5094C6.06215 24.349 4.675 21.6514 4.32083 18.4167H7.225C7.55556 20.8722 8.64757 22.9028 10.501 24.5083C12.3545 26.1139 14.5208 26.9167 17 26.9167C19.7625 26.9167 22.1059 25.9545 24.0302 24.0302C25.9545 22.1059 26.9167 19.7625 26.9167 17C26.9167 14.2375 25.9545 11.8941 24.0302 9.96979C22.1059 8.04549 19.7625 7.08333 17 7.08333C15.3708 7.08333 13.8479 7.46111 12.4312 8.21667C11.0146 8.97222 9.82222 10.0111 8.85417 11.3333H12.75V14.1667H4.25V5.66667H7.08333V8.99583C8.2875 7.48472 9.75729 6.31597 11.4927 5.48958C13.2281 4.66319 15.0639 4.25 17 4.25C18.7708 4.25 20.4295 4.58646 21.976 5.25937C23.5226 5.93229 24.8684 6.84132 26.0135 7.98646C27.1587 9.1316 28.0677 10.4774 28.7406 12.024C29.4135 13.5705 29.75 15.2292 29.75 17C29.75 18.7708 29.4135 20.4295 28.7406 21.976C28.0677 23.5226 27.1587 24.8684 26.0135 26.0135C24.8684 27.1587 23.5226 28.0677 21.976 28.7406C20.4295 29.4135 18.7708 29.75 17 29.75ZM20.9667 22.95L15.5833 17.5667V9.91667H18.4167V16.4333L22.95 20.9667L20.9667 22.95Z"
                    fill={colour}
                />
            </g>
        </svg>
    );
}
