import styled from 'styled-components';
import { theme } from 'theme';

export const ImageCircle = styled.div<{ $imageurl?: string }>`
    position: relative;
    border-radius: 50%;
    height: 70px;
    min-width: 70px;
    background: ${({ $imageurl: imageurl }) => {
        return imageurl ? `url("${imageurl}") no-repeat no-repeat` : theme.cardThirdBackgroundColour;
    }};
    background-size: cover;
`;
