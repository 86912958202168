import * as React from 'react';
import { breakpoints } from 'theme';

type WindowWidth = {
    currentWindowWidth: number;
    isMobileWidth: boolean;
    isTabletWidth: boolean;
    isDesktopWidth: boolean;
};

export default function useWindowWidth(): WindowWidth {
    const [windowWidth, setWindowWidth] = React.useState<number>(window.innerWidth);

    const resetWindowSize = () => {
        setWindowWidth(window.innerWidth);
    };

    React.useEffect(() => {
        window.addEventListener('resize', resetWindowSize);

        return () => {
            window.removeEventListener('resize', resetWindowSize);
        };
    });

    return {
        currentWindowWidth: windowWidth,
        isMobileWidth: windowWidth <= parseInt(breakpoints.sm),
        isTabletWidth: windowWidth > parseInt(breakpoints.sm) && windowWidth <= parseInt(breakpoints.lg),
        isDesktopWidth: windowWidth > parseInt(breakpoints.lg),
    };
}
