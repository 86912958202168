import { Organisation } from './Organisation';

export enum InvitationStatus {
    SENT = 'SENT',
    ACCEPTED = 'ACCEPTED',
    DECLINED = 'DECLINED',
}

export type Invitation = {
    id: string;
    organisation: Organisation;
    status: InvitationStatus;
};
