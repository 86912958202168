import { theme } from '../../../theme';
import { IconSize } from '../icon-sizes';

export default function ImageIcon({
    colour = theme.primaryButtonBorderColour,
    size = IconSize.LARGE,
}: {
    colour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M29 24H3C1.34312 24 0 22.6569 0 21V3C0 1.34312 1.34312 0 3 0H29C30.6569 0 32 1.34312 32 3V21C32 22.6569 30.6569 24 29 24ZM7 3.5C5.067 3.5 3.5 5.067 3.5 7C3.5 8.933 5.067 10.5 7 10.5C8.933 10.5 10.5 8.933 10.5 7C10.5 5.067 8.933 3.5 7 3.5ZM4 20H28V13L22.5303 7.53031C22.2374 7.23744 21.7626 7.23744 21.4696 7.53031L13 16L9.53031 12.5303C9.23744 12.2374 8.76256 12.2374 8.46963 12.5303L4 17V20Z"
                fill={colour}
            />
        </svg>
    );
}
