import ResourceCard from './ResourceCard';
import strings from '../../../strings/strings.json';
import styled from 'styled-components';
import { ResourcesInput } from './ResourcesForm';
import AddIcon from 'assets/icons/controls/AddIcon';
import SecondaryButton from 'components/buttons/SecondaryButton';
import { theme, sizes } from 'theme';

type ResourcesListInput = {
    resources: ResourcesInput[];
    toggleModalOpen: () => void;
    deleteResource: (id: string | null, title: string, url: string, deleted: boolean) => void;
    disabled?: boolean;
    addLinkButtonRef?: React.RefObject<HTMLButtonElement>;
};

export default function ResourcesList(props: ResourcesListInput): JSX.Element {
    const { resources, deleteResource, toggleModalOpen, disabled } = props;

    return (
        <ResourcesContainer>
            {resources.map((resource, index) =>
                !resource.deleted ? (
                    <ResourcesCardContainer key={index}>
                        <ResourceCard
                            key={index}
                            resource={resource}
                            deleteResource={deleteResource}
                            disabled={disabled ?? false}
                        />
                    </ResourcesCardContainer>
                ) : (
                    <></>
                ),
            )}
            <SecondaryButton
                fullwidth
                title={strings.resourcesForm.addResourcesButton}
                aria={strings.resourcesForm.addResourcesButtonAria}
                icon={<AddIcon />}
                onClick={() => toggleModalOpen()}
                disabled={disabled ?? false}
                buttonRef={props.addLinkButtonRef}
            />
        </ResourcesContainer>
    );
}

const ResourcesContainer = styled.div`
    background-color: ${theme.cardSecondaryBackgroundColour};
    padding: ${sizes.spacingMd};
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
`;

const ResourcesCardContainer = styled.div`
    width: 100%;
    padding: ${sizes.spacingMd};
    background: ${theme.cardBackground};
    border: 0.2px solid white;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: ${sizes.borderRadiusLg};
`;
