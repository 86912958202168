import { stringTrim } from 'lib/requestDataStringTrim';
import * as React from 'react';
import { AuthContext } from '../contextProviders/AuthContext';
import { API_URL, ApiResponse, genericServiceError, getHeaders, isLaterThanNow } from '../lib/_api-helpers';

type RequestBody = {
    id?: string;
};

type UseUpsertRequestData = {
    modifyData: <T, U>(requestBody: T) => Promise<ApiResponse<U>>;
    loading: boolean;
};

export default function useUpsertRequest(endpoint: string): UseUpsertRequestData {
    const authContext = React.useContext(AuthContext);

    let url = `${API_URL}/${endpoint}`;

    const [loading, setLoading] = React.useState(false);

    const modifyData = async <T, U>(requestBody: T) => {
        setLoading(true);

        let token = authContext.userData.tokens.accessToken ?? '';

        if (isLaterThanNow(authContext.tokenExpiryTime)) {
            token = await authContext.refreshToken();
        }

        const headers = getHeaders(token);

        const trimRequestBody = stringTrim(requestBody);

        const { id, ...params } = trimRequestBody as RequestBody;

        const method = id ? 'PUT' : 'POST';

        if (id) url += `/${id}`;

        try {
            const response = await fetch(url, {
                headers,
                method,
                body: JSON.stringify(params),
            });

            return await response.json();
        } catch (error) {
            return genericServiceError<U>();
        } finally {
            setLoading(false);
        }
    };

    return { modifyData, loading };
}
