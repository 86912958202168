import Stepper from 'components/stepper/Stepper';
import { QuestionnaireActivity, createEmptyQuestionnaireActivity } from 'models/inputModels/QuestionnaireActivity';
import EmptyForm from './EmptyForm';
import QuestionnaireDetailsForm from './QuestionnaireDetailsForm';
import QuestionnaireQuestionsForm from './QuestionnaireQuestionsForm';

type QuestionnaireCreateComponentProps = {
    saveActivityClick: () => void;
    moduleId: string;
};

export default function QuestionnaireCreateComponent({
    saveActivityClick,
    moduleId,
}: QuestionnaireCreateComponentProps): JSX.Element {
    return (
        <Stepper<QuestionnaireActivity>
            defaultObject={createEmptyQuestionnaireActivity(moduleId)}
            steps={[
                { name: 'Activity Type', Form: EmptyForm },
                { name: 'Activity Details', Form: QuestionnaireDetailsForm },
                { name: 'Questions', Form: QuestionnaireQuestionsForm },
            ]}
            defaultStep={1}
            complete={saveActivityClick}
        />
    );
}
