import * as React from 'react';
import styled from 'styled-components';
import strings from '../../../strings/strings.json';
import { sizes, theme } from '../../../theme';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { APIError } from '../../../lib/_api-helpers';
import { Module } from '../../../models/Module';
import Notification, { NotificationType } from '../../../components/notifiers/Notification';
import AccordionModule from './ModuleAccordion';
import { reorder } from '../../../lib/reorderList';
import useModifyRequest from 'hooks/useModifyRequest';
import { AccordionContainer } from 'components/accordion/Accordion';

type EditableModuleListProps = {
    activeModuleId?: string;
    modules: Module[];
    activitiesEditable?: boolean;
    courseId: string;
    hideAddModuleButton?: boolean;
    refetchCourse: () => Promise<void>;
    editModuleClick: (moduleId: string) => void;
    addActivityClick?: (moduleId: string) => void;
    editActivityClick?: (moduleId: string, activityId: string) => void;
    showIncompleteStepsWarning?: boolean;
};

export default function EditableModuleList({
    activeModuleId,
    modules,
    activitiesEditable = true,
    courseId,
    refetchCourse,
    editModuleClick,
    addActivityClick,
    editActivityClick,
    showIncompleteStepsWarning,
}: EditableModuleListProps): JSX.Element {
    const [errors, setErrors] = React.useState<APIError[]>([]);
    const [reorderSaved, setReorderSaved] = React.useState<boolean>(false);

    const [localModules, setLocalModules] = React.useState<Module[]>([]);
    const { modifyData: reorderModules, loading: reorderLoading } = useModifyRequest(
        `courses/${courseId}/reorder-modules`,
        'PUT',
    );

    React.useEffect(() => {
        if (!modules) {
            return;
        }

        setLocalModules(
            modules.sort((moduleOne, moduleTwo) => {
                return moduleOne.order > moduleTwo.order ? 1 : -1;
            }),
        );
    }, [modules]);

    async function onDragEnd(result: DropResult) {
        if (!result.destination) return;

        setErrors([]);
        setReorderSaved(false);

        const oldModules = [...localModules];

        const newModules = reorder(localModules, result.source.index, result.destination.index);

        setLocalModules(newModules);

        const reorderResponse = await reorderModules(newModules.map((x) => x.id));

        const { errors } = reorderResponse;
        if (errors) {
            setLocalModules(oldModules);
            setErrors(errors);
            return;
        }

        setReorderSaved(true);
    }

    const onDragStart = () => {
        if (reorderSaved) {
            setReorderSaved(false);
        }
    };

    return (
        <ModuleListContainer>
            <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                <Droppable droppableId={`modules-list`} type="module-droppable">
                    {(provided, snapshot) => (
                        <AccordionContainer
                            $isDraggingOver={snapshot.isDraggingOver}
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {localModules.map((module, index) => {
                                return (
                                    <Draggable
                                        key={module.id}
                                        draggableId={module.id}
                                        index={index}
                                        isDragDisabled={reorderLoading}
                                    >
                                        {(provided) => (
                                            <DroppableItem ref={provided.innerRef} {...provided.draggableProps}>
                                                <AccordionModule
                                                    dragHandleProps={provided.dragHandleProps}
                                                    key={module.id}
                                                    module={module}
                                                    editButtonClick={() => editModuleClick(module.id)}
                                                    activeModuleId={activeModuleId}
                                                    activitiesEditable={activitiesEditable}
                                                    activitiesSortable
                                                    moduleEditable
                                                    refetchCourse={refetchCourse}
                                                    addActivityClick={addActivityClick}
                                                    editActivityClick={editActivityClick}
                                                    showIncompleteStepsWarning={showIncompleteStepsWarning}
                                                />
                                            </DroppableItem>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </AccordionContainer>
                    )}
                </Droppable>
            </DragDropContext>
            {reorderLoading ? (
                <Notification
                    notificationType={NotificationType.WARNING}
                    title={strings.courseContentPage.reorderModulesSaving}
                />
            ) : reorderSaved ? (
                <Notification
                    notificationType={NotificationType.SUCCESS}
                    title={strings.courseContentPage.reorderModulesSaved}
                />
            ) : null}
            {errors && errors.length > 0 && !reorderLoading && (
                <Notification
                    notificationType={NotificationType.DANGER}
                    title={strings.courseContentPage.reorderModulesError}
                    description={strings.courseContentPage.reorderModulesErrorDetail}
                />
            )}
        </ModuleListContainer>
    );
}

const ModuleListContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingSm};
`;

const DroppableItem = styled.div`
    background-color: ${theme.cardBackground};
    border-radius: ${sizes.borderRadiusLg};
    &:focus {
        border: 2px solid ${theme.linkFocusBorderColour};
        outline: ${theme.linkFocusBorderColour};
        background-color: ${theme.primaryButtonFocusBackgroundColour};
        border-radius: 12px;
    }
`;
