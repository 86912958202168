import React, { useContext, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useGetRequest from 'hooks/useGetRequest';
import { AuthContext } from 'contextProviders/AuthContext';
import { CourseSchedule } from 'models/CourseSchedule';
import { Activity } from 'models/Activity';
import EnrolledUserTable from 'pages/trainer/scheduledCourses/components/EnrolledUserTable';
import { ScheduledLiveTrainingsTable } from 'pages/schedule/courses/components/ScheduledLiveTrainingsTable';
import QuestionnaireResults from 'pages/trainer/scheduledCourses/components/QuestionnaireResults';
import { MainSectionWithGaps } from 'components/sections/MainSection';
import ShareAndCancelCourse from './ShareAndCancelCourse';
import { RoleType } from 'models/User';
import SubpageHeader, { BackButtonDetails } from 'components/sections/SubpageHeader';
import Skeleton from 'react-loading-skeleton';
import strings from '../../strings/strings.json';
import { logError } from '../../lib/debug-helpers';
import Notification, { NotificationType } from '../notifiers/Notification';
import TabStructureComponent from 'components/tabs/TabStructureComponent';
import VideoCallIcon from 'assets/icons/controls/VideoCallIcon';
import { TabDefinition } from 'components/tabs/Tabs';
import LearnerIcon from 'assets/icons/role-icons/LearnerIcon';
import QuestionnaireIcon from 'assets/icons/pathway/QuestionnaireIcon';
import { styled } from 'styled-components';
import { breakpoints, sizes } from 'theme';
import ScheduleIcon from 'assets/icons/pathway/ScheduleIcon';
import { BodyRegular } from 'components/Elements';
import { TrainerCard } from './TrainerCard';
import WarningIcon from 'assets/icons/indicators/WarningIcon';
import { ErrorMessage } from 'components/forms/FormComponents';

type ScheduledCourseDetailsProps = {
    backButtonDetails: BackButtonDetails;
};

type State = {
    activeTabId: string;
};

export default function ScheduledCourseDetails({ backButtonDetails }: ScheduledCourseDetailsProps): JSX.Element {
    const { courseId } = useParams();
    const { state } = useLocation();
    const { activeTabId } = (state as State) ?? {};

    const {
        data: courseSchedule,
        loading: loadingCourse,
        called: calledCourse,
        errors: courseErrors,
    } = useGetRequest<CourseSchedule>(`courses/schedule/${courseId}`);

    const closeEnrolLinkModalButtonRef = useRef<HTMLButtonElement>(null);
    const cancelCourseButtonRef = useRef<HTMLButtonElement>(null);

    const { userData } = useContext(AuthContext);
    const userIsOrgAdmin = userData.activeRole === RoleType.ORGANISATION_ADMIN;

    if (!loadingCourse && !calledCourse) return <></>;

    if (loadingCourse)
        return (
            <>
                <SubpageHeader
                    title={strings.general.loading}
                    shadedBackground
                    backButtonDetails={backButtonDetails}
                />
                <MainSectionWithGaps>
                    <Skeleton height={400} />
                </MainSectionWithGaps>
            </>
        );

    if (courseErrors.length > 0) {
        logError(courseErrors);

        return (
            <MainSectionWithGaps>
                <Notification
                    notificationType={NotificationType.DANGER}
                    title={strings.scheduledCourseDetailsPage.loadFailed}
                    description={strings.errorMessages.genericDetail}
                    big
                />
            </MainSectionWithGaps>
        );
    }

    if (!courseSchedule) {
        return <></>;
    }

    const tabDefinitions: TabDefinition[] = [];

    courseSchedule.meetings.length > 0 &&
        tabDefinitions.push({
            id: 'live-training',
            name: 'Live training',
            icon: VideoCallIcon,
            content: <ScheduledLiveTrainingsTable courseSchedule={courseSchedule} />,
        });

    tabDefinitions.push({
        id: 'learners',
        name: 'Learners',
        icon: LearnerIcon,
        content: (
            <EnrolledUserTable
                courseScheduleId={courseSchedule.id}
                maximumLearners={courseSchedule.course.maximumLearners}
            />
        ),
    });

    const questionnaires: Activity[] =
        courseSchedule.course.modules.flatMap((module) =>
            module.activities.filter((x) => x.type === 'QUESTIONNAIRE'),
        ) ?? [];

    questionnaires.length > 0 &&
        tabDefinitions.push({
            id: 'questionnaires',
            name: 'Questionnaires',
            icon: QuestionnaireIcon,
            content: <QuestionnaireResults questionnaires={questionnaires} />,
        });

    const courseStartDateFormatted = new Date(courseSchedule.startDate).toLocaleDateString();
    const courseEndDateFormatted = new Date(courseSchedule.endDate).toLocaleDateString();

    const endOfYesterday = new Date();
    endOfYesterday.setDate(endOfYesterday.getDate() - 1);
    endOfYesterday.setHours(23, 59, 59, 59);

    const courseHasEnded = new Date(courseSchedule.endDate) <= endOfYesterday;

    return (
        <>
            <SubpageHeader
                title={courseSchedule.course.title}
                shadedBackground
                backButtonDetails={backButtonDetails}
            />
            <TopSection id="topSection">
                <TopSectionContent>
                    <DatesAndButtonsContainer id="datesAndButtonsContainer">
                        <DatesContainer id="datesContainer">
                            <DateContainer id="startDate">
                                <ScheduleIcon />
                                <BodyRegular>
                                    {`${strings.scheduledCourseDetails.schedule.startDate} ${courseStartDateFormatted}`}
                                </BodyRegular>
                            </DateContainer>
                            <DateContainer id="endDate">
                                <ScheduleIcon />
                                <BodyRegular>
                                    {`${strings.scheduledCourseDetails.schedule.endDate} ${courseEndDateFormatted}`}
                                </BodyRegular>
                            </DateContainer>
                            {courseHasEnded && (
                                <AlertContainer>
                                    <WarningIcon />
                                    <ErrorMessage $bold>
                                        {strings.scheduledCourseDetails.schedule.courseHasEnded}
                                    </ErrorMessage>
                                </AlertContainer>
                            )}
                        </DatesContainer>
                        <ShareAndCancelCourse
                            courseSchedule={courseSchedule}
                            numberOfLearners={courseSchedule?.enrolledUsers?.length ?? 0}
                            closeEnrolLinkModalButtonRef={closeEnrolLinkModalButtonRef}
                            cancelCourseButtonRef={cancelCourseButtonRef}
                        />
                    </DatesAndButtonsContainer>

                    {userIsOrgAdmin && courseSchedule.user && (
                        <TrainerCard
                            firstName={courseSchedule.user.firstName}
                            lastName={courseSchedule.user.lastName}
                            email={courseSchedule.user.email}
                        />
                    )}
                </TopSectionContent>
            </TopSection>
            <MainSectionWithGaps>
                <TabStructureComponent tabs={tabDefinitions} initialActiveTabId={activeTabId} />
            </MainSectionWithGaps>
        </>
    );
}

const DatesAndButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingRg};
    max-width: ${sizes.appMaxWidthWithoutPadding}px;
    padding: 0 ${sizes.edgePadding};
    @media (max-width: ${breakpoints.sm}) {
        padding: unset;
    }
`;

const DatesContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
`;

const TopSection = styled.div`
    margin: ${sizes.spacingLg} 0;
    width: 100%;
`;

const TopSectionContent = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: ${sizes.appMaxWidthWithoutPadding}px;
    margin: auto;
    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
        margin: 0 ${sizes.spacingMd};
        gap: ${sizes.spacingLg};
    }
`;

const DateContainer = styled.div`
    display: flex;
    gap: ${sizes.spacingMd};
`;

const AlertContainer = styled.div`
    display: flex;
    gap: ${sizes.spacingSm};
    align-items: center;
`;
