import { API_URL, ApiResponse, getHeaders } from './_api-helpers';

export default async function manualFetch<T>(
    endpoint: string,
    accessToken: string,
    method: string = 'GET',
    body?: string | undefined,
): Promise<ApiResponse<T>> {
    const url = `${API_URL}/${endpoint}`;

    const headers = getHeaders(accessToken);

    const response = await fetch(url, {
        headers,
        method,
        body,
    });

    return await response.json();
}
