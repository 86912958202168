import CloseIcon from 'assets/icons/controls/CloseIcon';
import { IconSize } from 'assets/icons/icon-sizes';
import LightBulbIcon from 'assets/icons/LightBulbIcon';
import { BodySmall, TertiaryButton } from 'components/Elements';
import React from 'react';
import styled from 'styled-components';
import { sizes, theme } from 'theme';
import strings from '../strings/strings.json';

type HintProps = {
    hint: string;
};

export default function Hint({ hint }: HintProps): JSX.Element {
    const [showHint, setShowHint] = React.useState<boolean>(true);

    if (!showHint) return <></>;

    return (
        <HintContainer>
            <IconAndText>
                <LightBulbIconContainer>
                    <LightBulbIcon size={IconSize.MEDIUM} colour={theme.textColour} />
                </LightBulbIconContainer>
                <BodySmall>{hint}</BodySmall>
            </IconAndText>
            <ButtonContainer>
                <TertiaryButton onClick={() => setShowHint(false)} aria-label={strings.hint.closeButtonAria}>
                    <CloseIcon size={IconSize.SMALL} colour={theme.textColour} />
                </TertiaryButton>
            </ButtonContainer>
        </HintContainer>
    );
}

const ButtonContainer = styled.div`
    margin-left: ${sizes.spacingMd};
`;

const IconAndText = styled.div`
    display: flex;
    align-items: center;
    gap: ${sizes.spacingMd};
`;

const LightBulbIconContainer = styled.div`
    width: ${sizes.spacingRg};
`;

const HintContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${theme.courseEditHintBackgroundColour};
    padding: ${sizes.spacingRg};
`;
