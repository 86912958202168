import styled from 'styled-components';
import { sizes } from 'theme';
import { BodyRegular, BodySmall, Headline5 } from 'components/Elements';
import NeveLearningLogoMonochrome from 'assets/icons/footer/NeveLearningLogoMonochrome';
import strings from '../../../strings/strings.json';
import Footer from '../../../components/footer/Footer';

type LearnerCoursePageFooterProps = {
    trainerEmail: string;
    organisation: string;
};

export default function LearnerCoursePageFooter({ trainerEmail, organisation }: LearnerCoursePageFooterProps) {
    return (
        <Footer>
            <Headline5 $inverted>{organisation}</Headline5>
            <ContactInfoContainer>
                <NeveLearningLogoMonochrome />
                <div>
                    <BodyRegular $inverted $bold>
                        {strings.learnerCoursePage.footerContactText}
                    </BodyRegular>
                    <BodySmall $inverted>{trainerEmail}</BodySmall>
                </div>
            </ContactInfoContainer>
        </Footer>
    );
}

const ContactInfoContainer = styled.div`
    display: flex;
    gap: ${sizes.spacingLg};
    align-items: center;
    word-break: break-word;
`;
