import { theme } from 'theme';
import { IconSize } from '../icon-sizes';

export default function FileIcon({ size = IconSize.LARGE, colour = theme.textColour }): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask
                id="mask0_36_92"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="38"
                height="38"
            >
                <rect width="38" height="38" fill={colour} />
            </mask>
            <g mask="url(#mask0_36_92)">
                <path
                    d="M12.6666 28.5H25.3333V25.3333H12.6666V28.5ZM12.6666 22.1666H25.3333V19H12.6666V22.1666ZM9.49998 34.8333C8.62915 34.8333 7.88366 34.5232 7.26352 33.9031C6.64338 33.2829 6.33331 32.5375 6.33331 31.6666V6.33329C6.33331 5.46246 6.64338 4.71697 7.26352 4.09683C7.88366 3.4767 8.62915 3.16663 9.49998 3.16663H22.1666L31.6666 12.6666V31.6666C31.6666 32.5375 31.3566 33.2829 30.7364 33.9031C30.1163 34.5232 29.3708 34.8333 28.5 34.8333H9.49998ZM20.5833 14.25V6.33329H9.49998V31.6666H28.5V14.25H20.5833Z"
                    fill={colour}
                />
            </g>
        </svg>
    );
}
