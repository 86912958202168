import { BodySmall, Headline5 } from '../Elements';
import PrimaryButton from 'components/buttons/PrimaryButton';
import styled from 'styled-components';
import { sizes } from 'theme';
import strings from '../../strings/strings.json';

type EmptyTableContentProps = {
    icon?: JSX.Element;
    title?: string;
    description?: string;
    buttonTitle?: string;
    buttonAria?: string;
    buttonIcon?: JSX.Element;
    buttonOnClick?: () => void;
};

export function EmptyTableContent({
    icon,
    title,
    description,
    buttonTitle,
    buttonAria,
    buttonIcon,
    buttonOnClick,
}: EmptyTableContentProps): JSX.Element {
    return (
        <EmptyTableContentContainer>
            {icon && icon}
            <Headline5>{title ?? strings.emptyTableContent.title}</Headline5>
            <BodySmall>{description ?? strings.emptyTableContent.description}</BodySmall>
            {buttonTitle && buttonAria && buttonOnClick && (
                <PrimaryButton title={buttonTitle} aria={buttonAria} icon={buttonIcon} onClick={buttonOnClick} />
            )}
        </EmptyTableContentContainer>
    );
}

const EmptyTableContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${sizes.spacingMd};
    margin: ${sizes.spacingRg};
`;
