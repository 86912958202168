// @ts-ignore
import confetti from '../../../../../assets/animations/confetti.gif';
import {Headline3 } from '../../../../../components/Elements';
import strings from '../../../../../strings/strings.json';
import Notification, { NotificationType } from '../../../../../components/notifiers/Notification';
import * as React from 'react';
import styled from 'styled-components';
import { breakpoints, sizes } from '../../../../../theme';

export default function QuestionnaireComplete(): JSX.Element {
    return (
        <CompleteContainer>
            <Image src={confetti} aria-hidden={true} />
            <TextContent>
                <Headline3>{strings.questionnaireCompletePage.questionnaireCompleteHeaderText}</Headline3>
                <NotificationContainer>
                    <Notification
                        notificationType={NotificationType.SUCCESS}
                        title={strings.questionnaireCompletePage.successNotificationText}
                    />
                </NotificationContainer>
            </TextContent>
        </CompleteContainer>
    );
}

const CompleteContainer = styled.div`
    position: relative;
`;

const NotificationContainer = styled.div`
    width: 60%;
    @media (max-width: ${breakpoints.sm}) {
        width: unset;
    }
`;

const Image = styled.img`
    position: absolute;
    z-index: -100;
    width: 100%;
    height: auto;
    top: -100px;
    left: 0;
    @media (max-width: ${breakpoints.sm}) {
        top: 0;
    }
`;

const TextContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingXl};
    margin-top: 100px;
`;
