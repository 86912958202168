import React from 'react';
import styled from 'styled-components';
import { breakpoints, sizes, theme } from 'theme';
import { useLocation } from 'react-router-dom';
import SecondaryButton from 'components/buttons/SecondaryButton';
import strings from '../../strings/strings.json';
import { BodyLarge, Headline3, Headline5, TertiaryButton } from 'components/Elements';
import { AuthContext } from 'contextProviders/AuthContext';
import CloseIcon from 'assets/icons/controls/CloseIcon';
import { IconSize } from 'assets/icons/icon-sizes';
import useWindowWidth from 'hooks/useWindowWidth';

export default function ContactSupportCard({
    closePanel,
    panelIsOpen,
}: {
    closePanel: () => void;
    panelIsOpen: boolean;
}) {
    const { userData } = React.useContext(AuthContext);
    const location = useLocation();
    const currentUserId = userData.id;
    const currentUserLocation = location.pathname;
    const { isMobileWidth } = useWindowWidth();

    function sendEmailToSupport() {
        const emailSubject = encodeURIComponent('Neve Learning Support');
        const emailBody = encodeURIComponent(
            `Describe your issue or feedback here: \n
            \n
            \n
            (User Id: ${currentUserId}, Location: ${currentUserLocation})`,
        );
        const mailtoLink = `mailto:support@nevelearning.co.uk?subject=${emailSubject}&body=${emailBody}`;

        window.location.href = mailtoLink;
    }

    return (
        <Card>
            <HeadlineAndCloseIconContainer>
                <Headline3>{strings.contactPanel.headlineText}</Headline3>
                <CloseButton
                    onClick={() => closePanel()}
                    aria-label={strings.contactPanel.closeButtonAria}
                    disabled={panelIsOpen ? false : true}
                >
                    <CloseIcon size={isMobileWidth ? IconSize.SMALL : IconSize.MEDIUM} />
                </CloseButton>
            </HeadlineAndCloseIconContainer>
            <BodyLarge>{strings.contactPanel.bodyText}</BodyLarge>
            <EmailText>{strings.contactPanel.neveSupportEmail}</EmailText>
            <SecondaryButton
                title={strings.contactPanel.sendEmailButton.title}
                aria={strings.contactPanel.sendEmailButton.aria}
                onClick={sendEmailToSupport}
                disabled={panelIsOpen ? false : true}
            />
        </Card>
    );
}

const Card = styled.div`
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingLg};
    @media (max-width: ${breakpoints.sm}) {
        padding: 20px;
    }
`;

const HeadlineAndCloseIconContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const CloseButton = styled(TertiaryButton)`
    padding: ${sizes.spacingXs} ${sizes.spacingXs} 0px ${sizes.spacingXs};
`;

const EmailText = styled(Headline5)`
    color: ${theme.primaryBrandColour};
`;
