import { theme } from '../../../theme';
import { IconSize } from '../icon-sizes';

export default function PlusIcon({
    plusColour = theme.primaryBrandColour,
    circleColour = theme.cardBackground,
    size = IconSize.SMALL,
}: {
    plusColour?: string;
    circleColour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="49.6367" cy="49.6367" r="36" fill={circleColour} />
            <path d="M62.3838 50L37.6174 50" stroke={plusColour} strokeWidth="8.25545" strokeLinecap="round" />
            <path d="M50 37.6169V62.3833" stroke={plusColour} strokeWidth="8.25545" strokeLinecap="round" />
        </svg>
    );
}
