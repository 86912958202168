import { LearnerActivity } from '../../../../../models/LearnerCourse';
import React from 'react';
import useModifyRequest from '../../../../../hooks/useModifyRequest';
import useGetRequest from '../../../../../hooks/useGetRequest';
import { LearnerQuestionnaire } from '../../../../../models/LearnerQuestionnaire';
import QuestionnaireContentLayout from './QuestionnaireContentLayout';
import { QuestionnaireAnswer } from '../../../../../models/QuestionnaireAnswer';
import { ApiResponse } from '../../../../../lib/_api-helpers';
import { PreviewContext } from '../../../../../contextProviders/PreviewContext';
import { v4 as uuidv4 } from 'uuid';

type QuestionnaireContentProps = {
    activity: LearnerActivity;
    courseScheduleId: string;
};

export default function QuestionnaireContentData({
    activity,
    courseScheduleId,
}: QuestionnaireContentProps): JSX.Element {
    const [currentQuestionNumber, setCurrentQuestionNumber] = React.useState<number | null>(null);

    const { modifyData: setActivityCompletion } = useModifyRequest(
        `learner/schedule/${courseScheduleId}/activity/${activity.id}/complete`,
        'POST',
    );

    const {
        data: questionnaire,
        loading,
        errors: questionnaireErrors,
        refetchData,
    } = useGetRequest<LearnerQuestionnaire>(`learner/schedule/${courseScheduleId}/questionnaire/${activity.id}`);

    const currentQuestion = questionnaire?.questions.find((x) => x.order === currentQuestionNumber);

    const { modifyData: postAnswer } = useModifyRequest(
        `learner/schedule/${courseScheduleId}/question/${currentQuestion?.id ?? null}`,
        'POST',
    );

    type AnswerFormData = {
        answer: string;
    };

    const submitAnswer = async (answer: string): Promise<ApiResponse<QuestionnaireAnswer>> => {
        return await postAnswer<AnswerFormData, QuestionnaireAnswer>({ answer: answer });
    };

    return (
        <QuestionnaireContentLayout
            questionnaire={questionnaire}
            loading={loading}
            errors={questionnaireErrors}
            currentQuestionNumber={currentQuestionNumber}
            currentQuestion={currentQuestion}
            refetchQuestionnaire={refetchData}
            setActivityToComplete={async () => {
                return await setActivityCompletion({ completed: new Date() });
            }}
            activitySummary={activity}
            answerQuestion={submitAnswer}
            setQuestionNumber={setCurrentQuestionNumber}
        />
    );
}

export function QuestionnairePreviewContentData({ activity }: QuestionnaireContentProps): JSX.Element {
    const [currentQuestionNumber, setCurrentQuestionNumber] = React.useState<number | null>(null);
    const [currentQuestionnaire, setCurrentQuestionnaire] = React.useState<LearnerQuestionnaire | null>(null);

    const previewContext = React.useContext(PreviewContext);

    React.useEffect(() => {
        if (!previewContext.scheduledCourse) return;

        const questionnaireActivity = previewContext.scheduledCourse.course.modules
            .flatMap((m) => m.activities)
            .find((a) => a.id === activity.id);

        if (!questionnaireActivity) {
            setCurrentQuestionnaire(null);
            return;
        }

        setCurrentQuestionnaire({
            id: questionnaireActivity.id,
            title: questionnaireActivity.title,
            description: questionnaireActivity.content,
            completed: questionnaireActivity.statuses.length > 0 && !!questionnaireActivity.statuses[0].completed,
            questions: questionnaireActivity.questions.map((x) => {
                return {
                    id: x.id,
                    question: x.question,
                    helpText: x.helpText,
                    order: x.order,
                    answer: x.answers[0] ? x.answers[0].answer : null,
                };
            }),
        });
    }, [activity.id, previewContext.scheduledCourse]);

    const currentQuestion = currentQuestionnaire?.questions.find((x) => x.order === currentQuestionNumber);

    async function completeQuestionnaire() {
        if (!previewContext.scheduledCourse) throw new Error('Course not found');
        if (!currentQuestionnaire) throw new Error('Questionnaire not found');

        previewContext.scheduledCourse.course.modules[moduleIndex].activities[activityIndex].statuses[0].completed =
            new Date();

        previewContext.setScheduledCourse(previewContext.scheduledCourse);

        setCurrentQuestionnaire({
            id: currentQuestionnaire.id,
            title: currentQuestionnaire.title,
            description: currentQuestionnaire.description,
            completed: true,
            questions: currentQuestionnaire.questions,
        });

        return;
    }

    async function answerQuestion(answer: string): Promise<ApiResponse<QuestionnaireAnswer>> {
        if (previewContext.scheduledCourse === undefined) throw new Error();
        if (!currentQuestionnaire) throw new Error();

        const question = previewContext.scheduledCourse.course.modules[moduleIndex].activities[
            activityIndex
        ].questions.find((x) => x.order === currentQuestionNumber);

        if (!question) return { errors: [{ field: '', code: '500' }] };

        if (question.answers[0] === undefined) {
            const newAnswer: QuestionnaireAnswer = {
                id: uuidv4(),
                answer: answer,
                dateCreated: new Date().toDateString(),
                dateUpdated: new Date().toDateString(),
                questionId: question.id,
                question: question,
            };

            question.answers = [newAnswer];
        } else {
            const question = previewContext.scheduledCourse.course.modules[moduleIndex].activities[
                activityIndex
            ].questions.find((x) => x.order === currentQuestionNumber);

            if (!question) return { errors: [{ field: '', code: '500' }] };

            question.answers[0].answer = answer;
            question.answers[0].dateUpdated = new Date().toDateString();
        }

        previewContext.setScheduledCourse(previewContext.scheduledCourse);

        setCurrentQuestionnaire((oldQuestionnaire) => {
            const questionToUpdate = oldQuestionnaire?.questions.find((x) => x.order === currentQuestionNumber);

            if (!questionToUpdate) return null;

            questionToUpdate.answer = answer;

            return oldQuestionnaire;
        });

        return { value: question.answers[0] };
    }

    if (!previewContext.scheduledCourse) return <>Course not found.</>;

    const moduleIndex = previewContext.scheduledCourse.course.modules.findIndex((m) =>
        m.activities.find((a) => a.id === activity.id),
    );

    const activityIndex = previewContext.scheduledCourse.course.modules[moduleIndex].activities.findIndex(
        (a) => a.id === activity.id,
    );

    if (!activity) return <>Questionnaire not found.</>;

    return (
        <QuestionnaireContentLayout
            activitySummary={activity}
            loading={false}
            errors={[]}
            currentQuestionNumber={currentQuestionNumber}
            currentQuestion={currentQuestion}
            setQuestionNumber={(newQuestionNumber: number) => {
                setCurrentQuestionNumber(newQuestionNumber);
            }}
            answerQuestion={answerQuestion}
            setActivityToComplete={async () => {
                await completeQuestionnaire();
                return { value: true };
            }}
            questionnaire={currentQuestionnaire}
            refetchQuestionnaire={async () => {}}
        />
    );
}
