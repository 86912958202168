import * as React from 'react';

type KeyboardTrapRef = {
    ref: React.RefObject<any>;
};

export default function useKeyboardTrap(closeModal: () => void, disabled?: boolean): KeyboardTrapRef {
    React.useEffect(() => {
        if (disabled) return;
        if (!modalRef.current) return;
        const focusableModalElements = findFocusableElements();
        const firstElement = focusableModalElements[0];

        firstElement.focus();

        function keyListener(e: KeyboardEvent) {
            if (!modalRef.current) return;
            const listener = keyListenersMap.get(e.key);
            return listener && listener(e);
        }

        document.addEventListener('keydown', keyListener);
        document.body.style.overflow = 'hidden';

        return () => {
            document.removeEventListener('keydown', keyListener);
            document.body.style.overflow = 'visible';
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabled]);

    const modalRef = React.createRef<any>();
    function findFocusableElements() {
        return modalRef.current.querySelectorAll(
            'a[href], button:not([disabled]):not([tabindex="-1"]), label[tabindex="0"], textarea, input[type="text"], input[type="number"], input[type="radio"], input[type="checkbox"], select',
        );
    }

    const handleTabKey = (e: any) => {
        if (disabled) return;
        const focusableModalElements = findFocusableElements();

        const firstElement = focusableModalElements[0];
        const lastElement = focusableModalElements[focusableModalElements.length - 1];
        if (!e.shiftKey && document.activeElement === lastElement) {
            firstElement.focus();
            return e.preventDefault();
        }

        if (e.shiftKey && document.activeElement === firstElement) {
            lastElement.focus();
            return e.preventDefault();
        }
    };

    const keyListenersMap = new Map([
        // escape key - by convention this should close a modal/overlay.
        ['Escape', closeModal],
        ['Tab', handleTabKey],
    ]);

    return { ref: modalRef };
}
