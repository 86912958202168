import { theme } from 'theme';
import { IconSize } from '../icon-sizes';

export default function ArrowUpIcon({
    colour = theme.primaryButtonBackgroundColour,
    size = IconSize.MEDIUM,
}: {
    colour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="12" transform="rotate(-180 12 12)" fill={colour} />
            <path d="M6 14L12.0716 8L18 14" stroke="white" strokeWidth="2" strokeLinecap="round" />
        </svg>
    );
}
