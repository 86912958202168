import * as React from 'react';
import { findHighestRole, RoleType, userHasZoomIntegration, UserModel } from '../models/User';
import { logError } from '../lib/debug-helpers';
import { API_URL, ApiResponse, getHeaders } from '../lib/_api-helpers';
import { jwtDecode } from 'jwt-decode';
import { Organisation } from 'models/Organisation';

const AUTH_URL = process.env.REACT_APP_AUTH_BASE_URL;
const CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID;
const SCOPES = ['email', 'openid', 'profile'];
const REDIRECT_URI = process.env.REACT_APP_AUTH_REDIRECT_URI;
const LOGOUT_URI = process.env.REACT_APP_AUTH_LOGOUT_URI;

export type Tokens = {
    accessToken: string | null;
    idToken: string | null;
    refreshToken: string | null;
};

export type UserData = {
    id: string;
    tokens: Tokens;
    courseSchedules: string[];
    roles: RoleType[];
    activeRole: RoleType | 'LEARNER';
    organisation: Organisation | null;
    hasZoomIntegration: boolean;
};

type CognitoIdToken = {
    given_name: string;
    family_name: string;
    email: string;
};

type CognitoAccessToken = {
    exp: number;
};

type AuthState = {
    login: (_: string | null) => void;
    logout: () => void;
    exchangeToken: (code: string) => Promise<void>;
    refreshToken: () => Promise<string>;
    userData: UserData;
    refreshUserData: () => Promise<void>;
    userTokenDetails: CognitoIdToken;
    updateUserDataItem: (k: string, v: string) => void;
    isAuthenticated: boolean;
    tokenExpiryTime: number;
    setNewActiveRole: (newRole: RoleType | 'LEARNER') => void;
    loginFailed: boolean;
    setLoginFailed: (value: React.SetStateAction<boolean>) => void;
};

async function getOrCreateApiUser(accessToken: string, idToken: string): Promise<UserModel> {
    const headers = getHeaders(accessToken);

    let user: UserModel;

    const userResponse = await fetch(`${API_URL}/users/me`, { headers });

    if (userResponse.status === 404) {
        const { given_name, family_name, email } = jwtDecode<CognitoIdToken>(idToken);

        const userCreateResponse = await fetch(`${API_URL}/users/me`, {
            headers,
            method: 'POST',
            body: JSON.stringify({
                email: email,
                firstName: given_name,
                lastName: family_name,
            }),
        });

        const userCreateResponseJson: ApiResponse<UserModel> = await userCreateResponse.json();

        if (userCreateResponseJson.errors || !userCreateResponseJson.value) {
            if (
                userCreateResponseJson.errors &&
                userCreateResponseJson.errors[0] &&
                userCreateResponseJson.errors[0].code === 'userWithEmailAlreadyExists'
            ) {
                throw new Error(
                    'User could not be created because there is an existing user with the same email address.',
                );
            }

            throw new Error('User could not be found or created.');
        }

        user = userCreateResponseJson.value;
    } else {
        const userResponseJson: ApiResponse<UserModel> = await userResponse.json();

        if (!userResponseJson.value) {
            throw new Error('User could not be found or created.');
        }

        user = userResponseJson.value;
    }

    return user;
}

export const AuthContext = React.createContext<AuthState>({
    login: (_: string | null) => {},
    logout: () => {},
    exchangeToken: async (_: string) => {},
    refreshToken: async () => {
        return '';
    },
    refreshUserData: async () => {},
    userData: {
        id: '',
        tokens: { accessToken: null, idToken: null, refreshToken: null },
        courseSchedules: [],
        roles: [],
        activeRole: 'LEARNER',
        organisation: null,
        hasZoomIntegration: false,
    },
    userTokenDetails: { given_name: '', family_name: '', email: '' },
    updateUserDataItem: (_, __) => {},
    isAuthenticated: false,
    tokenExpiryTime: 0,
    setNewActiveRole: (_: RoleType | 'LEARNER') => {},
    loginFailed: false,
    setLoginFailed: () => {},
});

export function AuthProvider(props: { children: React.ReactNode | React.ReactNode[] }): JSX.Element {
    const [userData, setUserData] = React.useState<UserData>(_getUserData());
    const [loginFailed, setLoginFailed] = React.useState<boolean>(false);

    function login(returnPath: string | null) {
        const loginEndpoint = `${AUTH_URL}/oauth2/authorize`;

        window.location.href = `${loginEndpoint}?client_id=${CLIENT_ID}&response_type=code&scope=${SCOPES.join(
            '+',
        )}&redirect_uri=${REDIRECT_URI}&state=${returnPath}`;
    }

    async function exchangeToken(code: string) {
        const body = `grant_type=authorization_code&client_id=${CLIENT_ID}&code=${code}&redirect_uri=${REDIRECT_URI}`;

        const tokenUrl = `${AUTH_URL}/oauth2/token`;

        const response = await fetch(tokenUrl, {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body,
        });
        if (!response.ok) {
            logError('code to token exchange failed.');
        }

        const { access_token, id_token, refresh_token } = await response.json();

        // get/create user in API
        let user: UserModel;

        try {
            user = await getOrCreateApiUser(access_token, id_token);
        } catch (error) {
            logError(error);
            setLoginFailed(true);
            return;
        }

        // get course schedule ids
        let courseSchedules: string[] = [];

        if (user?.courseSchedules && user?.courseSchedules.length > 0) {
            courseSchedules = user.courseSchedules.map((x) => x.id);
        }

        storeUserData({
            id: user?.id,
            tokens: { accessToken: access_token, idToken: id_token, refreshToken: refresh_token },
            courseSchedules,
            roles: user?.roles.map((x) => x.name),
            activeRole: findHighestRole(user?.roles.map((x) => x.name)),
            organisation: user.organisation,
            hasZoomIntegration: userHasZoomIntegration(user),
        });
    }

    async function refreshUserData(): Promise<void> {
        const currentUserData = _getUserData();
        const access_token = currentUserData.tokens.accessToken ?? '';
        const id_token = currentUserData.tokens.idToken ?? '';
        const refresh_token = currentUserData.tokens.refreshToken ?? '';

        const headers = getHeaders(access_token);

        const userResponse = await fetch(`${API_URL}/users/me`, { headers });

        const userResponseJson: ApiResponse<UserModel> = await userResponse.json();

        if (!userResponseJson.value) {
            throw new Error('User could not be found or created.');
        }

        const user = userResponseJson.value;

        let courseSchedules: string[] = [];

        if (user?.courseSchedules && user?.courseSchedules.length > 0) {
            courseSchedules = user.courseSchedules.map((x) => x.id);
        }

        storeUserData({
            id: user?.id,
            tokens: { accessToken: access_token, idToken: id_token, refreshToken: refresh_token },
            courseSchedules,
            roles: user?.roles.map((x) => x.name),
            activeRole: findHighestRole(user?.roles.map((x) => x.name)),
            organisation: user.organisation,
            hasZoomIntegration: userHasZoomIntegration(user),
        });
    }

    async function refreshToken(): Promise<string> {
        const body = `grant_type=refresh_token&client_id=${CLIENT_ID}&refresh_token=${userData.tokens.refreshToken}`;

        const tokenUrl = `${AUTH_URL}/oauth2/token`;

        const response = await fetch(tokenUrl, {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body,
        });
        if (!response.ok) {
            logError('refresh failed.');
            logout();
            return '';
        }

        const { access_token, id_token } = await response.json();

        // get/create user in API
        let user: UserModel;

        try {
            user = await getOrCreateApiUser(access_token, id_token);
        } catch (error) {
            logError(error);
            logout();
            return '';
        }

        // get course schedules
        let courseSchedules: string[] = [];

        if (user?.courseSchedules && user?.courseSchedules.length > 0) {
            courseSchedules = user.courseSchedules.map((x) => x.id);
        }

        storeUserData({
            id: user?.id,
            tokens: { accessToken: access_token, idToken: id_token, refreshToken: userData.tokens.refreshToken },
            courseSchedules,
            roles: user?.roles.map((x) => x.name),
            activeRole: findHighestRole(user?.roles.map((x) => x.name)),
            organisation: user.organisation,
            hasZoomIntegration: userHasZoomIntegration(user),
        });

        return access_token;
    }

    function logout() {
        clearUserData();
        const logoutEndpoint = `${AUTH_URL}/logout`;
        window.location.href = `${logoutEndpoint}?client_id=${CLIENT_ID}&logout_uri=${LOGOUT_URI}`;
    }

    function storeUserData(user: UserData) {
        setUserData(user);

        window.localStorage.setItem('accessToken', user.tokens.accessToken ?? '');
        window.localStorage.setItem('idToken', user.tokens.idToken ?? '');
        window.localStorage.setItem('refreshToken', user.tokens.refreshToken ?? '');
        window.localStorage.setItem('courseSchedules', user.courseSchedules.toString() ?? '');
        window.localStorage.setItem('roles', user.roles.toString() ?? '');
        window.localStorage.setItem('activeRole', user.activeRole);
        window.localStorage.setItem('organisation', user.organisation ? JSON.stringify(user.organisation) : '');
        window.localStorage.setItem('hasZoomIntegration', JSON.stringify(user.hasZoomIntegration));
        window.localStorage.setItem('id', user.id ?? '');
    }

    function clearUserData() {
        setUserData({
            id: '',
            tokens: { accessToken: null, idToken: null, refreshToken: null },
            courseSchedules: [],
            roles: [],
            activeRole: 'LEARNER',
            organisation: null,
            hasZoomIntegration: false,
        });

        window.localStorage.setItem('accessToken', '');
        window.localStorage.setItem('idToken', '');
        window.localStorage.setItem('refreshToken', '');
        window.localStorage.setItem('courseScheduleId', '');
        window.localStorage.setItem('roles', '');
        window.localStorage.setItem('activeRole', '');
        window.localStorage.setItem('organisation', '');
        window.localStorage.setItem('hasZoomIntegration', '');
        window.localStorage.setItem('id', '');
    }

    function updateUserDataItem(k: string, v: string) {
        setUserData({
            ...userData,
            [k]: v,
        });
    }

    function _getUserData(): UserData {
        const organisationAsString = window.localStorage.getItem('organisation');
        const organisation = organisationAsString ? JSON.parse(organisationAsString) : null;

        return {
            id: window.localStorage.getItem('id') ?? '',
            tokens: {
                accessToken: window.localStorage.getItem('accessToken') ?? null,
                idToken: window.localStorage.getItem('idToken') ?? null,
                refreshToken: window.localStorage.getItem('refreshToken') ?? null,
            },
            courseSchedules: window.localStorage.getItem('courseSchedules')
                ? (window.localStorage.getItem('courseSchedules')?.split(',') as string[])
                : [],
            roles: window.localStorage.getItem('roles')
                ? (window.localStorage.getItem('roles')?.split(',') as RoleType[])
                : [],
            activeRole: (window.localStorage.getItem('activeRole') as RoleType) ?? 'LEARNER',
            organisation: organisation,
            hasZoomIntegration: (window.localStorage.getItem('hasZoomIntegration') ?? 'false') === 'true',
        };
    }

    let userDetails = { given_name: '', family_name: '', email: '' };

    if (userData.tokens.idToken) {
        userDetails = jwtDecode<CognitoIdToken>(userData.tokens.idToken);
    }

    let tokenExpiryTime = 0;

    if (userData.tokens.accessToken) {
        tokenExpiryTime = jwtDecode<CognitoAccessToken>(userData.tokens.accessToken).exp;
    }

    function setNewActiveRole(newRole: RoleType | 'LEARNER') {
        updateUserDataItem('activeRole', newRole);
    }

    return (
        <AuthContext.Provider
            value={{
                login,
                exchangeToken,
                refreshToken,
                userData,
                refreshUserData,
                userTokenDetails: userDetails,
                updateUserDataItem,
                logout,
                isAuthenticated: !!userData.tokens.accessToken,
                tokenExpiryTime,
                setNewActiveRole,
                loginFailed,
                setLoginFailed,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
}
