import React from 'react';
import { useBlocker } from 'react-router-dom';

export default function useFormBlocker(activateBlocker: boolean, confirmMessage: string) {
    const blocker = useBlocker(activateBlocker);

    React.useEffect(() => {
        if (blocker.state === 'blocked') {
            //using timeout to fix firefox issue: https://github.com/facebook/react/issues/17355
            setTimeout(() => {
                if (window.confirm(confirmMessage)) {
                    blocker.proceed();
                } else {
                    blocker.reset();
                }
            }, 0);
        }
    }, [blocker]); // eslint-disable-line

    const handleBeforeUnload = React.useCallback(
        (event: any) => {
            if (activateBlocker) {
                event.preventDefault();
            }
        },
        [activateBlocker],
    );

    React.useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [handleBeforeUnload]);
}
