import * as React from 'react';
import styled from 'styled-components';
import strings from '../../strings/strings.json';
import { breakpoints, sizes, theme } from 'theme';
import { Step, StepsPanel } from './components/StepsPanel';
import PageInfoIcon from 'assets/icons/controls/PageInfoIcon';
import FileMultipleIcon from 'assets/icons/FileMultipleIcon';
import FlagIcon from 'assets/icons/FlagIcon';
import Skeleton from 'react-loading-skeleton';
import { ErrorNotifier } from 'components/notifiers/ErrorNotifier';
import useWindowWidth from 'hooks/useWindowWidth';
import { CourseEditorButtons } from './components/CourseEditorButtons';
import { CourseDetailsForm } from '../trainer/course/components/CourseDetailsForm';
import CourseStepContent from './components/CourseStepContent';
import CourseLearningObjectivesForm from '../trainer/course/components/CourseLearningObjectivesForm';
import { CourseStructureAndContentComponent } from './components/CourseStructureAndContentComponent';
import { EditCourseContext } from 'contextProviders/EditCourseContext';
import { CourseGeneratedContentIsApproved, CourseIsReadyToPublish } from 'models/Course';
import { useLocation, useNavigate } from 'react-router-dom';
import BoltIcon from 'assets/icons/BoltIcon';
import CourseAIGeneratedContentComponent from './components/generateContentComponents/CourseAIGeneratedContentComponent';
import BetaLabel from './components/BetaLabel';

export type StepId =
    | 'courseDetails'
    | 'courseLearningObjectives'
    | 'courseStructureAndContent'
    | 'courseAIGeneratedContent';

type State = {
    stepId: StepId;
    editActivityId: string;
};

export default function CourseEditorPage(): JSX.Element {
    const { state } = useLocation();
    const { stepId, editActivityId } = (state as State) ?? {};

    const [activeStep, setActiveStep] = React.useState<StepId>('courseDetails');
    const [stateSteps, setStateSteps] = React.useState<Step[]>([]);
    const [activeStepContent, setActiveStepContent] = React.useState<JSX.Element>();
    const [showIncompleteStepsWarning, setShowIncompleteStepsWarning] = React.useState<boolean>(false);
    const [showPublishWarning, setShowPublishWarning] = React.useState<boolean>(false);

    React.useEffect(() => {
        setActiveStep(stepId ?? 'courseDetails');
    }, [stepId]);

    const context = React.useContext(EditCourseContext);
    const navigate = useNavigate();

    const changeStep = (stepId: StepId) => {
        navigate(`/edit-course/${context.course!.id}`, { state: { stepId: stepId } });
    };

    React.useEffect(() => {
        if (context.course) {
            const steps: Step[] = [
                {
                    id: 'courseDetails',
                    title: strings.courseEditorPage.steps.courseDetails.title,
                    aria: strings.courseEditorPage.steps.courseDetails.aria,
                    icon: PageInfoIcon,
                    action: () => changeStep('courseDetails'),
                    content: (
                        <CourseStepContent
                            key={'courseDetails'}
                            title={strings.courseEditorPage.steps.courseDetails.title}
                            hint={strings.courseEditorPage.steps.courseDetails.hint}
                            content={
                                <CourseDetailsForm
                                    course={context.course!}
                                    refetchCourse={context.refetchCourse}
                                    showIncompleteStepsWarning={showIncompleteStepsWarning}
                                />
                            }
                        />
                    ),
                    completed:
                        !!context.course.title &&
                        context.course.minimumLearners >= 1 &&
                        context.course.maximumLearners >= 1,
                },
                {
                    id: 'courseLearningObjectives',
                    title: strings.courseEditorPage.steps.learningObjectives.title,
                    aria: strings.courseEditorPage.steps.learningObjectives.aria,
                    icon: FlagIcon,
                    action: () => changeStep('courseLearningObjectives'),
                    content: (
                        <CourseStepContent
                            key={'courseLearningObjectives'}
                            title={strings.courseEditorPage.steps.learningObjectives.title}
                            hint={strings.courseEditorPage.steps.learningObjectives.hint}
                            content={
                                <CourseLearningObjectivesForm
                                    course={context.course!}
                                    refetchCourse={context.refetchCourse}
                                    showIncompleteStepsWarning={showIncompleteStepsWarning}
                                />
                            }
                        />
                    ),
                    completed: context.course.learningObjectives.length >= 1,
                },
                {
                    id: 'courseStructureAndContent',
                    title: strings.courseEditorPage.steps.structureAndContent.title,
                    aria: strings.courseEditorPage.steps.structureAndContent.aria,
                    icon: FileMultipleIcon,
                    action: () => changeStep('courseStructureAndContent'),
                    content: (
                        <CourseStepContent
                            key={'courseStructureAndContent'}
                            content={
                                <CourseStructureAndContentComponent
                                    showIncompleteStepsWarning={showIncompleteStepsWarning}
                                    editActivityId={editActivityId}
                                />
                            }
                        />
                    ),
                    completed:
                        context.course.modules.length >= 1 &&
                        context.course.modules.every((m) => m.activities.length >= 1),
                },
                {
                    id: 'courseAIGeneratedContent',
                    title: strings.courseEditorPage.steps.aiGeneratedContent.title,
                    aria: strings.courseEditorPage.steps.aiGeneratedContent.aria,
                    icon: BoltIcon,
                    action: () => changeStep('courseAIGeneratedContent'),
                    content: (
                        <CourseStepContent
                            key={'courseAIGeneratedContent'}
                            content={
                                <CourseAIGeneratedContentComponent
                                    showIncompleteStepsWarning={showIncompleteStepsWarning}
                                />
                            }
                        />
                    ),
                    completed: CourseGeneratedContentIsApproved(context.course),
                    completedIcon: <BetaLabel />,
                },
            ];

            setStateSteps(steps);

            if (CourseIsReadyToPublish(context.course)) {
                setShowPublishWarning(false);
            }
        }
    }, [context.course, showIncompleteStepsWarning, showPublishWarning, editActivityId]); // eslint-disable-line

    React.useEffect(() => {
        const step = stateSteps.find((step) => step.id === activeStep);

        if (step) {
            setActiveStepContent(step.content);
        }
    }, [activeStep, stateSteps]);

    const { isMobileWidth } = useWindowWidth();

    if (!context.loading && !context.called) {
        return <></>;
    }

    if (context.loading && !context.called) {
        return (
            <CourseEditorContent id="course-editor-content">
                <Skeleton height={100} />
            </CourseEditorContent>
        );
    }

    if (context.errors.length > 0 || !context.course) {
        return (
            <CourseEditorContent id="course-editor-content">
                <ErrorNotifier title={'Error'} description={'No course found.'} />
            </CourseEditorContent>
        );
    }

    const courseReadyToPublish = CourseIsReadyToPublish(context.course);

    return (
        <CourseEditorContent id="course-editor-content">
            <Grid id="course-editor-content-grid">
                {isMobileWidth ? (
                    <>
                        <StepsSection
                            courseId={context.course.id}
                            steps={stateSteps}
                            activeStep={activeStep}
                            showIncompleteStepsWarning={showIncompleteStepsWarning}
                        />
                        <ButtonsSection
                            courseId={context.course.id}
                            courseReadyToPublish={courseReadyToPublish}
                            setShowIncompleteStepsWarning={setShowIncompleteStepsWarning}
                            showPublishWarning={showPublishWarning}
                            setShowPublishWarning={setShowPublishWarning}
                        />
                    </>
                ) : (
                    <>
                        <StepsAndButtonsSection id="steps-panel">
                            <StepsSection
                                courseId={context.course.id}
                                steps={stateSteps}
                                activeStep={activeStep}
                                showIncompleteStepsWarning={showIncompleteStepsWarning}
                            />
                            <ButtonsSection
                                courseId={context.course.id}
                                courseReadyToPublish={courseReadyToPublish}
                                setShowIncompleteStepsWarning={setShowIncompleteStepsWarning}
                                showPublishWarning={showPublishWarning}
                                setShowPublishWarning={setShowPublishWarning}
                            />
                        </StepsAndButtonsSection>
                        <StepContent id="step-content">{activeStepContent}</StepContent>
                    </>
                )}
            </Grid>
        </CourseEditorContent>
    );
}

const StepsAndButtonsSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right: 1px solid ${theme.cardSecondaryBorder};
    grid-column: 1;
    grid-row: 1;

    @media (max-width: ${breakpoints.xl}) {
        gap: ${sizes.spacingLg};
    }
`;

const StepsSection = styled(StepsPanel)`
    @media (max-width: ${breakpoints.sm}) {
        grid-column: 1;
        grid-row: 1;
    }
`;

const ButtonsSection = styled(CourseEditorButtons)`
    @media (max-width: ${breakpoints.sm}) {
        grid-column: 1;
        grid-row: 2;
    }
`;

const StepContent = styled.div`
    grid-column: 2;
    grid-row: 1;
    display: flex;
    flex-direction: column;
    padding: ${sizes.spacingLg} ${sizes.spacingLg} 0;
    overflow-y: scroll;

    & > div {
        max-width: 800px;
    }

    @media (max-width: ${breakpoints.xl}) {
        padding: ${sizes.spacingLg} ${sizes.spacingSm} 0 ${sizes.spacingRg};
    }

    @media (max-width: ${breakpoints.md}) {
        padding: ${sizes.spacingLg} ${sizes.spacingSm} 0 ${sizes.spacingRg};
    }
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: 20% 80%;
    height: calc(100vh - 150px);

    @media (max-width: ${breakpoints.xl}) {
        grid-template-columns: 30% 70%;
    }

    @media (max-width: ${breakpoints.md}) {
        grid-template-columns: 40% 60%;
    }

    @media (max-width: ${breakpoints.sm}) {
        grid-template-columns: 100%;
        margin-top: ${sizes.spacingMd};
        row-gap: ${sizes.spacingLg};
    }
`;

const CourseEditorContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 ${sizes.edgePadding};
    width: 100%;
`;
