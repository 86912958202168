import * as React from 'react';
import styled from 'styled-components';
import strings from '../../../strings/strings.json';
import EditableModuleList from './EditableModuleList';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { sizes } from 'theme';
import { ErrorMessage, FormCard } from 'components/forms/FormComponents';
import NavBackButton from 'components/buttons/NavBackButton';
import { EditCourseContext } from 'contextProviders/EditCourseContext';
import { ActivityTypeSelectionPageContent } from './ActivityTypeSelectionComponent';
import ActivityUpdateComponent from './ActivityUpdateComponent';
import { ModuleForm } from './ModuleForm';
import { Headline3 } from 'components/Elements';
import Notification, { NotificationType } from 'components/notifiers/Notification';
import { EveryModuleHasActivity } from 'models/Course';
import WarningIcon from 'assets/icons/indicators/WarningIcon';
import useModifyRequest from 'hooks/useModifyRequest';
import GenerateContentModal, { GenerateModalState } from './taskContentForms/GenerateContentModal';
import { CheckboxOption } from 'components/forms/Checkboxes';

type Component =
    | { name: 'modulesList' }
    | { name: 'addModule' }
    | { name: 'editModule'; moduleId: string }
    | { name: 'addActivity'; moduleId: string }
    | { name: 'editActivity'; moduleId: string; activityId: string };

type CourseStructureAndContentComponentProps = {
    showIncompleteStepsWarning: boolean;
    editActivityId: string;
};

export function CourseStructureAndContentComponent({
    showIncompleteStepsWarning,
    editActivityId,
}: CourseStructureAndContentComponentProps): JSX.Element {
    const [component, setComponent] = React.useState<Component>({ name: 'modulesList' });
    const [activeModuleId, setActiveModuleId] = React.useState<string>();

    const context = React.useContext(EditCourseContext);

    React.useEffect(() => {
        if (context.course && context.course.modules.length === 1) {
            const moduleId = context.course.modules[0].id;

            if (editActivityId) {
                setComponent({ name: 'editActivity', moduleId, activityId: editActivityId });
            } else {
                setActiveModuleId(moduleId);
            }
        }
    }, [context.course, editActivityId]);

    if (!context.course) return <></>;

    switch (component.name) {
        case 'modulesList':
            return (
                <ModulesList
                    activeModuleId={activeModuleId}
                    setActiveModuleId={setActiveModuleId}
                    setComponent={setComponent}
                    showIncompleteStepsWarning={showIncompleteStepsWarning}
                />
            );
        case 'addModule':
            return (
                <Base setComponent={setComponent}>
                    <ModuleForm
                        mode={'Create'}
                        courseId={context.course.id}
                        closeForm={(moduleId?: string) => {
                            setComponent({ name: 'modulesList' });
                            setActiveModuleId(moduleId ?? '');
                        }}
                    />
                </Base>
            );
        case 'editModule':
            const module = context.course.modules.find((module) => module.id === component.moduleId);

            if (!module) return <></>;

            return (
                <Base setComponent={setComponent}>
                    <ModuleForm
                        mode={'Edit and Delete'}
                        courseId={context.course.id}
                        module={module}
                        closeForm={() => setComponent({ name: 'modulesList' })}
                    />
                </Base>
            );
        case 'addActivity':
            return (
                <Base setComponent={setComponent}>
                    <ActivityTypeSelectionPageContent
                        moduleId={component.moduleId}
                        saveActivityClick={() => setComponent({ name: 'modulesList' })}
                    />
                </Base>
            );
        case 'editActivity':
            return (
                <Base setComponent={setComponent}>
                    <ActivityUpdateComponent
                        activityId={component.activityId}
                        completeUpdateQuestionnaire={() => setComponent({ name: 'modulesList' })}
                        focusContent={!!editActivityId}
                    />
                </Base>
            );
    }
}

type ModulesListProps = {
    activeModuleId: string | undefined;
    setActiveModuleId: React.Dispatch<React.SetStateAction<string | undefined>>;
    setComponent: (value: React.SetStateAction<Component>) => void;
    showIncompleteStepsWarning: boolean;
};

function ModulesList({
    activeModuleId,
    setActiveModuleId,
    setComponent,
    showIncompleteStepsWarning,
}: ModulesListProps): JSX.Element {
    const context = React.useContext(EditCourseContext);

    const [generateState, setGenerateState] = React.useState<GenerateModalState>(
        context.activityIdToShowGenerateContentModal ? 'show' : 'hide',
    );
    const [addAudio, setAddAudio] = React.useState<boolean>(false);

    const { modifyData: generateAudio, loading: generatingAudio } = useModifyRequest(
        `activities/task/${context.activityIdToShowGenerateContentModal}/generate/audio`,
        'POST',
    );

    React.useEffect(() => {
        if (generatingAudio) {
            setGenerateState('submitting');
        }
    }, [generatingAudio]);

    function showEditModule(moduleId: string) {
        setActiveModuleId(moduleId);
        setComponent({ name: 'editModule', moduleId: moduleId });
    }

    function showAddActivity(moduleId: string) {
        setActiveModuleId(moduleId);
        setComponent({ name: 'addActivity', moduleId: moduleId });
    }

    function showEditActivity(moduleId: string, activityId: string) {
        setActiveModuleId(moduleId);
        setComponent({ name: 'editActivity', moduleId: moduleId, activityId: activityId });
    }

    const addModuleButton = (
        <PrimaryButton
            title={strings.courseContentPage.addModuleButton}
            aria={strings.courseContentPage.addModuleButtonAria}
            onClick={() => setComponent({ name: 'addModule' })}
        />
    );

    const generateContent = async () => {
        if (!addAudio) {
            setGenerateState('hide');
        } else {
            const response = await generateAudio({});
            setGenerateState(response.value === true ? 'success' : 'error');
        }
    };

    const generateContentOptions: CheckboxOption[] = [
        {
            name: strings.generateContentModal.options.textToAudio,
            value: addAudio.toString(),
            inputProps: { onChange: () => setAddAudio(!addAudio) },
        },
    ];

    const closeModal = async () => {
        setGenerateState('hide');
        context.setActivityIdToShowGenerateContentModal(undefined);
        await context.refetchCourse();
    };

    const everyModuleHasActivity = EveryModuleHasActivity(context.course!);

    if (context.course!.modules.length > 0) {
        return (
            <>
                {generateState !== 'hide' && (
                    <GenerateContentModal
                        generateContent={generateContent}
                        close={closeModal}
                        contentOptions={generateContentOptions}
                        state={generateState}
                    />
                )}
                <CourseContentPageContent>
                    <Headline3>{strings.courseEditorPage.steps.structureAndContent.title}</Headline3>
                    {!everyModuleHasActivity && showIncompleteStepsWarning && (
                        <NotificationContainer>
                            <Notification
                                title={strings.courseEditorPage.incompleteModuleWarning.title}
                                description={strings.courseEditorPage.incompleteModuleWarning.description}
                                notificationType={NotificationType.WARNING}
                                maxWidth="100%"
                            />
                        </NotificationContainer>
                    )}
                    <EditableModuleList
                        courseId={context.course!.id}
                        modules={context.course!.modules}
                        activeModuleId={activeModuleId}
                        refetchCourse={context.refetchCourse}
                        editModuleClick={showEditModule}
                        addActivityClick={showAddActivity}
                        editActivityClick={showEditActivity}
                        showIncompleteStepsWarning={showIncompleteStepsWarning}
                    />
                    <AddModuleButtonRow>{addModuleButton}</AddModuleButtonRow>
                </CourseContentPageContent>
            </>
        );
    } else {
        return (
            <CourseContentPageContent>
                <Headline3>{strings.courseEditorPage.steps.structureAndContent.title}</Headline3>
                <NoModulesCard>
                    <Warning>
                        <WarningIcon />
                        <ErrorMessage>{strings.courseContentPage.noModules}</ErrorMessage>
                    </Warning>
                    {addModuleButton}
                </NoModulesCard>
            </CourseContentPageContent>
        );
    }
}

type BaseProps = {
    children: React.ReactNode;
    setComponent: (value: React.SetStateAction<Component>) => void;
};

const Base: React.FC<BaseProps> = ({ children, setComponent }) => {
    return (
        <>
            <NavBackButton
                backButtonTitle={strings.courseContentPage.backButton.title}
                backButtonAria={strings.courseContentPage.backButton.aria}
                backButtonHref={''}
                buttonFunctionOverride={() => setComponent({ name: 'modulesList' })}
            />
            {children}
        </>
    );
};

const AddModuleButtonRow = styled.div`
    display: flex;
    justify-content: end;
    width: 100%;
`;

const NoModulesCard = styled(FormCard).attrs({ as: 'div' })`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${sizes.spacingLg};
    padding: ${sizes.spacingLg} ${sizes.spacingMd};
    width: 100%;
`;

const CourseContentPageContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
    align-items: flex-start;
    width: 100%;
`;

const NotificationContainer = styled.div`
    width: 100%;
`;

const Warning = styled.div`
    display: flex;
    align-items: center;
    gap: ${sizes.spacingSm};
`;
