import { theme } from 'theme';
import { IconSize } from '../icon-sizes';

export default function AddIcon({
    colour = theme.primaryBrandColour,
    size = IconSize.SMALL,
}: {
    colour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 8L2 8" stroke={colour} strokeWidth="4" strokeLinecap="round" />
            <path d="M8 2V14" stroke={colour} strokeWidth="4" strokeLinecap="round" />
        </svg>
    );
}
