import styled from 'styled-components';
import strings from 'strings/strings.json';
import { WrappingText } from 'components/Elements';
import { CourseSchedule } from 'models/CourseSchedule';
import { theme } from 'theme';
import Table, { RightAlignedButtonCell, TableCell } from 'components/table/Table';
import useWindowWidth from 'hooks/useWindowWidth';
import { LocationType } from '../../../../models/ActivityMeeting';
import { IconSize } from '../../../../assets/icons/icon-sizes';
import NavigateWithArrow from '../../../../components/actions/NavigateWithArrow';
import VideoChatIcon from '../../../../assets/icons/pathway/VideoChatIcon';

type ScheduledLiveTrainingProps = {
    courseSchedule: CourseSchedule;
};

export function ScheduledLiveTrainingsTable({ courseSchedule }: ScheduledLiveTrainingProps): JSX.Element {
    const { isMobileWidth } = useWindowWidth();

    return (
        <Table
            headers={[
                {
                    name: strings.scheduledCourseDetailsPage.headers.liveTraining,
                    order: 1,
                    rowPercentage: 40,
                },
                {
                    name: strings.scheduledCourseDetailsPage.headers.startDate,
                    order: 2,
                    rowPercentage: 15,
                },
                {
                    name: strings.scheduledCourseDetailsPage.headers.time,
                    order: 3,
                    rowPercentage: 15,
                },
                {
                    name: strings.scheduledCourseDetailsPage.headers.location,
                    order: 4,
                    rowPercentage: 20,
                },
                {
                    name: '',
                    order: 5,
                    rowPercentage: 10,
                    rightAligned: true,
                    mobileRightColumn: true,
                },
            ]}
            rows={
                courseSchedule.meetings.map((meeting) => {
                    const d = new Date(meeting.meetingDatetime);
                    const shortDate = d.toLocaleDateString();
                    const time = d.toLocaleString('en-GB', {
                        hour: 'numeric',
                        minute: 'numeric',
                        hourCycle: 'h12',
                    });

                    const location =
                        // eslint-disable-next-line eqeqeq
                        meeting.locationType == LocationType.ONLINE
                            ? strings.scheduledCourseDetailsPage.onlineLocation
                            : meeting.location ?? '';

                    const linkHref = `/schedule/courses/${courseSchedule.id}/live-training/${meeting.activityId}`;

                    const cells: TableCell[] = [
                        {
                            name: strings.scheduledCourseDetailsPage.headers.liveTraining,
                            value: (
                                <LiveTrainingRow key={meeting.id}>
                                    {!isMobileWidth && (
                                        <IconContainer>
                                            <VideoChatIcon />
                                        </IconContainer>
                                    )}
                                    <WrappingText $bold>{meeting.activity.title}</WrappingText>
                                </LiveTrainingRow>
                            ),
                        },
                        {
                            name: strings.scheduledCourseDetailsPage.headers.startDate,
                            value: shortDate,
                        },
                        {
                            name: strings.scheduledCourseDetailsPage.headers.time,
                            value: time,
                        },
                        {
                            name: strings.scheduledCourseDetailsPage.headers.location,
                            value: location,
                        },
                        {
                            name: '',
                            value: (
                                <RightAlignedButtonCell>
                                    <NavigateWithArrow
                                        title={strings.scheduledCourseDetailsPage.viewLiveMeetingDetails}
                                        aria={strings.liveTrainingDetailPage.detailLinkAria}
                                        href={linkHref}
                                        iconSize={IconSize.LARGE}
                                    />
                                </RightAlignedButtonCell>
                            ),
                        },
                    ];

                    return {
                        id: meeting.id,
                        cells: cells,
                    };
                }) ?? []
            }
        />
    );
}

const LiveTrainingRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    background-color: ${theme.iconBackgroundColour};
    border-radius: 4px;
    margin-right: 10px;
    padding: 3px;
`;
