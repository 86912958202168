import strings from '../../../strings/strings.json';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from 'components/buttons/PrimaryButton';
import SecondaryButton from 'components/buttons/SecondaryButton';
import styled from 'styled-components';
import { sizes, breakpoints } from 'theme';
import CourseBuilderValidationWarning from './CourseBuilderValidationWarning';
import { Dispatch, SetStateAction } from 'react';

type CourseEditorButtonsProps = {
    courseId: string;
    courseReadyToPublish: boolean;
    setShowIncompleteStepsWarning: Dispatch<SetStateAction<boolean>>;
    showPublishWarning: boolean;
    setShowPublishWarning: Dispatch<SetStateAction<boolean>>;
};

export function CourseEditorButtons({
    courseId,
    courseReadyToPublish,
    setShowIncompleteStepsWarning,
    showPublishWarning,
    setShowPublishWarning,
}: CourseEditorButtonsProps): JSX.Element {
    const navigate = useNavigate();

    return (
        <ButtonsContainer id="course-editor-buttons">
            {showPublishWarning && (
                <CourseBuilderValidationWarning
                    title={strings.courseEditorPage.stepsButtonValidation.title}
                    description={strings.courseEditorPage.stepsButtonValidation.description}
                    setShowPublishWarning={setShowPublishWarning}
                />
            )}
            <SecondaryButton
                onClick={() => {
                    navigate('/trainer/dashboard');
                }}
                title={strings.courseEditorPage.saveButtonTitle}
                aria={strings.courseEditorPage.saveButtonTitleAria}
            />
            <SecondaryButton
                onClick={() => {
                    navigate(`/preview/${courseId}`, {
                        state: { previewContextEntryPoint: `/edit-course/${courseId}` },
                    });
                }}
                title={strings.courseEditorPage.previewCourseButtonTitle}
                aria={strings.courseEditorPage.previewCourseButtonAria}
            />
            <PrimaryButton
                onClick={() => {
                    if (courseReadyToPublish) {
                        navigate(`/trainer/course/${courseId}/view`);
                        setShowIncompleteStepsWarning(false);
                        setShowPublishWarning(false);
                    } else {
                        setShowIncompleteStepsWarning(true);
                        setShowPublishWarning(true);
                    }
                }}
                title={strings.courseEditorPage.reviewAndPublishButtonTitle}
                aria={strings.courseEditorPage.reviewAndPublishButtonTitleAria}
            />
        </ButtonsContainer>
    );
}

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
    padding-right: ${sizes.spacingMd};

    @media (max-width: ${breakpoints.md}) {
        padding-bottom: ${sizes.spacingXl};
    }

    @media (max-width: ${breakpoints.sm}) {
        padding-right: unset;
    }
`;
