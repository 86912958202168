import * as React from 'react';
import { AuthContext } from '../../contextProviders/AuthContext';
import { Navigate, useLocation } from 'react-router-dom';
import { Headline1 } from '../../components/Elements';
import styled from 'styled-components';
import strings from '../../strings/strings.json';
import NeveLogo from '../../components/NeveLogo';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import Notification, { NotificationType } from 'components/notifiers/Notification';

export type LoginState = {
    returnPath: string;
};

type State = {
    loginFailed: boolean;
};

export default function LoginPage(): JSX.Element {
    const { login, isAuthenticated } = React.useContext(AuthContext);
    const location = useLocation();

    const [loginFailed] = React.useState((location.state as State)?.loginFailed);

    const returnPath = (location.state as LoginState)?.returnPath ?? null;

    if (isAuthenticated) {
        return <Navigate to="/" replace />;
    }

    return (
        <WelcomeContainer>
            <NeveLogoContainer>
                <NeveLogo />
            </NeveLogoContainer>
            <Headline1 id="header">{strings.loginPage.title}</Headline1>
            <ButtonContainer>
                <PrimaryButton
                    title={strings.loginPage.submitButton}
                    aria={strings.loginPage.submitButtonAriaLabel}
                    onClick={() => login(returnPath ?? null)}
                    fullwidth
                />
            </ButtonContainer>
            {loginFailed && (
                <Notification
                    notificationType={NotificationType.DANGER}
                    title={strings.loginPage.errors.loginErrorTitle}
                    description={strings.loginPage.errors.loginErrorDescription}
                />
            )}
        </WelcomeContainer>
    );
}

const WelcomeContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
`;

const NeveLogoContainer = styled.div`
    margin-bottom: -20px;
    & svg {
        height: auto;
        width: 17rem;
    }
`;

const ButtonContainer = styled.div`
    width: 17rem;
    margin: 0 auto;
`;
