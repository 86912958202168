import React from 'react';
import { ModalBackground } from 'components/Elements';
import useKeyboardTrap from 'hooks/useKeyboardTrap';
import styled from 'styled-components';
import { breakpoints } from 'theme';
import RemoveLearnerForm from './RemoveLearnerForm';

export type RemoveLearnerModalProps = {
    courseScheduleId: string;
    idOfLearnerToDelete: string;
    closeModal: () => void;
    onCompleted: () => void;
};

export type ReasonForRemoval = {
    reasonForRemoval: string;
};

export default function RemoveLearnerModal({
    courseScheduleId,
    idOfLearnerToDelete,
    closeModal,
    onCompleted,
}: RemoveLearnerModalProps): JSX.Element {
    const { ref } = useKeyboardTrap(closeModal);

    // in the return statement, we have had to seperate the ModalBody and the Form into seperate components
    // useNeveForm was unsetting the ref and breaking the keyboard trap
    return (
        <ModalBackgroundWithScroll>
            <ModalBody ref={ref}>
                <RemoveLearnerForm
                    courseScheduleId={courseScheduleId}
                    idOfLearnerToDelete={idOfLearnerToDelete}
                    closeModal={closeModal}
                    onCompleted={onCompleted}
                />
            </ModalBody>
        </ModalBackgroundWithScroll>
    );
}
const ModalBackgroundWithScroll = styled(ModalBackground)`
    @media (max-width: ${breakpoints.sm}) {
        overflow-y: scroll;
    }
`;

const ModalBody = styled.div``;
