import React from 'react';
import styled from 'styled-components';
import { breakpoints, sizes, theme } from 'theme';
import AccordionPanel, { AccordionPanelProps } from './AccordionPanel';

type AccordionProps = {
    panels: AccordionPanelProps[];
    width?: string;
};

export default function Accordion({ panels, width }: AccordionProps): JSX.Element {
    return (
        <AccordionContainer width={width}>
            {panels.map((panel, index) => {
                return (
                    <AccordionPanel
                        key={index}
                        headerTitle={panel.headerTitle}
                        openPanel={panel.openPanel}
                        panelCompleted={panel.panelCompleted}
                        completedItemsText={panel.completedItemsText}
                        panelItems={panel.panelItems}
                    />
                );
            })}
        </AccordionContainer>
    );
}

export const AccordionContainer = styled.div<{ $isDraggingOver?: boolean; width?: string }>`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};

    background-color: ${({ $isDraggingOver }) => {
        return $isDraggingOver ? theme.primaryButtonHoverBackgroundColour : '';
    }};

    width: ${({ width }) => {
        return width ? width : '100%';
    }};

    @media (max-width: ${breakpoints.md}) {
        width: 100%;
    }
`;
