import React, { useContext } from 'react';
import strings from '../../strings/strings.json';
import styled from 'styled-components';
import { breakpoints, sizes } from 'theme';
import { CourseSchedule } from 'models/CourseSchedule';
import SecondaryButton, { SecondaryButtonColour } from 'components/buttons/SecondaryButton';
import { CopyLinkModal, CopyLinkModalProps, CopyLinkModalState } from 'components/modals/CopyLinkModal';
import ShareIcon from 'assets/icons/controls/ShareIcon';
import Alert from 'components/alert/Alert';
import PrimaryButton, { PrimaryButtonColour } from 'components/buttons/PrimaryButton';
import useModifyRequest from 'hooks/useModifyRequest';
import { logError } from 'lib/debug-helpers';
import { AuthContext } from 'contextProviders/AuthContext';
import { useNavigate } from 'react-router-dom';
import { RoleType } from 'models/User';
import WarningIcon from '../../assets/icons/indicators/WarningIcon';
import { ErrorMessage } from '../forms/FormComponents';
import { Flex } from 'components/Elements';

type ShareCourseProps = {
    courseSchedule: CourseSchedule;
    numberOfLearners: number;
    closeEnrolLinkModalButtonRef: React.RefObject<HTMLButtonElement>;
    cancelCourseButtonRef: React.RefObject<HTMLButtonElement>;
};

export default function ShareAndCancelCourse({
    courseSchedule,
    numberOfLearners,
    closeEnrolLinkModalButtonRef,
    cancelCourseButtonRef,
}: ShareCourseProps): JSX.Element {
    const navigate = useNavigate();
    const { userData } = useContext(AuthContext);

    const [copyLinkState, setCopyLinkState] = React.useState<CopyLinkModalState>({
        modalProps: {} as CopyLinkModalProps,
        modalOpen: false,
    });
    const [cancelAlertOpen, setCancelAlertOpen] = React.useState<boolean>(false);
    const [courseToCancel, setCourseToCancel] = React.useState<string | null>(null);
    const [cancelCourseError, setCancelCourseError] = React.useState<boolean>(false);

    const { modifyData: cancelCourse } = useModifyRequest(
        `organisations/${userData?.organisation?.id}/scheduled-courses/${courseToCancel}/cancel`,
        'POST',
    );
    function closeCancelAlert() {
        setCourseToCancel(null);
        setCancelAlertOpen(false);
        cancelCourseButtonRef.current && cancelCourseButtonRef.current.focus();
    }

    async function cancelSelectedCourse() {
        const response = await cancelCourse({});

        if (response.errors) {
            logError(response.errors);
            setCancelCourseError(true);
            return;
        }

        setCourseToCancel(null);
        setCancelAlertOpen(false);
        navigate('/organisation/courses');
    }

    function openCopyModal(url: string) {
        setCopyLinkState({
            modalOpen: true,
            modalProps: {
                title: strings.scheduledCourseDetails.schedule.copyLinkModalTitle,
                description: strings.scheduledCourseDetails.schedule.copyLinkModalDescription,
                inputValue: url,
                primaryButtonTitle: strings.scheduledCourseDetails.schedule.copyButtonTitle,
                primaryButtonAria: strings.scheduledCourseDetails.schedule.copyButtonAria,
                closeButtonAria: strings.scheduledCourseDetails.schedule.closeButtonAria,
                onClickCloseButton: () => {},
            },
        });
    }

    function closeCopyModal() {
        setCopyLinkState({
            modalOpen: false,
            modalProps: {} as CopyLinkModalProps,
        });
        closeEnrolLinkModalButtonRef.current && closeEnrolLinkModalButtonRef.current.focus();
    }

    const userIsOrgAdmin = userData.activeRole === RoleType.ORGANISATION_ADMIN;

    if (!courseSchedule.user) {
        return <></>;
    }

    const courseIsFull = numberOfLearners >= courseSchedule.course.maximumLearners;

    const endOfYesterday = new Date();
    endOfYesterday.setDate(endOfYesterday.getDate() - 1);
    endOfYesterday.setHours(23, 59, 59, 59);

    const courseHasEnded = new Date(courseSchedule.endDate) <= endOfYesterday;

    return (
        <ControlsSection>
            <ButtonsContainer id="shareAndCancelCourseButtons">
                <PrimaryButton
                    aria={strings.scheduledCoursesPage.viewCoursePageButtonAria}
                    title={strings.scheduledCoursesPage.viewCoursePageButtonTitle}
                    onClick={() => navigate(`/public/course/${courseSchedule.id}`)}
                />
                {!courseHasEnded && (
                    <SecondaryButton
                        title={strings.scheduledCoursesPage.copyEnrolLinkButtonTitle}
                        aria={strings.scheduledCoursesPage.copyEnrolLinkButtonAria}
                        onClick={() => {
                            const copyLinkUrl = `${window.location.origin}/public/course/${courseSchedule.id}`;
                            openCopyModal(copyLinkUrl);
                        }}
                        icon={<ShareIcon />}
                        buttonRef={closeEnrolLinkModalButtonRef}
                        disabled={courseIsFull}
                    />
                )}
                {userIsOrgAdmin && !courseHasEnded && (
                    <SecondaryButton
                        title={strings.scheduledCoursesPage.cancelCourseButtonTitle}
                        aria={strings.scheduledCoursesPage.cancelCourseButtonAria}
                        onClick={() => {
                            setCancelAlertOpen(true);
                            setCourseToCancel(courseSchedule.id);
                        }}
                        alternateColour={SecondaryButtonColour.DANGER}
                        buttonRef={cancelCourseButtonRef}
                    />
                )}
            </ButtonsContainer>
            {courseIsFull && !courseHasEnded && (
                <AlertContainer>
                    <WarningIcon />
                    <ErrorMessage $bold>{strings.scheduledCoursesPage.courseFullWarningMoreDetail}</ErrorMessage>
                </AlertContainer>
            )}

            {copyLinkState.modalOpen && (
                <CopyLinkModal {...copyLinkState.modalProps} onClickCloseButton={closeCopyModal} />
            )}

            {cancelAlertOpen && courseToCancel && (
                <Alert
                    alertText={strings.scheduledCoursesPage.cancelCourseAlertText}
                    buttonText={strings.scheduledCoursesPage.cancelSubmitButtonText}
                    ariaButtonText={strings.scheduledCoursesPage.cancelSubmitButtonAria}
                    buttonColour={PrimaryButtonColour.DANGER}
                    onSubmit={cancelSelectedCourse}
                    closeAlert={closeCancelAlert}
                    error={
                        cancelCourseError
                            ? {
                                  title: strings.scheduledCoursesPage.cancelCourseErrorTitle,
                                  description: strings.scheduledCoursesPage.cancelCourseErrorDescription,
                              }
                            : undefined
                    }
                />
            )}
        </ControlsSection>
    );
}

const ButtonsContainer = styled(Flex)`
    gap: ${sizes.spacingRg};
    width: 100%;
    @media (max-width: ${breakpoints.lg}) {
        flex-direction: column;
    }
`;

const AlertContainer = styled.div`
    display: flex;
    gap: ${sizes.spacingSm};
    align-items: center;
`;

const ControlsSection = styled.div`
    display: flex;
    gap: ${sizes.spacingSm};
    flex-direction: column;
`;
