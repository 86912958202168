import { theme } from '../../../theme';
import { IconSize } from '../icon-sizes';

export default function VideoChatIcon({
    colour = theme.textColour,
    size = IconSize.MEDIUM,
}: {
    colour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask
                id="mask0_3058_9206"
                maskUnits="userSpaceOnUse"
                x="0"
                y="4"
                width="24"
                height="14"
            >
                <path
                    d="M0 5.04951V13.813C0.00770607 15.7946 1.58745 17.3894 3.51397 17.3815H15.9824C16.3369 17.3815 16.622 17.0894 16.622 16.7342V7.97067C16.6143 5.98901 15.0346 4.39422 13.108 4.40211H0.639604C0.285125 4.40211 0 4.69423 0 5.04951ZM17.4157 8.46799L22.5634 4.61521C23.0103 4.23625 23.3571 4.33099 23.3571 5.01786V16.7657C23.3571 17.5473 22.9333 17.4525 22.5634 17.1683L17.4157 13.3234V8.46799Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_3058_9206)">
                <path
                    d="M-1.1145 18.1402H24.1943V2.90891H-1.1145V18.1402Z"
                    fill={colour}
                />
            </g>
        </svg>
    );
}
