import { theme } from '../../../theme';
import { IconSize } from '../icon-sizes';

export default function LinkIcon({
    colour = theme.primaryBrandColour,
    linkColour = theme.cardBackground,
    size = IconSize.MEDIUM,
}: {
    colour?: string;
    linkColour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12.9438" cy="12.9439" r="12" fill={colour} />
            <path
                d="M14.8127 10.8673C16.3734 12.4295 16.352 14.9342 14.8221 16.4725C14.8193 16.4757 14.8159 16.4791 14.8127 16.4822L13.0574 18.2375C11.5092 19.7857 8.99045 19.7855 7.44251 18.2375C5.89433 16.6896 5.89433 14.1705 7.44251 12.6226L8.41174 11.6534C8.66877 11.3963 9.11141 11.5672 9.12468 11.9304C9.14161 12.3933 9.22462 12.8584 9.37779 13.3075C9.42967 13.4596 9.3926 13.6278 9.27898 13.7414L8.93714 14.0833C8.20508 14.8153 8.18212 16.0073 8.90697 16.7466C9.63898 17.4931 10.8422 17.4975 11.5798 16.7599L13.3351 15.0048C14.0715 14.2684 14.0684 13.0782 13.3351 12.3449C13.2384 12.2484 13.141 12.1735 13.065 12.1211C13.0112 12.0842 12.9667 12.0351 12.9352 11.978C12.9037 11.9208 12.886 11.8571 12.8835 11.7918C12.8732 11.5158 12.971 11.2314 13.1891 11.0133L13.739 10.4633C13.8832 10.3191 14.1095 10.3014 14.2767 10.4181C14.4682 10.5518 14.6476 10.7021 14.8127 10.8673ZM18.4941 7.18577C16.9461 5.63781 14.4273 5.6376 12.8792 7.18577L11.1238 8.94109C11.1207 8.94422 11.1173 8.94762 11.1144 8.95075C9.58464 10.4891 9.5632 12.9938 11.1238 14.556C11.289 14.7212 11.4684 14.8715 11.6599 15.0052C11.8271 15.1219 12.0533 15.1042 12.1975 14.96L12.7475 14.41C12.9656 14.1919 13.0634 13.9075 13.053 13.6314C13.0505 13.5662 13.0328 13.5025 13.0013 13.4453C12.9698 13.3881 12.9254 13.3391 12.8716 13.3022C12.7955 13.2498 12.6981 13.1748 12.6015 13.0783C11.8682 12.345 11.8651 11.1548 12.6015 10.4185L14.3568 8.6634C15.0944 7.92578 16.2976 7.93022 17.0296 8.67672C17.7544 9.41594 17.7315 10.6079 16.9994 11.34L16.6576 11.6818C16.5439 11.7955 16.5069 11.9637 16.5588 12.1158C16.7119 12.5649 16.7949 13.03 16.8119 13.4929C16.8252 13.8561 17.2678 14.0269 17.5248 13.7699L18.494 12.8007C20.0423 11.2528 20.0423 8.73369 18.4941 7.18577Z"
                fill={linkColour}
            />
        </svg>
    );
}
