import styled from 'styled-components';
import { breakpoints, sizes, theme } from '../../theme';
import NavBackButton from '../buttons/NavBackButton';
import { BodyLarge, Headline2 } from '../Elements';
import useWindowWidth from 'hooks/useWindowWidth';

export type BackButtonDetails = {
    title: string;
    aria: string;
    href: string;
    navigateState?: any;
    functionOverride?: Function;
};

export default function SubpageHeader(props: {
    title: string | JSX.Element | null;
    subtitle?: string | JSX.Element | null;
    buttons?: JSX.Element | null;
    backButtonDetails?: BackButtonDetails;
    pageOverlap?: boolean;
    shadedBackground?: boolean;
    contentBeforeTitle?: JSX.Element | null;
}): JSX.Element {
    const { title, subtitle, backButtonDetails, buttons, pageOverlap, shadedBackground, contentBeforeTitle } = props;
    const { isMobileWidth, isTabletWidth, isDesktopWidth } = useWindowWidth();

    function renderDesktopLayout(): JSX.Element {
        if (title || buttons || subtitle || contentBeforeTitle) {
            return (
                <SubpageHeaderMainContentContainer>
                    {contentBeforeTitle}

                    <TitleAndButtonsContainer>
                        {title && <Headline2>{title}</Headline2>}
                        {buttons && <ButtonsContainer>{buttons}</ButtonsContainer>}
                    </TitleAndButtonsContainer>

                    {subtitle && (
                        <SubtitleAndValidationContainer>
                            {subtitle && <SubtitleContainer>{subtitle}</SubtitleContainer>}
                        </SubtitleAndValidationContainer>
                    )}
                </SubpageHeaderMainContentContainer>
            );
        }
        return <></>;
    }

    function renderTabletLayout(): JSX.Element {
        return (
            <SubpageHeaderMainContentContainer>
                {contentBeforeTitle}
                <Headline2>{title}</Headline2>
                {buttons && <ButtonsContainer>{buttons}</ButtonsContainer>}
                {subtitle && <SubtitleContainer>{subtitle}</SubtitleContainer>}
            </SubpageHeaderMainContentContainer>
        );
    }

    function renderMobileLayout(): JSX.Element {
        return (
            <SubpageHeaderMainContentContainer>
                {contentBeforeTitle}
                <Headline2>{title}</Headline2>
                {subtitle && <SubtitleContainer>{subtitle}</SubtitleContainer>}
                {buttons && <ButtonsContainer>{buttons}</ButtonsContainer>}
            </SubpageHeaderMainContentContainer>
        );
    }

    return (
        <BannerBackground $pageOverlap={pageOverlap} $shadedBackground={shadedBackground}>
            <LimitedContainer id="header" $pageOverlap={pageOverlap}>
                {backButtonDetails && (
                    <NavContainer id="backButtonContainer">
                        <NavBackButton
                            backButtonTitle={backButtonDetails.title}
                            backButtonAria={backButtonDetails.aria}
                            backButtonHref={backButtonDetails.href}
                            backButtonNavigateState={backButtonDetails.navigateState}
                            backButtonAsBackButton={false}
                            buttonFunctionOverride={backButtonDetails.functionOverride}
                        />
                    </NavContainer>
                )}

                {isDesktopWidth && renderDesktopLayout()}
                {isTabletWidth && renderTabletLayout()}
                {isMobileWidth && renderMobileLayout()}
            </LimitedContainer>
        </BannerBackground>
    );
}

const NavContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 80px;
    padding-top: ${sizes.spacingSm};
`;

const LimitedContainer = styled.header<{ $pageOverlap?: boolean }>`
    padding-bottom: ${({ $pageOverlap: $pageoverlap }) => {
        return $pageoverlap ? sizes.spacingLg : '0px';
    }};
    padding: 0 ${sizes.edgePadding};
    margin: auto;
    max-width: ${sizes.appMaxWidthWithoutPadding}px;
    overflow-wrap: anywhere;
`;

const SubpageHeaderMainContentContainer = styled.div`
    margin: ${sizes.spacingLg} 0;
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
`;

const TitleAndButtonsContainer = styled.div`
    display: flex;
    gap: ${sizes.spacingMd};
    align-items: center;
    justify-content: space-between;
`;

const SubtitleAndValidationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: ${sizes.spacingMd};
`;

const SubtitleContainer = styled(BodyLarge)`
    white-space: pre-wrap;
    overflow-wrap: anywhere;
`;

const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: fit-content;
    gap: ${sizes.spacingMd};
    @media (max-width: ${breakpoints.lg}) {
        justify-content: flex-start;
    }
    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
    }
`;

const BannerBackground = styled.div<{ $pageOverlap?: boolean; $shadedBackground?: boolean }>`
    min-height: 64px;
    background: ${({ $shadedBackground }) => {
        return $shadedBackground ? theme.courseBannerBackground : null;
    }};
    width: 100%;
    margin-bottom: ${({ $pageOverlap }) => {
        return $pageOverlap ? `-${sizes.spacingLg}` : 0;
    }};
`;
