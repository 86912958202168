import { theme } from 'theme';
import { IconSize } from '../icon-sizes';

export default function KeyboardCapslockBadgeIcon({ size = IconSize.LARGE, colour = theme.textColour }): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask
                id="mask0_127_332"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="33"
                height="33"
            >
                <rect width="33" height="33" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_127_332)">
                <path
                    d="M9.625 23.375H23.375V20.625H9.625V23.375ZM11.55 17.875L16.5 12.925L21.45 17.875L23.375 15.95L16.5 9.075L9.625 15.95L11.55 17.875ZM6.875 28.875C6.11875 28.875 5.47135 28.6057 4.93281 28.0672C4.39427 27.5286 4.125 26.8813 4.125 26.125V6.875C4.125 6.11875 4.39427 5.47135 4.93281 4.93281C5.47135 4.39427 6.11875 4.125 6.875 4.125H26.125C26.8813 4.125 27.5286 4.39427 28.0672 4.93281C28.6057 5.47135 28.875 6.11875 28.875 6.875V26.125C28.875 26.8813 28.6057 27.5286 28.0672 28.0672C27.5286 28.6057 26.8813 28.875 26.125 28.875H6.875ZM6.875 26.125H26.125V6.875H6.875V26.125Z"
                    fill={colour}
                />
            </g>
        </svg>
    );
}
