import { useParams } from 'react-router-dom';
import { Page, LearnerCourseContentError } from '../components/shared';
import strings from 'strings/strings.json';
import Skeleton from 'react-loading-skeleton';
import { ActivityType } from 'models/Activity';
import QuestionnaireContentData from './components/questionnaire/QuestionnaireContentData';
import React, { useContext } from 'react';
import LearnerCourseSummaryProvider, {
    LearnerCourseSummaryContext,
} from '../../../contextProviders/LearnerCourseSummaryProvider';
import TaskContentData from './components/task/TaskContentData';
import LiveTrainingContentData from './components/live-training/LiveTrainingContentData';

export default function ActivityPage(): JSX.Element {
    const params = useParams();
    const { activityId, courseScheduleId } = params;

    if (!courseScheduleId || !activityId) {
        return (
            <LearnerCourseContentError
                title={strings.activityPage.error.title}
                description={strings.activityPage.error.description}
            />
        );
    }

    return (
        <LearnerCourseSummaryProvider>
            <ActivityTypeSelector activityId={activityId} courseScheduleId={courseScheduleId} />
        </LearnerCourseSummaryProvider>
    );
}

export function ActivityTypeSelector({
    activityId,
    courseScheduleId,
}: {
    activityId: string;
    courseScheduleId: string;
}): JSX.Element {
    let activityContent = <></>;

    const { courseSummary, loading, errors } = useContext(LearnerCourseSummaryContext);

    if (loading) {
        return <ActivityPageBase activityContent={<Skeleton width={'75vw'} height={'50vh'} />} />;
    }

    if (errors.length > 0) {
        return (
            <LearnerCourseContentError
                title={strings.activityPage.error.title}
                description={strings.activityPage.error.description}
            />
        );
    }

    if (!courseSummary) {
        return <></>;
    }

    const activity = courseSummary.modules
        .flatMap((module) => module.activities)
        .find((activity) => activity.id === activityId);

    if (!activity) {
        return <></>;
    }

    switch (activity.type) {
        case ActivityType.LIVE_TRAINING:
            return <LiveTrainingContentData liveTrainingId={activity.id} courseScheduleId={courseScheduleId} />;
        case ActivityType.QUESTIONNAIRE:
            return <QuestionnaireContentData activity={activity} courseScheduleId={courseScheduleId} />;
        case ActivityType.TASK:
            return <TaskContentData taskId={activity.id} courseScheduleId={courseScheduleId} />;
        default:
            break;
    }

    return <ActivityPageBase activityContent={activityContent} />;
}

type ActivityPageBaseProps = {
    activityContent: JSX.Element;
    courseNavigation?: JSX.Element;
};

export function ActivityPageBase({ activityContent }: ActivityPageBaseProps): JSX.Element {
    return <Page id="page">{activityContent}</Page>;
}
