import { theme } from 'theme';
import { IconSize } from './icon-sizes';

export default function LightBulbIcon({
    colour = theme.textColour,
    size = IconSize.MEDIUM,
}: {
    colour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 24 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 31.8334C11.1292 31.8334 10.3837 31.5233 9.76354 30.9031C9.1434 30.283 8.83333 29.5375 8.83333 28.6667H15.1667C15.1667 29.5375 14.8566 30.283 14.2365 30.9031C13.6163 31.5233 12.8708 31.8334 12 31.8334ZM5.66667 27.0834V23.9167H18.3333V27.0834H5.66667ZM6.0625 22.3334C4.24167 21.2514 2.79688 19.8 1.72813 17.9792C0.659375 16.1584 0.125 14.1792 0.125 12.0417C0.125 8.74308 1.27951 5.93926 3.58854 3.63023C5.89757 1.3212 8.70139 0.166687 12 0.166687C15.2986 0.166687 18.1024 1.3212 20.4115 3.63023C22.7205 5.93926 23.875 8.74308 23.875 12.0417C23.875 14.1792 23.3406 16.1584 22.2719 17.9792C21.2031 19.8 19.7583 21.2514 17.9375 22.3334H6.0625ZM7.0125 19.1667H16.9875C18.175 18.3222 19.092 17.2799 19.7385 16.0396C20.3851 14.7993 20.7083 13.4667 20.7083 12.0417C20.7083 9.61391 19.8639 7.55558 18.175 5.86669C16.4861 4.1778 14.4278 3.33335 12 3.33335C9.57222 3.33335 7.51389 4.1778 5.825 5.86669C4.13611 7.55558 3.29167 9.61391 3.29167 12.0417C3.29167 13.4667 3.61493 14.7993 4.26146 16.0396C4.90799 17.2799 5.825 18.3222 7.0125 19.1667Z"
                fill={colour}
            />
        </svg>
    );
}
