import * as React from 'react';
import strings from '../../../strings/strings.json';
import styled from 'styled-components';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { useNavigate, useParams } from 'react-router-dom';
import { BodyRegular, ButtonRowLeft, Headline4, Headline5 } from 'components/Elements';
import { breakpoints, sizes } from 'theme';
import MainSection from 'components/sections/MainSection';
import SubpageHeader from 'components/sections/SubpageHeader';
import WhyIsEditingLimited from './components/WhyIsEditingLimited';
import BulletList from './components/BulletList';
import SecondaryButton from '../../../components/buttons/SecondaryButton';

export default function EditCourseWarningPage(): JSX.Element {
    const params = useParams();
    const { courseId } = params;
    const navigate = useNavigate();

    return (
        <>
            <SubpageHeader title={strings.publishCourseWarningPage.headerEditTitle} />
            <MainSection>
                <Container>
                    <WhatSection>
                        <Headline4>{strings.editCourseWarningPage.headingEdit}</Headline4>
                        <BodyRegular>{strings.editCourseWarningPage.editNote}</BodyRegular>
                    </WhatSection>
                    <EditSection>
                        <BulletSection>
                            <Headline5>{strings.editCourseWarningPage.editSubtitleOne}</Headline5>
                            <BulletList displayText={strings.bulletList.canEdit} />
                        </BulletSection>
                        <BulletSection>
                            <Headline5>{strings.editCourseWarningPage.editSubtitleTwo}</Headline5>
                            <BulletList displayText={strings.bulletList.cannotEdit} />
                        </BulletSection>
                        <ButtonRowLeft>
                            <PrimaryButton
                                onClick={() => navigate(`/edit-published-course/${courseId}`)}
                                title={strings.editCourseWarningPage.editButtonTitle}
                                aria={strings.editCourseWarningPage.editButtonTitleAria}
                            />
                            <SecondaryButton
                                onClick={() => navigate(`/trainer/course/${courseId}/view`)}
                                title={strings.editCourseWarningPage.cancelButton}
                                aria={strings.editCourseWarningPage.cancelButtonAria}
                            />
                        </ButtonRowLeft>
                    </EditSection>
                    <WhySection>
                        <WhyIsEditingLimited />
                    </WhySection>
                </Container>
            </MainSection>
        </>
    );
}

const Container = styled.div`
    display: grid;
    height: 100%;
    grid-template-areas: 'what why' 'edit why';
    grid-template-columns: 1fr;
    grid-gap: ${sizes.spacingLg};

    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
        grid-template-areas: 'what' 'why' 'edit';
        grid-template-columns: unset;
        grid-gap: ${sizes.spacingMd};
    }
`;

const WhatSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingSm};
    grid-area: what;
`;

const EditSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingLg};
    grid-area: edit;
`;

const BulletSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingSm};
`;

const WhySection = styled.div`
    grid-area: why;
`;
