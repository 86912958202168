import { BodySmall } from 'components/Elements';
import styled from 'styled-components';
import { theme, sizes } from 'theme';
import strings from '../../../strings/strings.json';

export default function BetaLabel(): JSX.Element {
    return (
        <BetaLabelContainer>
            <Text $inverted>{strings.courseAIGeneratedContentComponent.betaLabel}</Text>
        </BetaLabelContainer>
    );
}

const Text = styled(BodySmall)`
    width: 35px;
    text-align: center;
`;

const BetaLabelContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    background-color: ${theme.betaLabelBackgroundColor};
    border-radius: ${sizes.borderRadiusXl};
    color: ${theme.textColourInverted};
    padding: ${sizes.spacingSm};
`;
