import { theme } from '../../../theme';
import { IconSize } from '../icon-sizes';

export default function UploadIcon({
    colour = theme.primaryBrandColour,
    size = IconSize.MEDIUM,
}: {
    colour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2453_5098)">
                <path
                    d="M9.25 11.9994H6.75C6.33437 11.9994 6 11.665 6 11.2493V5.99853H3.25937C2.70312 5.99853 2.425 5.32656 2.81875 4.93275L7.57187 0.175807C7.80625 -0.0586023 8.19063 -0.0586023 8.425 0.175807L13.1812 4.93275C13.575 5.32656 13.2969 5.99853 12.7406 5.99853H10V11.2493C10 11.665 9.66562 11.9994 9.25 11.9994ZM16 11.7494V15.2499C16 15.6656 15.6656 16 15.25 16H0.75C0.334375 16 0 15.6656 0 15.2499V11.7494C0 11.3337 0.334375 10.9993 0.75 10.9993H5V11.2493C5 12.2151 5.78437 12.9996 6.75 12.9996H9.25C10.2156 12.9996 11 12.2151 11 11.2493V10.9993H15.25C15.6656 10.9993 16 11.3337 16 11.7494Z"
                    fill={colour}
                />
            </g>
            <defs>
                <clipPath id="clip0_2453_5098">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
