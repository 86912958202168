import strings from '../../../strings/strings.json';
import { breakpoints, sizes, theme } from '../../../theme';
import Table, { RightAlignedButtonCell, TableCell, TableRow } from '../../../components/table/Table';
import { EmptyTableContent } from '../../../components/table/EmptyTableContent';
import { UserModel } from '../../../models/User';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from 'components/buttons/PrimaryButton';
import NavigateWithArrow from '../../../components/actions/NavigateWithArrow';
import HeaderWithCount from './HeaderWithCount';
import { IconSize } from 'assets/icons/icon-sizes';
import TrainerIcon from 'assets/icons/role-icons/TrainerIcon';
import MainSection from 'components/sections/MainSection';
import styled from 'styled-components';
import { AuthContext } from 'contextProviders/AuthContext';
import useGetRequest from 'hooks/useGetRequest';
import { useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import { ErrorNotifier } from 'components/notifiers/ErrorNotifier';
import SecondaryButton from 'components/buttons/SecondaryButton';

export function ManageTrainersContent(): JSX.Element {
    const navigate = useNavigate();

    const { userData } = useContext(AuthContext);

    const {
        data: trainers,
        loading,
        errors,
        called,
    } = useGetRequest<UserModel[]>(`organisations/${userData.organisation?.id}/trainers`, !userData.organisation?.id);

    if (!called && !loading) return <></>;

    if (loading) {
        return (
            <ManageTrainersContentSection>
                <Skeleton height={100} />
            </ManageTrainersContentSection>
        );
    }

    if (errors.length > 0) {
        return (
            <ManageTrainersContentSection>
                <ErrorNotifier title={''} description={strings.OATrainersListPage.error} />
            </ManageTrainersContentSection>
        );
    }

    const trainerRows = (trainers ?? []).map((trainer: UserModel) => {
        const trainerName = `${trainer.firstName} ${trainer.lastName}`;

        const cells: TableCell[] = [
            {
                name: strings.OATrainersListPage.userNameHeading,
                isBold: true,
                value: trainerName,
            },
            {
                name: strings.OATrainersListPage.userEmailHeading,
                value: trainer.email,
            },
            {
                name: '',
                value: (
                    <RightAlignedButtonCell>
                        <NavigateWithArrow
                            title={''}
                            aria={strings.OATrainersListPage.viewTrainerButton.aria}
                            href={`/organisation/trainers/${trainer.id}`}
                            iconSize={IconSize.LARGE}
                        />
                    </RightAlignedButtonCell>
                ),
            },
        ];

        const tableRow: TableRow = { id: trainer.id, cells: cells };
        return tableRow;
    });
    const button =
        trainerRows.length > 0 ? (
            <SecondaryButton
                title={strings.OATrainersListPage.inviteTrainerButton.title}
                aria={strings.OATrainersListPage.inviteTrainerButton.aria}
                onClick={() => {
                    navigate('/organisation/trainers/invite');
                }}
            />
        ) : (
            <PrimaryButton
                title={strings.OATrainersListPage.inviteTrainerButton.title}
                aria={strings.OATrainersListPage.inviteTrainerButton.aria}
                onClick={() => {
                    navigate('/organisation/trainers/invite');
                }}
            />
        );
    return (
        <ManageTrainersContentSection id="trainersContent">
            <HeaderWithCount
                countText={`${trainerRows.length} ${strings.OATrainersListPage.numberOfTrainersText}`}
                actionButton={button}
            />

            <div>
                <Table
                    headers={[
                        { name: strings.OATrainersListPage.userNameHeading, order: 1, rowPercentage: 20 },
                        { name: strings.OATrainersListPage.userEmailHeading, order: 2, rowPercentage: 70 },
                        { name: '', order: 3, rowPercentage: 10, mobileRightColumn: true },
                    ]}
                    rows={trainerRows}
                    emptyTableContent={
                        <EmptyTableContent
                            icon={<TrainerIcon size={IconSize.XLARGE} colour={theme.textColour} />}
                            title={strings.OATrainersListPage.emptyTable.title}
                            description={strings.OATrainersListPage.emptyTable.description}
                            buttonTitle={strings.OATrainersListPage.inviteTrainerButton.title}
                            buttonAria={strings.OATrainersListPage.inviteTrainerButton.aria}
                            buttonOnClick={() => {
                                navigate('/organisation/trainers/invite');
                            }}
                        />
                    }
                />
            </div>
        </ManageTrainersContentSection>
    );
}

const ManageTrainersContentSection = styled(MainSection)`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingRg};
    padding: ${sizes.spacingSm} 0;
    min-height: 120px;

    @media (max-width: ${breakpoints.sm}) {
        padding: unset;
    }
`;
