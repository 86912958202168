import { UserModel } from './User';

export enum FileDocumentType {
    VIDEO = 'VIDEO',
    IMAGE = 'IMAGE',
    AUDIO = 'AUDIO',
}

export type FileDocument = {
    id: string;
    filename: string;
    s3Filepath: string;
    dateUpdated: Date;
    user?: UserModel;
    documentType: FileDocumentType;
};

export interface VideoDocument extends FileDocument {
    videoTranscript: string;
}

export interface ImageDocument extends FileDocument {
    imageAltText: string;
}

export interface AudioDocument extends FileDocument {}

export const VIDEO_TRANSCRIPT_MAX_LENGTH = 10000;
