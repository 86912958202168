import * as React from 'react';
import styled from 'styled-components';
import { animations, sizes, breakpoints, theme } from '../../theme';
import { TertiaryButton } from '../../components/Elements';
import strings from '../../strings/strings.json';
import { MagnificationChanger } from '../../components/settings/MagnificationChanger';

const ACCESSIBILITY_PANEL_KEY = 'accessibilityPanelOpen';

type AccessibilityPanelProps = {
    setOpen: (val: boolean) => void;
    isOpen: boolean;
};

export default function AccessibilityPanel({ isOpen, setOpen }: AccessibilityPanelProps): JSX.Element {
    React.useEffect(() => {
        getStoredAccessibilityPanelState();
        // eslint-disable-next-line
    }, []);

    function getStoredAccessibilityPanelState(): void {
        const boolString = localStorage.getItem(ACCESSIBILITY_PANEL_KEY);
        setOpen(boolString === 'true');
    }

    function handleAccessibilityPanelClick() {
        const newOpenStatus = !isOpen;
        localStorage.setItem(ACCESSIBILITY_PANEL_KEY, newOpenStatus ? 'true' : 'false');
        setOpen(newOpenStatus);
    }

    return (
        <Footer $isOpen={isOpen} id="options">
            <ButtonAnchor>
                <StyledSecondaryButton id="accessibilityPanelButton" onClick={handleAccessibilityPanelClick}>
                    <BoldContent>
                        {isOpen
                            ? `${strings.accessibilityPanel.closePanel}`
                            : `${strings.accessibilityPanel.showPanel}`}
                    </BoldContent>
                </StyledSecondaryButton>
            </ButtonAnchor>
            <Content>
                <Options $isOpen={isOpen}>
                    <MagnificationChanger />
                </Options>
            </Content>
        </Footer>
    );
}

const Footer = styled.footer<{ $isOpen: boolean }>`
    background-color: ${theme.accessibilityPanel.backgroundColour};
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: ${({ $isOpen }) => ($isOpen ? '130px' : '14px')};
    transition: ${animations.speed};
    z-index: 120;
    @media (max-width: ${breakpoints.sm}) {
        height: ${({ $isOpen }) => ($isOpen ? '135px' : '14px')};
    }
`;

const StyledSecondaryButton = styled(TertiaryButton)`
    @media (max-width: ${breakpoints.sm}) {
        width: 100%;
    }
`;

const Content = styled.div`
    height: 100%;
    max-width: ${sizes.appMaxWidth}px;
    margin: 0 auto;
`;

const ButtonAnchor = styled.div`
    position: absolute;
    top: -32px;
    left: 8px;
    background-color: ${theme.accessibilityPanel.openPanelButtonBackground};
    padding: 4px;
    @media (max-width: ${breakpoints.sm}) {
        left: 16px;
        right: 16px;
        top: -40px;
        padding: 8px;
        border-radius: ${sizes.spacingXs};
    }
`;

const Options = styled.div<{ $isOpen: boolean }>`
    height: 100%;
    display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')};
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const BoldContent = styled.div`
    font-weight: bold;
`;
