import { theme } from '../../../theme';
import { IconSize } from '../icon-sizes';

export default function ShareIcon({
    colour = theme.primaryBrandColour,
    size = IconSize.MEDIUM,
}: {
    colour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 28 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.03872 37.4818C3.1185 37.4818 2.33074 37.1541 1.67543 36.4988C1.02013 35.8435 0.692474 35.0558 0.692474 34.1356V15.7312C0.692474 14.811 1.02013 14.0232 1.67543 13.3679C2.33074 12.7126 3.1185 12.385 4.03872 12.385H9.05808V15.7312H4.03872V34.1356H24.1162V15.7312H19.0968V12.385H24.1162C25.0364 12.385 25.8241 12.7126 26.4795 13.3679C27.1348 14.0232 27.4624 14.811 27.4624 15.7312V34.1356C27.4624 35.0558 27.1348 35.8435 26.4795 36.4988C25.8241 37.1541 25.0364 37.4818 24.1162 37.4818H4.03872ZM12.4043 25.7699V7.07281L9.72733 9.74981L7.38496 7.36561L14.0774 0.673126L20.7699 7.36561L18.4276 9.74981L15.7506 7.07281V25.7699H12.4043Z"
                fill={colour}
            />
        </svg>
    );
}
