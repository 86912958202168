import { logError } from '../lib/debug-helpers';
import { APIError, ApiResponse, isLaterThanNow, TEN_MB_ROUNDED } from '../lib/_api-helpers';
import React from 'react';
import { AuthContext } from '../contextProviders/AuthContext';
import {
    CompletedPart,
    completeMultipartUpload,
    getMultipartPresignedUrls,
    getPresignedUrl,
    uploadFile,
    uploadPart,
} from '../lib/document-upload-helpers';

export type UseUploadRequestData = {
    upload: (file: File) => Promise<ApiResponse<UploadResults>>;
    uploading: boolean;
    errors: APIError[];
};

export type UploadResults = {
    s3Key: string;
    filename: string;
};

export default function useUploadRequest(): UseUploadRequestData {
    const authContext = React.useContext(AuthContext);

    const [errors, setErrors] = React.useState<APIError[]>([]);
    const [uploading, setUploading] = React.useState<boolean>(false);

    async function upload(file: File): Promise<ApiResponse<UploadResults>> {
        setUploading(true);
        setErrors([]);

        const token = await ensureTokenValid();

        try {
            if (file.size > TEN_MB_ROUNDED) {
                const presignedUrlsResponse = await getMultipartPresignedUrls(file, token);

                if (!presignedUrlsResponse.value)
                    throw new Error('Problem getting presigned URLs for multipart upload.');

                const numberOfParts = Math.ceil(file.size / TEN_MB_ROUNDED);

                const uploadsArray: Promise<ApiResponse<CompletedPart>>[] = [];

                for (let i = 0; i < numberOfParts; i++) {
                    const start = i * TEN_MB_ROUNDED;
                    const end = Math.min(start + TEN_MB_ROUNDED, file.size);
                    const blob = file.slice(start, end);

                    uploadsArray.push(uploadPart(blob, presignedUrlsResponse.value.presignedUrls[i], i + 1));
                }

                const uploadResponses = await Promise.all(uploadsArray);

                if (uploadResponses.some((x) => x.errors && x.errors.length > 0))
                    throw new Error('Some chunks did not upload successfully.');
                if (uploadResponses.some((x) => !x.value))
                    throw new Error('Some chunks did not return ETags - cannot complete multipart upload.');

                const completedParts = uploadResponses
                    .map((x) => x.value)
                    .filter((x: CompletedPart | undefined): x is CompletedPart => !!x);

                const completeUploadResponse = await completeMultipartUpload(
                    presignedUrlsResponse.value.uploadId,
                    presignedUrlsResponse.value.s3Key,
                    completedParts,
                    token,
                );

                if (!completeUploadResponse.value || completeUploadResponse.errors)
                    throw new Error('Cannot complete multipart upload.');

                return {
                    value: {
                        s3Key: presignedUrlsResponse.value.s3Key,
                        filename: presignedUrlsResponse.value.filename,
                    },
                };
            } else {
                const response = await getPresignedUrl(file, token);

                if (response.errors && response.errors.length > 0) {
                    logError(response.errors);
                    throw new Error('Cannot get presigned URL');
                }

                if (!response.value) {
                    logError('no presigned url');
                    throw new Error('Cannot get presigned URL');
                }

                const uploadResponse = await uploadFile(
                    file,
                    response.value?.presignedUrl,
                    response.value?.presignedFormFields,
                );

                if (!uploadResponse.value) {
                    logError('Upload failed');
                    throw new Error('Upload failed');
                }

                return { value: { s3Key: response.value.s3Key, filename: response.value.filename } };
            }
        } catch (err: any) {
            logError(err);
            setErrors([{ field: 'global', code: 'Upload failed' }]);
            return { errors: [{ field: 'global', code: 'Upload failed' }] };
        } finally {
            setUploading(false);
        }
    }

    async function ensureTokenValid(): Promise<string> {
        let token = authContext.userData.tokens.accessToken ?? '';

        if (isLaterThanNow(authContext.tokenExpiryTime)) {
            token = await authContext.refreshToken();
        }

        return token;
    }

    return { upload, uploading, errors };
}
