import useKeyboardTrap from '../../../hooks/useKeyboardTrap';
import { Headline4, ModalBackground, TertiaryButton } from '../../../components/Elements';
import styled from 'styled-components';
import CloseIcon from '../../../assets/icons/controls/CloseIcon';
import strings from '../../../strings/strings.json';
import { breakpoints, FONTSIZE, sizes, theme } from '../../../theme';
import * as React from 'react';
import { ChangeEvent } from 'react';
import { ACCEPTED_IMAGE_MIME_TYPES, MAX_IMAGE_SIZE } from '../../../lib/_api-helpers';
import useUploadRequest from '../../../hooks/useUploadRequest';
import { logError } from '../../../lib/debug-helpers';
import useModifyRequest from '../../../hooks/useModifyRequest';
import WarningIcon from '../../../assets/icons/indicators/WarningIcon';
import { ErrorMessage } from '../../../components/forms/FormComponents';
import Spinner from '../../../components/Spinner';
import UploadIcon from '../../../assets/icons/controls/UploadIcon';
import SecondaryButton, { SecondaryButtonColour } from 'components/buttons/SecondaryButton';
import BinIcon from 'assets/icons/controls/BinIcon';
import { AlertBodyComponent } from 'components/alert/Alert';
import ProfileImageEmptyIcon from 'assets/icons/ProfileImageEmptyIcon';

export type ProfileImageModalProps = {
    closeModal: () => void;
    refetchUser: () => void;
    currentProfileImage: string;
};

export default function ProfileImageModal({
    closeModal,
    refetchUser,
    currentProfileImage,
}: ProfileImageModalProps): JSX.Element {
    const { ref } = useKeyboardTrap(closeModal);
    const fileInputRef = React.useRef<HTMLInputElement>(null);

    const { modifyData: uploadProfileImage } = useModifyRequest(`users/me/profile-image`, 'PATCH');

    const { upload } = useUploadRequest();

    const [uploadError, setUploadError] = React.useState<string | null>(null);
    const [processing, setProcessing] = React.useState<boolean>(false);
    const [showAreYouSure, setShowAreYouSure] = React.useState<boolean>(false);

    const handleButtonKeyDown = (event: React.KeyboardEvent<HTMLLabelElement>) => {
        if (event.key === 'Enter' || event.key === ' ') {
            if (!fileInputRef.current) return;
            fileInputRef.current.click();
            event.preventDefault();
        }
    };

    async function handleFileChange(e: ChangeEvent<HTMLInputElement>) {
        setUploadError(null);
        setProcessing(true);

        const localFile = e.target.files ? e.target.files[0] : null;

        if (!localFile) {
            setUploadError(strings.errorMessages.profileImageUploadNoFile);
            setProcessing(false);
            return;
        }

        if (!ACCEPTED_IMAGE_MIME_TYPES.includes(localFile.type)) {
            setUploadError(strings.errorMessages.profileImageUploadWrongFileType);
            setProcessing(false);
            return;
        }

        if (localFile.size > MAX_IMAGE_SIZE) {
            setUploadError(strings.errorMessages.profileImageUploadTooLarge);
            setProcessing(false);
            return;
        }

        const uploadResponse = await upload(localFile);

        if (uploadResponse.errors && uploadResponse.errors.length > 0) {
            logError(uploadResponse.errors);
            setProcessing(false);
            setUploadError(strings.errorMessages.profileImageUploadGeneralServerError);
            return;
        }

        if (!uploadResponse.value) {
            logError('Get presigned URL failed');
            setProcessing(false);
            setUploadError(strings.errorMessages.profileImageUploadGeneralServerError);
            return;
        }

        // set image s3 in back end
        const setImageResponse = await uploadProfileImage({ profileImagePath: uploadResponse.value.s3Key });

        if (setImageResponse.errors && setImageResponse.errors.length > 0) {
            logError(setImageResponse.errors);
            setProcessing(false);
            setUploadError(strings.errorMessages.profileImageUploadGeneralServerError);
            return;
        }

        await refetchUser();
        closeModal();
    }

    async function removeProfileImage() {
        setUploadError(null);
        setProcessing(true);

        const setImageResponse = await uploadProfileImage({ profileImagePath: '' });

        if (setImageResponse.errors && setImageResponse.errors.length > 0) {
            logError(setImageResponse.errors);
            setUploadError(strings.errorMessages.profileImageRemoveGeneralServerError);
            setProcessing(false);
            setShowAreYouSure(false);
            return;
        }

        await refetchUser();
        setProcessing(false);
        closeModal();
    }

    return (
        <ModalBackground>
            <ModalBody ref={ref}>
                {!showAreYouSure ? (
                    <>
                        <CloseRow>
                            <PaddedTertiaryButton
                                onClick={closeModal}
                                aria-label={strings.profilePage.closeImageModalAria}
                            >
                                <CloseIcon />
                            </PaddedTertiaryButton>
                        </CloseRow>
                        <CenteredRow>
                            <Headline4>{strings.profilePage.imageModalTitle}</Headline4>
                        </CenteredRow>
                        <MainRow>
                            {uploadError && (
                                <>
                                    <div>
                                        <WarningIcon />
                                    </div>
                                    <ErrorMessage>{uploadError}</ErrorMessage>
                                </>
                            )}
                            {processing && <Spinner />}
                            {!uploadError && !processing && (
                                <ImageCircle
                                    $imageUrl={currentProfileImage}
                                    aria-label={strings.profilePage.profileImageAriaLabel}
                                >
                                    {!currentProfileImage && <ProfileImageEmptyIcon />}
                                </ImageCircle>
                            )}
                        </MainRow>
                        {!processing && (
                            <ButtonsContainer>
                                {currentProfileImage && (
                                    <SecondaryButton
                                        title={strings.profilePage.removeProfileImageButton.title}
                                        aria={strings.profilePage.removeProfileImageButton.aria}
                                        icon={<BinIcon />}
                                        alternateColour={SecondaryButtonColour.ALTERNATE}
                                        onClick={() => {
                                            setShowAreYouSure(true);
                                        }}
                                    />
                                )}
                                <FileUploadButton
                                    htmlFor="upload-input"
                                    tabIndex={0}
                                    onKeyDown={handleButtonKeyDown}
                                    aria-label={strings.profilePage.imageModalUploadButtonAria}
                                >
                                    <UploadIcon colour={theme.textColourInverted} />
                                    {strings.profilePage.imageModalUploadButtonTitle}
                                </FileUploadButton>
                                <HiddenFileInput
                                    id="upload-input"
                                    type="file"
                                    ref={fileInputRef}
                                    onChange={handleFileChange}
                                />
                            </ButtonsContainer>
                        )}
                    </>
                ) : (
                    <AlertBodyComponent
                        closeAlert={() => {
                            setShowAreYouSure(false);
                        }}
                        alertText={strings.profilePage.removeProfileImageAlert.text}
                        submitButtonTitle={strings.profilePage.removeProfileImageAlert.button.title}
                        submitButtonAria={strings.profilePage.removeProfileImageAlert.button.aria}
                        submitButtonOnClick={() => removeProfileImage()}
                    />
                )}
            </ModalBody>
        </ModalBackground>
    );
}

const ImageCircle = styled.div<{ $imageUrl: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 150px;
    width: 150px;
    background: ${({ $imageUrl }) => {
        return $imageUrl ? `url("${$imageUrl}") no-repeat no-repeat` : theme.cardThirdBackgroundColour;
    }};
    background-size: cover;
`;

const PaddedTertiaryButton = styled(TertiaryButton)`
    padding: ${sizes.spacingSm};
`;

const FileUploadButton = styled.label`
    font-family: ${theme.fontFamilyMain};
    font-size: ${FONTSIZE.BodyRegular};
    font-weight: bold;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${sizes.spacingSm};
    cursor: pointer;
    height: 48px;
    padding: 0 ${sizes.spacingLg} 3px ${sizes.spacingLg};
    border-radius: 24px;
    letter-spacing: 0;
    text-align: center;
    border: 2px solid ${theme.primaryButtonBorderColour} !important;
    color: ${theme.primaryButtonTextColour};
    background-color: ${theme.primaryButtonBackgroundColour};
    box-sizing: border-box;
    &:focus {
        background-color: ${theme.primaryButtonFocusBackgroundColour};
        border: 2px solid ${theme.primaryButtonBorderColour};
        outline: ${theme.primaryButtonBorderColour};
        color: ${theme.primaryButtonFocusTextColour};
    }
    &:hover {
        background-color: ${theme.primaryButtonHoverBackgroundColour};
    }

    @media (max-width: ${breakpoints.sm}) {
        width: 100%;
    }
`;

const HiddenFileInput = styled.input`
    display: none;
`;

const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${sizes.spacingMd};
    background-color: ${theme.cardBackground};
    padding: ${sizes.spacingMd} ${sizes.spacingMd} ${sizes.spacingRg};
    border: solid 1px ${theme.cardBorder};
    border-radius: 8px;
    height: 400px;
    margin: 140px auto;
    width: 520px;

    @media (max-width: ${breakpoints.sm}) {
        padding: ${sizes.spacingLg} ${sizes.spacingMd};
        margin: 120px auto;
        width: 100%;
    }
`;

const CloseRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    margin-bottom: -32px;
    z-index: 99991;
    height: 48px;
`;

const CenteredRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: ${sizes.spacingMd};
`;

const MainRow = styled(CenteredRow)`
    flex-grow: 1;
`;

const ButtonsContainer = styled(CenteredRow)`
    display: flex;
    padding: ${sizes.spacingMd} ${sizes.spacingRg} ${sizes.spacingSm};

    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column-reverse;
    }
`;
