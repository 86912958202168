import * as React from 'react';
import strings from '../../strings/strings.json';
import OrganisationForm from './components/OrganisationForm';
import { useParams } from 'react-router-dom';
import useGetRequest from 'hooks/useGetRequest';
import { Organisation } from 'models/Organisation';
import MainSection from '../../components/sections/MainSection';
import SubpageHeader from '../../components/sections/SubpageHeader';

export default function UpdateOrganisationPage(): JSX.Element {
    const params = useParams();
    const { id } = params;

    const { data: organisation } = useGetRequest<Organisation>(`organisations/${id}`, !id);

    const backButtonDetails = React.useMemo(() => {
        return {
            title: strings.updateOrganisationPage.backButtonTitle,
            aria: strings.updateOrganisationPage.backButtonTitleAria,
            href: '/admin/dashboard',
        };
    }, []);

    if (id && !organisation) {
        return <div></div>;
    }

    return (
        <>
            <SubpageHeader title={strings.updateOrganisationPage.title} backButtonDetails={backButtonDetails} />
            <MainSection>
                <OrganisationForm organisation={organisation} />
            </MainSection>
        </>
    );
}
