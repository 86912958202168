import { BodyRegular, Headline5 } from 'components/Elements';
import Hint from 'components/Hint';
import BetaLabel from '../BetaLabel';
import BoltIcon from 'assets/icons/BoltIcon';
import { IconSize } from 'assets/icons/icon-sizes';
import styled from 'styled-components';
import { breakpoints, sizes } from 'theme';
import strings from '../../../../strings/strings.json';
import { Activity } from 'models/Activity';
import SecondaryButton from 'components/buttons/SecondaryButton';
import { useNavigate } from 'react-router-dom';
import { EditCourseContext } from 'contextProviders/EditCourseContext';
import React from 'react';
import { FormCard } from 'components/forms/FormComponents';
import RemoveButton from 'components/buttons/RemoveButton';
import AudioPlayer from 'pages/learner/components/AudioPlayer';

type Status = 'none' | 'queued' | 'error' | 'review' | 'approved';

type GeneratedContentSectionProps = {
    activity: Activity;
    setActivity: React.Dispatch<React.SetStateAction<Activity>>;
};

export function GeneratedContentSection({ activity, setActivity }: GeneratedContentSectionProps): JSX.Element {
    const navigate = useNavigate();
    const context = React.useContext(EditCourseContext);

    let status: Status;

    if (activity.audioDocument?.s3Filepath) {
        status = 'approved';
    } else if (
        activity.generateContentStatuses.length === 1 &&
        !activity.generateContentStatuses[0].dateGenerated &&
        !activity.generateContentStatuses[0].dateErrored
    ) {
        status = 'queued';
    } else if (
        activity.generateContentStatuses.length === 1 &&
        activity.generateContentStatuses[0].dateGenerated &&
        !activity.generateContentStatuses[0].dateErrored &&
        !activity.generateContentStatuses[0].dateApproved
    ) {
        status = 'review';
    } else if (activity.generateContentStatuses.length === 1 && activity.generateContentStatuses[0].dateErrored) {
        status = 'error';
    } else {
        status = 'none';
    }

    let body = <></>;

    switch (status) {
        case 'none':
        default:
            return <></>;
        case 'queued':
            body = <BodyRegular>{strings.activityUpdatePage.generatedContent.queued}</BodyRegular>;
            break;
        case 'review':
        case 'error':
            body = (
                <SecondaryButton
                    title={strings.activityUpdatePage.generatedContent.toReview}
                    aria={strings.activityUpdatePage.generatedContent.toReview}
                    onClick={() =>
                        navigate(`/edit-course/${context.course!.id}`, {
                            state: { stepId: 'courseAIGeneratedContent' },
                        })
                    }
                />
            );
            break;
        case 'approved':
            const removeAudio = () => {
                setActivity({ ...activity, audioDocument: null });
            };

            body = (
                <>
                    <Hint hint={strings.activityUpdatePage.generatedContent.hint} />
                    <AudioAndRemoveButton>
                        <AudioPlayer filepath={activity.audioDocument?.s3Filepath ?? ''} />
                        <RemoveButton title={'Remove'} aria={'Remove'} onClick={removeAudio} submitting={false} />
                    </AudioAndRemoveButton>
                </>
            );
            break;
    }

    return (
        <ResourceGroup>
            <GeneratedContentCard as="div">
                <Container>
                    <Header>
                        <BoltAndTitle>
                            <BoltIcon size={IconSize.MEDIUM} />
                            <Headline5>{strings.activityUpdatePage.generatedContent.title}</Headline5>
                        </BoltAndTitle>
                        <BetaLabel />
                    </Header>
                    {body}
                </Container>
            </GeneratedContentCard>
        </ResourceGroup>
    );
}

const AudioAndRemoveButton = styled.div`
    display: flex;
    gap: ${sizes.spacingMd};
    align-items: center;
    @media (max-width: ${breakpoints.lg}) {
        flex-direction: column;
        align-items: start;
    }
`;

const BoltAndTitle = styled.div`
    display: flex;
    gap: ${sizes.spacingSm};
    align-items: center;
`;

const Header = styled.div`
    display: flex;
    gap: ${sizes.spacingMd};
    align-items: center;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
`;

const GeneratedContentCard = styled(FormCard)`
    width: 100%;
`;

const ResourceGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingSm};
`;
