import styled from 'styled-components';
import { breakpoints, sizes, theme } from '../../theme';
import InformationIcon from '../../assets/icons/indicators/InformationIcon';
import { BodyLarge, BodyRegular, ExternalSecondaryLinkButton } from '../Elements';
import strings from '../../strings/strings.json';
import { ACCESSIBILITY_GUIDE } from '../accessibilityGuide/AccessibilityGuide';
import useWindowWidth from '../../hooks/useWindowWidth';
import SecondaryButton from '../buttons/SecondaryButton';
import { IconSize } from '../../assets/icons/icon-sizes';
import ExternalLinkIcon from '../../assets/icons/navigation/ExternalLinkIcon';
import * as React from 'react';

export default function AccessibilityNotifier({
    accessibilityGuideKey,
    fixButtonFunction,
    fixButtonName,
    fixButtonAria,
    fixButtonIcon,
}: {
    accessibilityGuideKey: string;
    fixButtonFunction?: Function;
    fixButtonName?: string;
    fixButtonAria?: string;
    fixButtonIcon?: JSX.Element;
}): JSX.Element {
    const { currentWindowWidth } = useWindowWidth();

    const accessibilityTip = ACCESSIBILITY_GUIDE.find((x) => x.id === accessibilityGuideKey);

    if (!accessibilityTip) return <></>;

    return (
        <NotificationContainer>
            <TitleSection>
                <TitleText>
                    {currentWindowWidth >= parseInt(breakpoints.md) && (
                        <IconContainer>
                            <InformationIcon colour={theme.textColour} />
                        </IconContainer>
                    )}
                    <BodyLarge $bold>{accessibilityTip.title}</BodyLarge>
                </TitleText>
                <AccessibilityTagSection>
                    {currentWindowWidth < parseInt(breakpoints.md) && (
                        <IconContainer>
                            <InformationIcon colour={theme.textColour} />
                        </IconContainer>
                    )}
                    <AccessibilityTag>{strings.general.accessibilityTagForNotification}</AccessibilityTag>
                </AccessibilityTagSection>
            </TitleSection>
            {typeof accessibilityTip.description === 'string' ? (
                <DescriptionSection>{accessibilityTip.description}</DescriptionSection>
            ) : (
                accessibilityTip.description
            )}

            {accessibilityTip.readMore && (
                <ExternalLinkContainer>
                    <ExternalSecondaryLinkButton
                        href={accessibilityTip.readMore}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label={strings.general.openInNewTab}
                    >
                        {strings.general.readMore}
                    </ExternalSecondaryLinkButton>
                    <IconContainer>
                        <ExternalLinkIcon size={IconSize.MEDIUM} colour={theme.primaryBrandColour} />
                    </IconContainer>
                </ExternalLinkContainer>
            )}
            {fixButtonFunction && fixButtonName && fixButtonAria && (
                <div>
                    <SecondaryButton
                        title={fixButtonName}
                        aria={fixButtonAria}
                        onClick={fixButtonFunction}
                        icon={fixButtonIcon}
                    />
                </div>
            )}
        </NotificationContainer>
    );
}

const NotificationContainer = styled.div`
    @media (max-width: ${breakpoints.sm}) {
        width: 100%;
    }
    max-width: 60rem;
    display: flex;
    flex-direction: column;
    border-radius: ${sizes.borderRadiusMd};
    padding: ${sizes.spacingMd};
    gap: ${sizes.spacingMd};
    border: solid 3px ${theme.notificationAccessibilityBorder};
    background: ${theme.notificationAccessibilityBackground};
`;

const IconContainer = styled.div`
    display: flex;
    align-items: center;
`;

const DescriptionSection = styled(BodyRegular)`
    white-space: pre-wrap;
`;

const TitleText = styled.div`
    display: flex;
    gap: ${sizes.spacingMd};
    justify-content: flex-start;
    align-items: center;
`;

const TitleSection = styled.div`
    display: flex;
    gap: ${sizes.spacingMd};
    justify-content: space-between;
    align-items: flex-start;
    @media (max-width: ${breakpoints.md}) {
        flex-direction: column-reverse;
        justify-content: flex-start;
        gap: ${sizes.spacingSm};
    }
`;

const AccessibilityTag = styled.div`
    padding: ${sizes.spacingXs} ${sizes.spacingSm};
    border-radius: ${sizes.borderRadiusMd};
    background: ${theme.notificationWarningBackground};
`;

const AccessibilityTagSection = styled.div`
    display: flex;
    gap: ${sizes.spacingMd};
    justify-content: flex-start;
    align-items: center;
`;

const ExternalLinkContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${sizes.spacingXs};
`;
