import useGetRequest from '../../../../../hooks/useGetRequest';
import { LearnerLiveTraining } from '../../../../../models/LearnerLiveTraining';
import LiveTrainingContentLayout from './LiveTrainingContentLayout';
import React from 'react';
import { PreviewContext } from '../../../../../contextProviders/PreviewContext';
import { LocationType } from '../../../../../models/ActivityMeeting';

type LiveTrainingContentDataProps = {
    liveTrainingId: string;
    courseScheduleId: string;
};

export default function LiveTrainingContentData({
    liveTrainingId,
    courseScheduleId,
}: LiveTrainingContentDataProps): JSX.Element {
    const {
        data: liveTraining,
        loading,
        errors,
        refetchData,
    } = useGetRequest<LearnerLiveTraining>(`learner/schedule/${courseScheduleId}/live-training/${liveTrainingId}`);

    React.useEffect(() => {
        refetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [liveTrainingId]);

    return <LiveTrainingContentLayout liveTraining={liveTraining} loading={loading} errors={errors} />;
}

export function LiveTrainingPreviewContentData({ liveTrainingId }: LiveTrainingContentDataProps): JSX.Element {
    const previewContext = React.useContext(PreviewContext);

    const moduleIndex =
        previewContext.scheduledCourse?.course.modules.findIndex((m) =>
            m.activities.find((a) => a.id === liveTrainingId),
        ) ?? 0;

    const activity = previewContext.scheduledCourse?.course.modules[moduleIndex].activities.find(
        (a) => a.id === liveTrainingId,
    );

    if (!activity) return <></>;

    const liveTraining: LearnerLiveTraining = {
        id: activity.id,
        title: activity.title,
        durationInMins: activity.durationInMins ?? 0,
        startDateTime: new Date(),
        locationType: LocationType.IN_PERSON,
        location: '123 test street, test city',
        joinLink: '',
    };

    return <LiveTrainingContentLayout liveTraining={liveTraining} loading={false} errors={[]} />;
}
