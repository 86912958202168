import styled from 'styled-components';
import NavigateWithArrow from 'components/actions/NavigateWithArrow';
import { BodyRegular, Headline4 } from 'components/Elements';
import { Course } from 'models/Course';
import { breakpoints, sizes, theme } from 'theme';
import strings from '../../../strings/strings.json';

type CourseCardProps = {
    course: Course;
};

export default function CourseCard({ course }: CourseCardProps): JSX.Element {
    const { published } = course;

    const linkTitle = published
        ? `${strings.dashboardPage.courseCard.linkTitlePublished}`
        : `${strings.dashboardPage.courseCard.linkTitleUnpublished}`;
    const linkAria = `${strings.dashboardPage.courseCard.linkAria} ${course.title}`;
    const linkHref = published ? `/trainer/course/${course.id}/view` : `/edit-course/${course.id}`;

    return (
        <CourseCardContainer className={'course-card'}>
            <HeadlineContainer>
                <Headline4>{course.title}</Headline4>
                <BodyRegular>
                    {strings.dashboardPage.courseCard.dateCreatedText} {formatDate(course.dateCreated)}
                </BodyRegular>
            </HeadlineContainer>
            <NavigateWithArrow title={linkTitle} href={linkHref} aria={linkAria} />
        </CourseCardContainer>
    );
}

function formatDate(dateString: string): string {
    const d = new Date(dateString);
    return `${d.toLocaleString('default', { month: 'long' })} ${d.toLocaleString('default', { year: 'numeric' })}`;
}

const CourseCardContainer = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    min-height: 160px;
    gap: ${sizes.spacingLg};
    @media (max-width: ${breakpoints.sm}) {
        gap: ${sizes.spacingMd};
    }
    box-sizing: border-box;
    border: 1px solid ${theme.cardBorder};
    border-radius: 8px;
    box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;
    background: ${theme.cardBackground};
    word-wrap: break-word;
    min-width: 50%;
`;

const HeadlineContainer = styled.div`
    max-width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingXs};
`;
