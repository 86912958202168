import * as React from 'react';
import { StepperFormProps } from 'components/stepper/stepperTypes';
import { Question, QuestionnaireActivity } from 'models/inputModels/QuestionnaireActivity';
import { Headline4 } from 'components/Elements';
import strings from '../../../strings/strings.json';
import TextField from 'components/forms/TextField';
import {
    createErrorMessage,
    createFieldErrorFromAPIError,
    createGlobalErrorFromAPIError,
    fieldErrorCodes,
    requiredFieldErrorMessage,
} from 'shared/error-messages';
import styled from 'styled-components';
import { ConditionalValidationErrorMessage } from 'components/forms/ValidationErrorMessage';
import useUpsertRequest from 'hooks/useUpsertRequest';
import ActivityWizardButtonRow from './ActivityWizardButtonRow';
import useNeveForm from 'components/forms/NeveForm';
import { EditCourseContext } from 'contextProviders/EditCourseContext';
import AddIcon from 'assets/icons/controls/AddIcon';
import BinIcon from 'assets/icons/controls/BinIcon';
import { IconSize } from 'assets/icons/icon-sizes';
import SecondaryButton, { SecondaryButtonColour } from 'components/buttons/SecondaryButton';
import { ErrorMessage } from 'components/forms/FormComponents';
import { logError } from 'lib/debug-helpers';
import { APIError } from 'lib/_api-helpers';
import { sizes, theme } from 'theme';
import useFormBlocker from 'components/forms/useFormBlocker';

type QuestionInput = {
    question: string;
    helpText: string;
    order: number;
};

type QuestionnaireQuestionsInput = {
    questions: QuestionInput[];
};

export default function QuestionnaireQuestionsForm(props: StepperFormProps<QuestionnaireActivity>): JSX.Element {
    const { modifyData: upsertQuestionnaire, loading: createLoading } = useUpsertRequest(`activities/questionnaire`);

    const { previous, newObject, setNewObject, complete } = props;
    const initialQuestions =
        newObject.questions.length === 0 ? [0] : newObject.questions.map((question, index) => index);
    const initialQuestionNumber = newObject.questions.length === 0 ? 1 : newObject.questions.length + 1;

    const [questions, setQuestions] = React.useState(initialQuestions);
    const [globalErrorMessage, setGlobalErrorMessage] = React.useState<string>('');
    const [nextQuestionNumber, setNextQuestionNumber] = React.useState(initialQuestionNumber);

    const {
        register,
        unregister,
        handleSubmit,
        watch,
        setError,
        clearErrors,
        setFocus,
        formState: { errors },
    } = useNeveForm<QuestionnaireQuestionsInput>({
        questions: newObject.questions.map((question) => ({
            question: question.question,
            helpText: question.helpText,
            order: question.order,
        })),
    });

    const context = React.useContext(EditCourseContext);

    React.useEffect(() => {
        setFocus(`questions.${nextQuestionNumber - 1}.question`);
    }, [nextQuestionNumber, setFocus]);

    const questionInputs = watch('questions');

    async function onSubmit(formData: QuestionnaireQuestionsInput) {
        // check to ensure there is at least one question.
        // we filter out undefined because unregistering the input (when removing a question)
        // leaves an empty slot in the array.
        if (!formData.questions || formData.questions.filter((x) => x !== undefined).length === 0) {
            setError('questions', {
                type: 'custom',
                message: strings.questionnaireQuestionsForm.noQuestionsError,
            });
        }

        const response = await upsertQuestionnaire({
            ...newObject,
            moduleId: newObject.moduleId,
            questions: buildQuestionsFromInputs(formData.questions),
        });

        const { errors } = response;

        if (errors) {
            logError(errors);
            updateErrors(errors);
            return;
        }

        await context.refetchCourse();
        !!complete && complete();
    }

    function updateErrors(apiErrors: APIError[]): void {
        apiErrors.forEach((apiError) => {
            const { field, code } = apiError;

            setError(field as keyof QuestionnaireQuestionsInput, createFieldErrorFromAPIError(field, code));

            if (field === 'global') {
                return setGlobalErrorMessage(createGlobalErrorFromAPIError(code));
            } else {
                return setGlobalErrorMessage(requiredFieldErrorMessage(field));
            }
        });
    }

    function addNewQuestion() {
        setQuestions((prevState) => [...prevState, nextQuestionNumber]);
        setNextQuestionNumber((prevState) => prevState + 1);

        clearErrors('questions');
    }

    function removeQuestion(questionNumber: number) {
        const newQuestions = questions.filter((x) => x !== questionNumber);

        unregister(`questions.${questionNumber}`);

        setQuestions(newQuestions);
    }

    function backStep() {
        setNewObject({ ...newObject, questions: buildQuestionsFromInputs(questionInputs) });

        previous();
    }

    function buildQuestionsFromInputs(questions: QuestionInput[]): Question[] {
        return (
            questions
                // when a question is unregistered, the slot in the array is set to undefined
                .filter((x) => x !== undefined)
                .map((question, index) => ({
                    question: question.question,
                    helpText: question.helpText,
                    order: index,
                }))
        );
    }

    return (
        <PaddedForm onSubmit={handleSubmit(onSubmit)}>
            <Headline4>{strings.questionnaireQuestionsForm.instruction}</Headline4>
            <div>
                {questions.map((i) => {
                    let questionErrorMessage = '';
                    let helpTextErrorMessage = '';

                    if (errors.questions && errors.questions[i] !== undefined) {
                        questionErrorMessage = errors.questions[i]?.question?.message ?? '';
                        helpTextErrorMessage = errors.questions[i]?.helpText?.message ?? '';
                    }

                    return (
                        <React.Fragment key={i}>
                            <QuestionCard>
                                <TextField
                                    fieldName={`question-${i}`}
                                    labelText={strings.questionnaireQuestionsForm.questionInputLabel}
                                    inputAria={strings.questionnaireQuestionsForm.questionInputAria}
                                    inputProps={register(`questions.${i}.question`, {
                                        required: {
                                            value: true,
                                            message: requiredFieldErrorMessage(
                                                strings.questionnaireQuestionsForm.questionInputLabel,
                                            ),
                                        },
                                        maxLength: {
                                            value: 2000,
                                            message: createErrorMessage(
                                                strings.questionnaireQuestionsForm.questionInputLabel,
                                                fieldErrorCodes.maxLength,
                                            ),
                                        },
                                    })}
                                    errorMessage={questionErrorMessage}
                                    required
                                />

                                <HelperTextHintAndTextField>
                                    <TextField
                                        fieldName={`help-text-${i}`}
                                        labelText={strings.questionnaireQuestionsForm.helpTextInputLabel}
                                        inputAria={strings.questionnaireQuestionsForm.helpTextInputAria}
                                        inputProps={register(`questions.${i}.helpText`, {
                                            maxLength: {
                                                value: 2000,
                                                message: createErrorMessage(
                                                    strings.questionnaireQuestionsForm.helpTextInputLabel,
                                                    fieldErrorCodes.maxLength,
                                                ),
                                            },
                                        })}
                                        errorMessage={helpTextErrorMessage}
                                        hint={i === 0 ? strings.questionnaireQuestionsForm.helpTextHint : undefined}
                                    />
                                </HelperTextHintAndTextField>
                                <SecondaryButton
                                    title={strings.questionnaireQuestionsForm.removeQuestionButton}
                                    aria={strings.questionnaireQuestionsForm.removeQuestionButtonAria}
                                    onClick={() => removeQuestion(i)}
                                    icon={<BinIcon size={IconSize.MEDIUM} />}
                                    alternateColour={SecondaryButtonColour.ALTERNATE}
                                />
                            </QuestionCard>
                            <StyledHorizontalRule />
                        </React.Fragment>
                    );
                })}
                <ConditionalValidationErrorMessage error={errors.questions?.message ?? ''} />
                {globalErrorMessage !== '' && <ErrorMessage id="errorMessage">{globalErrorMessage}</ErrorMessage>}
                <ButtonContainer>
                    <SecondaryButton
                        fullwidth
                        title={strings.questionnaireQuestionsForm.addQuestionButton}
                        aria={strings.questionnaireQuestionsForm.addQuestionButtonAria}
                        icon={<AddIcon />}
                        onClick={() => addNewQuestion()}
                    />
                </ButtonContainer>
            </div>
            <ActivityWizardButtonRow
                submitButtonTitle={strings.questionnaireQuestionsForm.submitButton}
                submitButtonAria={strings.questionnaireQuestionsForm.submitButtonAria}
                buttonsDisabled={createLoading}
                previousButtonFunction={backStep}
            />
        </PaddedForm>
    );
}

export function EditQuestionnaireQuestionsForm(props: StepperFormProps<QuestionnaireActivity>): JSX.Element {
    useFormBlocker(true, strings.courseEditorPage.unsavedChangesPrompt);

    return (
        <QuestionnaireQuestionsForm
            next={props.next}
            previous={props.previous}
            changeStep={props.changeStep}
            steps={props.steps}
            newObject={props.newObject}
            setNewObject={props.setNewObject}
            complete={props.complete}
        />
    );
}

const HelperTextHintAndTextField = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingSm};
`;

const PaddedForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingLg};
`;

const QuestionCard = styled.div`
    background-color: ${theme.cardSecondaryBackgroundColour};
    border-radius: ${sizes.spacingSm};
    padding: ${sizes.spacingMd};
`;

const StyledHorizontalRule = styled.hr`
    border-top: 1px solid ${theme.cardSecondaryBorder};
    margin-top: ${sizes.spacingLg};
    margin-bottom: ${sizes.spacingLg};
`;

const ButtonContainer = styled.div`
    width: 100%;
`;
