import styled from 'styled-components';
import { sizes, theme } from '../../../../theme';
import { IntegrationEmployeeStatus } from '../../../../models/hr-integration/IntegrationEmployee';
import { ErrorMessage } from '../../../../components/forms/FormComponents';
import { BodyRegular, BodySmall } from '../../../../components/Elements';
import strings from '../../../../strings/strings.json';
import TickIcon from '../../../../assets/icons/controls/TickIcon';
import ErrorIcon from '../../../../assets/icons/indicators/ErrorIcon';
import WarningIcon from '../../../../assets/icons/indicators/WarningIcon';

export default function CharlieHRStatus({
    status,
    lastSyncDate,
    errorReason,
    errorDate,
}: {
    status: IntegrationEmployeeStatus;
    lastSyncDate: Date | null;
    errorReason: string | null;
    errorDate: Date | null;
}): JSX.Element {
    function getStatus() {
        if (status === IntegrationEmployeeStatus.UP_TO_DATE) {
            return (
                <TextAndIconContainer>
                    <TickIcon colour={theme.successColour} />
                    <TextContainer>
                        <GoodText>{strings.organisationIntegrationEmployeesPage.statuses.upToDate}</GoodText>
                        <GoodSubtext>
                            {strings.organisationIntegrationEmployeesPage.lastSyncDateText}
                            {lastSyncDate?.toLocaleDateString() ??
                                strings.organisationIntegrationEmployeesPage.unknownDate}
                        </GoodSubtext>
                    </TextContainer>
                </TextAndIconContainer>
            );
        } else if (errorReason) {
            return (
                <TextAndIconContainer>
                    <ErrorIcon />
                    <TextContainer>
                        <FailedText>{strings.organisationIntegrationEmployeesPage.statuses.failed}</FailedText>
                        <FailedSubtext>
                            {strings.organisationIntegrationEmployeesPage.lastErrorDateText}
                            {errorDate?.toLocaleDateString() ??
                                strings.organisationIntegrationEmployeesPage.unknownDate}
                        </FailedSubtext>
                    </TextContainer>
                </TextAndIconContainer>
            );
        } else if (status === IntegrationEmployeeStatus.OUT_OF_SYNC) {
            return (
                <TextAndIconContainer>
                    <WarningIcon />
                    <TextContainer>
                        <FailedText>{strings.organisationIntegrationEmployeesPage.statuses.syncFailed}</FailedText>
                        <FailedSubtext>
                            {strings.organisationIntegrationEmployeesPage.lastSyncDateText}
                            {lastSyncDate?.toLocaleDateString() ??
                                strings.organisationIntegrationEmployeesPage.unknownDate}
                        </FailedSubtext>
                    </TextContainer>
                </TextAndIconContainer>
            );
        }

        return (
            <TextAndIconContainer>
                <BodyRegular>{strings.organisationIntegrationEmployeesPage.statuses.notImported}</BodyRegular>
            </TextAndIconContainer>
        );
    }

    function getFriendlyErrorReason() {
        if (!errorReason) return strings.errorMessages.genericCreateIntegrationUserError;

        type ErrorKey = keyof typeof strings.errorMessages;

        if (strings.errorMessages.hasOwnProperty(errorReason)) return strings.errorMessages[errorReason as ErrorKey];

        return strings.errorMessages.genericCreateIntegrationUserError;
    }

    return (
        <EmployeeStatusContainer>
            {getStatus()}
            {errorReason ? <ErrorMessage>{getFriendlyErrorReason()}</ErrorMessage> : null}
        </EmployeeStatusContainer>
    );
}

const TextAndIconContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${sizes.spacingSm};
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const FailedText = styled(BodyRegular)`
    color: ${theme.errorColour};
`;

const FailedSubtext = styled(BodySmall)`
    color: ${theme.errorColour};
`;

const GoodText = styled(BodyRegular)`
    color: ${theme.successColour};
`;

const GoodSubtext = styled(BodySmall)`
    color: ${theme.successColour};
`;

const EmployeeStatusContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingSm};
`;
