import { StepNavigation } from '../structure/StepNavigation';

type PageHeaderBackButtonProps = {
    backButtonTitle: string;
    backButtonAria: string;
    backButtonHref: string;
    backButtonNavigateState?: any;
    backButtonAsBackButton?: boolean;
    buttonFunctionOverride?: Function;
};

export default function NavBackButton({
    backButtonTitle,
    backButtonAria,
    backButtonHref,
    backButtonNavigateState,
    backButtonAsBackButton,
    buttonFunctionOverride,
}: PageHeaderBackButtonProps): JSX.Element {
    return (
        <div>
            <StepNavigation
                title={backButtonTitle}
                aria={backButtonAria}
                href={backButtonHref}
                navigateState={backButtonNavigateState}
                isBackButton={true}
                asBackButton={backButtonAsBackButton}
                buttonFunctionOverride={buttonFunctionOverride}
            />
        </div>
    );
}
