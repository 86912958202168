import { theme } from 'theme';
import { IconSize } from './icon-sizes';

export default function FlagIcon({
    colour = theme.primaryBrandColour,
    size = IconSize.MEDIUM,
}: {
    colour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.13672 17.9456V3.88168H11.5823L11.9133 5.53627H16.5461V13.8092H10.755L10.4241 12.1546H5.7913V17.9456H4.13672ZM12.1201 12.1546H14.8915V7.19085H10.5482L10.2173 5.53627H5.7913V10.5H11.7892L12.1201 12.1546Z"
                fill={colour}
            />
        </svg>
    );
}
