import * as React from 'react';

export enum StepStatus {
    INCOMPLETE = 0,
    CURRENT = 1,
    COMPLETE = 2,
}

export type StepInput = {
    name: string;
    Form: (props: StepperFormProps<any>) => JSX.Element;
};

export type StepperFormProps<T> = {
    next: () => void;
    previous: () => void;
    changeStep: (step: number) => void;
    steps: StepInput[];
    newObject: T;
    setNewObject: React.Dispatch<React.SetStateAction<T>>;
    complete?: () => void;
};
