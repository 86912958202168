/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any */

export function logDebug(message?: any, ...optionalParams: any[]): void {
    // eslint-disable-next-line no-console
    console.debug(message, ...optionalParams);
}

export function logError(message?: any, ...optionalParams: any[]): void {
    // eslint-disable-next-line no-console
    console.error(message, ...optionalParams);
}
