import * as React from 'react';
import Stepper from 'components/stepper/Stepper';
import EmptyForm from './EmptyForm';
import { createEmptyQuestionnaireActivity, QuestionnaireActivity } from 'models/inputModels/QuestionnaireActivity';
import { EditQuestionnaireDetailsForm } from './QuestionnaireDetailsForm';
import { EditQuestionnaireQuestionsForm } from './QuestionnaireQuestionsForm';
import { Activity } from 'models/Activity';

type EditQuestionnaireStepperProps = {
    activity?: Activity;
    completeUpdateQuestionnaire: () => void;
};

export function EditQuestionnaireStepper({
    activity,
    completeUpdateQuestionnaire,
}: EditQuestionnaireStepperProps): JSX.Element {
    const steps = [
        {
            name: 'Activity Details',
            Form: EditQuestionnaireDetailsForm,
        },
        { name: 'Questions', Form: EditQuestionnaireQuestionsForm },
    ];

    if (!activity) {
        steps.unshift({ name: 'Activity Type', Form: EmptyForm });
    }

    return (
        <Stepper<QuestionnaireActivity>
            defaultObject={activity || createEmptyQuestionnaireActivity()}
            steps={steps}
            defaultStep={0}
            complete={completeUpdateQuestionnaire}
        />
    );
}
