import { theme } from 'theme';
import { IconSize } from '../icon-sizes';

export default function LiftIcon({ size = IconSize.LARGE, colour = theme.textColour }): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask
                id="mask0_7_283"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="38"
                height="38"
            >
                <rect width="38" height="38" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_7_283)">
                <path
                    d="M11.0833 28.5H15.8333V22.1667H17.4167V18.2083C17.4167 17.3375 17.1066 16.592 16.4865 15.9719C15.8663 15.3517 15.1208 15.0417 14.25 15.0417H12.6667C11.7958 15.0417 11.0503 15.3517 10.4302 15.9719C9.81007 16.592 9.5 17.3375 9.5 18.2083V22.1667H11.0833V28.5ZM13.4583 13.4583C14.0125 13.4583 14.4809 13.267 14.8635 12.8844C15.2462 12.5017 15.4375 12.0333 15.4375 11.4792C15.4375 10.925 15.2462 10.4566 14.8635 10.074C14.4809 9.69132 14.0125 9.5 13.4583 9.5C12.9042 9.5 12.4358 9.69132 12.0531 10.074C11.6705 10.4566 11.4792 10.925 11.4792 11.4792C11.4792 12.0333 11.6705 12.5017 12.0531 12.8844C12.4358 13.267 12.9042 13.4583 13.4583 13.4583ZM20.5833 17.4167H28.5L24.5417 11.0833L20.5833 17.4167ZM24.5417 26.9167L28.5 20.5833H20.5833L24.5417 26.9167ZM7.91667 33.25C7.04583 33.25 6.30035 32.9399 5.68021 32.3198C5.06007 31.6997 4.75 30.9542 4.75 30.0833V7.91667C4.75 7.04583 5.06007 6.30035 5.68021 5.68021C6.30035 5.06007 7.04583 4.75 7.91667 4.75H30.0833C30.9542 4.75 31.6997 5.06007 32.3198 5.68021C32.9399 6.30035 33.25 7.04583 33.25 7.91667V30.0833C33.25 30.9542 32.9399 31.6997 32.3198 32.3198C31.6997 32.9399 30.9542 33.25 30.0833 33.25H7.91667ZM7.91667 30.0833H30.0833V7.91667H7.91667V30.0833Z"
                    fill={colour}
                />
            </g>
        </svg>
    );
}
