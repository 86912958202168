import React from 'react';
import CloseIcon from 'assets/icons/controls/CloseIcon';
import { Headline4, TertiaryButton } from 'components/Elements';
import PrimaryButton from 'components/buttons/PrimaryButton';
import SecondaryButton from 'components/buttons/SecondaryButton';
import { FormCard } from 'components/forms/FormComponents';
import useNeveForm from 'components/forms/NeveForm';
import { TextAreaField } from 'components/forms/TextAreaField';
import useModifyRequest from 'hooks/useModifyRequest';
import { logError } from 'lib/debug-helpers';
import {
    requiredFieldErrorMessage,
    createErrorMessage,
    fieldErrorCodes,
    createGlobalErrorFromAPIError,
} from 'shared/error-messages';
import styled from 'styled-components';
import { sizes, theme, breakpoints } from 'theme';
import { ReasonForRemoval } from './RemoveLearnerModal';
import Notification, { NotificationType } from 'components/notifiers/Notification';
import strings from '../../../../strings/strings.json';

export type RemoveLearnerFormProps = {
    courseScheduleId: string;
    idOfLearnerToDelete: string;
    closeModal: () => void;
    onCompleted: () => void;
};

export default function RemoveLearnerForm({
    courseScheduleId,
    idOfLearnerToDelete,
    closeModal,
    onCompleted,
}: RemoveLearnerFormProps) {
    const [errorMessage, setErrorMessage] = React.useState<string>('');

    const {
        modifyData: removeLearnerAndSendNotification,
        loading: submitting,
        errors: apiErrors,
    } = useModifyRequest(`courses/${courseScheduleId}/remove-learner/${idOfLearnerToDelete}`, 'PUT');

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useNeveForm<ReasonForRemoval>({
        reasonForRemoval: '',
    });

    async function removeLearner(): Promise<void> {
        const reasonForRemoval = watch('reasonForRemoval');
        const response = await removeLearnerAndSendNotification({ reasonForRemoval: reasonForRemoval });

        if (response.errors) {
            logError(response.errors);

            let responseErrorMessage =
                response.errors[0] && response.errors[0].code
                    ? createGlobalErrorFromAPIError(response.errors[0].code)
                    : 'Learner was not removed from course.';
            setErrorMessage(responseErrorMessage);

            return;
        } else {
            closeModal();
            onCompleted();
        }
    }
    return (
        <Form onSubmit={handleSubmit(removeLearner)}>
            <div>
                <CloseRow>
                    <PaddedTertiaryButton onClick={closeModal} aria-label={strings.removeLearnerForm.closeButtonAria}>
                        <CloseIcon />
                    </PaddedTertiaryButton>
                </CloseRow>
                <CenteredRow>
                    <Headline4>{strings.removeLearnerForm.headlineText}</Headline4>
                </CenteredRow>
            </div>
            <MainRow>
                <TextAreaField
                    maxLength={100}
                    charactersLeft={100 - watch('reasonForRemoval').length}
                    fieldName={strings.removeLearnerForm.textAreaField.fieldName}
                    aria={strings.removeLearnerForm.textAreaField.aria}
                    errorMessage={errors.reasonForRemoval?.message}
                    required
                    inputProps={register('reasonForRemoval', {
                        required: {
                            value: true,
                            message: requiredFieldErrorMessage(
                                strings.removeLearnerForm.textAreaField.requiredErrorText,
                            ),
                        },
                        maxLength: {
                            value: 100,
                            message: createErrorMessage(
                                strings.removeLearnerForm.textAreaField.fieldName,
                                fieldErrorCodes.maxLength,
                            ),
                        },
                    })}
                />
            </MainRow>
            <ButtonsContainer>
                <SecondaryButton
                    title={strings.removeLearnerForm.backButton.title}
                    aria={strings.removeLearnerForm.backButton.aria}
                    onClick={closeModal}
                />
                <PrimaryButton
                    title={strings.removeLearnerForm.removeButton.title}
                    aria={strings.removeLearnerForm.removeButton.aria}
                    type="submit"
                    disabled={submitting}
                    submitting={submitting}
                />
            </ButtonsContainer>
            {apiErrors.length > 0 && <Notification notificationType={NotificationType.DANGER} title={errorMessage} />}
        </Form>
    );
}

const PaddedTertiaryButton = styled(TertiaryButton)`
    padding: ${sizes.spacingSm};
`;

const Form = styled(FormCard)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${sizes.spacingRg};
    background-color: ${theme.cardBackground};
    padding: ${sizes.spacingRg};
    border: solid 1px ${theme.cardBorder};
    border-radius: ${sizes.borderRadiusLg};
    margin: 200px auto;
    max-width: 600px;

    @media (max-width: ${breakpoints.sm}) {
        padding: ${sizes.spacingMd} ${sizes.spacingMd} 5rem ${sizes.spacingMd};
        gap: ${sizes.spacingSm};
        margin: 112px auto 0 auto;
        width: 100%;
        border-radius: unset;
    }
`;

const CloseRow = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
`;

const CenteredRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${sizes.spacingMd};
`;

const MainRow = styled(CenteredRow)`
    flex-grow: 1;
`;

const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${sizes.spacingLg};
    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column-reverse;
    }
`;
