type AccessibilityOption = {
    name: string;
    value: string;
    section: string;
};

export const ACCESSIBILITY_OPTIONS: AccessibilityOption[] = [
    {
        name: 'I have reading difficulty',
        value: 'readingDifficulty',
        section: 'vision',
    },
    {
        name: 'I am colour sensitive',
        value: 'colourSensitive',
        section: 'vision',
    },
    {
        name: 'I have hearing difficulty',
        value: 'hearingDifficulty',
        section: 'hearing',
    },
    {
        name: 'I struggle with noisy environments',
        value: 'noisyEnvironments',
        section: 'hearing',
    },
    {
        name: 'I am a wheelchair user',
        value: 'wheelchairUser',
        section: 'mobility',
    },
    {
        name: 'I have a limited range of motion',
        value: 'limitedRangeMotion',
        section: 'mobility',
    },
    {
        name: 'I use a guide dog',
        value: 'guideDog',
        section: 'vision',
    },
];
