import React from 'react';
import styled from 'styled-components';
import { sizes, theme } from '../../theme';

type IconButtonProps = {
    icon: JSX.Element;
    aria: string;
    onClick: () => void;
    disabled: boolean;
    alternateColour?: boolean;
};
export function IconButton({ icon, aria, onClick, disabled, alternateColour }: IconButtonProps) {
    return (
        <IconButtonContainer aria-label={aria} onClick={onClick} disabled={disabled} alternateColour={alternateColour}>
            <div>{icon}</div>
        </IconButtonContainer>
    );
}

const IconButtonContainer = styled.button<{
    alternateColour?: boolean;
}>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 0;
    height: 48px;
    padding: ${sizes.spacingRg};
    border: ${({ alternateColour }) => {
        return alternateColour
            ? `2px solid ${theme.secondaryButtonAlternateBorderColour} !important`
            : `2px solid ${theme.secondaryButtonBorderColour} !important`;
    }};
    border-radius: 24px;
    cursor: pointer;

    background-color: ${({ disabled }) => {
        return disabled ? theme.primaryButtonDisabledBackgroundColour : theme.secondaryButtonBackgroundColour;
    }};

    &:hover {
        background-color: ${({ disabled }) => {
            return disabled ? undefined : theme.secondaryButtonHoverBackgroundColour;
        }};
    }
    &:focus {
        background-color: ${theme.primaryButtonFocusBackgroundColour};
        border: 2px solid ${theme.primaryButtonBorderColour};
        outline: ${theme.primaryButtonBorderColour};
        color: ${theme.primaryButtonFocusTextColour};
    }
`;
