import * as React from 'react';
import StepperNavigation from './StepperNavigation';
import styled from 'styled-components';
import { formTheme } from '../forms/FormComponents';
import { sizes, breakpoints, theme } from '../../theme';
import { StepInput } from './stepperTypes';

export default function Stepper<T>({
    steps,
    defaultStep = 0,
    defaultObject,
    isSmall,
    complete,
}: {
    steps: StepInput[];
    defaultStep?: number;
    defaultObject: T;
    isSmall?: boolean;
    complete?: () => void;
}): JSX.Element {
    const [currentStep, setCurrentStep] = React.useState(defaultStep);
    const [newObject, setNewObject] = React.useState<T>(defaultObject);

    const next = () => setCurrentStep((prevState) => Math.min(prevState + 1, steps.length - 1));

    const previous = () => setCurrentStep((prevState) => Math.max(prevState - 1, 0));

    const changeStep = (step: number) => setCurrentStep(step);

    const currentStepComponent = steps[currentStep];
    return (
        <StepperContainer isSmall={isSmall}>
            <StepperNavigation steps={steps} currentStep={currentStep} />
            <StepperFormContainer>
                <currentStepComponent.Form
                    newObject={newObject}
                    setNewObject={setNewObject}
                    next={next}
                    previous={previous}
                    changeStep={changeStep}
                    steps={steps}
                    complete={complete}
                />
            </StepperFormContainer>
        </StepperContainer>
    );
}

const StepperContainer = styled.div<{ isSmall?: boolean }>`
    max-width: ${({ isSmall }) => {
        return isSmall ? formTheme.smallFormCardWidth : formTheme.formCardWidth;
    }};
    border: solid 1px ${theme.cardBorder};
    border-radius: ${sizes.spacingSm};
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.1);
    background-color: ${theme.cardBackground};
    overflow: hidden;
    @media (max-width: ${breakpoints.sm}) {
        width: 100%;
    }
`;

const StepperFormContainer = styled.div`
    padding: ${sizes.spacingMd};
`;
