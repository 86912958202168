import { theme } from '../../../theme';
import { IconSize } from '../icon-sizes';

export default function TextHelperIcon({
    colour = theme.primaryBrandColour,
    size = IconSize.MEDIUM,
}: {
    colour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.50272 21.2972C8.50319 21.5921 8.59037 21.8808 8.75396 22.1264L9.55504 23.3306C9.69188 23.5365 9.8775 23.7053 10.0954 23.8221C10.3132 23.9389 10.5566 24 10.8038 24H13.6964C13.9435 24 14.1869 23.9389 14.4048 23.8221C14.6226 23.7053 14.8082 23.5365 14.9451 23.3306L15.7462 22.1264C15.9097 21.8808 15.9971 21.5923 15.9974 21.2972L15.9993 19.4996H8.50037L8.50272 21.2972ZM4 8.24986C4 10.3297 4.77108 12.2271 6.04183 13.6769C6.81619 14.5605 8.02741 16.4064 8.48912 17.9636C8.491 17.9758 8.4924 17.9879 8.49428 18.0001H16.0054C16.0072 17.9879 16.0087 17.9762 16.0105 17.9636C16.4722 16.4064 17.6835 14.5605 18.4578 13.6769C19.7286 12.2271 20.4997 10.3297 20.4997 8.24986C20.4997 3.68481 16.7924 -0.0140222 12.224 3.99638e-05C7.44243 0.0145709 4 3.88918 4 8.24986ZM12.2498 4.49994C10.1822 4.49994 8.4999 6.18225 8.4999 8.24986C8.4999 8.66423 8.16429 8.99985 7.74992 8.99985C7.33555 8.99985 6.99994 8.66423 6.99994 8.24986C6.99994 5.35493 9.35489 2.99998 12.2498 2.99998C12.6642 2.99998 12.9998 3.33559 12.9998 3.74996C12.9998 4.16433 12.6642 4.49994 12.2498 4.49994Z"
                fill={colour}
            />
        </svg>
    );
}
