import { theme } from '../../../theme';
import { IconSize } from '../icon-sizes';

export default function ScheduleIcon({
    colour = theme.primaryBrandColour,
    size = IconSize.MEDIUM,
}: {
    colour?: string;
    backgroundColour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 31 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.3095 25.0675C9.11879 25.0675 8.11233 24.6565 7.29015 23.8343C6.46797 23.0121 6.05688 22.0056 6.05688 20.8149C6.05688 19.6241 6.46797 18.6177 7.29015 17.7955C8.11233 16.9733 9.11879 16.5622 10.3095 16.5622C11.5003 16.5622 12.5067 16.9733 13.3289 17.7955C14.1511 18.6177 14.5622 19.6241 14.5622 20.8149C14.5622 22.0056 14.1511 23.0121 13.3289 23.8343C12.5067 24.6565 11.5003 25.0675 10.3095 25.0675ZM3.50528 34.4234C2.56969 34.4234 1.76878 34.0903 1.10253 33.424C0.436275 32.7578 0.103149 31.9569 0.103149 31.0213V7.20636C0.103149 6.27077 0.436275 5.46986 1.10253 4.80361C1.76878 4.13736 2.56969 3.80423 3.50528 3.80423H5.20634V0.4021H8.60847V3.80423H22.217V0.4021H25.6191V3.80423H27.3202C28.2558 3.80423 29.0567 4.13736 29.7229 4.80361C30.3892 5.46986 30.7223 6.27077 30.7223 7.20636V31.0213C30.7223 31.9569 30.3892 32.7578 29.7229 33.424C29.0567 34.0903 28.2558 34.4234 27.3202 34.4234H3.50528ZM3.50528 31.0213H27.3202V14.0106H3.50528V31.0213ZM3.50528 10.6085H27.3202V7.20636H3.50528V10.6085Z"
                fill={colour}
            />
        </svg>
    );
}
