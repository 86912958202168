import strings from '../../../strings/strings.json';
import { breakpoints, sizes, theme } from 'theme';
import { BodySmall } from 'components/Elements';
import { FileDescription } from './SelectFileForm';
import styled from 'styled-components';
import useWindowWidth from 'hooks/useWindowWidth';
import SecondaryButton, { SecondaryButtonColour } from 'components/buttons/SecondaryButton';
import { IconButton } from 'components/buttons/IconButton';
import ImageIcon from 'assets/icons/pathway/ImageIcon';
import VideoIcon from 'assets/icons/pathway/VideoIcon';
import BinIcon from 'assets/icons/controls/BinIcon';
import { IconSize } from 'assets/icons/icon-sizes';
import AudioIcon from 'assets/icons/pathway/AudioIcon';

type FileCardProps = {
    fileName: string;
    uploadDate: Date;
    removeFile: () => void;
    disabled: boolean;
};

type InnerFileCardProps = FileCardProps & {
    fileIcon: JSX.Element;
    removeFileButtonTitle: string;
    removeFileButtonAria: string;
};

export function VideoCard({ fileName, uploadDate, removeFile, disabled }: FileCardProps): JSX.Element {
    return (
        <FileCard
            fileName={fileName}
            uploadDate={uploadDate}
            removeFile={removeFile}
            disabled={disabled}
            fileIcon={<VideoIcon />}
            removeFileButtonTitle={strings.learningMaterialsForm.removeVideoButton}
            removeFileButtonAria={strings.learningMaterialsForm.removeVideoButtonAria}
        />
    );
}

export function ImageCard({ fileName, uploadDate, removeFile, disabled }: FileCardProps): JSX.Element {
    return (
        <FileCard
            fileName={fileName}
            uploadDate={uploadDate}
            removeFile={removeFile}
            disabled={disabled}
            fileIcon={<ImageIcon />}
            removeFileButtonTitle={strings.learningMaterialsForm.removeImageButton}
            removeFileButtonAria={strings.learningMaterialsForm.removeImageButtonAria}
        />
    );
}

export function AudioCard({ fileName, uploadDate, removeFile, disabled }: FileCardProps): JSX.Element {
    return (
        <FileCard
            fileName={fileName}
            uploadDate={uploadDate}
            removeFile={removeFile}
            disabled={disabled}
            fileIcon={<AudioIcon />}
            removeFileButtonTitle={strings.learningMaterialsForm.audio.removeAudioButton}
            removeFileButtonAria={strings.learningMaterialsForm.audio.removeAudioButtonAria}
        />
    );
}

function FileCard({
    fileName,
    uploadDate,
    removeFile,
    disabled,
    fileIcon,
    removeFileButtonTitle,
    removeFileButtonAria,
}: InnerFileCardProps): JSX.Element {
    const { currentWindowWidth } = useWindowWidth();

    return (
        <Card>
            <FileIconAndDescription>
                <div>{fileIcon}</div>
                <FileDescription>
                    <BodySmall $bold>{fileName}</BodySmall>
                    <BodySmall>{new Date(uploadDate).toLocaleDateString()}</BodySmall>
                </FileDescription>
            </FileIconAndDescription>

            {currentWindowWidth < parseInt(breakpoints.sm) ? (
                <IconButton
                    icon={<BinIcon size={IconSize.MEDIUM} />}
                    aria={removeFileButtonAria}
                    onClick={removeFile}
                    disabled={disabled}
                    alternateColour
                />
            ) : (
                <SecondaryButton
                    title={removeFileButtonTitle}
                    aria={removeFileButtonAria}
                    onClick={removeFile}
                    icon={
                        <BinIconContainer>
                            <BinIcon size={IconSize.MEDIUM} />
                        </BinIconContainer>
                    }
                    disabled={disabled}
                    alternateColour={SecondaryButtonColour.ALTERNATE}
                />
            )}
        </Card>
    );
}

export const BinIconContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const Card = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: ${sizes.spacingSm} ${sizes.spacingSm} ${sizes.spacingSm} ${sizes.spacingMd};

    overflow: hidden;

    border: 1px solid ${theme.cardBorder};
    border-radius: ${sizes.borderRadiusMd};

    @media (max-width: ${breakpoints.sm}) {
        padding: ${sizes.spacingSm} ${sizes.spacingMd};
    }
`;

export const FileIconAndDescription = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${sizes.spacingMd};
`;
