import { theme } from '../../../theme';
import { IconSize } from '../icon-sizes';

export default function OnlineMaterialsIcon({
    colour = theme.primaryBrandColour,
    size = IconSize.MEDIUM,
}: {
    colour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 47 39" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.2545 23.907L33.3344 11.8803L30.3011 8.84701L21.2545 17.8937L16.7312 13.3703L13.7511 16.3504L21.2545 23.907ZM0.12793 38.7009V34.4437H46.9576V38.7009H0.12793ZM6.51379 32.3151C5.34305 32.3151 4.34083 31.8982 3.50712 31.0645C2.67341 30.2308 2.25655 29.2286 2.25655 28.0578V4.64298C2.25655 3.47224 2.67341 2.47002 3.50712 1.63631C4.34083 0.802597 5.34305 0.385742 6.51379 0.385742H40.5717C41.7425 0.385742 42.7447 0.802597 43.5784 1.63631C44.4121 2.47002 44.829 3.47224 44.829 4.64298V28.0578C44.829 29.2286 44.4121 30.2308 43.5784 31.0645C42.7447 31.8982 41.7425 32.3151 40.5717 32.3151H6.51379ZM6.51379 28.0578H40.5717V4.64298H6.51379V28.0578Z"
                fill={colour}
            />
        </svg>
    );
}
