import { theme } from '../../../theme';
import { IconSize } from '../icon-sizes';

export default function AudioIcon({
    colour = theme.primaryButtonBorderColour,
    size = IconSize.LARGE,
}: {
    colour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20 16.25V13.75H25V16.25H20ZM21.5 25L17.5 22L19 20L23 23L21.5 25ZM19 10L17.5 8L21.5 5L23 7L19 10ZM3.75 18.75V11.25H8.75L15 5V25L8.75 18.75H3.75ZM12.5 11.0625L9.8125 13.75H6.25V16.25H9.8125L12.5 18.9375V11.0625Z"
                fill={colour}
            />
        </svg>
    );
}
