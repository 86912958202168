import styled, { keyframes } from 'styled-components';
import { theme } from '../theme';

export default function Spinner(): JSX.Element {
    return <LoadingSpinner />;
}

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const LoadingSpinner = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 5px solid ${theme.spinnerColour};
    animation: ${spin} 1s infinite linear;
    clip-path: polygon(0 0, 100% 0, 100% 66.6%, 0 66.6%);
`;
