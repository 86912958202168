import React from 'react';
import styled from 'styled-components';
import { FONTSIZE, breakpoints, sizes, theme } from 'theme';

export type Tab = {
    id: number;
    name: string;
};

type TabsProps = {
    tabs: Tab[];
    activeTab: number;
    setActiveTab: Function;
};

export default function Tabs({ tabs, activeTab, setActiveTab }: TabsProps): JSX.Element {
    return (
        <TabContainer>
            {tabs.map((tab) => (
                <TabItem
                    key={tab.id}
                    $active={activeTab === tab.id}
                    onClick={() => {
                        setActiveTab(tab.id);
                    }}
                >
                    {tab.name}
                </TabItem>
            ))}
        </TabContainer>
    );
}

const TabContainer = styled.div`
    display: flex;
    @media (max-width: ${breakpoints.sm}) {
        display: grid;
        grid-template-columns: 50% 50%;
    }
`;

const TabItem = styled.button<{ $active?: boolean }>`
    font-size: ${FONTSIZE.BodySmall};
    font-family: ${theme.fontFamilyMain};
    min-width: 180px;
    min-height: 48px;
    border: none;
    border-radius: ${sizes.borderRadiusMd} ${sizes.borderRadiusMd} 0 0;
    background-color: ${({ $active }) => ($active ? theme.cardBackground : 'transparent')};
    border-bottom: 4px solid ${({ $active }) => ($active ? theme.activeTab : 'transparent')};
    cursor: pointer;
    &:focus {
        border-top: 2px solid ${theme.navigationBar.buttonFocusBorder};
        border-left: 2px solid ${theme.navigationBar.buttonFocusBorder};
        border-right: 2px solid ${theme.navigationBar.buttonFocusBorder};
        outline: ${theme.navigationBar.buttonFocusBorder};
        border-radius: ${sizes.borderRadiusMd};
        margin: 0;
    }
`;
