import styled from 'styled-components';
import { breakpoints, sizes } from '../../theme';
import CheckIcon from 'assets/icons/form-icons/CheckIcon';
import { BodyRegular } from 'components/Elements';

export default function LearningObjective({ description }: { description: string }): JSX.Element {
    return (
        <LearningObjectiveContainer>
            <LearningObjectiveIconContainer>
                <CheckIcon />
            </LearningObjectiveIconContainer>
            <BodyRegular>{description}</BodyRegular>
        </LearningObjectiveContainer>
    );
}

const LearningObjectiveContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    gap: ${sizes.spacingSm};
    @media (max-width: ${breakpoints.lg}) {
        max-width: unset;
    }
`;

const LearningObjectiveIconContainer = styled.div`
    padding: ${sizes.spacingMd};
    height: 48px;
    width: 48px;
    border-radius: 50%;
    background-color: #dcf4ea;
`;
