import strings from '../../../strings/strings.json';
import React from 'react';
import { TextAreaField } from 'components/forms/TextAreaField';
import useModifyRequest from 'hooks/useModifyRequest';
import { createErrorMessage, fieldErrorCodes } from 'shared/error-messages';
import useNeveForm from 'components/forms/NeveForm';
import { FormCard } from 'components/forms/FormComponents';
import PrimaryButton from 'components/buttons/PrimaryButton';
import SaveNotifier from 'components/notifiers/SaveNotifier';
import { ErrorNotifierSlim } from 'components/notifiers/ErrorNotifierSlim';
import { Organisation } from 'models/Organisation';
import styled from 'styled-components';
import { sizes } from 'theme';

type OrganisationDescription = {
    description: string;
};

type OrganisationDescriptionFormProps = {
    organisation: Organisation;
    refetchOrganisation: () => Promise<void>;
    savedDescription: boolean;
    setSavedDescription: (value: React.SetStateAction<boolean>) => void;
};

export function OrganisationDescriptionForm({
    organisation,
    refetchOrganisation,
    savedDescription,
    setSavedDescription,
}: OrganisationDescriptionFormProps): JSX.Element {
    const [showError, setShowError] = React.useState<boolean>(false);

    const { modifyData: updateDescription, loading: updatingDescription } = useModifyRequest(
        `organisations/${organisation.id}/description`,
        'PATCH',
    );

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors, isDirty },
        reset,
    } = useNeveForm<OrganisationDescription>({
        description: organisation?.description ?? '',
    });

    async function onUpdateOrganisationDescription({ description }: OrganisationDescription) {
        setShowError(false);

        const response = await updateDescription({ description: description });

        if (response.errors && response.errors.length > 0) {
            setShowError(true);
        } else {
            setSavedDescription(true);
            reset({ description });
            await refetchOrganisation();
        }
    }

    return (
        <OrganisationDescriptionFormCard onSubmit={handleSubmit(onUpdateOrganisationDescription)}>
            <TextAreaField
                maxLength={500}
                charactersLeft={500 - watch('description').length}
                fieldName={strings.updateOrganisationForm.descriptionInput.title}
                aria={strings.updateOrganisationForm.descriptionInput.aria}
                errorMessage={errors.description?.message}
                inputProps={register('description', {
                    maxLength: {
                        value: 500,
                        message: createErrorMessage(
                            strings.updateOrganisationForm.descriptionInput.title,
                            fieldErrorCodes.maxLength,
                        ),
                    },
                })}
            />
            <ErrorAndButtonContainer>
                {showError && <ErrorNotifierSlim description={strings.updateOrganisationForm.error} />}
                <SaveButtonContainer>
                    <PrimaryButton
                        type="submit"
                        title={strings.updateOrganisationForm.submitButton.title}
                        aria={strings.updateOrganisationForm.submitButton.aria}
                        disabled={updatingDescription}
                    />
                </SaveButtonContainer>
            </ErrorAndButtonContainer>
            {savedDescription && !isDirty && (
                <>
                    <SaveNotifier />
                </>
            )}
        </OrganisationDescriptionFormCard>
    );
}

const OrganisationDescriptionFormCard = styled(FormCard)`
    border: unset;
    box-shadow: unset;
`;

const SaveButtonContainer = styled.div`
    display: flex;
    justify-content: end;
`;

const ErrorAndButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingRg};
`;
