import * as React from 'react';
import strings from '../../../strings/strings.json';
import styled from 'styled-components';
import { ButtonRowRight } from 'components/Elements';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { logError } from 'lib/debug-helpers';
import { APIError } from 'lib/_api-helpers';
import { createGlobalErrorFromAPIError, requiredFieldErrorMessage } from 'shared/error-messages';
import { ErrorMessage, FormCard, formTheme } from 'components/forms/FormComponents';
import TextField from 'components/forms/TextField';
import { Organisation } from 'models/Organisation';
import useUpsertRequest from 'hooks/useUpsertRequest';
import useNeveForm from 'components/forms/NeveForm';
import { sizes } from '../../../theme';

type OrganisationInput = {
    name: string;
};

type OrganisationFormProps = {
    organisation?: Organisation | null;
};

export default function OrganisationForm({ organisation }: OrganisationFormProps): JSX.Element {
    const navigate = useNavigate();

    const { modifyData: upsertOrganisation } = useUpsertRequest('organisations');

    const [globalErrorMessage, setGlobalErrorMessage] = React.useState<string>('');

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useNeveForm<OrganisationInput>({
        name: organisation?.name ?? '',
    });

    async function onSubmit(formData: OrganisationInput) {
        const newOrganisation = {
            name: formData.name,
            id: organisation?.id,
        };
        const response = await upsertOrganisation(newOrganisation);

        const { errors } = response;
        if (errors) {
            logError(errors);
            updateErrors(errors);
            return;
        }

        navigate('/admin/dashboard/');
    }

    function updateErrors(apiErrors: APIError[]): void {
        apiErrors.forEach((apiError) => {
            const { field, code } = apiError;
            if (field === 'global') {
                return setGlobalErrorMessage(createGlobalErrorFromAPIError(code));
            }
        });
    }

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                    fieldName="organisationName"
                    labelText={strings.organisationForm.createOrganisationInputLabel}
                    inputAria={strings.organisationForm.createOrganisationInputAriaLabel}
                    inputProps={register('name', {
                        required: {
                            value: true,
                            message: requiredFieldErrorMessage(strings.organisationForm.createOrganisationInputLabel),
                        },
                    })}
                    errorMessage={errors.name?.message}
                    required
                />
                {globalErrorMessage && (
                    <ErrorMessageWithPadding id="errorMessage">{globalErrorMessage}</ErrorMessageWithPadding>
                )}
                <ButtonRowRight>
                    <PrimaryButton
                        type="submit"
                        title={strings.organisationForm.saveButton}
                        aria={strings.organisationForm.saveButtonAriaLabel}
                    />
                </ButtonRowRight>
            </Form>
        </>
    );
}

const ErrorMessageWithPadding = styled(ErrorMessage)`
    padding-bottom: ${sizes.spacingSm};
`;

const Form = styled(FormCard)`
    max-width: ${formTheme.smallFormCardWidth};
`;
