import * as React from 'react';

export function useHeight({ on = true /* no value means on */ } = {} as any) {
    const ref = React.useRef<HTMLDivElement>();
    const [height, set] = React.useState(0);
    const heightRef = React.useRef(height);
    const [ro] = React.useState(
        () =>
            new ResizeObserver((_) => {
                if (ref.current && heightRef.current !== ref.current.offsetHeight) {
                    heightRef.current = ref.current.offsetHeight;
                    set(ref.current.offsetHeight);
                }
            }),
    );
    React.useLayoutEffect(() => {
        if (on && ref.current) {
            set(ref.current.offsetHeight);
            ro.observe(ref.current, {});
        }
        return () => ro.disconnect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [on, ref.current]);
    return [ref, height as any];
}
