import PrimaryButton from 'components/buttons/PrimaryButton';
import { ButtonRowRight } from 'components/Elements';
import { FormCardWithGaps } from 'components/forms/FormComponents';
import useNeveForm from 'components/forms/NeveForm';
import TextField from 'components/forms/TextField';
import Notification, { NotificationType } from 'components/notifiers/Notification';
import MainSection from 'components/sections/MainSection';
import SubpageHeader, { BackButtonDetails } from 'components/sections/SubpageHeader';
import SuccessCard from 'components/notifiers/SuccessCard';
import { AuthContext } from 'contextProviders/AuthContext';
import useModifyRequest from 'hooks/useModifyRequest';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    createErrorMessage,
    createGlobalErrorFromAPIError,
    globalErrorCodes,
    requiredFieldErrorMessage,
} from 'shared/error-messages';
import styled from 'styled-components';
import strings from '../../../strings/strings.json';

type InviteTrainerFormInput = {
    fullName: string;
    emailAddress: string;
};

export function TrainerInvitePage(): JSX.Element {
    const navigate = useNavigate();
    const authContext = React.useContext(AuthContext);
    const organisationId = authContext.userData.organisation?.id;

    const [saved, setSaved] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>('');

    const { modifyData: inviteTrainer, loading } = useModifyRequest(
        `organisations/${organisationId}/invite-trainer`,
        'POST',
    );

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
        reset,
        setFocus,
        getValues,
    } = useNeveForm<InviteTrainerFormInput>({
        fullName: '',
        emailAddress: '',
    });

    const backButtonDetails: BackButtonDetails = {
        title: strings.inviteTrainerForm.backButton.title,
        aria: strings.inviteTrainerForm.backButton.aria,
        href: '/organisation/overview',
        navigateState: { state: { activeTabId: 'manageTrainers' } },
    };

    async function onSubmit(formData: InviteTrainerFormInput) {
        const invitation = {
            inviteeName: formData.fullName,
            inviteeEmail: formData.emailAddress,
        };

        const response = await inviteTrainer(invitation);

        if (response.errors) {
            let responseErrorMessage =
                response.errors[0] && response.errors[0].code
                    ? createGlobalErrorFromAPIError(response.errors[0].code)
                    : strings.inviteTrainerForm.errorMessage.title;

            if (responseErrorMessage === globalErrorCodes.userAlreadyTrainerForOrganisation) {
                responseErrorMessage = `${responseErrorMessage} ${authContext.userData.organisation?.name}`;
            }

            setErrorMessage(responseErrorMessage);

            if (responseErrorMessage === globalErrorCodes.userAlreadyInvitedToBeTrainerForOrganisation) {
                setFocus('emailAddress');
            }
        } else {
            setSaved(true);
        }

        reset(formData);
    }

    function refreshForm() {
        setSaved(false);
        setErrorMessage('');
        reset({ fullName: '', emailAddress: '' });
    }

    if (saved) {
        const emailAddress = getValues('emailAddress');

        return (
            <MainSection>
                <SuccessCard
                    notificationTitle={strings.inviteTrainerForm.successCard.notification.title}
                    notificationDescription={`${strings.inviteTrainerForm.successCard.notification.description} ${emailAddress}.`}
                    primaryButtonClick={() => refreshForm()}
                    primaryButtonTitle={strings.inviteTrainerForm.successCard.primaryButton.title}
                    primaryButtonAria={strings.inviteTrainerForm.successCard.primaryButton.aria}
                    secondaryButtonClick={() =>
                        navigate('/organisation/overview', { state: { activeTabId: 'manageTrainers' } })
                    }
                    secondaryButtonTitle={strings.inviteTrainerForm.successCard.secondaryButton.title}
                    secondaryButtonAria={strings.inviteTrainerForm.successCard.secondaryButton.aria}
                />
            </MainSection>
        );
    }

    return (
        <>
            <SubpageHeader
                title={strings.inviteTrainerForm.heading}
                shadedBackground
                backButtonDetails={backButtonDetails}
                pageOverlap
            />
            <MainSection>
                <TrainerInvitationFormCard onSubmit={handleSubmit(onSubmit)}>
                    <TextField
                        fieldName={'fullName'}
                        labelText={strings.inviteTrainerForm.fields.fullName.text}
                        inputAria={strings.inviteTrainerForm.fields.fullName.aria}
                        inputProps={register('fullName', {
                            required: {
                                value: true,
                                message: requiredFieldErrorMessage(strings.inviteTrainerForm.fields.fullName.text),
                            },
                        })}
                        errorMessage={errors.fullName?.message}
                        required
                    />
                    <TextField
                        fieldName={'emailAddress'}
                        labelText={strings.inviteTrainerForm.fields.emailAddress.text}
                        inputAria={strings.inviteTrainerForm.fields.emailAddress.aria}
                        inputProps={register('emailAddress', {
                            required: {
                                value: true,
                                message: requiredFieldErrorMessage(strings.inviteTrainerForm.fields.emailAddress.text),
                            },
                            pattern: {
                                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                message: createErrorMessage(
                                    strings.inviteTrainerForm.fields.emailAddress.text,
                                    strings.errorMessages.fieldErrorInvalidEmail,
                                ),
                            },
                        })}
                        errorMessage={errors.emailAddress?.message}
                        required
                    />
                    <ButtonRowRight>
                        <PrimaryButton
                            type="submit"
                            title={strings.inviteTrainerForm.submitButton.title}
                            aria={strings.inviteTrainerForm.submitButton.aria}
                            disabled={loading}
                        />
                    </ButtonRowRight>
                    {!isDirty && errorMessage && (
                        <>
                            <Notification notificationType={NotificationType.DANGER} title={errorMessage} />
                        </>
                    )}
                </TrainerInvitationFormCard>
            </MainSection>
        </>
    );
}

const TrainerInvitationFormCard = styled(FormCardWithGaps)`
    max-width: 480px;
`;
