import { BodyRegular, BodySmall, Headline5, Headline6 } from '../../../components/Elements';
import strings from '../../../strings/strings.json';
import SecondaryButton from '../../../components/buttons/SecondaryButton';
import * as React from 'react';
import { ChangeEvent } from 'react';
import { MeetingCard, MeetingDetailSection, MeetingHeaderSection, MeetingInfo } from './commonElements';
import DurationLabel from '../../../components/DurationLabel';
import styled from 'styled-components';
import { breakpoints, sizes, theme } from '../../../theme';
import EmbeddedDateInput from '../../../components/forms/EmbeddedDateInput';
import { SelectSingleEventHandler } from 'react-day-picker';
import EmbeddedTimeInput, { AmOrPm } from '../../../components/forms/EmbeddedTimeInput';
import { get12HourPeriod, getDateFromHourMinutePeriod, getLocaleTimeRangeString } from '../../../lib/time-helpers';
import RadioFields from '../../../components/forms/RadioFields';
import { MeetingProviderOption } from '../ScheduleCoursePage';
import TextField from '../../../components/forms/TextField';
import ScheduleIcon from '../../../assets/icons/pathway/ScheduleIcon';
import TimeIcon from '../../../assets/icons/pathway/TimeIcon';
import LinkIcon from '../../../assets/icons/pathway/LinkIcon';
import { ErrorNotifierSlim } from "../../../components/notifiers/ErrorNotifierSlim";
import { LocationType } from '../../../models/ActivityMeeting';
import LocationIcon from '../../../assets/icons/controls/LocationIcon';
import { IconSize } from '../../../assets/icons/icon-sizes';
import useWindowWidth from '../../../hooks/useWindowWidth';
import Notification, { NotificationType } from '../../../components/notifiers/Notification';
import { animated, useSpring } from '@react-spring/web';
import { useHeight } from '../../../hooks/useHeight';
import AccessibilityNotifier from '../../../components/notifiers/AccessibilityNotifier';

type CollapsibleMeetingCardProps = {
    title: string;
    durationInMins: number | null;
    moduleName: string;
    courseStartDate: Date;
    courseEndDate: Date;
    meetingDate: Date | undefined;
    setMeetingDate: Function;
    meetingTime: Date | undefined;
    setMeetingTime: Function;
    meetingProvider: MeetingProviderOption | undefined;
    meetingLocationType: LocationType | undefined;
    meetingIndex: number;
    register: Function;
    setValue: Function;
    validateLocation: (
        locationType: LocationType | undefined,
        joinLink: string | undefined,
        location: string | undefined,
        meetingIndex: number,
    ) => boolean;
    meetingJoinLink: string | undefined;
    meetingLocation: string | undefined;
    meetingStartDateError: string | undefined;
    meetingStartTimeError: string | undefined;
    meetingJoinLinkError: string | undefined;
    meetingLocationTypeError: string | undefined;
    meetingLocationError: string | undefined;
};

export default function CollapsibleMeetingCard({
    title,
    moduleName,
    durationInMins,
    courseStartDate,
    courseEndDate,
    meetingDate,
    setMeetingDate,
    meetingTime,
    setMeetingTime,
    meetingProvider,
    meetingLocationType,
    meetingIndex,
    register,
    setValue,
    validateLocation,
    meetingJoinLink,
    meetingLocation,
    meetingStartDateError,
    meetingStartTimeError,
    meetingJoinLinkError,
    meetingLocationTypeError,
    meetingLocationError,
}: CollapsibleMeetingCardProps): JSX.Element {
    // NOTE: The method for animation used in this component is the only method I've seen that works
    // when the content can have varying heights and will vary programatically whilst
    // the 'accordion' is open
    const [heightRef, height] = useHeight();

    const [expanded, setExpanded] = React.useState<boolean>(false);
    const [hour, setHour] = React.useState<number>(meetingTime ? meetingTime.getHours() % 12 : 9);
    const [minute, setMinute] = React.useState<number>(meetingTime ? meetingTime.getMinutes() : 0);
    const [amOrPm, setAmOrPm] = React.useState<AmOrPm>(
        meetingTime ? get12HourPeriod(meetingTime.getHours()) : AmOrPm.am,
    );

    const { height: animatedHeight } = useSpring({
        from: { height: 0 },
        to: {
            height: height,
        },
    });

    const { currentWindowWidth } = useWindowWidth();

    React.useEffect(() => {
        setMeetingTime(getDateFromHourMinutePeriod(hour ?? 1, minute, amOrPm));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hour, minute, amOrPm]);

    const selectMeetingDate: SelectSingleEventHandler = (date) => {
        setMeetingDate(date);
    };

    function handleJoinLinkValidation(event: ChangeEvent<HTMLInputElement>) {
        validateLocation(meetingLocationType, event.target.value, meetingLocation, meetingIndex);
        setValue(`meetings.${meetingIndex}.joinLink`, event.target.value);
    }

    function handleLocationValidation(event: ChangeEvent<HTMLInputElement>) {
        validateLocation(meetingLocationType, meetingJoinLink, event.target.value, meetingIndex);
        setValue(`meetings.${meetingIndex}.location`, event.target.value);
    }

    function collapseCard() {
        validateLocation(meetingLocationType, meetingJoinLink, meetingLocation, meetingIndex);
        setExpanded(false);
    }

    const errorsExist =
        meetingStartDateError ||
        meetingStartTimeError ||
        meetingJoinLinkError ||
        meetingLocationError ||
        meetingLocationTypeError;

    const allErrors = `${meetingStartDateError ? ' - ' + meetingStartDateError + '\n' : ''}${
        meetingStartTimeError ? ' - ' + meetingStartTimeError + '\n' : ''
    }${meetingLocationTypeError ? ' - ' + meetingLocationTypeError + '\n' : ''}${
        meetingLocationError ? ' - ' + meetingLocationError + '\n' : ''
    }${meetingJoinLinkError ? ' - ' + meetingJoinLinkError + '\n' : ''}`;

    return (
        <MeetingCard>
            <MeetingHeaderSection>
                <div>
                    <Headline6>{title}</Headline6>
                    <BodyRegular>{moduleName}</BodyRegular>
                </div>
                {expanded ? (
                    <div>
                        <DurationLabel
                            durationInMins={durationInMins ?? 0}
                            boldDurationText
                            hideTitle={currentWindowWidth < parseInt(breakpoints.sm)}
                        />
                    </div>
                ) : (
                    <SecondaryButton
                        title={strings.scheduleCourseStepper.collapsibleMeetingCard.configureButton}
                        aria={strings.scheduleCourseStepper.collapsibleMeetingCard.configureButtonAria}
                        onClick={() => {
                            setExpanded(true);
                        }}
                    />
                )}
            </MeetingHeaderSection>
            <animated.div style={{ overflow: 'hidden', height: animatedHeight }}>
                <CardContent ref={heightRef}>
                    {expanded ? (
                        <>
                            <DateAndTimeSection>
                                <DateAndTimeCard>
                                    <EmbeddedDateInput
                                        selectedDate={meetingDate}
                                        setSelectedDate={selectMeetingDate}
                                        earliestDate={courseStartDate}
                                        latestDate={courseEndDate}
                                        labelTitle={`${strings.datePickerModal.startDate}: `}
                                        required
                                    />
                                </DateAndTimeCard>
                                <DateAndTimeCard>
                                    <EmbeddedTimeInput
                                        hour={hour}
                                        setHour={setHour}
                                        minute={minute}
                                        setMinute={setMinute}
                                        amOrPm={amOrPm}
                                        setAmOrPm={setAmOrPm}
                                    />
                                </DateAndTimeCard>
                            </DateAndTimeSection>
                            {meetingStartDateError && <ErrorNotifierSlim description={meetingStartDateError} />}
                            {meetingStartTimeError && <ErrorNotifierSlim description={meetingStartTimeError} />}
                            <Headline5>
                                {strings.scheduleCourseStepper.collapsibleMeetingCard.selectTrainingLocationLabel}
                            </Headline5>
                            <RadioFields
                                options={[
                                    {
                                        value: LocationType.ONLINE,
                                        title: strings.scheduleCourseStepper.collapsibleMeetingCard.onlineOption,
                                        aria: strings.scheduleCourseStepper.collapsibleMeetingCard.onlineOptionAria,
                                        inputProps: register(`meetings.${meetingIndex}.locationType`),
                                    },
                                    {
                                        value: LocationType.IN_PERSON,
                                        title: strings.scheduleCourseStepper.collapsibleMeetingCard.inPersonOption,
                                        aria: strings.scheduleCourseStepper.collapsibleMeetingCard.inPersonOptionAria,
                                        inputProps: register(`meetings.${meetingIndex}.locationType`),
                                    },
                                ]}
                                maxWidth={'300px'}
                            />
                            {meetingLocationTypeError && <ErrorNotifierSlim description={meetingLocationTypeError} />}
                            {/* eslint-disable-next-line eqeqeq */}
                            {meetingProvider == MeetingProviderOption.useOwnProvider &&
                                meetingLocationType === LocationType.ONLINE && (
                                    <>
                                        <InputContainer>
                                            <TextField
                                                fieldName={`joinLink${meetingIndex}`}
                                                labelText={
                                                    strings.scheduleCourseStepper.configureMeetingsForm.joinLinkInput
                                                }
                                                inputAria={`${strings.scheduleCourseStepper.configureMeetingsForm.joinLinkInputAria}: ${title}`}
                                                inputProps={register(`meetings.${meetingIndex}.joinLink`)}
                                                errorMessage={meetingJoinLinkError}
                                                onChange={handleJoinLinkValidation}
                                                required
                                            />
                                        </InputContainer>
                                    </>
                                )}
                            {meetingLocationType === LocationType.IN_PERSON && (
                                <>
                                    <InputContainer>
                                        <TextField
                                            fieldName={`location${meetingIndex}`}
                                            labelText={
                                                strings.scheduleCourseStepper.collapsibleMeetingCard.locationInput
                                            }
                                            inputAria={
                                                strings.scheduleCourseStepper.collapsibleMeetingCard.locationInputAria
                                            }
                                            inputProps={register(`meetings.${meetingIndex}.location`)}
                                            errorMessage={meetingLocationError}
                                            onChange={handleLocationValidation}
                                            required
                                        />
                                    </InputContainer>
                                    <AccessibilityNotifier accessibilityGuideKey={'AccessibleVenues'} />
                                </>
                            )}
                            <ExpandedCardButtonRow>
                                <SecondaryButton
                                    title={strings.scheduleCourseStepper.collapsibleMeetingCard.closeMeetingButton}
                                    aria={strings.scheduleCourseStepper.collapsibleMeetingCard.closeMeetingButtonAria}
                                    onClick={collapseCard}
                                />
                            </ExpandedCardButtonRow>
                        </>
                    ) : (
                        <>
                            {meetingDate && (
                                <MeetingDetailSection>
                                    <MeetingInfo>
                                        <ScheduleIcon />
                                        <BodySmall>{meetingDate.toLocaleDateString()}</BodySmall>
                                    </MeetingInfo>
                                    <MeetingInfo>
                                        <TimeIcon />
                                        <BodySmall>
                                            {getLocaleTimeRangeString(meetingTime ?? new Date(), durationInMins ?? 1)}
                                        </BodySmall>
                                    </MeetingInfo>
                                </MeetingDetailSection>
                            )}
                            {meetingLocationType === LocationType.ONLINE && (
                                <>
                                    {!meetingJoinLinkError && (
                                        <MeetingInfo>
                                            <LinkIcon />
                                            <BodySmall>
                                                {/* eslint-disable-next-line eqeqeq */}
                                                {meetingProvider == MeetingProviderOption.useOwnProvider ? (
                                                    <>
                                                        {meetingJoinLink
                                                            ? meetingJoinLink
                                                            : strings.scheduleCourseStepper.collapsibleMeetingCard
                                                                  .linkNotSetNotification}
                                                    </>
                                                ) : (
                                                    'Zoom'
                                                )}
                                            </BodySmall>
                                        </MeetingInfo>
                                    )}
                                </>
                            )}
                            {meetingLocationType === LocationType.IN_PERSON && (
                                <>
                                    {!meetingLocationError && (
                                        <MeetingInfo>
                                            <LocationIcon size={IconSize.MEDIUM} />
                                            <BodySmall>
                                                {meetingLocation
                                                    ? meetingLocation
                                                    : strings.scheduleCourseStepper.collapsibleMeetingCard
                                                          .locationNotSet}
                                            </BodySmall>
                                        </MeetingInfo>
                                    )}
                                </>
                            )}
                            {errorsExist && (
                                <Notification
                                    notificationType={NotificationType.DANGER}
                                    title={strings.scheduleCourseStepper.collapsibleMeetingCard.errorsExistOnMeeting}
                                    description={allErrors}
                                />
                            )}
                        </>
                    )}
                </CardContent>
            </animated.div>
        </MeetingCard>
    );
}

const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
`;

const ExpandedCardButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

const InputContainer = styled.div`
    max-width: 420px;
    @media (max-width: ${breakpoints.sm}) {
        width: unset;
    }
`;

const DateAndTimeCard = styled.div`
    background-color: ${theme.cardSecondaryBackgroundColour};
    border-radius: ${sizes.borderRadiusLg};
    padding: ${sizes.spacingMd};
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
`;

const DateAndTimeSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: ${sizes.spacingLg};
    @media (max-width: ${breakpoints.md}) {
        flex-direction: column;
    }
`;
