import styled from 'styled-components';
import { sizes, theme } from 'theme';

type CheckboxInputProps = {
    id: string;
    name: string;
    value: string;
    props: object;
    unfocusable?: boolean;
    aria: string;
};

export default function CheckboxInput({ id, name, value, props, unfocusable, aria }: CheckboxInputProps) {
    return (
        <Checkbox
            aria-label={aria}
            type="checkbox"
            id={id}
            name={name}
            value={value}
            {...props}
            tabIndex={unfocusable ? -1 : 0}
        />
    );
}

export const TextBlock = styled.label`
    display: block;
    width: 100%;
    border: solid 1px ${theme.cardBorder};
    border-radius: ${sizes.borderRadiusLg};
    padding: ${sizes.rem.rg} ${sizes.rem.sm} ${sizes.rem.md} 3.5rem;
`;

const Checkbox = styled.input`
    cursor: pointer;
    width: 1.75rem;
    height: 1.75rem;
    &:focus ~ ${TextBlock} {
        background-color: ${theme.inputRadioButton.focusBackgroundColour};
        border: 2px solid ${theme.inputRadioButton.checkedBorderColour};
    }
    &:checked {
        accent-color: ${theme.inputRadioButton.activeRadioColour};
    }
    &:checked ~ ${TextBlock} {
        border: 2px solid ${theme.inputRadioButton.checkedBorderColour};
    }
    &:hover ~ ${TextBlock} {
        background-color: ${theme.inputRadioButton.hoverBackgroundColour};
    }
`;
