import * as React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { WrappingText } from 'components/Elements';
import { sizes, theme } from 'theme';
import { Activity } from 'models/Activity';
import Table, { RightAlignedButtonCell, TableCell } from 'components/table/Table';
import useWindowWidth from 'hooks/useWindowWidth';
import strings from 'strings/strings.json';
import DownloadIcon from 'assets/icons/controls/DownloadIcon';
import { API_URL } from 'lib/_api-helpers';
import { AuthContext } from 'contextProviders/AuthContext';
import SecondaryButton from '../../../../components/buttons/SecondaryButton';
import QuestionnaireIcon from '../../../../assets/icons/pathway/QuestionnaireIcon';
import { IconSize } from '../../../../assets/icons/icon-sizes';
import { downloadCsvFile } from '../../../../lib/downloadCsvFile';

type QuestionnaireResultsProps = {
    questionnaires: Activity[];
};

type DownloadError = {
    activityId: string;
    error: string;
};

export default function QuestionnaireResults({ questionnaires }: QuestionnaireResultsProps): JSX.Element {
    const { courseId } = useParams();
    const { isMobileWidth, isDesktopWidth } = useWindowWidth();
    const authContext = React.useContext(AuthContext);

    const [downloadError, setDownloadError] = React.useState<DownloadError | null>(null);

    if (!questionnaires) return <></>;

    async function downloadFile(activityId: string): Promise<void> {
        setDownloadError(null);

        const activity = questionnaires.find((questionnaire) => questionnaire.id === activityId);

        if (!activity) {
            return;
        }

        const url = `${API_URL}/activities/answers/file?activity_id=${activity.id}&course_schedule_id=${courseId}`;
        let token = authContext.userData.tokens.accessToken ?? '';

        const downloadResponse = await downloadCsvFile(url, token, activity.title);

        if (downloadResponse.status === 204) {
            setDownloadError({ activityId: activity.id, error: 'There are no responses yet' });
            return;
        }

        if (!downloadResponse.ok) {
            setDownloadError({ activityId: activity.id, error: 'An unknown error occurred' });
            return;
        }
    }

    return (
        <Table
            headers={[
                {
                    name: strings.scheduledQuestionnaire.questionnaireTitle,
                    order: 1,
                    rowPercentage: 50,
                },
                {
                    name: strings.scheduledQuestionnaire.responsesTitle,
                    order: 2,
                    rowPercentage: 30,
                },
                {
                    name: '',
                    order: 3,
                    rowPercentage: 20,
                    mobileRightColumn: true,
                },
            ]}
            rows={questionnaires.map((activity) => {
                const completedCount = activity.statuses.reduce((count, status) => {
                    return count + (status.completed ? 1 : 0);
                }, 0);

                const showError = !!downloadError && downloadError.activityId === activity.id && !!downloadError.error;

                const cells: TableCell[] = [
                    {
                        name: strings.scheduledQuestionnaire.questionnaireTitle,
                        value: (
                            <ColumnContainer>
                                <IconContainer>
                                    <QuestionnaireIcon size={IconSize.MEDIUM} />
                                </IconContainer>
                                <WrappingText>{activity.title}</WrappingText>
                            </ColumnContainer>
                        ),
                    },
                    {
                        name: strings.scheduledQuestionnaire.responsesTitle,
                        value: isDesktopWidth ? (
                            <ColumnContainer>
                                <WrappingText>{completedCount}</WrappingText>
                            </ColumnContainer>
                        ) : (
                            <ColumnContainer>
                                <WrappingText>
                                    {strings.scheduledQuestionnaire.responsesTitle}: {completedCount}
                                </WrappingText>
                            </ColumnContainer>
                        ),
                    },
                    {
                        name: '',
                        value: showError ? (
                            <div>{downloadError.error}</div>
                        ) : (
                            <RightAlignedButtonCell>
                                <SecondaryButton
                                    title={
                                        isMobileWidth ? '' : strings.scheduledQuestionnaire.downloadQuestionnaireButton
                                    }
                                    aria={strings.scheduledQuestionnaire.downloadQuestionnaireButtonAria}
                                    onClick={async () => {
                                        await downloadFile(activity.id);
                                    }}
                                    icon={<DownloadIcon />}
                                    compact
                                    disabled={completedCount === 0}
                                />
                            </RightAlignedButtonCell>
                        ),
                    },
                ];

                return {
                    id: activity.id,
                    cells: cells,
                };
            })}
        />
    );
}

const ColumnContainer = styled.div`
    display: flex;
    align-items: center;
`;

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    background-color: ${theme.iconBackgroundColour};
    border-radius: ${sizes.borderRadiusMd};
    margin-right: 10px;
    padding: ${sizes.spacingXs};
`;
