import styled from 'styled-components';
import { breakpoints, FONTSIZE, sizes, theme } from '../../../theme';
import strings from '../../../strings/strings.json';
import { BodyLarge, BodyRegular, Headline4 } from '../../../components/Elements';
import { ImageCircle } from 'components/ImageCircle';

type YourTrainerCardProps = {
    profileImageFilepath: string | undefined;
    trainerName: string;
    trainerBio: string | null;
    organisationName: string;
};

export default function YourTrainerCard({
    profileImageFilepath,
    trainerName,
    trainerBio,
    organisationName,
}: YourTrainerCardProps): JSX.Element {
    return (
        <>
            <Headline4Blue>{strings.publicCoursePage.yourTrainerHeadline}</Headline4Blue>
            <TrainerAndOrgContainer>
                <TrainerImageAndName>
                    <div>
                        <ImageCircle $imageurl={profileImageFilepath} />
                    </div>
                    <div>
                        <HeadlineTextBlue $bold>{trainerName}</HeadlineTextBlue>
                        <BodyLarge>{organisationName}</BodyLarge>
                    </div>
                </TrainerImageAndName>
                {trainerBio && <BodyRegular>{trainerBio}</BodyRegular>}
            </TrainerAndOrgContainer>
        </>
    );
}

const TrainerImageAndName = styled.div`
    display: flex;
    gap: ${sizes.spacingMd};
    align-items: center;
    min-width: 33%;

    @media (max-width: ${breakpoints.lg}) {
        align-items: start;
        width: 100%;
    }

    @media (max-width: ${breakpoints.sm}) {
        align-items: center;
        width: unset;
    }
`;

const Headline4Blue = styled(Headline4)`
    color: ${theme.primaryBrandColour};
    min-width: 320px;
`;

const HeadlineTextBlue = styled(BodyLarge)`
    font-size: ${FONTSIZE.Headline6};
    color: ${theme.primaryBrandColour};
`;

const TrainerAndOrgContainer = styled.div`
    width: 100%;
    gap: ${sizes.spacingSm};
    background-color: ${theme.cardBackground};
    border-radius: ${sizes.borderRadiusXl};
    border-top: 1px solid var(--neutral-05, ${theme.cardBorder});
    border-right: 1px solid var(--neutral-05, ${theme.cardBorder});
    border-bottom: 3px solid var(--neutral-05, ${theme.cardBorder});
    border-left: 1px solid var(--neutral-05, ${theme.cardBorder});
    display: flex;
    flex-direction: row;
    padding: ${sizes.spacingLg};
    align-items: center;
    word-break: break-word;

    @media (max-width: ${breakpoints.lg}) {
        flex-direction: column;
        gap: ${sizes.spacingMd};
    }
`;
