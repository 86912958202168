import { theme } from 'theme';
import { IconSize } from '../icon-sizes';

export default function HourglassIcon({ size = IconSize.LARGE, colour = theme.textColour }): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.3334 28.3333H22.6667V24.0833C22.6667 22.5249 22.1118 21.1909 21.0021 20.0812C19.8924 18.9714 18.5584 18.4166 17 18.4166C15.4417 18.4166 14.1077 18.9714 12.9979 20.0812C11.8882 21.1909 11.3334 22.5249 11.3334 24.0833V28.3333ZM17 15.5833C18.5584 15.5833 19.8924 15.0284 21.0021 13.9187C22.1118 12.8089 22.6667 11.4749 22.6667 9.91659V5.66659H11.3334V9.91659C11.3334 11.4749 11.8882 12.8089 12.9979 13.9187C14.1077 15.0284 15.4417 15.5833 17 15.5833ZM5.66669 31.1666V28.3333H8.50002V24.0833C8.50002 22.643 8.83648 21.2912 9.5094 20.028C10.1823 18.7648 11.1209 17.7555 12.325 16.9999C11.1209 16.2444 10.1823 15.235 9.5094 13.9718C8.83648 12.7086 8.50002 11.3569 8.50002 9.91659V5.66659H5.66669V2.83325H28.3334V5.66659H25.5V9.91659C25.5 11.3569 25.1636 12.7086 24.4906 13.9718C23.8177 15.235 22.8792 16.2444 21.675 16.9999C22.8792 17.7555 23.8177 18.7648 24.4906 20.028C25.1636 21.2912 25.5 22.643 25.5 24.0833V28.3333H28.3334V31.1666H5.66669Z"
                fill={colour}
            />
        </svg>
    );
}
