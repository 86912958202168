import { AuthContext } from 'contextProviders/AuthContext';
import { PreviewContext } from 'contextProviders/PreviewContext';
import { LearnerModule, LearnerActivity, LearnerCourse } from 'models/LearnerCourse';
import { LearnerCourseContentError } from 'pages/learner/components/shared';
import { ModulePageContent } from 'pages/learner/module/ModulePage';
import React from 'react';
import { useParams } from 'react-router-dom';
import strings from 'strings/strings.json';

export default function ModulePreviewPage(): JSX.Element {
    const params = useParams();
    const { moduleId } = params;

    const previewContext = React.useContext(PreviewContext);
    const authContext = React.useContext(AuthContext);

    if (!moduleId || previewContext.scheduledCourse?.course === undefined) {
        return (
            <LearnerCourseContentError
                title={strings.modulePage.error.title}
                description={strings.modulePage.error.description}
            />
        );
    }

    const learnerCourse: LearnerCourse = {
        id: previewContext.scheduledCourse.course.id,
        title: previewContext.scheduledCourse.course.title,
        description: previewContext.scheduledCourse.course.description,
        startDate: previewContext.scheduledCourse.startDate,
        endDate: previewContext.scheduledCourse.endDate,
        modules: previewContext.scheduledCourse.course.modules.map((module) => {
            const learnerModule: LearnerModule = {
                id: module.id,
                title: module.title,
                description: module.description,
                order: module.order,
                dateCreated: new Date(module.dateCreated),
                activities: module.activities.map((activity) => {
                    const locationType = previewContext.scheduledCourse?.meetings.find(
                        (m) => m.activityId === activity.id,
                    )?.locationType;

                    const learnerActivity: LearnerActivity = {
                        id: activity.id,
                        title: activity.title,
                        order: activity.order,
                        type: activity.type,
                        locationType: locationType,
                        startDate: activity.meetings ? activity.meetings[0].meetingDatetime : undefined,
                        completed: false,
                        questionsTotal: activity.questions.length,
                        questionsAnswered: 0,
                        durationInMins: activity.durationInMins,
                        moduleId: activity.moduleId,
                    };
                    return learnerActivity;
                }),
            };
            return learnerModule;
        }),
        learningObjectives: previewContext.scheduledCourse.course.learningObjectives,
        cancelledDate: null,
        trainerInfo: {
            firstName: authContext.userTokenDetails.given_name,
            lastName: authContext.userTokenDetails.family_name,
            email: authContext.userTokenDetails.email,
            bio: strings.learnerCoursePage.previewBioText,
            profileImagePath: null,
            organisationName: authContext.userData.organisation?.name ?? '',
            organisationLogo: authContext.userData.organisation?.logoS3Filepath ?? null,
        },
    };

    return (
        <ModulePageContent
            learnerCourse={learnerCourse}
            moduleId={moduleId}
            courseId={previewContext.scheduledCourse.course.id}
            courseTitle={previewContext.scheduledCourse.course.title}
            urlPrefix={'/preview'}
        />
    );
}
