import { theme } from '../../../theme';
import { IconSize } from '../icon-sizes';

export default function NotificationIcon({
    colour = theme.primaryBrandColour,
    size = IconSize.MEDIUM,
    badge = false,
}: {
    colour?: string;
    size?: IconSize;
    badge?: boolean;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 29 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.833374 29.4583V26.0416H4.25004V14.0833C4.25004 11.7201 4.96185 9.62027 6.38546 7.78381C7.80907 5.94735 9.65976 4.7444 11.9375 4.17496V2.97913C11.9375 2.26732 12.1867 1.66229 12.6849 1.16402C13.1832 0.665758 13.7882 0.416626 14.5 0.416626C15.2118 0.416626 15.8169 0.665758 16.3151 1.16402C16.8134 1.66229 17.0625 2.26732 17.0625 2.97913V4.17496C19.3403 4.7444 21.191 5.94735 22.6146 7.78381C24.0382 9.62027 24.75 11.7201 24.75 14.0833V26.0416H28.1667V29.4583H0.833374ZM14.5 34.5833C13.5605 34.5833 12.7561 34.2487 12.087 33.5796C11.4179 32.9105 11.0834 32.1062 11.0834 31.1666H17.9167C17.9167 32.1062 17.5822 32.9105 16.9131 33.5796C16.244 34.2487 15.4396 34.5833 14.5 34.5833ZM7.66671 26.0416H21.3334V14.0833C21.3334 12.2041 20.6643 10.5954 19.3261 9.25725C17.9879 7.91906 16.3792 7.24996 14.5 7.24996C12.6209 7.24996 11.0122 7.91906 9.674 9.25725C8.3358 10.5954 7.66671 12.2041 7.66671 14.0833V26.0416Z"
                fill={colour}
            />
            {badge && (
                <circle cx="23.3334" cy="8.66667" r="8.33333" fill="#AC1D00" stroke="white" strokeWidth="0.666667" />
            )}
        </svg>
    );
}
