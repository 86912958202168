import PrimaryButton from 'components/buttons/PrimaryButton';
import SecondaryButton from 'components/buttons/SecondaryButton';
import { BodyRegular, Headline4, ModalBackground, ModalBody, ModalCard } from 'components/Elements';
import Checkboxes, { CheckboxOption } from 'components/forms/Checkboxes';
import Hint from 'components/Hint';
import useKeyboardTrap from 'hooks/useKeyboardTrap';
import React from 'react';
import styled from 'styled-components';
import { breakpoints, sizes, theme } from 'theme';
import strings from '../../../../strings/strings.json';
import { ErrorNotifier } from 'components/notifiers/ErrorNotifier';
import SuccessNotifier from 'components/notifiers/SuccessNotifier';
import { BoltWithCircleIcon } from 'assets/icons/BoltIcon';
import BetaLabel from '../BetaLabel';

export type GenerateModalState = 'hide' | 'show' | 'submitting' | 'success' | 'error';

type GenerateContentModalProps = {
    state: GenerateModalState;
    close: () => Promise<void>;
    generateContent: () => void;
    contentOptions: CheckboxOption[];
};

export default function GenerateContentModal({
    state,
    close,
    generateContent,
    contentOptions,
}: GenerateContentModalProps): JSX.Element {
    if (state === 'success') {
        return (
            <Base close={close}>
                <SuccessNotifier
                    title={strings.generateContentModal.success.title}
                    description={strings.generateContentModal.success.description}
                />
                <Buttons>
                    <PrimaryButton
                        title={strings.generateContentModal.closeButton.title}
                        aria={strings.generateContentModal.closeButton.aria}
                        onClick={close}
                    />
                </Buttons>
            </Base>
        );
    }

    return (
        <Base close={close}>
            <Hint hint={strings.generateContentModal.hint} />
            <BodyRegular>{strings.generateContentModal.description}</BodyRegular>
            <Checkboxes options={contentOptions} />
            <ButtonRowContainer>
                <Buttons>
                    <SecondaryButton
                        title={strings.generateContentModal.skipButton.title}
                        aria={strings.generateContentModal.skipButton.aria}
                        onClick={close}
                        disabled={state === 'submitting'}
                    />
                    <PrimaryButton
                        title={strings.generateContentModal.finishButton.title}
                        aria={
                            state === 'error'
                                ? strings.generateContentModal.finishButton.titleTryAgain
                                : strings.generateContentModal.finishButton.title
                        }
                        onClick={generateContent}
                        submitting={state === 'submitting'}
                    />
                </Buttons>
            </ButtonRowContainer>

            {state === 'error' && (
                <ErrorNotifier
                    title={strings.generateContentModal.error.title}
                    description={strings.generateContentModal.error.description}
                />
            )}
        </Base>
    );
}

type BaseProps = {
    close: () => void;
    children: React.ReactNode;
};

function Base({ close, children }: BaseProps): JSX.Element {
    const { ref } = useKeyboardTrap(close);

    return (
        <ModalBackground>
            <GenerateModalBody ref={ref}>
                <GenerateContentModalCard>
                    <ModalContentContainer>
                        <TopRow>
                            <IconAndTitleRow>
                                <BoltWithCircleIcon />
                                <Headline4>{strings.generateContentModal.title}</Headline4>
                            </IconAndTitleRow>
                            <BetaLabel />
                        </TopRow>

                        {children}
                    </ModalContentContainer>
                </GenerateContentModalCard>
            </GenerateModalBody>
        </ModalBackground>
    );
}

const GenerateContentModalCard = styled(ModalCard)`
    @media (max-width: ${breakpoints.sm}) {
        height: 100%;
        overflow-y: auto;
    }
`;

const GenerateModalBody = styled(ModalBody)`
    @media (max-width: ${breakpoints.sm}) {
        height: 100%;
        width: 100%;
        background-color: ${theme.cardBackground};
    }
`;

const TopRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const IconAndTitleRow = styled.div`
    display: flex;
    gap: ${sizes.spacingMd};
`;

const Buttons = styled.div`
    display: flex;
    justify-content: end;
    gap: ${sizes.spacingMd};

    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
        width: 100%;
    }
`;

const ButtonRowContainer = styled.div`
    display: flex;
    justify-content: end;
    width: 100%;
`;

const ModalContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingRg};
    padding: ${sizes.spacingMd};
    width: 600px;

    @media (max-width: ${breakpoints.sm}) {
        width: 100%;
    }
`;
