import { theme } from '../../../theme';
import { IconSize } from '../icon-sizes';

export default function PreviousIcon({
    colour = theme.primaryBrandColour,
    size = IconSize.MEDIUM,
}: {
    colour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 -1.39567e-06C7.16344 -2.16819e-06 -3.52771e-06 7.16344 -4.30023e-06 16C-5.07275e-06 24.8366 7.16344 32 16 32C24.3556 32 31.2152 25.5952 31.9372 17.4272L16.2698 17.4272L20.035 21.0143C20.3413 21.3049 20.3475 21.7892 20.0475 22.086L19.3539 22.7797C19.0601 23.0734 18.5852 23.0734 18.2946 22.7797L12.2203 16.7085C11.9266 16.4148 11.9266 15.9398 12.2203 15.6493L18.2946 9.57493C18.5883 9.28121 19.0633 9.28121 19.3539 9.57493L20.0475 10.2686C20.3444 10.5654 20.3381 11.0498 20.035 11.3404L16.2698 14.9275L31.9646 14.9275C31.4129 6.59101 24.4761 -6.54664e-07 16 -1.39567e-06Z"
                fill={colour}
            />
        </svg>
    );
}
