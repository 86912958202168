import ArrowUpIcon from '../../assets/icons/controls/ArrowUpIcon';
import strings from '../../strings/strings.json';
import ArrowDownIcon from '../../assets/icons/controls/ArrowDownIcon';
import React from 'react';
import styled from 'styled-components';
import { FONTSIZE, sizes, theme } from '../../theme';
import { FormTextInput } from './FormComponents';
import { BodyRegular } from '../Elements';

export enum AmOrPm {
    am = 1,
    pm = 2,
}

type EmbeddedTimeInputProps = {
    hour: number;
    setHour: Function;
    minute: number;
    setMinute: Function;
    amOrPm: AmOrPm;
    setAmOrPm: Function;
};

export default function EmbeddedTimeInput({
    hour,
    setHour,
    minute,
    setMinute,
    amOrPm,
    setAmOrPm,
}: EmbeddedTimeInputProps): JSX.Element {
    function incrementHour() {
        setHour(hour === undefined || hour === 12 ? 1 : hour + 1);
    }

    function decrementHour() {
        setHour(hour === undefined || hour === 1 ? 12 : hour - 1);
    }

    function incrementMinute() {
        setMinute(minute === undefined || minute === 59 ? 0 : minute + 1);
    }

    function decrementMinute() {
        setMinute(minute === undefined || minute === 0 ? 59 : minute - 1);
    }

    function changeHour(e: any) {
        let newHour: number | undefined = !e.target.value ? undefined : +e.target.value;

        if (newHour === undefined) {
            newHour = undefined;
        } else if (hour === undefined && newHour === 0) {
            //0 hour doesn't exist in analogue.
            newHour = undefined;
        } else if (hour === 1 && newHour === 0) {
            //decrement from 1 to 12.
            newHour = 12;
        } else if (newHour <= 0 || newHour === 13) {
            newHour = 1;
        } else if (newHour > 12) {
            newHour = hour; //don't let user input a number more than 12.
        }
        setHour(newHour);
    }

    function changeMinute(e: any) {
        let newMinute: number | undefined = !e.target.value ? undefined : +e.target.value;

        if (newMinute === undefined) {
            newMinute = undefined;
        } else if (newMinute === -1) {
            newMinute = 59;
        } else if (newMinute === 60) {
            newMinute = 0;
        } else if (newMinute > 59) {
            newMinute = minute;
        }
        setMinute(newMinute);
    }

    function defaultHourIfEmpty() {
        if (hour === undefined) {
            setHour(1);
        }
    }

    function defaultMinuteIfEmpty() {
        if (minute === undefined) {
            setMinute(0);
        }
    }

    return (
        <>
            <SelectorColumn>
                <PickerRow>
                    <TimeSelectorRow>
                        <TimeSelectorColumn>
                            <ArrowButton
                                type="button"
                                tabIndex={-1}
                                aria-label={strings.timePickerModal.incrementHour}
                                onClick={incrementHour}
                            >
                                <ArrowUpIcon />
                            </ArrowButton>
                            <NumberInput
                                type="number"
                                value={hour}
                                aria-label={strings.timePickerModal.hourInputAria}
                                onChange={changeHour}
                                onBlur={defaultHourIfEmpty}
                            />
                            <ArrowButton
                                type="button"
                                tabIndex={-1}
                                aria-label={strings.timePickerModal.decrementHour}
                                onClick={decrementHour}
                            >
                                <ArrowDownIcon />
                            </ArrowButton>
                        </TimeSelectorColumn>
                        <ColonColumn>
                            <p>:</p>
                        </ColonColumn>
                        <TimeSelectorColumn>
                            <ArrowButton
                                type="button"
                                tabIndex={-1}
                                aria-label={strings.timePickerModal.incrementMinute}
                                onClick={incrementMinute}
                            >
                                <ArrowUpIcon />
                            </ArrowButton>
                            <NumberInput
                                type="number"
                                value={minute === undefined ? undefined : minute.toString().padStart(2, '0')}
                                aria-label={strings.timePickerModal.minuteInputAria}
                                onChange={changeMinute}
                                onBlur={defaultMinuteIfEmpty}
                            />
                            <ArrowButton
                                type="button"
                                tabIndex={-1}
                                aria-label={strings.timePickerModal.decrementMinute}
                                onClick={decrementMinute}
                            >
                                <ArrowDownIcon />
                            </ArrowButton>
                        </TimeSelectorColumn>
                    </TimeSelectorRow>
                    <AmOrPmSelectorRow>
                        <AmOrPmButton
                            type="button"
                            aria-label={strings.timePickerModal.amAria}
                            selected={amOrPm === AmOrPm.am}
                            onClick={() => setAmOrPm(AmOrPm.am)}
                        >
                            {strings.timePickerModal.am}
                        </AmOrPmButton>
                        <AmOrPmButton
                            type="button"
                            aria-label={strings.timePickerModal.pmAria}
                            selected={amOrPm === AmOrPm.pm}
                            onClick={() => setAmOrPm(AmOrPm.pm)}
                        >
                            {strings.timePickerModal.pm}
                        </AmOrPmButton>
                    </AmOrPmSelectorRow>
                </PickerRow>

                <TimeSelectedRow>
                    <TimeSelectedColumn>
                        <BodyRegular>
                            {strings.timePickerModal.timeSelected}:{' '}
                            <b>
                                {hour ? hour : '-'}:{(minute ?? '').toString().padStart(2, '0')} {AmOrPm[amOrPm]}
                            </b>
                        </BodyRegular>
                    </TimeSelectedColumn>
                </TimeSelectedRow>
            </SelectorColumn>
        </>
    );
}

const PickerRow = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingLg};
`;

const TimeSelectedRow = styled.div`
    display: flex;
    flex-direction: row;
`;

const TimeSelectedColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: ${theme.cardSecondaryBackgroundColour};
    border-radius: ${sizes.borderRadiusMd};
    padding: ${sizes.spacingSm};
`;

const NumberInput = styled(FormTextInput)`
    width: 60px;
    text-align: center;
`;

const AmOrPmButton = styled.button<{ selected: boolean }>`
    min-height: 48px;
    min-width: 76px;
    background: ${(p) =>
        p.selected ? theme.secondaryButtonBackgroundSelectedColour : theme.secondaryButtonBackgroundColour};
    border: 1px solid ${theme.cardSecondaryBorder};
    border-radius: ${sizes.borderRadiusMd};
    font-size: ${FONTSIZE.BodyRegular};
    cursor: pointer;
    &:hover {
        background-color: ${theme.secondaryButtonHoverBackgroundColour};
    }
    &:focus {
        background-color: ${theme.secondaryButtonBackgroundSelectedColour};
        border: 1px solid ${theme.secondaryButtonBorderColour};
        outline: ${theme.secondaryButtonBorderColour};
        color: ${theme.primaryButtonFocusTextColour};
    }
`;

const ColonColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const SelectorColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: ${sizes.spacingLg};
    height: 100%;
`;

const AmOrPmSelectorRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: ${sizes.spacingMd};
`;

const TimeSelectorRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: ${sizes.spacingSm};
`;

const TimeSelectorColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${sizes.spacingMd};
`;

const ArrowButton = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
`;
