import TaskIcon from 'assets/icons/pathway/TaskIcon';
import VideoChatIcon from 'assets/icons/pathway/VideoChatIcon';
import { BodyRegular } from 'components/Elements';
import { ActivityType } from 'models/Activity';
import styled from 'styled-components';
import { breakpoints, sizes } from 'theme';
import strings from '../../../strings/strings.json';
import { IconSize } from 'assets/icons/icon-sizes';
import QuestionnaireIcon from 'assets/icons/pathway/QuestionnaireIcon';

type ActivityTypeLabelProps = {
    activityType: ActivityType;
};

export default function ActivityTypeLabel(activityTypeLabelProps: ActivityTypeLabelProps): JSX.Element {
    let labelContents: JSX.Element = <></>;

    switch (activityTypeLabelProps.activityType) {
        case ActivityType.LIVE_TRAINING:
            labelContents = (
                <Label>
                    <VideoChatIcon size={IconSize.MEDIUM} />
                    <BodyRegular>{strings.activityTypeLabels.liveTraining}</BodyRegular>
                </Label>
            );
            break;
        case ActivityType.QUESTIONNAIRE:
            labelContents = (
                <Label>
                    <QuestionnaireIcon size={IconSize.MEDIUM} />
                    <BodyRegular>{strings.activityTypeLabels.questionnaire}</BodyRegular>
                </Label>
            );
            break;
        case ActivityType.TASK:
            labelContents = (
                <Label>
                    <TaskIcon size={IconSize.MEDIUM} />
                    <BodyRegular>{strings.activityTypeLabels.task}</BodyRegular>
                </Label>
            );
            break;
        default:
            return <></>;
    }

    return <>{labelContents}</>;
}

const Label = styled.div`
    min-width: 155px;
    display: flex;
    align-items: center;
    gap: ${sizes.spacingSm};
    white-space: nowrap;
    @media (max-width: ${breakpoints.sm}) {
        min-width: unset;
    }
`;
