import { BodyLarge, ButtonRowLeft, Headline4, ModalBackground, TertiaryButton } from 'components/Elements';
import TextFieldReadOnly from 'components/forms/TextFieldReadOnly';
import React from 'react';
import PrimaryButton from 'components/buttons/PrimaryButton';
import strings from '../../strings/strings.json';
import styled from 'styled-components';
import { breakpoints, sizes, theme } from 'theme';
import useKeyboardTrap from '../../hooks/useKeyboardTrap';
import useWindowWidth from '../../hooks/useWindowWidth';
import CloseIcon from '../../assets/icons/controls/CloseIcon';
import { IconSize } from '../../assets/icons/icon-sizes';
import Notification, { NotificationType } from '../notifiers/Notification';

export type CopyLinkModalProps = {
    title: string;
    description?: string;
    inputValue: string;
    primaryButtonTitle: string;
    primaryButtonAria: string;
    closeButtonAria: string;
    onClickCloseButton: () => void;
};

export type CopyLinkModalState = {
    modalProps: CopyLinkModalProps;
    modalOpen: boolean;
};

export function CopyLinkModal({
    title,
    description,
    inputValue,
    primaryButtonTitle,
    primaryButtonAria,
    closeButtonAria,
    onClickCloseButton,
}: CopyLinkModalProps): JSX.Element {
    const { ref } = useKeyboardTrap(onClickCloseButton);
    const { currentWindowWidth } = useWindowWidth();

    const [showSaveNotifier, setShowSaveNotifier] = React.useState(false);

    const onClickPrimaryButton = () => {
        navigator.clipboard.writeText(inputValue);
        setShowSaveNotifier(true);
    };

    return (
        <ModalBackground>
            <ModalBody ref={ref}>
                <ModalCard>
                    <ModalContentContainer>
                        <RowPushItemsApart>
                            <Headline4>{title}</Headline4>
                            <TertiaryButton onClick={onClickCloseButton} aria-label={closeButtonAria}>
                                <IconContainer>
                                    <CloseIcon size={IconSize.SMALL} />
                                </IconContainer>
                            </TertiaryButton>
                        </RowPushItemsApart>
                        {description && <BodyLarge>{description}</BodyLarge>}
                        <TextFieldReadOnly
                            fieldName="url"
                            labelText={strings.copyLinkModal.labelText}
                            labelTextAria={strings.copyLinkModal.copyLinkButtonModalTitleAria}
                            inputValue={inputValue}
                        />
                        {currentWindowWidth <= parseInt(breakpoints.sm) && showSaveNotifier && (
                            <Notification
                                notificationType={NotificationType.SUCCESS}
                                title={strings.copyLinkModal.copiedText}
                            />
                        )}
                        <ButtonRowLeftExtraSmallGap>
                            <PrimaryButton
                                type="button"
                                title={primaryButtonTitle}
                                aria={primaryButtonAria}
                                onClick={onClickPrimaryButton}
                            />
                            {currentWindowWidth > parseInt(breakpoints.sm) && showSaveNotifier && (
                                <Notification
                                    notificationType={NotificationType.SUCCESS}
                                    title={strings.copyLinkModal.copiedText}
                                />
                            )}
                        </ButtonRowLeftExtraSmallGap>
                    </ModalContentContainer>
                </ModalCard>
            </ModalBody>
        </ModalBackground>
    );
}

const ModalContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
`;

const ModalBody = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: auto;
    max-width: 800px;
`;

const ModalCard = styled.div`
    flex-grow: 1;
    width: 100%;
    min-height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: ${sizes.spacingLg};
    border: solid 1px ${theme.cardBorder};
    background-color: ${theme.cardBackground};
    border-radius: ${sizes.borderRadiusLg};
    @media (max-width: ${breakpoints.sm}) {
        padding-top: 130px;
        height: 100%;
        border: none;
        justify-content: space-between;
    }
`;

const IconContainer = styled.div`
    padding: ${sizes.spacingSm};
`;

const ButtonRowLeftExtraSmallGap = styled(ButtonRowLeft)`
    height: 74px;
    gap: ${sizes.spacingMd};
    align-items: center;
    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column-reverse;
    }
`;

const RowPushItemsApart = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${sizes.spacingSm};
    width: 100%;
`;
