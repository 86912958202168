/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { createGlobalStyle } from 'styled-components';
import { theme } from '../theme';

export enum MAGNIFICATION_VALUE {
    Small = 1,
    Medium = 1.2,
    Large = 1.5,
}

type Magnification = {
    magnificationValue: MAGNIFICATION_VALUE;
    updateMagnification: (mag: MAGNIFICATION_VALUE) => void;
};

export const MagnificationContext = React.createContext<Magnification>({
    magnificationValue: MAGNIFICATION_VALUE.Small,
    updateMagnification: (mag: MAGNIFICATION_VALUE) => {},
});

export function MagnificationProvider(props: { children: React.ReactNode | React.ReactNode[] }): JSX.Element {
    const [magnificationValue, setMagnificationValue] = React.useState<MAGNIFICATION_VALUE>(MAGNIFICATION_VALUE.Small);
    return (
        <MagnificationContext.Provider
            value={{ magnificationValue: magnificationValue, updateMagnification: setMagnificationValue }}
        >
            <GlobalStyle magnificationValue={magnificationValue} />
            {props.children}
        </MagnificationContext.Provider>
    );
}

const GlobalStyle = createGlobalStyle<{ magnificationValue: MAGNIFICATION_VALUE }>`
    html {
        height: 100%;
        font-size: ${({ magnificationValue }) => `${16 * (magnificationValue ?? 1)}px`};
        line-height: 1.5;
    }
    // smooth scroll breaks cypress. see - https://github.com/cypress-io/cypress/issues/3200#issuecomment-746275851
    html:not(.cypress-tests) {
        scroll-behavior: smooth;
    }

    * {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }
  
    body {
        min-height: 100vh;
        font-family: ${theme.fontFamilyMain};
        background-color: ${theme.platformBackgroundColour}
    }
    
    h1, h2, h3 {
        font-family: ${theme.fontFamilyHeader};
    }

    a {
        color: ${theme.linkColour};
        cursor: pointer;
        text-decoration: none;  
    }
    
    // hide number arrow inputs
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
`;
