import React from 'react';
import { BodyRegular } from '../../../../components/Elements';
import strings from '../../../../strings/strings.json';
import CharlieHREmployees from './CharlieHREmployees';
import styled from 'styled-components';
import { sizes } from '../../../../theme';
import Spinner from '../../../../components/Spinner';
import useGetRequest from '../../../../hooks/useGetRequest';
import { IntegrationConnectionStatus } from '../../../../models/hr-integration/IntegrationConnectionStatus';
import { ErrorMessage } from '../../../../components/forms/FormComponents';
import { ErrorNotifier } from '../../../../components/notifiers/ErrorNotifier';
import CharlieHRConfigureButton from './CharlieHRConfigureButton';

export default function CharlieHRIntegrationContent(): JSX.Element {
    const [friendlyErrorMessage, setFriendlyErrorMessage] = React.useState<string | null>(null);

    const {
        data: connectionStatus,
        loading: loadingConnectionStatus,
        errors: connectionStatusErrors,
        refetchData: refetchConnectionStatus,
    } = useGetRequest<IntegrationConnectionStatus>('hr-integration/connection-status');

    async function onClose() {
        await refetchConnectionStatus();
    }

    if (loadingConnectionStatus) {
        return (
            <CharlieContentBlock>
                <LoadingBlock>
                    <Spinner />
                    <BodyRegular>{strings.organisationIntegrationSetupPage.loadingStatus}</BodyRegular>
                </LoadingBlock>
            </CharlieContentBlock>
        );
    }

    if (connectionStatusErrors.length > 0) {
        return (
            <CharlieContentBlock>
                <ErrorNotifier
                    title={strings.organisationIntegrationSetupPage.genericConnectionError}
                    description={strings.errorMessages.genericDetail}
                />
            </CharlieContentBlock>
        );
    }

    if (connectionStatus?.status === 'callable')
        return <CharlieHREmployees refreshConnection={refetchConnectionStatus} />;

    return (
        <CharlieContentBlock>
            <BodyRegular>{strings.organisationIntegrationSetupPage.instructions}</BodyRegular>
            {friendlyErrorMessage && <ErrorMessage>{friendlyErrorMessage}</ErrorMessage>}
            <CharlieHRConfigureButton
                buttonTitle={strings.organisationIntegrationSetupPage.configureButtonTitle}
                setError={setFriendlyErrorMessage}
                onClose={onClose}
            />
        </CharlieContentBlock>
    );
}

const LoadingBlock = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${sizes.spacingMd};
`;

const CharlieContentBlock = styled.div`
    margin: ${sizes.spacingMd};
    gap: ${sizes.spacingMd};
    display: flex;
    flex-direction: column;
`;
