import * as React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// @ts-ignore
import { ReactComponent as NeveLogo } from '../../assets/icons/navigation/neve-learning-logo.svg';
import styled from 'styled-components';
import { breakpoints, FONTSIZE, sizes, theme } from '../../theme';
import strings from '../../strings/strings.json';
import { RoleType } from '../../models/User';
import { AuthContext } from '../../contextProviders/AuthContext';
import useWindowWidth from '../../hooks/useWindowWidth';
import useGetRequest from '../../hooks/useGetRequest';
import { NotificationModel } from '../../models/Notification';
import { animated, useSpring } from '@react-spring/web';
import useKeyboardTrap from 'hooks/useKeyboardTrap';
import ContactSupportCard from 'components/contact/ContactSupportCard';
import DashboardIcon from 'assets/icons/navigation/DashboardIcon';
import ScheduleIcon from 'assets/icons/navigation/ScheduleIcon';
import ProfileIcon from 'assets/icons/navigation/ProfileIcon';
import NotificationIcon from 'assets/icons/navigation/NotificationIcon';
import SupportIcon from 'assets/icons/navigation/SupportIcon';
import OrganisationIcon from 'assets/icons/navigation/OrganisationIcon';
import CoursesIcon from 'assets/icons/navigation/CoursesIcon';
import NotificationWrapper from 'components/notifications/NotificationWrapper';

type MenuItem = {
    id: string;
    label: string | JSX.Element;
    buttonAria: string;
    address: string;
    roles: (RoleType | 'LEARNER')[];
    onClick: () => void;
    components: { active: React.ComponentType; inactive: React.ComponentType };
};

export default function Navigation(): JSX.Element {
    const { userData } = React.useContext(AuthContext);
    const [activeMenuItemId, setActiveMenuItemId] = React.useState<string>('');
    const [notificationsActive, setNotificationsActive] = React.useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const { currentWindowWidth } = useWindowWidth();

    const { data: notifications, refetchData } = useGetRequest<NotificationModel[]>('users/me/notifications');

    const [notificationsFetchCount, setNotificationsFetchCount] = React.useState(0);

    const unacknowledgedNotifications = notifications?.filter((notification) => !notification.acknowledged);

    React.useEffect(() => {
        const timer = setTimeout(() => {
            refetchData().then(() => {
                setNotificationsFetchCount(notificationsFetchCount + 1);
            });
        }, 1000 * 60); // 1 minute
        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notificationsFetchCount]);

    const [panelIsOpen, setPanelIsOpen] = React.useState<boolean>(false);
    const panelsRef = React.useRef<HTMLDivElement>(null);

    const { translateX } = useSpring({
        translateX: panelIsOpen ? `0` : '500px',
    });

    const keyboardTrapIsDisabled = !panelIsOpen;

    const { ref } = useKeyboardTrap(() => {
        setPanelIsOpen(false);
    }, keyboardTrapIsDisabled);

    React.useEffect(() => {
        updateActiveMenuItemId();
    });

    const updateActiveMenuItemId = () => {
        const menuItem = getMenuItems(userData.activeRole).find((m) => {
            return m.address === location.pathname;
        });
        if (!notificationsActive) {
            if (menuItem) {
                setActiveMenuItemId(menuItem.id);
            } else {
                setActiveMenuItemId('');
            }
        }
    };

    const getMenuItems = (activeRole: RoleType | 'LEARNER') => {
        const menuItems: MenuItem[] = [
            {
                id: 'CoursesMenuItem',
                label: strings.appHeader.learnerCourses.label,
                buttonAria: strings.appHeader.learnerCourses.aria,
                components: {
                    active: () => <CoursesIcon />,
                    inactive: () => <CoursesIcon colour={theme.navigationBar.buttonText} />,
                },
                roles: ['LEARNER'],
                address: `/learner/courses`,
                onClick: () => {
                    navigate('/learner/Courses');
                    setActiveMenuItemId('CoursesMenuItem');
                    setNotificationsActive(false);
                    setPanelIsOpen(false);
                },
            },
            {
                id: 'DashboardMenuItem',
                label: strings.appHeader.dashboard.label,
                buttonAria: strings.appHeader.dashboard.aria,
                components: {
                    active: () => <DashboardIcon />,
                    inactive: () => <DashboardIcon colour={theme.navigationBar.buttonText} />,
                },
                roles: [RoleType.TRAINER],
                address: '/trainer/dashboard',
                onClick: () => {
                    navigate('/trainer/dashboard');
                    setActiveMenuItemId('DashboardMenuItem');
                    setNotificationsActive(false);
                    setPanelIsOpen(false);
                },
            },
            {
                id: 'ScheduleMenuItem',
                label: strings.appHeader.schedule.label,
                buttonAria: strings.appHeader.schedule.aria,
                components: {
                    active: () => <ScheduleIcon />,
                    inactive: () => <ScheduleIcon colour={theme.navigationBar.buttonText} />,
                },
                roles: [RoleType.TRAINER],
                address: '/trainer/scheduled-courses',
                onClick: () => {
                    navigate('/trainer/scheduled-courses');
                    setActiveMenuItemId('ScheduleMenuItem');
                    setNotificationsActive(false);
                    setPanelIsOpen(false);
                },
            },
            {
                id: 'DashboardMenuItem',
                label: strings.appHeader.dashboard.label,
                buttonAria: strings.appHeader.dashboard.aria,
                components: {
                    active: () => <DashboardIcon />,
                    inactive: () => <DashboardIcon colour={theme.navigationBar.buttonText} />,
                },
                roles: [RoleType.ADMIN],
                address: '/admin/dashboard',
                onClick: () => {
                    navigate('/admin/dashboard');
                    setActiveMenuItemId('DashboardMenuItem');
                    setNotificationsActive(false);
                    setPanelIsOpen(false);
                },
            },
            {
                id: 'OaCoursesMenuItem',
                label: strings.appHeader.courses.label,
                buttonAria: strings.appHeader.courses.aria,
                components: {
                    active: () => <ScheduleIcon />,
                    inactive: () => <ScheduleIcon colour={theme.navigationBar.buttonText} />,
                },
                roles: [RoleType.ORGANISATION_ADMIN],
                address: '/organisation/courses',
                onClick: () => {
                    navigate('/organisation/courses');
                    setActiveMenuItemId('OaCoursesMenuItem');
                    setNotificationsActive(false);
                    setPanelIsOpen(false);
                },
            },
            {
                id: 'OrganisationMenuItem',
                label: strings.appHeader.organisation.label,
                buttonAria: strings.appHeader.organisation.aria,
                components: {
                    active: () => <OrganisationIcon />,
                    inactive: () => <OrganisationIcon colour={theme.navigationBar.buttonText} />,
                },
                roles: [RoleType.ORGANISATION_ADMIN],
                address: '/organisation/overview',
                onClick: () => {
                    navigate('/organisation/overview');
                    setActiveMenuItemId('OrganisationMenuItem');
                    setNotificationsActive(false);
                    setPanelIsOpen(false);
                },
            },
            {
                id: 'NotificationMenuItem',
                label: strings.appHeader.notifications.label,
                buttonAria: strings.appHeader.notifications.aria,
                components: {
                    active: () => {
                        if (unacknowledgedNotifications && unacknowledgedNotifications.length > 0)
                            return <NotificationIcon badge />;

                        return <NotificationIcon />;
                    },
                    inactive: () => {
                        if (unacknowledgedNotifications && unacknowledgedNotifications.length > 0)
                            return <NotificationIcon colour={theme.navigationBar.buttonText} badge />;

                        return <NotificationIcon colour={theme.navigationBar.buttonText} />;
                    },
                },
                roles: [RoleType.TRAINER, RoleType.ADMIN, RoleType.ORGANISATION_ADMIN, 'LEARNER'],
                address: '/notifications',
                onClick: () => {
                    if (currentWindowWidth < parseInt(breakpoints.sm)) {
                        navigate('/notifications');
                        setActiveMenuItemId('NotificationMenuItem');
                    } else {
                        if (!notificationsActive) {
                            setActiveMenuItemId('NotificationMenuItem');
                            setNotificationsActive(true);
                            setPanelIsOpen(false);
                        } else {
                            const menuItem = getMenuItems(userData.activeRole).find((m) => {
                                return m.address === location.pathname;
                            });
                            if (menuItem) {
                                setActiveMenuItemId(menuItem.id);
                            } else {
                                setActiveMenuItemId('');
                            }
                            setNotificationsActive(false);
                        }
                    }
                },
            },
            {
                id: 'ProfileMenuItem',
                label: strings.appHeader.profile.label,
                buttonAria: strings.appHeader.profile.aria,
                components: {
                    active: () => <ProfileIcon />,
                    inactive: () => <ProfileIcon colour={theme.navigationBar.buttonText} />,
                },
                roles: [RoleType.TRAINER, RoleType.ADMIN, RoleType.ORGANISATION_ADMIN, 'LEARNER'],
                address: '/profile',
                onClick: () => {
                    navigate('/profile');
                    setActiveMenuItemId('ProfileMenuItem');
                    setNotificationsActive(false);
                },
            },
            {
                id: 'SupportMenuItem',
                label: strings.appHeader.support.label,
                buttonAria: strings.appHeader.support.aria,
                components: {
                    active: () => <SupportIcon />,
                    inactive: () => <SupportIcon colour={theme.navigationBar.buttonText} />,
                },
                roles: [RoleType.TRAINER, RoleType.ORGANISATION_ADMIN, 'LEARNER'],
                address: '/support',
                onClick: () => {
                    setPanelIsOpen(!panelIsOpen);
                    setNotificationsActive(false);
                },
            },
        ];
        return menuItems.filter((menuItem) => {
            return menuItem.roles.includes(activeRole);
        });
    };

    const menuItems: MenuItem[] = getMenuItems(userData.activeRole);

    return (
        <>
            <NavigationContainer>
                <LimitedContainer>
                    <NavigationLogoSection>
                        <NeveLogoLink to={'/'}>
                            <StyledNeveLogo
                                alt={strings.appHeader.neveLearningLogoAltText}
                                aria-label={strings.appHeader.neveLearningLogoAria}
                            />
                        </NeveLogoLink>
                    </NavigationLogoSection>

                    <NavigationButtonsSection>
                        {menuItems.map((currentMenuItem, index) => {
                            const isActive = activeMenuItemId === currentMenuItem.id;
                            const MenuItemComponent = isActive
                                ? currentMenuItem.components.active
                                : currentMenuItem.components.inactive;
                            return (
                                <NavbarLink onClick={currentMenuItem.onClick} key={index}>
                                    <MenuItemButton
                                        id={currentMenuItem.id}
                                        role="img"
                                        aria-label={currentMenuItem.buttonAria}
                                        key={index}
                                    >
                                        <MenuItemIcon>
                                            <MenuItemComponent />
                                        </MenuItemIcon>
                                        <MenuItemText $active={isActive}>{currentMenuItem.label}</MenuItemText>
                                    </MenuItemButton>
                                </NavbarLink>
                            );
                        })}

                        {notificationsActive && (
                            <NotificationWrapper
                                closePanel={() => {
                                    setNotificationsActive(!notificationsActive);
                                    const menuItem = getMenuItems(userData.activeRole).find((m) => {
                                        return m.address === location.pathname;
                                    });
                                    if (menuItem) {
                                        setActiveMenuItemId(menuItem.id);
                                    } else {
                                        setActiveMenuItemId('');
                                    }
                                }}
                            />
                        )}

                        {panelIsOpen && <ContactSupportOverlay></ContactSupportOverlay>}
                        <ContactSupportPanel ref={panelsRef && ref} style={{ translateX, overflow: 'hidden' }}>
                            <ContactSupportCard closePanel={() => setPanelIsOpen(false)} panelIsOpen={panelIsOpen} />
                        </ContactSupportPanel>
                    </NavigationButtonsSection>
                </LimitedContainer>
            </NavigationContainer>
            <NavigationPadding />
        </>
    );
}

export type NavigationSlimProps = {
    buttonTitle: string;
    buttonAria: string;
    buttonOnClick: () => void;
};

export const NavigationPadding = styled.div`
    height: ${sizes.headerHeight};
    @media (max-width: ${breakpoints.sm}) {
        height: ${sizes.headerMobileHeight};
    }
    width: 0;
`;

export const NeveLogoLink = styled(Link)<{ active?: boolean }>`
    border: 2px solid transparent;
    height: 100%;
    width: auto;
    display: flex;
    align-items: center;
    &:focus {
        background-color: ${theme.navigationBar.buttonFocusBackground};
        border: 2px solid ${theme.navigationBar.buttonFocusBorder};
        outline: ${theme.navigationBar.buttonFocusBorder};
        border-radius: 4px;
        margin: 0;
    }
    @media (max-width: ${breakpoints.sm}) {
        height: 35px;
        width: auto;
    }
`;

export const StyledNeveLogo = styled(NeveLogo)`
    height: 45px;
    width: auto;
    @media (max-width: ${breakpoints.sm}) {
        height: 35px;
        width: auto;
    }
`;

const NavbarLink = styled.button`
    border-style: none;
    background-color: ${theme.navigationBar.background};
    &:focus {
        background-color: ${theme.navigationBar.buttonFocusBackground};
        border: 2px solid ${theme.navigationBar.buttonFocusBorder};
        outline: ${theme.navigationBar.buttonFocusBorder};
        border-radius: 4px;
        margin: 0;
    }
    &:hover {
        background-color: ${theme.navigationBar.buttonHoverBackground};
        border-radius: 4px;
    }
    margin: 2px;
`;

export const NavigationContainer = styled.nav`
    position: fixed;
    width: 100%;
    left: 0;
    height: ${sizes.headerHeight};
    @media (max-width: ${breakpoints.sm}) {
        height: ${sizes.headerMobileHeight};
    }
    border-bottom: 1px solid ${theme.navigationBar.border};
    background-color: ${theme.navigationBar.background};
    z-index: 120;
`;

export const LimitedContainer = styled.div`
    max-width: ${sizes.appMaxWidthWithoutPadding}px;
    height: 100%;
    display: grid;
    grid-template-columns: 30% 70%;
    @media (max-width: ${breakpoints.sm}) {
        grid-template-columns: 100%;
    }
    margin: 0 auto;
`;

export const NavigationLogoSection = styled.div`
    display: flex;
    align-items: center;
    padding: 0 15px;
    @media (max-width: ${breakpoints.sm}) {
        justify-content: space-between;
        border-bottom: ${theme.navigationBar.divider} 1px solid;
        padding: 5px 15px;
    }
`;

const NavigationButtonsSection = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 15px;

    @media (max-width: ${breakpoints.sm}) {
        justify-content: space-between;
        padding: 0 10px;
        overflow-x: scroll;
    }
    ::-webkit-scrollbar {
        display: none;
    }
`;

const MenuItemButton = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    cursor: pointer;
    text-decoration: none;
    padding: 0 8px;
`;

const MenuItemIcon = styled.div`
    height: 48px;
    @media (max-width: ${breakpoints.sm}) {
        height: 36px;
    }
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const MenuItemText = styled.div<{ $active?: boolean }>`
    border-bottom: ${({ $active }) =>
        $active ? `4px solid ${theme.navigationBar.buttonFocusBorder}` : '4px solid transparent'};
    color: ${({ $active }) => ($active ? theme.navigationBar.buttonFocusText : theme.navigationBar.buttonText)};
    font-family: ${theme.fontFamilyMain};
    font-size: ${FONTSIZE.BodySmall};
    font-weight: ${({ $active }) => ($active ? 'bold' : 'normal')};
    letter-spacing: 0;
`;

const ContactSupportOverlay = styled.div`
    position: fixed;
    top: 80px;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    @media (max-width: ${breakpoints.sm}) {
        top: 112px;
    }
`;

const ContactSupportPanel = styled(animated.div)`
    background: ${theme.cardBackground};
    height: 100vh;
    position: absolute;
    right: 0;
    top: 80px;
    transform: translateX(500px);
    width: 500px;
    @media (max-width: ${breakpoints.sm}) {
        width: 90%;
        top: 112px;
    }
`;
