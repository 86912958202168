import useGetRequest from '../../../../../hooks/useGetRequest';
import React, { useContext } from 'react';
import TaskContentLayout from './TaskContentLayout';
import { PreviewContext } from '../../../../../contextProviders/PreviewContext';
import useModifyRequest from '../../../../../hooks/useModifyRequest';
import { ActivityStatusUpdate } from '../../../../../models/inputModels/ActivityStatusUpdate';
import { ApiResponse } from '../../../../../lib/_api-helpers';
import { LearnerTask } from '../../../../../models/LearnerTask';

type TaskContentDataProps = {
    courseScheduleId: string;
    taskId: string;
};

export default function TaskContentData({ courseScheduleId, taskId }: TaskContentDataProps): JSX.Element {
    const {
        data: task,
        loading,
        errors,
        refetchData,
    } = useGetRequest<LearnerTask>(`learner/schedule/${courseScheduleId}/task/${taskId}`);

    const {
        modifyData: setActivityCompletion,
        loading: loadingSetStatus,
        errors: errorsSettingActivityStatus,
    } = useModifyRequest(`learner/schedule/${courseScheduleId}/activity/${taskId}/complete`, 'POST');

    const toggleTaskCompletion = async () => {
        let newActivityStatus: ActivityStatusUpdate;

        if (!task?.completed) {
            newActivityStatus = { completed: new Date() };
        } else {
            newActivityStatus = { completed: null };
        }

        const response = await setActivityCompletion(newActivityStatus);

        if (!response || response.errors) {
            return { errors: response.errors };
        }

        await refetchData();

        return { value: true };
    };

    React.useEffect(() => {
        refetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskId]);

    return (
        <TaskContentLayout
            task={task}
            loading={loading}
            errors={errors}
            toggleTaskCompletion={toggleTaskCompletion}
            toggleCompletionLoading={loadingSetStatus}
            toggleCompletionErrors={errorsSettingActivityStatus}
        />
    );
}

export function TaskPreviewContentData({ taskId }: TaskContentDataProps): JSX.Element {
    const { scheduledCourse, setScheduledCourse } = useContext(PreviewContext);
    const [currentTask, setCurrentTask] = React.useState<LearnerTask | null>(null);

    const moduleIndex =
        scheduledCourse?.course.modules.findIndex((m) => m.activities.find((a) => a.id === taskId)) ?? 0;

    const activity = scheduledCourse?.course.modules[moduleIndex].activities.find((a) => a.id === taskId);

    React.useEffect(() => {
        if (!activity) return;

        setCurrentTask({
            completed: activity.statuses.length > 0 && !!activity.statuses[0].completed,
            description: activity.description,
            content: activity.content,
            durationInMins: activity.durationInMins,
            imageDocument: activity.imageDocument,
            videoDocument: activity.videoDocument,
            audioDocument: activity.audioDocument,
            resources: activity.resources,
            title: activity.title,
            id: activity.id,
        });
    }, [activity]);

    if (!scheduledCourse) return <>Course not found.</>;

    if (!activity) return <>Task not found.</>;

    async function onTaskCompletion(): Promise<ApiResponse<boolean>> {
        if (!scheduledCourse) return { errors: [{ field: '', code: '500' }] };
        if (!activity) return { errors: [{ field: '', code: '500' }] };

        const taskIsComplete = activity.statuses[0].completed !== null;

        taskIsComplete ? (activity.statuses[0].completed = null) : (activity.statuses[0].completed = new Date());

        setScheduledCourse(scheduledCourse);

        setCurrentTask({
            completed: activity.statuses.length > 0 && !!activity.statuses[0].completed,
            description: activity.content,
            content: activity.content,
            durationInMins: activity.durationInMins,
            imageDocument: activity.imageDocument,
            videoDocument: activity.videoDocument,
            audioDocument: activity.audioDocument,
            resources: activity.resources,
            title: activity.title,
            id: activity.id,
        });

        return { value: true };
    }

    return (
        <TaskContentLayout
            task={currentTask}
            loading={false}
            errors={[]}
            toggleTaskCompletion={onTaskCompletion}
            toggleCompletionLoading={false}
            toggleCompletionErrors={[]}
        />
    );
}
