import { StrategyProps } from './strategies';
import React from 'react';

export default function LogoStrategy({ localFile, setFileMetaDataValid }: StrategyProps): JSX.Element {
    React.useEffect(() => {
        setFileMetaDataValid(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localFile]);

    return <></>;
}
