import * as React from 'react';
import { ErrorMessage } from './FormComponents';
import { fieldErrorCodes, fieldErrorNames } from '../../shared/error-messages';

type ConditionalValidationErrorMessageProps = {
    error?: string;
};
export function ConditionalValidationErrorMessage({
    error,
}: ConditionalValidationErrorMessageProps): JSX.Element | null {
    if (!error) {
        return null;
    }
    let message = error;
    if (message.charAt(0) === '[') {
        // Error from the API:
        // JSON-serialised as a [field, code] tuple by `createFieldErrorFromAPIError`
        message = getMessageForApiError(message);
    }
    return <ErrorMessage className="error-message">{message}</ErrorMessage>;
}

function getMessageForApiError(message: string): string {
    const [field, code] = JSON.parse(message);
    return `${fieldErrorNames[field]} ${fieldErrorCodes[code]}`;
}
