import AudioIcon from 'assets/icons/pathway/AudioIcon';
import { BodyLarge } from 'components/Elements';
import { GenerateType } from 'models/Activity';
import styled from 'styled-components';
import { sizes, theme } from 'theme';
import strings from '../../../../strings/strings.json';

export function GenerateTypeContentComponent({ generateType }: GenerateTypeContentComponentProps): JSX.Element {
    switch (generateType) {
        case GenerateType.TEXT_TO_AUDIO:
            return (
                <GenerateTypeContent>
                    <AudioIcon colour={theme.textColour} />
                    <BodyLarge $bold>{strings.courseAIGeneratedContentComponent.generateTypeTitle}</BodyLarge>
                </GenerateTypeContent>
            );
        default:
            return <></>;
    }
}

export type GenerateTypeContentComponentProps = {
    generateType: GenerateType;
};

const GenerateTypeContent = styled.div`
    display: flex;
    align-items: center;
    gap: ${sizes.spacingSm};
`;
