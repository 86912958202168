import { theme } from 'theme';
import { IconSize } from '../icon-sizes';

export default function LocationIcon({ size = IconSize.SMALL, colour = theme.primaryBrandColour }): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3419_589)">
                <path
                    d="M7.8763 0C4.47805 0 1.72311 2.7541 1.72311 6.15235C1.72311 8.89895 5.74468 13.9063 7.28548 15.7238C7.35775 15.8103 7.44811 15.8798 7.5502 15.9275C7.65229 15.9753 7.76361 16 7.8763 16C7.98899 16 8.10031 15.9753 8.2024 15.9275C8.30448 15.8798 8.39485 15.8103 8.46712 15.7238C10.0079 13.9063 14.0295 8.89978 14.0295 6.15235C14.0295 2.7541 11.2745 0 7.8763 0ZM7.8763 7.99981C6.77132 7.99981 5.87635 7.10399 5.87635 5.99985C5.87635 4.89488 6.77216 3.9999 7.8763 3.9999C8.98044 3.9999 9.87625 4.89488 9.87625 5.99985C9.87625 7.10399 8.98044 7.99981 7.8763 7.99981Z"
                    fill={colour}
                />
            </g>
            <defs>
                <clipPath id="clip0_3419_589">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
