import { BodyRegular } from '../../../components/Elements';
import * as React from 'react';
import SecondaryButton from 'components/buttons/SecondaryButton';
import EditIcon from 'assets/icons/controls/EditIcon';
import styled from 'styled-components';
import { breakpoints, sizes } from 'theme';
import strings from '../../../strings/strings.json';
import SaveNotifier from 'components/notifiers/SaveNotifier';

type TrainerBioReadonlyProps = {
    bio: string;
    showEditableForm: React.Dispatch<React.SetStateAction<boolean>>;
    showSavedBio: boolean;
};

export function TrainerBioReadonly({ bio, showEditableForm, showSavedBio }: TrainerBioReadonlyProps): JSX.Element {
    return (
        <TrainerBioReadonlyContainer>
            <BodyRegular $bold>{strings.trainerBioReadonly.title}</BodyRegular>

            {bio ? (
                <>
                    <BodyRegular>{bio}</BodyRegular>
                    <EditBioButtonContainer>
                        <SecondaryButton
                            title={strings.trainerBioReadonly.editButton.title}
                            aria={strings.trainerBioReadonly.editButton.aria}
                            icon={<EditIcon />}
                            onClick={() => showEditableForm(true)}
                        />
                    </EditBioButtonContainer>
                </>
            ) : (
                <AddBioButtonContainer>
                    <SecondaryButton
                        title={strings.trainerBioReadonly.addButton.title}
                        aria={strings.trainerBioReadonly.addButton.aria}
                        icon={<EditIcon />}
                        onClick={() => showEditableForm(true)}
                    />
                </AddBioButtonContainer>
            )}
            {showSavedBio && <SaveNotifier />}
        </TrainerBioReadonlyContainer>
    );
}

const AddBioButtonContainer = styled.div`
    display: flex;
    margin-top: ${sizes.spacingSm};
`;

const EditBioButtonContainer = styled.div`
    display: flex;
    justify-content: end;

    @media (max-width: ${breakpoints.md}) {
        margin-top: ${sizes.spacingMd};
    }
`;

const TrainerBioReadonlyContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 666px;

    @media (max-width: ${breakpoints.md}) {
        width: 100%;
    }
`;
