import { Headline3 } from 'components/Elements';
import styled from 'styled-components';
import { sizes } from 'theme';
import Hint from '../../../components/Hint';

export type CourseStepContentProps = {
    title?: string;
    titleIcon?: JSX.Element;
    hint?: string;
    content: JSX.Element;
};

export default function CourseStepContent({ title, titleIcon, hint, content }: CourseStepContentProps): JSX.Element {
    return (
        <CourseStepContentContainer>
            {title && (
                <TitleAndIconContainer>
                    <Headline3>{title}</Headline3>
                    {titleIcon && titleIcon}
                </TitleAndIconContainer>
            )}
            {hint && <Hint hint={hint} />}
            {content}
        </CourseStepContentContainer>
    );
}

const TitleAndIconContainer = styled.div`
    display: flex;
    gap: ${sizes.spacingLg};
    align-items: center;
`;

const CourseStepContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingRg};
`;
