import strings from '../../../strings/strings.json';
import { Headline4, Headline5 } from '../../../components/Elements';
import * as React from 'react';
import { ErrorNotifierSlim } from "../../../components/notifiers/ErrorNotifierSlim";
import styled from 'styled-components';
import { breakpoints, sizes } from '../../../theme';
import { StepperFormProps } from '../../../components/stepper/stepperTypes';
import { NewSchedule } from '../ScheduleCoursePage';
import StepperButtonRow from '../../../components/stepper/StepperButtonRow';
import EmbeddedDateInput from '../../../components/forms/EmbeddedDateInput';
import { FormWithGaps } from './commonElements';

export default function SelectCourseDateForm(props: StepperFormProps<NewSchedule>): JSX.Element {
    const { next, newObject, setNewObject } = props;

    const [selectedStartDate, setSelectedStartDate] = React.useState<Date | undefined>(
        newObject.courseStartDate || new Date(),
    );
    const [startDateError, setStartDateError] = React.useState<string | null>(null);
    const [endDateError, setEndDateError] = React.useState<string | null>(null);

    const [selectedEndDate, setSelectedEndDate] = React.useState<Date | undefined>(newObject.courseEndDate);

    React.useEffect(() => {
        if (!!selectedStartDate && !!selectedEndDate && selectedStartDate > selectedEndDate) {
            setSelectedEndDate(selectedStartDate);
        }
    }, [selectedStartDate, selectedEndDate]);

    React.useEffect(() => {
        if (selectedEndDate) {
            setEndDateError(null);
        }
    }, [selectedEndDate]);

    async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setStartDateError(null);
        setEndDateError(null);

        if (!selectedStartDate) {
            setStartDateError(strings.scheduleCourseStepper.selectCourseDateForm.startDateRequiredText);
            return;
        }

        const startDateWithoutTime = new Date(selectedStartDate);
        startDateWithoutTime.setHours(0, 0, 0, 0);

        if (!selectedEndDate) {
            setEndDateError(strings.scheduleCourseStepper.selectCourseDateForm.endDateRequiredText);
            return;
        }

        const endDateWithoutTime = new Date(selectedEndDate);
        endDateWithoutTime.setHours(0, 0, 0, 0);

        setNewObject({
            ...newObject,
            courseStartDate: startDateWithoutTime,
            courseEndDate: endDateWithoutTime,
        });

        next();
    }

    return (
        <FormWithGaps onSubmit={onSubmit}>
            <Headline4>{strings.scheduleCourseStepper.selectCourseDateForm.startCourseDatesTitle}</Headline4>
            <DatesContainer>
                <DateContainer>
                    <DateHeadline>{strings.scheduleCourseStepper.selectCourseDateForm.startDateTitle} *</DateHeadline>
                    <DateInputContainer>
                        <EmbeddedDateInput
                            selectedDate={selectedStartDate}
                            setSelectedDate={setSelectedStartDate}
                            labelTitle={`${strings.datePickerModal.startDate}: `}
                            required
                        />
                    </DateInputContainer>
                    {startDateError && <ErrorNotifierSlim description={startDateError} />}
                </DateContainer>
                <DateContainer>
                    <DateHeadline>{strings.scheduleCourseStepper.selectCourseDateForm.endDateTitle} *</DateHeadline>
                    <DateInputContainer>
                        <EmbeddedDateInput
                            selectedDate={selectedEndDate}
                            setSelectedDate={setSelectedEndDate}
                            earliestDate={selectedStartDate}
                            labelTitle={`${strings.datePickerModal.endDate}: `}
                            required
                        />
                    </DateInputContainer>
                    {endDateError && <ErrorNotifierSlim description={endDateError} />}
                </DateContainer>
            </DatesContainer>

            <StepperButtonRowContainer>
                <StepperButtonRow
                    submitButtonTitle={strings.scheduleCourseStepper.selectCourseDateForm.submitButton}
                    submitButtonAria={strings.scheduleCourseStepper.selectCourseDateForm.submitButtonAria}
                    showPreviousButton={false}
                />
            </StepperButtonRowContainer>
        </FormWithGaps>
    );
}

const StepperButtonRowContainer = styled.div`
    display: flex;
    justify-content: end;
`;

const DateHeadline = styled(Headline5)`
    margin-left: ${sizes.spacingMd};
`;

const DatesContainer = styled.div`
    display: flex;
    gap: 130px;
    @media (max-width: ${breakpoints.md}) {
        flex-direction: column;
        gap: ${sizes.spacingLg};
    }
`;

const DateContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingSm};
`;

const DateInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 240px;
    @media (max-width: ${breakpoints.sm}) {
        width: unset;
    }
`;
