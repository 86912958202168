import React from 'react';
import { AuthContext } from '../contextProviders/AuthContext';

export default function LogoutPage(): JSX.Element {
    const { logout } = React.useContext(AuthContext);

    React.useEffect(() => {
        logout();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <p>Logging out...</p>;
}
