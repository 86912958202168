import { BodyRegular } from 'components/Elements';
import React from 'react';
import { StrategyProps } from './strategies';
import strings from '../../../strings/strings.json';
import styled from 'styled-components';

export default function AudioStrategy({
    localFile,
    setFileMetaData,
    setFileMetaDataValid,
}: StrategyProps): JSX.Element {
    const [audioDuration, setAudioDuration] = React.useState<number | undefined>(undefined);

    React.useEffect(() => {
        setFileMetaData(undefined);
        setFileMetaDataValid(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localFile]);

    const audio = document.createElement('audio');
    audio.src = URL.createObjectURL(localFile);
    audio.ondurationchange = function (_) {
        setAudioDuration(audio.duration);
    };

    return (
        <>
            {audioDuration && (
                <BodyRegular>
                    <BoldText>{strings.fileUpload.audio.audioDurationLabel}</BoldText> {Math.round(audioDuration / 60)}{' '}
                    {strings.fileUpload.audio.audioDurationMinutesLabel}
                </BodyRegular>
            )}
        </>
    );
}

const BoldText = styled.span`
    font-weight: bold;
`;
