import { theme } from 'theme';
import { IconSize } from '../icon-sizes';

export default function ChecklistIcon({ size = IconSize.LARGE, colour = theme.textColour }): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask
                id="mask0_36_127"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="34"
                height="34"
            >
                <rect width="34" height="34" fill={colour} />
            </mask>
            <g mask="url(#mask0_36_127)">
                <path
                    d="M7.86248 26.9168L2.83331 21.8876L4.81665 19.9043L7.82706 22.9147L13.8479 16.8939L15.8312 18.9126L7.86248 26.9168ZM7.86248 15.5835L2.83331 10.5543L4.81665 8.57096L7.82706 11.5814L13.8479 5.56055L15.8312 7.5793L7.86248 15.5835ZM18.4166 24.0835V21.2501H31.1666V24.0835H18.4166ZM18.4166 12.7501V9.9168H31.1666V12.7501H18.4166Z"
                    fill={colour}
                />
            </g>
        </svg>
    );
}
