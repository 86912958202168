import * as React from 'react';
import strings from '../../../strings/strings.json';
import { Headline3 } from '../../../components/Elements';
import styled from 'styled-components';
import { theme, sizes, breakpoints } from 'theme';
import { StepButtonComponent } from './StepButtonComponent';
import { TabDefinition } from 'components/tabs/Tabs';
import useWindowWidth from 'hooks/useWindowWidth';
import TabStructureComponent from 'components/tabs/TabStructureComponent';
import { StepId } from '../CourseEditorPage';
import { CompletionIndicator } from 'components/progressBar/CompletionIndicator';

export type Step = {
    id: StepId;
    title: string;
    aria: string;
    icon: React.ElementType;
    content: JSX.Element;
    action: () => void;
    completed: boolean;
    completedIcon?: JSX.Element;
};

type StepsPanelProps = {
    courseId: string;
    steps: Step[];
    activeStep: string;
    showIncompleteStepsWarning: boolean;
};

export function StepsPanel({ steps, activeStep, showIncompleteStepsWarning }: StepsPanelProps): JSX.Element {
    const { isMobileWidth } = useWindowWidth();

    const tabs: TabDefinition[] = steps.map((step) => {
        return {
            id: step.id,
            name: step.title,
            icon: step.icon,
            content: step.content,
        };
    });

    const stepComponents: JSX.Element[] = steps.map((step) => (
        <StepButtonComponent
            key={step.id}
            title={step.title}
            aria={step.aria}
            icon={step.icon}
            onClick={step.action}
            active={step.id === activeStep}
            completed={step.completed}
            showIncompleteStepsWarning={showIncompleteStepsWarning}
            completedIcon={step.completedIcon}
        />
    ));

    return (
        <StepsContainer id="create-course-steps">
            <HeadlineAndProgress>
                <Headline3>{strings.courseEditorPage.steps.headline}</Headline3>
                <CompletionIndicator completed={steps.filter((step) => step.completed).length} total={steps.length} />
            </HeadlineAndProgress>
            {isMobileWidth ? (
                <TabStructureComponent tabs={tabs} initialActiveTabId={activeStep} />
            ) : (
                <StepButtons>{stepComponents}</StepButtons>
            )}
        </StepsContainer>
    );
}

const HeadlineAndProgress = styled.div`
    display: flex;
    justify-content: space-between;
    margin-right: ${sizes.spacingSm};
`;

const StepButtons = styled.div`
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${theme.cardSecondaryBorder};
`;

const StepsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingRg};
    margin-top: ${sizes.spacingLg};

    @media (max-width: ${breakpoints.sm}) {
        margin-top: ${sizes.spacingSm};
    }
`;
