import * as React from 'react';
import strings from '../../strings/strings.json';
import Notification, { NotificationType } from './Notification';
import styled from 'styled-components';

export default function SaveNotifier({ title = strings.general.saveSuccess, description = '' }): JSX.Element {
    return (
        <NotificationContainer>
            <Notification notificationType={NotificationType.SUCCESS} title={title} description={description} />
        </NotificationContainer>
    );
}

const NotificationContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
`;
