import * as React from 'react';
import strings from '../../../strings/strings.json';
import styled from 'styled-components';
import { ButtonRowRight, Headline4 } from 'components/Elements';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { FormCardWithGaps } from 'components/forms/FormComponents';
import { StepperFormProps } from 'components/stepper/stepperTypes';
import { UserRole } from 'models/inputModels/UserRole';
import useNeveForm from 'components/forms/NeveForm';
import { ROLE_DETAILS, RoleType } from '../../../models/User';
import Checkboxes from '../../../components/forms/Checkboxes';
import Notification, { NotificationType } from '../../../components/notifiers/Notification';

type ChangeUserRoleInput = {
    roles: RoleType[];
};

export default function ChangeUserRoleForm(props: StepperFormProps<UserRole>): JSX.Element {
    const [globalErrorMessage, setGlobalErrorMessage] = React.useState<string>('');
    const { next, setNewObject, newObject } = props;

    const { register, handleSubmit } = useNeveForm<ChangeUserRoleInput>({
        roles: newObject.roles,
    });

    const submitRoleType = async (formData: ChangeUserRoleInput) => {
        if (formData.roles === null) {
            setGlobalErrorMessage(strings.activityTypeSelectionPage.typeValidationError);
            return;
        }

        if (!formData.roles.some((x) => x === RoleType.TRAINER) && newObject.hasTrainerScheduledCourses) {
            setGlobalErrorMessage(strings.errorMessages.trainerIsRunningCourses);
            return;
        }

        setNewObject({
            ...newObject,
            roles: formData.roles,
        });

        next();
    };

    let roleOptions = [];

    for (let roleDetail of ROLE_DETAILS) {
        if (roleDetail.name === 'LEARNER') continue;

        roleOptions.push({
            value: roleDetail.name,
            name: roleDetail.friendlyName,
            inputProps: register('roles'),
        });
    }

    return (
        <Form onSubmit={handleSubmit(submitRoleType)}>
            <Headline4>
                {strings.changeUserRolePage.instructionText} {newObject.firstName} {newObject.lastName}:
            </Headline4>
            <Notification
                notificationType={NotificationType.INFO}
                description={strings.changeUserRolePage.allUsersLearnersNote}
                title={strings.changeUserRolePage.allUsersLearnersNoteTitle}
            />
            <Checkboxes options={roleOptions} />
            {globalErrorMessage && (
                <Notification
                    notificationType={NotificationType.DANGER}
                    title={strings.selectOrganisationForm.roleChangeError}
                    description={globalErrorMessage}
                />
            )}
            <ButtonRowRight>
                <PrimaryButton
                    title={strings.changeUserRolePage.submitButton}
                    aria={strings.changeUserRolePage.submitButtonAriaLabel}
                    type="submit"
                />
            </ButtonRowRight>
        </Form>
    );
}

const Form = styled(FormCardWithGaps)`
    width: 100%;
    border: none;
    box-shadow: none;
`;
