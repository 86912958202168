import { theme } from '../../../theme';
import { IconSize } from '../icon-sizes';

export default function ArrowRightIcon({
    colour = theme.primaryButtonTextColour,
    size = IconSize.SMALL,
}: {
    colour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.80892 20L0 18.225L8.38216 10L0 1.775L1.80892 0L12 10L1.80892 20Z" fill={colour} />
        </svg>
    );
}
