import { theme } from '../../../theme';
import { IconSize } from '../icon-sizes';

export default function DashboardIcon({
    colour = theme.primaryBrandColour,
    size = IconSize.MEDIUM,
}: {
    colour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.75 31.75C2.7875 31.75 1.96354 31.4073 1.27813 30.7219C0.592708 30.0365 0.25 29.2125 0.25 28.25V3.75C0.25 2.7875 0.592708 1.96354 1.27813 1.27813C1.96354 0.592708 2.7875 0.25 3.75 0.25H28.25C29.2125 0.25 30.0365 0.592708 30.7219 1.27813C31.4073 1.96354 31.75 2.7875 31.75 3.75V28.25C31.75 29.2125 31.4073 30.0365 30.7219 30.7219C30.0365 31.4073 29.2125 31.75 28.25 31.75H3.75ZM12.5 28.25V17.75H3.75V28.25H12.5ZM16 28.25H28.25V17.75H16V28.25ZM3.75 14.25H28.25V3.75H3.75V14.25Z"
                fill={colour}
            />
        </svg>
    );
}
