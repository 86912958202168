import strings from '../../../../strings/strings.json';
import Spinner from '../../../../components/Spinner';
import React from 'react';
import { IntegrationSessionData } from '../../../../models/hr-integration/IntegrationSessionData';
import { logError } from '../../../../lib/debug-helpers';
import { useVault } from '@apideck/vault-react';
import useModifyRequest from '../../../../hooks/useModifyRequest';
import SecondaryButton from '../../../../components/buttons/SecondaryButton';

type CharlieHRConfigureButtonProps = {
    buttonTitle: string;
    setError: (error: string | null) => void;
    onClose: () => void;
};

export default function CharlieHRConfigureButton({ buttonTitle, setError, onClose }: CharlieHRConfigureButtonProps) {
    const { open } = useVault();

    const { modifyData: requestSession, loading: requestingSession } = useModifyRequest(
        'hr-integration/session',
        'POST',
    );

    const handleIntegrate = async () => {
        setError(null);

        const response = await requestSession<any, IntegrationSessionData>({});

        if (response.errors) {
            logError(response.errors);
            setError(strings.organisationIntegrationSetupPage.apiDeckSessionErrorMessage);
            return;
        }

        if (!(response.value && response.value.sessionToken)) {
            logError(response);
            setError(strings.organisationIntegrationSetupPage.apiDeckSessionErrorMessage);
            return;
        }

        open({ token: response.value.sessionToken, onClose });
    };

    return (
        <div>
            <SecondaryButton
                title={buttonTitle}
                aria={strings.organisationIntegrationSetupPage.configureButtonAria}
                onClick={handleIntegrate}
                disabled={requestingSession}
                icon={requestingSession ? <Spinner /> : undefined}
            />
        </div>
    );
}
