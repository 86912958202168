import { stringTrim } from 'lib/requestDataStringTrim';
import * as React from 'react';
import { AuthContext } from '../contextProviders/AuthContext';
import { APIError, API_URL, ApiResponse, genericServiceError, getHeaders, isLaterThanNow } from '../lib/_api-helpers';

type RequestMethod = 'PUT' | 'PATCH' | 'POST';

type UseModifyRequestData = {
    modifyData: <T, U>(requestBody: T) => Promise<ApiResponse<U>>;
    loading: boolean;
    errors: APIError[];
};

export default function useModifyRequest(endpoint: string, method: RequestMethod): UseModifyRequestData {
    const authContext = React.useContext(AuthContext);

    const url = `${API_URL}/${endpoint}`;

    const [loading, setLoading] = React.useState(false);
    const [errors, setErrors] = React.useState<APIError[]>([]);

    const modifyData = async <T, U>(requestBody: T) => {
        setLoading(true);

        let token = authContext.userData.tokens.accessToken ?? '';

        if (isLaterThanNow(authContext.tokenExpiryTime)) {
            token = await authContext.refreshToken();
        }

        const headers = getHeaders(token);

        const trimRequestBody = stringTrim(requestBody);

        try {
            const response = await fetch(url, {
                headers,
                method: method,
                body: JSON.stringify(trimRequestBody),
            });

            return await response.json();
        } catch (error) {
            setErrors([{ field: 'global', code: 'serviceError' }]);
            return genericServiceError<U>();
        } finally {
            setLoading(false);
        }
    };

    return { modifyData, loading, errors };
}
