import { Resource } from './Resources';
import { ActivityQuestion } from './ActivityQuestion';
import { ActivityStatus } from './ActivityStatus';
import { ActivityMeeting, LocationType } from './ActivityMeeting';
import { AudioDocument, ImageDocument, VideoDocument } from './FileDocument';
import { IconSize } from 'assets/icons/icon-sizes';
import QuestionnaireIcon from 'assets/icons/pathway/QuestionnaireIcon';
import TaskIcon from 'assets/icons/pathway/TaskIcon';
import VideoChatIcon from 'assets/icons/pathway/VideoChatIcon';
import { LearnerActivity } from './LearnerCourse';
import LocationIcon from 'assets/icons/controls/LocationIcon';
import { theme } from 'theme';
import ValidateIcon from 'assets/icons/indicators/ValidateIcon';

export type Activity = {
    id: string;
    type: ActivityType;
    title: string;
    description: string;
    content: string;
    order: number;
    resources: Resource[];
    questions: ActivityQuestion[];
    statuses: ActivityStatus[];
    meetings: ActivityMeeting[];
    dateCreated: Date;
    durationInMins: number | null;
    videoDocument: VideoDocument | null;
    imageDocument: ImageDocument | null;
    audioDocument: AudioDocument | null;
    moduleId: string;
    buildMethod: BuildMethod;
    generateContentStatuses: GenerateContentStatus[];
};

export enum ActivityType {
    TASK = 'TASK',
    LIVE_TRAINING = 'LIVE_TRAINING',
    QUESTIONNAIRE = 'QUESTIONNAIRE',
}

export enum BuildMethod {
    NONE = 'NONE',
    TEXT_AND_IMAGE = 'TEXT_AND_IMAGE',
    VIDEO = 'VIDEO',
    AUDIO = 'AUDIO',
}

export enum GenerateErrorReason {
    NONE = 'NONE',
    MISSING_ENV_VAR_POLLY_REGION = 'Missing environment variable: POLLY_REGION',
    MISSING_ENV_VAR_DOCUMENTS_REGION = 'Missing environment variable: DOCUMENTS_REGION',
    MISSING_ENV_VAR_DOCUMENTS_BUCKET_NAME = 'Missing environment variable: DOCUMENTS_BUCKET_NAME',
    ACTIVITY_NOT_TASK = 'Activity not task',
    TASK_NO_CONTENT = 'Task has no content',
    CONTENT_ALREADY_GENERATED = 'Content already generated',
    POLLY_ERROR = 'Error getting audio from Polly',
    POLLY_RESPONSE_NO_AUDIO = 'Polly response has no audio',
    UPLOAD_AUDIO_ERROR = 'Error uploading audio',
    UPDATE_STATUS_ERROR = 'Error updating status',
}

export enum GenerateType {
    TEXT_TO_AUDIO = 'TEXT_TO_AUDIO',
}

export type GenerateContentStatus = {
    id: string;
    generateType: GenerateType;
    dateCreated: Date;
    dateUpdated: Date;
    dateGenerated: Date | null;
    s3Filepath: string;
    dateErrored: Date | null;
    error: GenerateErrorReason;
    dateApproved: Date | null;
};

export function isActivityCompleted(activity: Activity): boolean {
    if (activity.type === ActivityType.LIVE_TRAINING) {
        const meeting = activity.meetings?.length > 0 ? activity.meetings[0] : null;

        if (!meeting) return false;

        if (!activity.durationInMins) {
            return false;
        }

        const meetingEndDate = new Date(new Date(meeting.meetingDatetime).getTime() + activity.durationInMins * 60000);

        return meetingEndDate < new Date();
    }

    return !!activity.statuses[0].completed ?? false;
}

export function getActivityTypeUrl(activity: Activity): string {
    switch (activity.type) {
        case ActivityType.LIVE_TRAINING:
            return 'live-training';
        case ActivityType.QUESTIONNAIRE:
            return 'questionnaire';
        case ActivityType.TASK:
            return 'task';
        default:
            return '';
    }
}

export function getActivityTypeIcon(activity: LearnerActivity, iconSize: IconSize) {
    if (activity.completed) {
        return <ValidateIcon size={iconSize} />;
    }

    switch (activity.type) {
        case ActivityType.LIVE_TRAINING:
            switch (activity.locationType) {
                case LocationType.IN_PERSON:
                    return <LocationIcon size={iconSize} colour={theme.textColour} />;
                case LocationType.ONLINE:
                    return <VideoChatIcon size={iconSize} colour={theme.textColour} />;
                default:
                    return <></>;
            }
        case ActivityType.QUESTIONNAIRE:
            return <QuestionnaireIcon size={iconSize} colour={theme.textColour} />;
        case ActivityType.TASK:
            return <TaskIcon size={iconSize} colour={theme.textColour} />;
        default:
            return <></>;
    }
}
