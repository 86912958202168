import React from 'react';
import styled from 'styled-components';
import { breakpoints, sizes, theme } from '../../theme';

export function TrainerHeader(props: { children: React.ReactNode | React.ReactNode[] }): JSX.Element {
    return (
        <BannerBackground>
            <LimitedContainer>{props.children}</LimitedContainer>
        </BannerBackground>
    );
}

const BannerBackground = styled.div`
    background: ${theme.trainerDashboardBannerBackground};
    @media only screen and (min-width: ${breakpoints.lg}) {
        background: linear-gradient(
            to right,
            ${theme.trainerDashboardBannerBackground} 50%,
            ${theme.trainerDashboardBannerImageBackground} 50%
        );
        height: 260px;
    }
    height: 195px;
    width: 100%;
`;

const LimitedContainer = styled.header`
    display: flex;
    width: 100%;
    max-width: ${sizes.appMaxWidthWithoutPadding}px;
    align-items: center;
    height: 100%;
    padding-left: ${sizes.edgePadding};
    padding-right: ${sizes.edgePadding};
    margin-left: auto;
    margin-right: auto;
`;
