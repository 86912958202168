import PrimaryButton from 'components/buttons/PrimaryButton';
import SecondaryButton from 'components/buttons/SecondaryButton';
import { BodyRegular, ButtonRowRight, Headline4, TertiaryButton } from 'components/Elements';
import { ErrorNotifier } from 'components/notifiers/ErrorNotifier';
import MainSection from 'components/sections/MainSection';
import SuccessCard from 'components/notifiers/SuccessCard';
import { AuthContext } from 'contextProviders/AuthContext';
import useGetRequest from 'hooks/useGetRequest';
import useModifyRequest from 'hooks/useModifyRequest';
import { Invitation, InvitationStatus } from 'models/Invitation';
import { RoleType } from 'models/User';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { breakpoints, sizes } from 'theme';
import strings from '../../strings/strings.json';
import Notification, { NotificationType } from '../../components/notifiers/Notification';

export default function InvitationPage(): JSX.Element {
    const params = useParams();
    const { id } = params;
    const { userData, setNewActiveRole, refreshUserData } = React.useContext(AuthContext);

    const navigate = useNavigate();

    const { data: invitation, loading: loadingInvitation } = useGetRequest<Invitation>(
        `users/me/invitations/${id}`,
        !id,
    );

    const [error, setError] = React.useState<boolean>(false);
    const [accepted, setAccepted] = React.useState<boolean>(false);

    const { modifyData: respondToInvitation } = useModifyRequest(`users/me/invitations/${invitation?.id}`, 'POST');

    async function declineInvitation() {
        const response = await respondToInvitation({ acceptInvitation: false });

        if (response.errors) {
            setError(true);
        } else {
            navigate('/');
        }
    }

    async function acceptInvitation() {
        const response = await respondToInvitation({ acceptInvitation: true });

        if (response.errors) {
            setError(true);
        } else {
            if (!userData.roles.some((role) => role === RoleType.TRAINER)) {
                await refreshUserData();
            }

            setAccepted(true);
        }
    }

    function skipInvitation() {
        navigate('/');
    }

    function goToDashboard() {
        setNewActiveRole(RoleType.TRAINER);
        navigate('/');
    }

    if (!invitation) {
        return (
            <MainSection>
                <Content>
                    <Notification
                        notificationType={NotificationType.DANGER}
                        title={strings.invitationPage.description.noInvitation}
                    />
                </Content>
            </MainSection>
        );
    }

    if (loadingInvitation) {
        return (
            <MainSection>
                <Content>
                    <Skeleton height={'200px'} />
                </Content>
            </MainSection>
        );
    }

    if (invitation.status !== InvitationStatus.SENT) {
        return (
            <MainSection>
                <Content>
                    <Notification
                        notificationType={NotificationType.INFO}
                        title={strings.inviteTrainerForm.invitationAlreadyAcknowledged}
                        description={strings.inviteTrainerForm.invitationAlreadyAcknowledgedDetail}
                    />
                </Content>
            </MainSection>
        );
    }

    if (accepted) {
        return (
            <MainSection>
                <Content>
                    <SuccessCard
                        notificationTitle={strings.invitationPage.invitationAccepted}
                        notificationDescription={`${strings.invitationPage.description.accepted} ${invitation?.organisation.name}.`}
                        primaryButtonClick={goToDashboard}
                        primaryButtonTitle={strings.invitationPage.button.home.title}
                        primaryButtonAria={strings.invitationPage.button.home.aria}
                    />
                </Content>
            </MainSection>
        );
    }

    return (
        <MainSection>
            <Content>
                <Headline4>{strings.invitationPage.title}</Headline4>
                <BodyRegular>{`${strings.invitationPage.heading} ${invitation?.organisation.name} ${strings.invitationPage.headingPartTwo}`}</BodyRegular>
                <ButtonsContainer>
                    <ButtonRowRight>
                        <SecondaryButton
                            title={strings.invitationPage.button.decline.title}
                            aria={strings.invitationPage.button.decline.aria}
                            onClick={declineInvitation}
                        />
                        <PrimaryButton
                            title={strings.invitationPage.button.accept.title}
                            aria={strings.invitationPage.button.accept.aria}
                            onClick={acceptInvitation}
                        />
                    </ButtonRowRight>
                    <ButtonRowRight>
                        <TertiaryButton
                            title={strings.invitationPage.button.skip.title}
                            aria-label={strings.invitationPage.button.skip.aria}
                            onClick={skipInvitation}
                        >
                            {strings.invitationPage.button.skip.title}
                        </TertiaryButton>
                    </ButtonRowRight>
                </ButtonsContainer>
                {error && (
                    <ErrorNotifier
                        title={strings.invitationPage.error.title}
                        description={strings.invitationPage.error.description}
                    />
                )}
            </Content>
        </MainSection>
    );
}

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingLg};
    width: 450px;
    margin: ${sizes.spacingRg} 0;

    @media (max-width: ${breakpoints.sm}) {
        margin: ${sizes.spacingLg} ${sizes.spacingRg};
        width: unset;
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingLg};
`;
