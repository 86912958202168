import styled from 'styled-components';
import { breakpoints, theme } from '../../theme';
import { Headline3 } from '../Elements';

type LargeCreateButtonProps = {
    title: string;
    aria: string;
    icon: JSX.Element;
    onClick: () => void;
    buttonRef?: React.RefObject<HTMLButtonElement>;
};

export default function LargeCreateButton({
    title,
    aria,
    icon,
    onClick,
    buttonRef,
}: LargeCreateButtonProps): JSX.Element {
    return (
        <CreateButton aria-label={aria} onClick={onClick} ref={buttonRef}>
            {icon}
            <Headline3 $inverted>{title}</Headline3>
        </CreateButton>
    );
}

const CreateButton = styled.button`
    @media (max-width: ${breakpoints.sm}) {
        width: 100%;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 550px;
    height: 220px;
    background-color: ${theme.primaryButtonBackgroundColour};
    color: ${theme.primaryButtonTextColour};
    &:hover {
        background-color: ${theme.primaryButtonHoverBackgroundColour};
        border-color: ${theme.primaryButtonBorderColour};
        color: ${theme.primaryButtonTextColour};
    }
    &:focus {
        background-color: ${theme.primaryButtonFocusBackgroundColour};
        border-color: ${theme.primaryButtonBorderColour};
        color: ${theme.textColour} !important;
    }
    &:visited {
        color: ${theme.textSecondaryColour};
    }
    border-radius: 8px;
    border: 2px solid ${theme.primaryButtonBorderColour};
    box-shadow:
        0 0 10px rgba(0, 0, 0, 0.15),
        4px 4px 8px 0 rgba(0, 0, 0, 0.15);
`;
