import { BodyRegular } from '../../../components/Elements';
import strings from '../../../strings/strings.json';
import React from 'react';
import styled from 'styled-components';
import { sizes, theme } from '../../../theme';
import ErrorIcon from '../../../assets/icons/indicators/ErrorIcon';

export default function CancelledIndicator(): JSX.Element {
    return (
        <CancelledStatusContainer>
            <ErrorIcon />
            <BodyRegular>{strings.OAScheduledCoursesListPage.cancelledStatus}</BodyRegular>
        </CancelledStatusContainer>
    );
}

const CancelledStatusContainer = styled.div`
    display: flex;
    padding: ${sizes.spacingSm};
    background-color: ${theme.notificationDangerBackground};
    border-radius: ${sizes.borderRadiusMd};
    align-items: center;
    gap: ${sizes.spacingSm};
`;
