import ErrorIcon from 'assets/icons/indicators/ErrorIcon';
import { BodySmall } from 'components/Elements';
import styled from 'styled-components';
import { theme } from 'theme';
import { NotifierSlimContainer } from './NotifierSlimContainer';

export type NotifierSlimProps = {
    description: string;
};

export function ErrorNotifierSlim({ description }: NotifierSlimProps): JSX.Element {
    return (
        <NotifierSlimContainer>
            <ErrorIcon />
            <ErrorDescription>{description}</ErrorDescription>
        </NotifierSlimContainer>
    );
}

export const ErrorDescription = styled(BodySmall)`
    color: ${theme.errorColour};
`;
