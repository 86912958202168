import * as React from 'react';
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';
import AccessibilityPanel from './components/AccessibilityPanel';

export default function AuthLayout(): JSX.Element {
    const [accessibilityPanelExpanded, setAccessibilityPanelExpanded] = React.useState<boolean>(true);

    return (
        <AppContainer>
            <AuthPageContainer>
                <Outlet />
            </AuthPageContainer>
            <AccessibilityPanel setOpen={setAccessibilityPanelExpanded} isOpen={accessibilityPanelExpanded} />
        </AppContainer>
    );
}

const AppContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
`;

export const AuthPageContainer = styled.div`
    margin: 110px auto 0 auto;
`;
