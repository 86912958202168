import { TextAreaField } from 'components/forms/TextAreaField';
import TextField from 'components/forms/TextField';
import { validateThreeCharacterLimit } from 'lib/custom-form-validation';
import { FieldErrors, FieldValues, UseFormRegister, UseFormWatch } from 'react-hook-form';
import { createErrorMessage, fieldErrorCodes, requiredFieldErrorMessage } from 'shared/error-messages';
import strings from '../../../../strings/strings.json';

export type TaskInput = {
    title: string;
    description: string;
    durationInMins: number | null;
};

type TaskUpdateDetailsSectionProps<TaskInput extends FieldValues> = {
    register: UseFormRegister<TaskInput>;
    watch: UseFormWatch<TaskInput>;
    errors: FieldErrors<TaskInput>;
    onChange: () => void;
};

export function TaskUpdateDetailsSection({
    register,
    watch,
    errors,
    onChange,
}: TaskUpdateDetailsSectionProps<TaskInput>): JSX.Element {
    const titleMaxLength = 120;
    const descriptionMaxLength = 500;

    return (
        <div>
            <TextField
                fieldName="activityTitle"
                labelText={strings.activityForm.titleInputLabel}
                inputAria={strings.activityForm.titleInputAria}
                inputProps={register('title', {
                    required: {
                        value: true,
                        message: requiredFieldErrorMessage(strings.activityForm.titleInputLabel),
                    },
                    maxLength: {
                        value: titleMaxLength,
                        message: createErrorMessage(strings.activityForm.titleInputLabel, fieldErrorCodes.maxLength),
                    },
                    onChange: onChange,
                })}
                errorMessage={errors.title?.message}
                required
                autoFocus
            />
            <TextAreaField
                maxLength={descriptionMaxLength}
                charactersLeft={descriptionMaxLength - watch('description').length}
                fieldName={strings.activityForm.descriptionLabel}
                aria={strings.activityForm.descriptionAria}
                errorMessage={errors.description?.message}
                inputProps={register('description', {
                    required: {
                        value: true,
                        message: requiredFieldErrorMessage(strings.activityForm.titleInputLabel),
                    },
                    maxLength: {
                        value: descriptionMaxLength,
                        message: createErrorMessage(strings.activityForm.descriptionLabel, fieldErrorCodes.maxLength),
                    },
                    onChange: onChange,
                })}
                required
            />
            <TextField
                fieldName="activityTaskDuration"
                labelText={strings.activityForm.durationInputLabel}
                inputAria={strings.activityForm.durationInputAria}
                inputProps={register('durationInMins', {
                    validate: {
                        validateThreeCharacterLimit,
                    },
                    onChange: onChange,
                })}
                errorMessage={errors.durationInMins?.message}
                inputType="number"
                width="8rem"
                flavourText={strings.activityForm.durationInputMins}
            />
        </div>
    );
}
