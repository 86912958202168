import { theme } from 'theme';
import { IconSize } from '../icon-sizes';

export default function EventListIcon({ size = IconSize.LARGE, colour = theme.textColour }): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask
                id="mask0_38_328"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="26"
                height="26"
            >
                <rect width="26" height="26" fill={colour} />
            </mask>
            <g mask="url(#mask0_38_328)">
                <path
                    d="M17.3334 22.75C16.7376 22.75 16.2275 22.5378 15.8032 22.1135C15.3789 21.6892 15.1667 21.1792 15.1667 20.5833V16.25C15.1667 15.6542 15.3789 15.1441 15.8032 14.7198C16.2275 14.2955 16.7376 14.0833 17.3334 14.0833H21.6667C22.2626 14.0833 22.7726 14.2955 23.197 14.7198C23.6213 15.1441 23.8334 15.6542 23.8334 16.25V20.5833C23.8334 21.1792 23.6213 21.6892 23.197 22.1135C22.7726 22.5378 22.2626 22.75 21.6667 22.75H17.3334ZM17.3334 20.5833H21.6667V16.25H17.3334V20.5833ZM2.16675 19.5V17.3333H11.9167V19.5H2.16675ZM17.3334 11.9167C16.7376 11.9167 16.2275 11.7045 15.8032 11.2802C15.3789 10.8559 15.1667 10.3458 15.1667 9.75V5.41667C15.1667 4.82083 15.3789 4.31076 15.8032 3.88646C16.2275 3.46215 16.7376 3.25 17.3334 3.25H21.6667C22.2626 3.25 22.7726 3.46215 23.197 3.88646C23.6213 4.31076 23.8334 4.82083 23.8334 5.41667V9.75C23.8334 10.3458 23.6213 10.8559 23.197 11.2802C22.7726 11.7045 22.2626 11.9167 21.6667 11.9167H17.3334ZM17.3334 9.75H21.6667V5.41667H17.3334V9.75ZM2.16675 8.66667V6.5H11.9167V8.66667H2.16675Z"
                    fill={colour}
                />
            </g>
        </svg>
    );
}
