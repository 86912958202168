import styled from 'styled-components';
import { theme, FONTSIZE } from 'theme';

type CompletionIndicatorProps = {
    completed: number;
    total: number;
};

export function CompletionIndicator({ completed, total }: CompletionIndicatorProps): JSX.Element {
    return (
        <Circle $progress={(360 * Math.round((completed / total) * 100)) / 100}>
            <Text>{completed + '/' + total}</Text>
        </Circle>
    );
}

const Circle = styled.div<{ $progress: number }>`
    height: 3.875rem;
    width: 3.875rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: radial-gradient(${theme.circularProgressBar.darkVersionIndicatorTrackColour} 50%, transparent 51%),
        conic-gradient(
            transparent 0deg ${({ $progress }) => $progress}deg,
            ${theme.circularProgressBar.lightVersionBackground} ${({ $progress }) => $progress}deg 360deg
        ),
        conic-gradient(${theme.primaryBrandColour} 0deg, ${theme.circularProgressBar.darkVersionBackgroundColour});
`;

const Text = styled.div`
    height: 3.125rem;
    width: 3.125rem;
    border-radius: 100%;
    color: ${theme.circularProgressBar.darkVersionIndicatorColour};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${FONTSIZE.BodySmall};
    font-weight: 700;
`;
