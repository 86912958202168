import Tabs, { TabDefinition } from 'components/tabs/Tabs';
import React from 'react';
import styled from 'styled-components';
import { theme, sizes, breakpoints } from 'theme';

export type TabComponentProps = {
    tabs: TabDefinition[];
    rightElement?: JSX.Element;
    initialActiveTabId?: string;
};

export default function TabStructureComponent({
    tabs,
    rightElement,
    initialActiveTabId,
}: TabComponentProps): JSX.Element {
    const [activeTabId, setActiveTabId] = React.useState<string>(
        initialActiveTabId ? initialActiveTabId : tabs[0] ? tabs[0].id : '',
    );

    return (
        <div>
            <Tabs
                tabs={tabs}
                activeTabId={activeTabId}
                onClickTab={(newId: string) => setActiveTabId(newId)}
                rightElement={rightElement}
            />
            <TabContent id="tabContent">
                {tabs.map((tab, index) => {
                    return activeTabId === tab.id && React.cloneElement(tab.content, { key: index });
                })}
            </TabContent>
        </div>
    );
}

const TabContent = styled.div`
    border-left: ${theme.cardBorder} solid 1px;
    padding: ${sizes.spacingMd};
    background-color: ${theme.cardBackground};

    @media (max-width: ${breakpoints.md}) {
        border-right: ${theme.cardBorder} solid 1px;
    }
`;
