import styled from 'styled-components';
import { sizes } from '../../theme';

export const MainSection = styled.div`
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding-left: ${sizes.edgePadding};
    padding-right: ${sizes.edgePadding};
    max-width: ${sizes.appMaxWidthWithoutPadding}px;
`;

export const MainSectionWithGaps = styled(MainSection)`
    margin: ${sizes.spacingLg} 0;
    gap: ${sizes.spacingLg};
`;

export const MainSectionWithSmallGaps = styled(MainSection)`
    margin-top: ${sizes.spacingSm};
    gap: ${sizes.spacingSm};
`;

export default MainSection;
