import { useParams } from 'react-router-dom';
import { LearnerCourseContentError } from '../learner/components/shared';
import strings from 'strings/strings.json';
import React, { useContext } from 'react';
import { ActivityPageBase } from '../learner/activity/ActivityPage';
import {
    LearnerCourseSummaryContext,
    LearnerCourseSummaryPreviewProvider,
} from '../../contextProviders/LearnerCourseSummaryProvider';
import Skeleton from 'react-loading-skeleton';
import { ActivityType } from '../../models/Activity';
import { QuestionnairePreviewContentData } from '../learner/activity/components/questionnaire/QuestionnaireContentData';
import { TaskPreviewContentData } from '../learner/activity/components/task/TaskContentData';
import { LiveTrainingPreviewContentData } from '../learner/activity/components/live-training/LiveTrainingContentData';

export default function ActivityPreviewPage(): JSX.Element {
    const params = useParams();
    const { courseId, activityId } = params;

    if (!courseId || !activityId) {
        return (
            <LearnerCourseContentError
                title={strings.modulePage.error.title}
                description={strings.modulePage.error.description}
            />
        );
    }

    return (
        <LearnerCourseSummaryPreviewProvider>
            <ActivityPreviewTypeSelector courseScheduleId={courseId} activityId={activityId} />
        </LearnerCourseSummaryPreviewProvider>
    );
}

function ActivityPreviewTypeSelector({
    courseScheduleId,
    activityId,
}: {
    courseScheduleId: string;
    activityId: string;
}): JSX.Element {
    let activityContent = <></>;

    const { courseSummary, loading, errors } = useContext(LearnerCourseSummaryContext);

    if (loading) {
        return <ActivityPageBase activityContent={<Skeleton width={'75vw'} height={'50vh'} />} />;
    }

    if (errors.length > 0) {
        return (
            <LearnerCourseContentError
                title={strings.activityPage.error.title}
                description={strings.activityPage.error.description}
            />
        );
    }

    if (!courseSummary) {
        return <></>;
    }

    const activity = courseSummary.modules
        .flatMap((module) => module.activities)
        .find((activity) => activity.id === activityId);

    if (!activity) {
        return <></>;
    }

    switch (activity.type) {
        case ActivityType.LIVE_TRAINING:
            return <LiveTrainingPreviewContentData liveTrainingId={activity.id} courseScheduleId={courseScheduleId} />;
        case ActivityType.QUESTIONNAIRE:
            return <QuestionnairePreviewContentData activity={activity} courseScheduleId={courseScheduleId} />;
        case ActivityType.TASK:
            return <TaskPreviewContentData taskId={activity.id} courseScheduleId={courseScheduleId} />;
        default:
            break;
    }

    return <ActivityPageBase activityContent={activityContent} />;
}
