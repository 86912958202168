import { IconSize } from 'assets/icons/icon-sizes';
import styled from 'styled-components';
import { sizes } from 'theme';
import { BodyRegular } from '../../../components/Elements';

export type InformationWithIconComponentProps = {
    icon: JSX.Element;
    title: string;
    value: string;
};

export function InformationWithIconComponent({ icon, title, value }: InformationWithIconComponentProps): JSX.Element {
    return (
        <InformationWithIcon>
            <IconContainer>{icon}</IconContainer>
            <InfoColumn>
                <BodyRegular $bold>{title}</BodyRegular>
                <InfoColumnValue>{value}</InfoColumnValue>
            </InfoColumn>
        </InformationWithIcon>
    );
}

const InformationWithIcon = styled.div`
    display: flex;
    align-items: center;
    gap: ${sizes.spacingMd};
    max-width: 300px;
    min-height: 72px;
`;

const InfoColumnValue = styled(BodyRegular)`
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

const IconContainer = styled.div`
    min-width: ${IconSize.MEDIUM};
`;

const InfoColumn = styled.div`
    display: flex;
    flex-direction: column;
    word-break: break-word;
`;
