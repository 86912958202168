import { AmOrPm } from '../components/forms/EmbeddedTimeInput';

export function convert24hTo12hTime(time: Date): string {
    const date = new Date(time);
    const hours = date.getHours();
    const minutes = date.getMinutes();

    let period = 'am';
    let hours12 = hours;

    if (hours >= 12) {
        period = 'pm';
        hours12 = convert24HourTo12Hour(hours);
    }

    return `${hours12}:${minutes.toString().padStart(2, '0')} ${period}`;
}

export function convert24HourTo12Hour(hours24: number): number {
    return hours24 >= 13 ? hours24 - 12 : hours24;
}

export function get12HourPeriod(hours24: number) {
    return hours24 >= 12 ? AmOrPm.pm : AmOrPm.am;
}

export function getDateFromHourMinutePeriod(hour: number, minute: number, period: AmOrPm): Date {
    if (period === AmOrPm.am) {
        if (hour >= 1 && hour <= 11) {
            //do nothing. //1am -> 1:00 //11am -> 11:00
        } else if (hour === 12)
            hour = 0; //12am -> 0:00
        else throw new Error();
    } else if (period === AmOrPm.pm) {
        if (hour >= 1 && hour <= 11) hour = hour + 12;
        else if (hour === 12) hour = 12; //12pm -> 12:00
    } else throw new Error();

    return new Date(0, 0, 0, hour, minute);
}

export function getFriendlyTimeString(date: Date): string {
    return date
        .toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hourCycle: 'h12' })
        .replaceAll(' ', '');
}

export function getLocaleTimeRangeString(startTime: Date, durationInMins: number): string {
    const meetingStartTimeString = startTime
        .toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hourCycle: 'h12' })
        .replaceAll(' ', '');

    const meetingEndTime = new Date(startTime.getTime() + 60000 * (durationInMins ?? 1));

    const meetingEndTimeString = meetingEndTime
        ? meetingEndTime
              .toLocaleTimeString('en-GB', {
                  hour: '2-digit',
                  minute: '2-digit',
                  hourCycle: 'h12',
              })
              .replaceAll(' ', '')
        : '';

    return meetingStartTimeString + ' - ' + meetingEndTimeString;
}
