import * as React from 'react';
import styled from 'styled-components';
import { IconLinkButtonText } from '../Elements';
import { Link } from 'react-router-dom';
import { sizes, theme } from 'theme';
import NextIcon from 'assets/icons/controls/NextIcon';
import { IconSize } from 'assets/icons/icon-sizes';

type NavigateWithArrowProps = {
    title?: string;
    href: string;
    inverted?: boolean;
    aria: string;
    iconSize?: IconSize;
};

export default function NavigateWithArrow({ title, href, aria, iconSize }: NavigateWithArrowProps): JSX.Element {
    return (
        <NavigateWithArrowContainer to={href} id="courseDetailsEdit" aria-label={aria}>
            {title && (
                <>
                    <IconLinkButtonText>{title}</IconLinkButtonText>
                </>
            )}
            <NextIcon size={iconSize ?? IconSize.MEDIUM} />
        </NavigateWithArrowContainer>
    );
}

const NavigateWithArrowContainer = styled(Link)`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    gap: ${sizes.spacingSm};
    &:focus {
        background-color: ${theme.linkFocusBackgroundColour};
        border: 1px solid ${theme.linkFocusBorderColour};
        outline: ${theme.linkFocusBorderColour};
        border-radius: 4px;
    }
    &:hover {
        background-color: ${theme.linkHoverBackgroundColour};
        border-radius: 4px;
    }
`;
