import * as React from 'react';
import styled from 'styled-components';
import strings from '../../../strings/strings.json';
import PageFooter from '../../../components/structure/PageFooter';
import { Course } from '../../../models/Course';
import { sizes } from '../../../theme';
import { useParams } from 'react-router-dom';
import useGetRequest from '../../../hooks/useGetRequest';
import SubpageHeader from '../../../components/sections/SubpageHeader';
import MainSection from '../../../components/sections/MainSection';
import { CourseDetailsForm } from './components/CourseDetailsForm';

export default function CourseTitleAndDescriptionPage(): JSX.Element {
    const params = useParams();
    const { courseId } = params;
    const { data: selectedCourse, loading } = useGetRequest<Course>(`courses/${courseId}`, !courseId);

    const backButtonDetails = React.useMemo(() => {
        return {
            title: selectedCourse?.title ?? '',
            aria: strings.courseTitleAndDescriptionPage.backButtonAria,
            href: `/trainer/course/${selectedCourse?.id}`,
        };
    }, [selectedCourse]);

    if (loading) return <p>loading...</p>;
    if (!selectedCourse) return <div></div>;

    return (
        <>
            <SubpageHeader
                title={strings.courseTitleAndDescriptionPage.headerTitle}
                backButtonDetails={backButtonDetails}
                pageOverlap
                shadedBackground
            />
            <MainSection>
                <Container>
                    <CourseTitleAndDescriptionPageContent>
                        <CourseDetailsForm course={selectedCourse} />
                    </CourseTitleAndDescriptionPageContent>
                    <PageFooter
                        title={strings.courseTitleAndDescriptionPage.footerActionTitle}
                        aria={strings.courseTitleAndDescriptionPage.footerActionTitleAria}
                        href={`/trainer/course/${courseId}/learning-objectives`}
                    />
                </Container>
            </MainSection>
        </>
    );
}

const Container = styled.div`
    max-width: 800px;
`;

const CourseTitleAndDescriptionPageContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: ${sizes.spacingMd};
`;
