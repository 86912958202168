import { theme } from '../../../theme';
import { IconSize } from '../icon-sizes';

export default function NextIcon({
    colour = theme.primaryBrandColour,
    size = IconSize.MEDIUM,
}: {
    colour?: string;
    size?: IconSize;
}): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.9998 32C25.8363 32 32.9998 24.8366 32.9998 16C32.9998 7.16344 25.8363 0 16.9998 0C8.6442 0 1.78456 6.40484 1.06256 14.5728H16.7299L12.9647 10.9857C12.6585 10.6951 12.6523 10.2108 12.9522 9.91396L13.6459 9.22029C13.9396 8.92657 14.4146 8.92657 14.7052 9.22029L20.7795 15.2915C21.0732 15.5852 21.0732 16.0602 20.7795 16.3507L14.7052 22.4251C14.4115 22.7188 13.9365 22.7188 13.6459 22.4251L12.9522 21.7314C12.6554 21.4346 12.6616 20.9502 12.9647 20.6596L16.7299 17.0725H1.03516C1.58692 25.409 8.52364 32 16.9998 32Z"
                fill={colour}
            />
        </svg>
    );
}
